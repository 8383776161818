import React from "react";

interface AlertProps {
  onClick?: Function;
  message: string | React.ReactNode;
  style: "danger" | "info" | "warning";
  actionMessage?: string;
  actionOnClick?: Function;
}

export const somethingWentWrong = `Something went wrong, check your connection and try again.`;

const Alert: React.FC<AlertProps> = ({
  message,
  style,
  onClick,
  actionMessage,
  actionOnClick,
}) => {
  const hasAction = (): boolean => !!actionMessage && !!actionOnClick;

  return (
    <section
      onClick={(): void => onClick?.()}
      className={`no-animation ${onClick ? `clickable` : ``}`}
    >
      <div className={`alert alert-${style}`} role="alert">
        <div style={{ display: `flex`, flexDirection: `row` }}>
          <div
            style={{
              flex: 3,
              alignSelf: `center`,
              fontSize: 14,
              textAlign: hasAction() ? `left` : `center`,
            }}
          >
            {message}
          </div>
          {hasAction() && (
            <div
              className="btn"
              style={{ flex: 1, border: `1px solid white` }}
              onClick={() => actionOnClick?.()}
            >
              {actionMessage}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Alert;
