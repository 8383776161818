import React, { useContext, useMemo } from "react";
import { Rating } from "react-simple-star-rating";
import { useMutation, useQuery } from "@apollo/client";
import {
  DemoGameRating,
  demoGameRatingsMutation,
  demoGameRatingsQuery,
} from "../../dtos/demo-games.dto";
import { AuthContext } from "../../context/auth.context";
import { first } from "lodash";
import { useLoginModal } from "../modals/login-modal.context";

interface Props {
  gameId: string;
}

export const DemoGamesRating = ({ gameId }: Props) => {
  const { userId } = useContext(AuthContext);
  const { openLoginModal } = useLoginModal();

  const { data } = useQuery<{
    ratings: DemoGameRating[];
  }>(demoGameRatingsQuery, {
    variables: { gameId, userId },
    skip: !userId,
  });

  const [mutate] = useMutation(demoGameRatingsMutation);

  const { value } = first(data?.ratings) || {};

  const rating = useMemo(
    () => (
      <Rating
        onClick={(value) =>
          mutate({
            variables: {
              gameId,
              value,
            },
          })
        }
        ratingValue={value || 0}
        size={30}
        fillColor="#008ca5"
        emptyColor="#bdbdbd"
      />
    ),
    [value]
  );

  return (
    <div className="rating">
      <p>Rate this slot</p>

      {userId ? (
        rating
      ) : (
        <button className="btn btn--small" onClick={() => openLoginModal()}>
          Login or join now
        </button>
      )}
    </div>
  );
};
