import React, {useContext} from "react";
import {Option} from "./option";
import {AuthContext} from "../../../context/auth.context";
import {gql, useQuery} from "@apollo/client";
import {CreditableDto, CreditableType, Dto} from "../../../dtos/partnership.dto";
import Loading from "../../loading";

const TypeFields = `
  enabled
  balance
`

const Query = gql`
    query partnerShip($id: ID!) {
        partnership(id: $id) {
            id
            regularPost { ${TypeFields} }
            pinnedPost { ${TypeFields} }
            banner { ${TypeFields} }
            topBanner { ${TypeFields} }
        }
    }
`;

interface OptionDto {
    data?: CreditableDto;
    title: string;
    description: string;
    requiresApproval?: boolean;
    requiresScheduling?: boolean;
    onClick: () => void;
}

interface Properties {
    requestMode: (type: CreditableType) => void;
}

export const Options: React.FC<Properties> = ({ requestMode }) => {
    const { user } = useContext(AuthContext);

    const { data, loading, error } = useQuery<{ partnership: Dto }>(Query, {
        variables: { id: user?.partnership?.id },
        skip: !user?.partnership?.id,
        pollInterval: 5000
    });

    const getData = (type: CreditableType) => data?.partnership?.[type.toString()]

    const options: OptionDto[] = [
        {
            data: getData(CreditableType.REGULAR_POST),
            title: "Regular post",
            description: "This option consists of a regular post on the CasinoRing-timeline.",
            requiresApproval: true,
            onClick: () => { requestMode(CreditableType.REGULAR_POST) }
        },
        {
            data: getData(CreditableType.PINNED_POST),
            title: "Pinned post",
            description: "This option works the same as a regular post, only now its pinned on top of the timeline. This way every visitor will see your post first.",
            requiresApproval: true,
            requiresScheduling: true,
            onClick: () => { requestMode(CreditableType.PINNED_POST) }
        },
        {
            data: getData(CreditableType.BANNER),
            title: "Banner",
            description: "Shows a banner image from you in between posts. A hyperlink can also be added.",
            requiresApproval: true,
            onClick: () => { requestMode(CreditableType.BANNER) }
        },
        {
            data: getData(CreditableType.TOP_BANNER),
            title: "Top banner",
            description: "Displays a larger banner in the main slider of the front page. Just like the regular banner, you can add a hyperlink.",
            requiresApproval: true,
            onClick: () => { requestMode(CreditableType.TOP_BANNER) }
        }
    ]

    const hasNoValidOptions = () => options.filter(option => option.data?.enabled).length === 0;

    return (
        <>
            {loading && <Loading />}
            {
                error &&
                <div className="modal-info">
                    <h2>Error fetching partnership</h2>
                    <p>Could not determine partnership setup. Please try again or contact CasinoRing when the issue persists.</p>
                </div>
            }
            {
                hasNoValidOptions() &&
                <div className="modal-info">
                    <h2>Partnership not configured</h2>
                    <p>No partnership has been setup yet. Please contact CasinoRing when this issue persists.</p>
                </div>
            }
            {options.map((option) => (
                option.data?.enabled &&
                <Option
                    title={option.title}
                    description={option.description}
                    credits={option.data?.balance}
                    requiresApproval={option.requiresApproval}
                    requiresScheduling={option.requiresScheduling}
                    onClick={option.onClick}
                />
            ))}
        </>
    )
};
