import React, { useEffect } from "react";

const isBrowser = () => typeof window !== "undefined";

const WRAPPER_ID = "cookiescript-declaration-script";

const SingleContentCookieScriptDeclaration: React.FC = () => {
  useEffect(() => {
    // Inject Cookiescript declaration
    if (isBrowser()) {
      const e = document.createElement("script");
      e.src =
        "//report.cookie-script.com/r/e0cf582af55dd0539bf91bdea50cecff.js";
      e.type = "text/javascript";
      e.charset = "UTF-8";
      e.addEventListener('load', () => {
        window?.dispatchEvent(new Event("CookieScriptLoaded"));
      });

      const wrapper = document.getElementById(
        WRAPPER_ID
      );

      if (wrapper) {
        wrapper.appendChild(e);
      }
    }
  }, []);

  return (<>
    <div data-cookiescriptreport="report" />
    <div id={WRAPPER_ID} />
  </>);
};

export default SingleContentCookieScriptDeclaration;
