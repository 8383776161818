import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Alert, { somethingWentWrong } from "../components/alert";
import Loading from "../components/loading";
import { SeoContext } from "../context/seo.context";
import { PrizeWinnerDto, Status } from "../dtos/prize-winner.dto";
import WysiwygContent from "../components/wysiwyg-content";
import { useForm } from "react-hook-form";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";
import { mediaFields } from "../dtos/media.dto";
import ErrorPage from "./error";

interface Props {
  path: string;
  id?: string;
}

interface FormField {
  key: string;
  name: string;
  hint: string;
}

const query = gql`
  query prizeWinner($id: ID!) {
    prizeWinner(id: $id) {
      id
      giveaway {
        winnerTitle
        winnerDescription
        winnerAddress
      }
      prize_drop {
        winnerPageTitle
        winnerPageImage {
          ${mediaFields}
        }
        winnerPageDescription
      }
      status
      name
      address
      postalCode
      city
      state
      country
    }
  }
`;

const usePageContent = (data?: PrizeWinnerDto) =>
  useMemo(() => {
    if (data?.giveaway)
      return {
        title: data.giveaway.winnerTitle,
        description: data.giveaway.winnerDescription,
      };

    if (data?.prize_drop) {
      const image = data.prize_drop.winnerPageImage
        ? `<p><img src="${data.prize_drop.winnerPageImage?.url}"/></p>`
        : "";

      return {
        title: data.prize_drop.winnerPageTitle,
        description: `${image}${data.prize_drop.winnerPageDescription}`,
      };
    }

    return {
      title: "",
      description: "",
    };
  }, [data]);

const PrizeWinnerPage: React.FC<Props> = ({ id }) => {
  const { setData } = useContext(SeoContext);
  const [openSnackbar] = useSnackbar(snackbarConfig);

  const [mutable, setMutable] = useState<PrizeWinnerDto>({});

  const { data, loading, error } = useQuery<{
    prizeWinner: PrizeWinnerDto;
  }>(query, { variables: { id }, skip: !id });

  const { title, description } = usePageContent(data?.prizeWinner);

  useEffect(() => {
    if (data?.prizeWinner) setMutable(data.prizeWinner);
  }, [data?.prizeWinner]);

  const [update] = useMutation(gql`
    mutation updatePrizeWinner($input: updatePrizeWinnerInput!) {
      updatePrizeWinner(input: $input) {
        prizeWinner {
          status
        }
      }
    }
  `);

  const { handleSubmit, register, errors } = useForm();

  const formFields: FormField[] = [
    {
      key: "name",
      name: "Full name",
      hint: "The first, middle and last name of the recipient",
    },
    {
      key: "address",
      name: "Address",
      hint: "Address line containing the full address",
    },
    {
      key: "postalCode",
      name: "Postal code",
      hint: "Postal code that corresponds to the given address ",
    },
    {
      key: "city",
      name: "City",
      hint: "City or town of residence",
    },
    {
      key: "state",
      name: "State",
      hint: "State, province or region of given city",
    },
    {
      key: "country",
      name: "Country",
      hint: "Country of residence",
    },
  ];

  const onSubmit = async ({
    name,
    address,
    postalCode,
    city,
    state,
    country,
  }): Promise<void> => {
    // Save submitted data through mutation
    const result = await update({
      variables: {
        input: {
          where: { id: mutable?.id },
          data: {
            name,
            address,
            postalCode,
            city,
            state,
            country,
            status: Status.DETAILS,
          },
        },
      },
    });

    // Provide visual feedback
    openSnackbar(
      result
        ? "Submitted your details"
        : "Error saving details, please try again"
    );

    // Update status
    if (result?.data) {
      setMutable({
        ...mutable,
        status: result?.data?.updateGiveawayWinner?.giveawayWinner?.status,
      });
    }
  };

  const renderForm = () => (
    <section className="form-container">
      <h3>Supply your details</h3>
      <p>
        Please supply your details below. The won items will be send to this
        address.
      </p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {formFields.map((field) => (
          <div key={field.key} className="input-text__group">
            <input
              type="text"
              id={field.key}
              name={field.key}
              placeholder={field.name}
              value={(mutable as any)[field.key]}
              onChange={(event): void =>
                setMutable({
                  ...mutable,
                  [field.key]: event.target.value,
                })
              }
              className={errors[field.key] && "validation-error"}
              ref={register({
                required: "Required",
                minLength: { value: 2, message: "Min 2 characters" },
                maxLength: { value: 150, message: "Max 150 characters" },
              })}
            />
            <label htmlFor={field.key}>{field.name}</label>
            {errors[field.key] && (
              <p className="validation-error-message">
                {errors[field.key].message}
              </p>
            )}
            {field.hint && (
              <small id={`${field.key}-help`} className="hint">
                {field.hint}
              </small>
            )}
          </div>
        ))}

        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </section>
  );

  const renderSubmission = () => (
    <section className="form-container">
      <h3>Details submitted</h3>
      <p>
        Thanks for submitting your details. We have received your details for
        this prize win. We will send the won prize to the provided address.
      </p>
    </section>
  );

  useEffect(() => {
    setData({
      title,
      description,
    })
  }, [title, description]);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  if (!mutable?.giveaway?.winnerTitle && !mutable?.prize_drop?.id)
    return <ErrorPage />;

  return (
    <>
      <section className="page-content page-content-single">
        <h1 className="page-content__extratitle">{title}</h1>
        <div className="page-content__text">
          <WysiwygContent html={description} />
        </div>
        {
          (!!mutable?.giveaway?.winnerAddress || !!mutable?.prize_drop?.id) &&
          (mutable?.status === Status.CREATED ? renderForm() : renderSubmission())
        }
      </section>
    </>
  );
};

export default PrizeWinnerPage;
