import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { SortableCasinosDto } from "../dtos/casino.dto";
import { mediaFields } from "../dtos/media.dto";
import Loading from "./loading";
import MediaImage from "./media-image";
import classNames from "classnames";
import { useContext } from "react";
import { LocationContext } from "../context/location.context";
import { LocalisationFlag } from "./localisation-flag";
import { SideBanner } from "./side-banner";
import { isHome } from "../utils/location";
import { SidebarMenu } from "./sidebar-menu";
import { LayoutContext } from "../context/layout.context";
import { SortableStreamersLobbyOffersDto } from "../dtos/streamerslobby.dto";
import { streamersLobbyOffersQuery } from "../pages/streamerslobby";
import WysiwygContent from "./wysiwyg-content";

export interface SidebarProps {
  collapsed?: boolean;
  topChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  topChildren,
  bottomChildren,
}) => {
  const location = useContext(LocationContext);
  const { props: { streamersLobbyOffers  } } = useContext(LayoutContext);

  const { data, loading } = useQuery<{ sortedCasino: SortableCasinosDto }>(gql`
    query SidebarQuery {
      sortedCasino {
        SortedCasinos {
          casino {
            id
            affiliate {
                slug
            }
            bonusOffer
            logo {
              ${mediaFields}
            }
            hideFromHomepage
          }
        }
      }
    }
  `, { skip: !!streamersLobbyOffers });

  const getBonusOffers = () => {
    if (!data?.sortedCasino?.SortedCasinos) return [];
    return data.sortedCasino.SortedCasinos.filter(({ casino }) => {
      return casino.affiliate?.slug && casino.bonusOffer && !casino.hideFromHomepage;
    });
  };

  const { loading: streamersLobbyLoading, data: streamersLobbyData } = useQuery<{ sortedStreamersLobbyOffer: SortableStreamersLobbyOffersDto }>(
    streamersLobbyOffersQuery,
    { skip: !streamersLobbyOffers }
  );

  return (
    <>
      <div
        className={classNames(
          "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
          "sidebar",
          { "sidebar-collapsed": collapsed }
        )}
      >
        <aside>
          {topChildren}
          {
            !streamersLobbyOffers &&
            <div className="casino">
              <h4 className="casino__title">
                BEST BONUS OFFERS
                {location.data && (
                  <LocalisationFlag data={location.data.geoContentLocation} />
                )}
              </h4>
              {loading && <Loading />}

              <div className="casino__list">
                {getBonusOffers().length == 0 && (
                  <p className="casino__list-item-notice">
                    There are no bonus offers available yet for your location
                  </p>
                )}

                {getBonusOffers().map(({ casino }) => (
                  <a
                    key={casino.id}
                    href={casino.affiliate?.slug}
                    className="casino__list-item"
                  >
                    <div className="casino__list-item__image">
                      <MediaImage src={casino.logo} format="thumbnail" />
                    </div>
                    <div className="casino__list-item__content">
                      <p className="casino__list-item__title">
                        {casino.bonusOffer}
                      </p>
                      <div className="casino__list-item__play">Play now</div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          }

          {
            streamersLobbyOffers &&
            <div className="casino">
              <h4 className="casino__title">
                ALL OFFERS
              </h4>
              {streamersLobbyLoading && <Loading />}

              <div className="casino__list">
                {streamersLobbyData?.sortedStreamersLobbyOffer?.SortedStreamersLobbyOffers.map(({ streamers_lobby_offer: offer }) => (
                  <a
                    key={offer.id}
                    className="casino__list-item"
                    href={offer.url}
                    target="_blank"
                  >
                    {offer?.logo && <div className="casino__list-item__image">
                      <MediaImage src={offer.logo} format="thumbnail" />
                    </div>}
                    <div className="casino__list-item__content">
                      <p className="casino__list-item__title">
                        <WysiwygContent html={offer.description} />
                      </p>
                      <div className="casino__list-item__play">Play now</div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          }

          {bottomChildren}

          {isHome() ? <SideBanner /> : <SidebarMenu />}
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
