import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import {Options} from "./marketing-modal/options";
import {CreditableMutationTypes, CreditableType} from "../../dtos/partnership.dto";
import {Input} from "./marketing-modal/input";
import classNames from "classnames";
import {Quotation} from "./marketing-modal/quotation";
import {RequestInformation} from "./marketing-modal/request-information";
import axios from "axios";

interface Properties {
    show: boolean
    onHide: () => void
}

enum Mode {
    DEFAULT,
    REGULAR_POST,
    PINNED_POST,
    BANNER,
    TOP_BANNER,
    REQUESTED
}

export const MarketingModal: React.FC<Properties> = ({ show, onHide }) => {
    const [mode, setMode] = useState(Mode.DEFAULT);
    const [body, setBody] = useState<JSX.Element>();
    const [loading, setLoading] = useState<boolean>(false);
    const cancelTokenSource = useMemo(() => axios.CancelToken.source(), []);

    const requestMode = (type: CreditableType) => setMode(Mode[CreditableType[type]]);
    const setRequested = () => setMode(Mode.REQUESTED);

    const getTitle = () => {
        switch(mode) {
            case Mode.REGULAR_POST: return 'Request regular post'
            case Mode.PINNED_POST: return 'Request pinned post'
            case Mode.BANNER: return 'Request banner'
            case Mode.TOP_BANNER: return 'Request top banner'
            case Mode.REQUESTED: return 'Request submitted'
            default: return 'Manage your account'
        }
    }

    useEffect(() => {
        switch(mode) {
            case Mode.REGULAR_POST: {
                setBody(
                    <Input
                        type={CreditableMutationTypes.REGULAR_POST}
                        requested={setRequested}
                        cancelToken={cancelTokenSource.token}
                        setLoading={setLoading}
                        requiresApproval={true}
                    />)
                break;
            }
            case Mode.PINNED_POST: {
                setBody(
                    <Input
                        type={CreditableMutationTypes.PINNED_POST}
                        requested={setRequested}
                        cancelToken={cancelTokenSource.token}
                        setLoading={setLoading}
                        requiresApproval={true}
                    />)
                break;
            }
            case Mode.BANNER: {
                setBody(
                    <Input
                        type={CreditableMutationTypes.BANNER}
                        requested={setRequested}
                        cancelToken={cancelTokenSource.token}
                        setLoading={setLoading}
                        requiresApproval={true}
                    />)
                break;
            }
            case Mode.TOP_BANNER: {
                setBody(
                    <Input
                        type={CreditableMutationTypes.TOP_BANNER}
                        requested={setRequested}
                        cancelToken={cancelTokenSource.token}
                        setLoading={setLoading}
                        requiresApproval={true}
                    />)
                break;
            }
            case Mode.REQUESTED: {
                setBody(<RequestInformation />)
                break;
            }
            default: {
                setBody(<Options requestMode={requestMode} />);
                break;
            }
        }
    }, [mode]);

    useEffect(() => {
        if (!show) return;
        setMode(Mode.DEFAULT);
    }, [show])

    const dismiss = () => {
        if (!loading || mode === Mode.REQUESTED) return onHide();

        if (window.confirm('Are you sure you want to cancel your upload?')){
            cancelTokenSource.cancel();
            onHide();
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            dialogClassName="modal-dialog-centered"
        >
            <div className="modal-header">
                <h5 className="modal-title">{getTitle()}</h5>
                <button
                    type="button"
                    className="modal-close"
                    onClick={dismiss}
                >
                    {mode === Mode.REQUESTED ? 'close' : 'cancel'} <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div
                className={
                    classNames({ 'modal-marketing-body': true,
                        'modal-marketing-body__tiled': mode === Mode.DEFAULT
                    })
                }
            >
                {body}
            </div>

            {mode === Mode.DEFAULT && <Quotation onHide={onHide} />}
        </Modal>
    );
};
