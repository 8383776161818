import React, { useRef } from "react";
import { VideoDto } from "../dtos/video.dto";
import { HTMLStreamElement, Stream } from "@cloudflare/stream-react";
import { useVideoTracking } from "../utils/matomo";

interface VideoProps {
  url: string;
  data: VideoDto;
  autoPlay?: boolean;
}

const Video: React.FC<VideoProps> = ({ url, data, autoPlay = true }) => {
  const ref = useRef<HTMLStreamElement>(null);
  const { setMetaData, onPlay, onPause, onSeek, onRateChange } =
    useVideoTracking(url);

  const onLoadedMetaData = () => {
    if (!ref.current) return;

    ref.current.onfullscreenchange = (event) => {
      const element = event.currentTarget as HTMLElement;

      setMetaData({
        fullscreen: !!document.fullscreenElement,
        dimensions: {
          width: element.offsetWidth,
          height: element.offsetHeight,
        },
      });
    };

    setMetaData({
      title: data.providerId,
      duration: ref.current.duration,
      dimensions: {
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      },
    });
  };

  return (
    <div className="video">
      <Stream
        width="100%"
        height="100%"
        autoplay={autoPlay}
        controls
        streamRef={ref}
        onLoadedMetaData={onLoadedMetaData}
        onPlay={onPlay}
        onRateChange={() => {
          if (ref.current) onRateChange(ref.current.playbackRate);
        }}
        onPause={onPause}
        onSeeked={() => {
          if (ref.current) onSeek(ref.current.currentTime);
        }}
        src={data.providerId}
      />
    </div>
  );
};

export default Video;
