import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { AuthContext } from "../../context/auth.context";
import { gql, useMutation, useQuery } from "@apollo/client";
import { updateUserMutation } from "../../dtos/user.dto";
import { useOpenSnackbar } from "../../utils/snackbar";

interface Properties {
  profileId: string;
}

const Query = gql`
  query LiveNotification($userId: ID!) {
    user(id: $userId) {
      notifyLiveStream {
        id
      }
    }
  }
`;

interface Response {
  user: {
    notifyLiveStream: { id: string }[];
  };
}

const LiveNotificationToggle: React.FC<Properties> = ({ profileId }) => {
  const { userId } = useContext(AuthContext);
  const openSnackbar = useOpenSnackbar();

  const { error, loading, data, refetch } = useQuery<Response>(Query, {
    skip: !userId,
    variables: { userId },
  });

  const [mutate] = useMutation(updateUserMutation);

  const [checked, setChecked] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [animateInitial, setAnimateInitial] = useState(true);

  const toggle = async () => {
    if (loading) return;

    let users = [
      ...(data?.user?.notifyLiveStream?.length
        ? data.user.notifyLiveStream.map((profile) => profile.id)
        : []),
      profileId,
    ];

    if (checked) {
      users = users.filter((profile) => profile !== profileId);
    }

    setChecked(!checked);
    openSnackbar({ content: "Updated notification preference" });

    await mutate({
      variables: {
        input: {
          where: { id: userId },
          data: {
            notifyLiveStream: [...new Set(users)],
          },
        },
      },
    });

    await refetch();
  };

  const shouldBeChecked = () => {
    if (!data?.user?.notifyLiveStream?.length) return false;
    return (
      data.user.notifyLiveStream.filter((profile) => profile.id === profileId)
        .length > 0
    );
  };

  const onAnimationEnd = () => {
    setAnimate(false);
    setAnimateInitial(false);
  };

  useEffect(() => {
    setChecked(shouldBeChecked());
  }, [data]);

  if (error) return null;
  if (loading) return null;

  return (
    <button
      className={classNames("btn", "btn-notification", {
        "btn-notification_animated": animate,
        "btn-notification_animated-initial": animateInitial,
      })}
      onClick={() => setAnimate(true)}
      onAnimationEnd={onAnimationEnd}
    >
      <i
        className={classNames({
          "icon-notification": !checked,
          "icon-notification-ring": checked,
        })}
        onClick={toggle}
      />
    </button>
  );
};

export default LiveNotificationToggle;
