import { gql, useMutation } from "@apollo/client";
import { PerformPrizeDropResult } from "../../../dtos/prize-drop.dto";

const MUTATION_PERFORM_PRIZE_DROP = gql`
  mutation PerformPrizeDrop($id: ID!) {
    performPrizeDrop(prizeDropId: $id)
  }
`;

export const usePerformPrizeDropMutation = () => {
  const [mutate, { loading }] = useMutation<{
    performPrizeDrop: PerformPrizeDropResult;
  }>(MUTATION_PERFORM_PRIZE_DROP);

  return {
    mutate: async (id: string) =>
      mutate({
        variables: { id },
      }),
    loading,
  };
};
