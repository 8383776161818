import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "react-bootstrap";
import { apiBaseUrl } from "../../utils/api";
import { AuthContext, IAuthWithJwtResponse } from "../../context/auth.context";
import { loginMutation } from "../../dtos/user.dto";
import AlertError from "../alert-error";
import { Link } from "gatsby";
import Loading from "../loading";
import { useOnBack } from "../../utils/history";
import { RegistrationContext } from "../../context/registration.context";
import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { LoginOffer } from "./login-modal.context";

export interface LoginModalProps {
  show: boolean;
  onClose: () => void;
  offer?: LoginOffer
}

export const LoginModal: React.FC<LoginModalProps> = ({ show, onClose, offer }) => {
  const { setAuth } = useContext(AuthContext);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [login, { error, loading }] = useMutation<{
    login: IAuthWithJwtResponse;
  }>(loginMutation);
  const { update: registrationUpdate } = useContext(RegistrationContext);
  const [tacAccepted, setTacAccepted] = useState(false)

  const submit = async (event: any) => {
    event.preventDefault();

    const { data } = await login({
      variables: {
        identifier,
        password,
      },
    });

    if (data?.login) {
      setAuth?.(data.login);
      onClose();
    }
  };

  useOnBack(() => onClose());

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onClose}
      dialogClassName={`login-modal modal-dialog-centered ${offer ? "login-offer-modal" : ""}`}
    >
      <div className="modal-header">
        {offer && <img
          style={{
            width: 87.5,
            marginLeft: 37.5,
            marginTop: 25,
            marginBottom: 25
          }}
          className="modal-header__logo"
          src="/images/logo-blue.svg"
        />}

        <button
          type="button"
          className="modal-close"
          aria-label="Close"
          onClick={onClose}
        >
          cancel <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-container">
        {offer && <div className="modal-offer">
          <h1>{offer.title}</h1>
          {offer.description}

          <Link
            to="/register"
            onClick={onClose}
            className="modal-button"
          >
            JOIN NOW!
          </Link>

          <h2>And be part of our community</h2>

          <a className="modal-link" href={offer.url} target="_blank" onClick={onClose}>
            Not interested, take me straight to the deal
          </a>
        </div>}

        {offer && <div className="modal-divider"></div>}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          {!offer && <img
            style={{
              width: 92,
              margin: "auto",
              marginBottom: 32,
            }}
            className="modal-header__logo"
            src="/images/logo-blue.svg"
          />}
          <div
            className="modal-body"
            style={{
              margin: "auto",
              minWidth: "90%",
              padding: "30px 45px"
            }}
          >
            <form className="login__form" onSubmit={(event) => submit(event)}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="input-text__group" style={{ flex: 1 }}>
                  <input
                    type="text"
                    style={{ maxWidth: "100%" }}
                    id="email-modal"
                    className="form-control"
                    placeholder="E-mail"
                    autoComplete="email"
                    onChange={(event) => setIdentifier(event.target.value)}
                  />
                  <label htmlFor="email-modal">E-mail</label>
                </div>
                <div className="input-text__group">
                  <input
                    type="password"
                    id="password-modal"
                    className="form-control"
                    placeholder="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <label htmlFor="password-modal">Password</label>
                </div>

                {error && <AlertError error={error} />}

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Link
                    style={{ flex: 1 }}
                    to="/register"
                    onClick={() => {
                      registrationUpdate({ username: identifier, password: password })
                      onClose()
                    }}
                    className="btn btn--medium btn--input btn--dark"
                  >
                    JOIN NOW
                  </Link>

                  <button
                    style={{ flex: 1, margin: 0 }}
                    type="submit"
                    className="btn btn--medium btn--input"
                    disabled={!(!loading && identifier && password)}
                  >
                    LOGIN
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 16,
                  }}
                >
                  <Link
                    style={{
                      textAlign: "center",
                    }}
                    to="/forgot-password/"
                    onClick={onClose}
                    className="login__extra-link underline"
                  >
                    FORGET PASSWORD?
                  </Link>
                </div>

                <h5 style={{ marginTop: 25 }}>Social login</h5>

                <div className="login-modal-terms input-switch__group">
                  <input
                    type="checkbox"
                    name="terms"
                    id="login-terms"
                    checked={tacAccepted}
                    onChange={({target}) => setTacAccepted(target.checked)}
                  />
                  <label htmlFor="login-terms"  />
                  <p style={{ marginLeft: 15 }}>
                    <b>Terms and conditions</b>
                    <br />I AGREE TO THE CASINORING{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="clickable highlight-text"
                      href={`${window.origin}/terms-and-conditions/`}
                    >
                      TERMS AND CONDITIONS
                    </a>{" "}
                    AND{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="clickable highlight-text"
                      href={`${window.origin}/privacy-statement/`}
                    >
                      PRIVACY STATEMENT
                    </a>
                  </p>
                </div>

                <Tooltip text={!tacAccepted && "Please accept the Term and Conditions above"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <a
                      style={{ flex: 1, height: 42, padding: 0 }}
                      href={`${apiBaseUrl()}/connect/twitch`}
                      className={classNames('btn btn--medium btn--twitch btn--input', { disabled: !tacAccepted })}
                    >
                      <img
                        style={{
                          margin: "auto",
                          height: "100%",
                          padding: 8,
                          width: "auto",
                        }}
                        src="/images/twitch-white.svg"
                      />
                    </a>

                    <a
                      style={{ flex: 1, margin: 0, height: 42, padding: 0 }}
                      href={`${apiBaseUrl()}/connect/google`}
                      className={classNames('btn btn--medium btn--youtube btn--input', { disabled: !tacAccepted })}
                    >
                      <img
                        style={{
                          margin: "auto",
                          height: "100%",
                          padding: 10,
                          width: "auto",
                        }}
                        src="/images/youtube-white.svg"
                      />
                    </a>
                  </div>
                </Tooltip>
              </div>
            </form>

            {loading && <Loading />}
          </div>
        </div>
      </div>
    </Modal>
  );
};
