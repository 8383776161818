import React from "react";
import { UserDto } from "../../dtos/user.dto";
import { isYoutubeChannelId } from "../../utils/youtube";

interface SocialsProps {
  user?: UserDto;
}

const Socials: React.FC<SocialsProps> = ({ user }) => {
  const hasAny = () =>
    !!user?.twitterUsername ||
    !!user?.facebookUsername ||
    !!user?.instagramUsername ||
    !!user?.snapchatUsername ||
    !!user?.twitchUsername ||
    !!user?.youtubeUsername;

  return (
    <>
      {hasAny() && (
        <div className="account-socials">
          <h4 className="account-socials__title">SOCIALS</h4>
          <ul className="account-socials__list">
            {user?.twitchUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://twitch.tv/${user.twitchUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-twitch"></i>
                </a>
              </li>
            )}
            {user?.youtubeUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://youtube.com/${
                    isYoutubeChannelId(user.youtubeUsername)
                      ? "channel"
                      : "c"
                  }/${user.youtubeUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-youtube"></i>
                </a>
              </li>
            )}
            {user?.snapchatUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://snapchat.com/add/${user.snapchatUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-snapchat"></i>
                </a>
              </li>
            )}
            {user?.twitterUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://twitter.com/${user.twitterUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-twitter"></i>
                </a>
              </li>
            )}
            {user?.facebookUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://facebook.com/${user.facebookUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-facebook"></i>
                </a>
              </li>
            )}
            {user?.instagramUsername && (
              <li className="account-socials__list-item">
                <a
                  className="account-socials__list-link"
                  href={`https://instagram.com/${user.instagramUsername}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon-instagram"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Socials;
