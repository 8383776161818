import React, { useContext } from "react";
import Slider from "react-slick";
import { LocationContext } from "../../context/location.context";
import { gql, useQuery } from "@apollo/client";
import { SortableCasinosDto } from "../../dtos/casino.dto";
import { mediaFields } from "../../dtos/media.dto";
import { LocalisationFlag } from "../localisation-flag";
import { DemoGamesOffersPreview } from "./offers/preview";
import { useWindowSize } from "react-use";

export const DemoGamesOffers = () => {
  const location = useContext(LocationContext);
  const { width } = useWindowSize();

  const { data: raw } = useQuery<{ sortedCasino: SortableCasinosDto }>(gql`
    query {
      sortedCasino {
        SortedCasinos {
          casino {
            id
            affiliate {
                slug
            }
            bonusOffer
            logo {
              ${mediaFields}
            }
            hideFromHomepage
          }
        }
      }
    }
  `);

  const data = raw?.sortedCasino?.SortedCasinos?.filter(({ casino }) => {
    return casino.affiliate?.slug && casino.bonusOffer && !casino.hideFromHomepage;
  }) || []

  const getSize = () => {
    if (width <= 576) return 1;
    if (width <= 768) return 2;
    return 3;
  }

  const scroll = Math.floor((data.length || 1) / getSize());

  return data.length ? (
    <div className="offers">
      <h3>
        Want to play for real?
        {location.data && <LocalisationFlag data={location.data.geoContentLocation} />}
      </h3>

      <Slider
        slidesToShow={getSize()}
        slidesToScroll={scroll}
        easing={"ease-in-out"}
        autoplay
        autoplaySpeed={"50"}
        pauseOnHover
        speed={250}
        centerMode
        centerPadding={0}
        infinite={data.length > getSize()}
      >
        {data.map(({ casino }) => <DemoGamesOffersPreview key={casino.id} data={casino} />)}
      </Slider>
    </div>
  ) : null
}