import React, { useContext, useEffect } from "react";
import {
  DocumentNode,
  gql,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import { Link } from "gatsby";
import { RouteProps } from ".";

import { FollowerDto } from "../dtos/follower.dto";
import { userFields, UserDto, userSidebarFields } from "../dtos/user.dto";
import { aggregateFields, AggregateDto } from "../dtos/aggregate.dto";

import Loading from "../components/loading";
import Member from "../components/member";
import Alert, { somethingWentWrong } from "../components/alert";
import Infinity from "../components/infinity";
import ProfileInfoSidebar from "../components/profile/sidebar/info";
import { SeoContext } from "../context/seo.context";
import { LayoutContext } from "../context/layout.context";

export enum FollowersPageMode {
  Following = "Following",
  Followers = "Followers",
}

interface FollowersPageProps extends RouteProps {
  id?: string;
  mode: FollowersPageMode;
}

const FollowersPageQueryPageSize = 9;
const FollowersPageQuery = (mode: FollowersPageMode): DocumentNode => {
  const ofUser = mode == FollowersPageMode.Followers ? "user" : "owner";
  const toUser = mode == FollowersPageMode.Followers ? "owner" : "user";

  return gql`
    query FollowersPageQuery($id: ID!, $start: Int) {
        user(id: $id) {
            ${userSidebarFields}
        }
        followers(where: { ${ofUser}: $id, active_ne: false }, start: $start, limit: ${FollowersPageQueryPageSize}, sort:"createdAt:DESC") {
            id

            # Makes sure it's always owner 
            # to prevent switching in the html afterwards
            owner: ${toUser} {
                ${userFields}
            }
        }
        followerCount: followersConnection(where: { user: $id, active_ne: false }) {
            ${aggregateFields}
        }
        followingCount: followersConnection(where: { owner: $id, active_ne: false }) {
            ${aggregateFields}
        }
    }
`;
};

const FollowersPage: React.FC<FollowersPageProps> = ({ id, mode }) => {
  const layoutContext = useContext(LayoutContext);
  const seoContext = useContext(SeoContext);
  const getQueryVariables = (start?: number): OperationVariables => ({
    id,
    start,
  });

  const [followers, setFollowers] = React.useState<FollowerDto[]>([]);

  const { data, loading, error, fetchMore } = useQuery<{
    user: UserDto;
    followers: FollowerDto[];
    followerCount: AggregateDto;
    followingCount: AggregateDto;
  }>(FollowersPageQuery(mode), {
    skip: !id,
    variables: getQueryVariables(),
    onCompleted: (data) => {
      setFollowers(data.followers || []);
    },
  });

  const loadMore = (): Promise<{}> => {
    return fetchMore({
      variables: getQueryVariables(followers.length),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setFollowers([...followers, ...fetchMoreResult.followers]);

        return prev;
      },
    });
  };

  const count = (): number => {
    return (
      (mode === FollowersPageMode.Followers
        ? data?.followerCount?.aggregate?.count
        : data?.followingCount.aggregate?.count) || 0
    );
  };

  const title = (extend?: string): string =>
    mode === FollowersPageMode.Followers
      ? `Followers of ${data?.user?.profileName} ${extend ? extend : ""}`
      : `${data?.user?.profileName} is following ${extend ? extend : ""}`;

  useEffect(() => {
    seoContext.setData({
      noIndex: true,
    });

    if (data) {
      layoutContext.updateProps({
        additionalSidebar: {
          top: <ProfileInfoSidebar user={data.user} />,
        },
      });

      seoContext.setData({
        title: title(),
        noIndex: true,
      });
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error || data?.user?.blocked)
    return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <section className="header">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flex-align-start">
            <h2>{title(`[${count()}]`)}</h2>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flex-align-end">
            <Link to={`/profile/${id}`} className="header__link">
              BACK TO MEMBER PAGE
            </Link>
          </div>
        </div>
      </section>

      <Infinity
        data={followers}
        pageSize={FollowersPageQueryPageSize}
        fetchMore={(): Promise<{}> => loadMore()}
      >
        <section className="members light-border">
          <div className="members__list row small-gutters">
            {followers.map((follower) => (
              <Member key={follower.id} user={follower.owner} />
            ))}
          </div>
        </section>
      </Infinity>
    </>
  );
};

export default FollowersPage;
