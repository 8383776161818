import { GiveawayWinnerContentDto } from "./giveaway.dto";
import { PrizeDropDto } from "./prize-drop.dto";

export enum Status {
  CREATED = "created",
  DETAILS = "details",
  READY = "ready",
  PREPARED = "prepared",
  SHIPPED = "shipped",
}

export interface PrizeWinnerDto {
  id?: string;
  giveaway?: GiveawayWinnerContentDto;
  prize_drop?: PrizeDropDto;
  status?: Status;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;
}
