import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { useMutation } from "@apollo/client";
import { orderBy, take } from "lodash";
import { AuthContext } from "../../context/auth.context";
import { PermissionContext } from "../../context/permission.context";
import {
  createGiveawayAnswerMutation,
  deleteGiveawayAnswerMutation,
  GiveawayAnswerDto,
} from "../../dtos/giveaway-answer.dto";
import { GiveawayDto, giveawayExpired } from "../../dtos/giveaway.dto";
import Loading from "../loading";
import { useConfirmModal } from "../modals/confirm-modal.context";
import InsufficientCredits from "./insufficient-credits";
import { useLoginModal } from "../modals/login-modal.context";
import { GiveawayRulesModal } from "../modals/giveaway-rules-modal";
import PartnerParticipationNotice from "./partner-participation-notice";
import BeGambleAwareNotice from "./be-gamble-aware-notice";

interface RandomCompetitionProps {
  giveaway: GiveawayDto;
  onUpdate: () => void | Promise<any>;
  creditable?: boolean;
}

const RandomCompetition: React.FC<RandomCompetitionProps> = ({
  giveaway,
  onUpdate,
  creditable,
}) => {
  const authContext = useContext(AuthContext);
  const permissionContext = useContext(PermissionContext);
  const [terms, setTerms] = useState(false);
  const expired = giveawayExpired(giveaway);
  const { openConfirmModal } = useConfirmModal();
  const { openLoginModal } = useLoginModal();
  const [answer, setAnswer] = useState<string>();
  const [rulesOpen, setRulesOpen] = useState<boolean>(false);

  const [createGiveawayAnswer, { loading: savingAnswer }] = useMutation(
    createGiveawayAnswerMutation
  );

  const [deleteGiveawayAnswer] = useMutation(deleteGiveawayAnswerMutation);

  const participated = () => {
    return !!giveaway?.giveaway_answers?.find(
      (answer) => answer.owner?.id === authContext.userId
    );
  };

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();

    const response = await createGiveawayAnswer({
      variables: {
        input: {
          owner: authContext.userId,
          giveaway: giveaway.id,
          answers: answer || ""
        },
      },
    });

    if (response) {
      await onUpdate();
    }
  };

  const performDelete = async (answer: GiveawayAnswerDto) => {
    const { data } = await deleteGiveawayAnswer({
      variables: { id: answer.id },
    });

    if (data) {
      await onUpdate();
    }
  };

  const winners = () => {
    const result = giveaway.winnerSeed || 0;
    const ordered = orderBy(giveaway.giveaway_answers, (d) =>
      Math.abs(result - (d.winnerSeed || 0))
    );

    return take(ordered, giveaway.winnerAmount);
  };

  const renderGuessingForm = () => {
    const isSubmitDisabled = savingAnswer || !terms || (!!giveaway.dataRequest && !answer);

    return (
      <>
        <GiveawayRulesModal
          show={rulesOpen}
          onClose={() => setRulesOpen(false)}
          rules={giveaway.rules}
        />

        <h4 style={{ color: "#008ca5" }}>Participate</h4>
        {giveaway.entryCost && (
          <p>Entry cost are {giveaway.entryCost} CasinoRing-points</p>
        )}

        <form
          className="page-content__form form-inline"
          onSubmit={(event) => submit(event)}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
          {
            !!giveaway.dataRequest &&
            <div className="input-text__group" style={{ marginBottom: 15 }}>
              <input
                id="answer"
                type="text"
                name="answer"
                placeholder="Your answer"
                onChange={(e): void => setAnswer(e.target.value)}
              />
              <label htmlFor="answer">
                {giveaway.dataRequest}
              </label>
            </div>
          }

          <div className="input-switch__group" style={{ display: 'flex', width: "100%" }}>
            <input
              type="checkbox"
              name="terms"
              id="terms"
              value="terms"
              checked={terms}
              onChange={(event) => setTerms(event.target.checked)}
            />
            <label htmlFor="terms"></label>
            <p onClick={() => setRulesOpen(true)}>
              <strong>Rules</strong>
              <br />I HAVE READ AND ACCEPT THE{" "}
              <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                RULES &amp; CONDITIONS
              </a>{" "}
              OF THE GIVEAWAY
            </p>
          </div>
          </div>

          <button type="submit" className="btn" disabled={isSubmitDisabled}>
            JOIN GIVEAWAY
          </button>
        </form>

        {savingAnswer && <Loading />}
      </>
    );
  };

  const renderParticipation = () => {
    if (expired) {
      return <i>The giveaway is expired or closed.</i>;
    }

    if (!authContext.userId) {
      return (
        <button className="btn btn--medium" onClick={() => openLoginModal()}>
          Login to participate
        </button>
      );
    }

    if (authContext.isPartner) {
      return <PartnerParticipationNotice />;
    }

    if (participated()) {
      return <i>You already participated.</i>;
    }

    if (!creditable) {
      return <InsufficientCredits requiredCredits={giveaway.entryCost} />;
    }

    return renderGuessingForm();
  };

  const renderWinners = () => (
    <>
      <GiveawayRulesModal
        show={rulesOpen}
        onClose={() => setRulesOpen(false)}
        rules={giveaway.rules}
      />

      <h4 style={{ color: "#008ca5" }}>
        Winners ({giveaway.winnerAmount || 0}){" "}
      </h4>

      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "100%" }}>Name</th>
          </tr>
        </thead>
        <tbody>
          {winners().map((answer, i) => (
            <tr key={answer.id}>
              <td>{i + 1}. </td>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
    </>
  );

  const renderParticipations = () => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Participants ({giveaway?.giveaway_answers?.length})
      </h4>
      <table>
        <thead>
          <tr>
            <th style={{ width: "37.5%" }}>Name</th>
            {!!giveaway.dataRequest && !giveaway.hideDataRequestAnswer && <th style={{ width: "50%" }}>Answer</th>}
            <th style={{ width: "12.5%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {giveaway?.giveaway_answers?.map((answer) => (
            <tr key={answer.id}>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
              {
                !!giveaway.dataRequest && !giveaway.hideDataRequestAnswer &&
                <td>
                  {answer.answers}
                </td>
              }
              <td>
                {!expired &&
                  permissionContext.can(answer, "giveaway-answer.delete") && (
                    <span
                      className="clickable"
                      onClick={() =>
                        openConfirmModal({
                          title:
                            "Are you sure you want to delete this guess?",
                          onClose: (result) =>
                            result && performDelete(answer),
                        })
                      }
                    >
                      Delete
                    </span>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      <BeGambleAwareNotice />
      {renderParticipation()}
      {giveawayExpired(giveaway) && !giveaway.disabledAutomaticWinner && renderWinners()}
      {renderParticipations()}
    </>
  );
};

export default RandomCompetition;
