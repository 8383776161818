import { gql } from "@apollo/client";
import { MediaDto, mediaFields } from "./media.dto";

export enum CasinoFeatureTypeEnum {
  NEW = "new",
  EXCLUSIVE = "exclusive",
}

export interface CasinoFeatureDto {
  type: CasinoFeatureTypeEnum;
}

export interface CasinoCTA {
  id?: string;
  title?: string;
  content?: string;
  button?: string;
  url?: string;
}

export interface CasinoDetailDto {
  id?: string;
  title?: string;
  intro?: string;
  teaser?: MediaDto;
  content?: string;
  structuredData?: any;
  metaTitle?: string;
  metaDescription?: string;
  topCTA?: CasinoCTA;
  bottomCTA?: CasinoCTA;
}

export interface CasinoDto {
  id?: string;
  slug?: string;
  slugNL?: string;
  name?: string;
  affiliate?: {
    slug?: string;
  }
  logo?: MediaDto;
  content?: string;
  contentMoreInfo?: string;
  bonusOffer?: string;
  features?: CasinoFeatureDto[];
  hideFromHomepage?: boolean;
  detail?: CasinoDetailDto;
  detailNL?: CasinoDetailDto;
}

export interface SortedCasinoDto {
  casino: CasinoDto;
}

export interface SortableCasinosDto {
  SortedCasinos: SortedCasinoDto[];
}

export const casinoToSelectItem = (casino?: CasinoDto) =>
  casino
    ? {
        value: casino.id as string,
        label: casino.name as string,
      }
    : undefined;

export const casinoOverviewQuery = gql`
  query {
    sortedCasino {
      SortedCasinos {
        casino {
          id
          slug
          name
          affiliate {
              slug
          }
          content
          contentMoreInfo
          createdAt
          logo {
            ${mediaFields}
          }
          features {
            type
          }
          detail {
            id
          }
        }
      }
    }
  }
`;

export const casinoSelectorQuery = gql`
  query casinoSelectorQuery($input: String!, $limit: Int!) {
    items: casinos(
      where: { name_contains: $input }
      limit: $limit
      sort: "name:ASC"
    ) {
      value: id
      label: name
    }
  }
`;

export const casinoDetailCTAFields = `
  title
  content
  button
  url
`;

export const casinoDetailFields = `
  id
  title
  intro
  teaser {
    ${mediaFields}
  }
  content
  structuredData
  metaTitle
  metaDescription
  topCTA {
    ${casinoDetailCTAFields}
  }
  bottomCTA {
    ${casinoDetailCTAFields}
  }
`

export const casinoDetailQuery = gql`
  query casinoDetailQuery($slug: String!, $locale: String) {
    casinos(
      where: { slug: $slug, locale: $locale }
      limit: 1
    ) {
      slug
      slugNL
      detail {
        ${casinoDetailFields}
      }
      detailNL {
        ${casinoDetailFields}
      }
    }
  }
`;
