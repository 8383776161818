import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import { BaseEmoji, EmojiData, Picker } from "emoji-mart";
import ReactMde from "react-mde";
import Dropzone from "react-dropzone";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../../context/auth.context";
import Loading from "../loading";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../../utils/snackbar";
import {
  createPostMutation,
  PostDto,
  postMultiplier,
  updatePostMutation,
} from "../../dtos/post.dto";
import { Link, navigate } from "gatsby";
import { slotSelectorQuery, slotToSelectItem } from "../../dtos/slot.dto";
import { casinoSelectorQuery, casinoToSelectItem } from "../../dtos/casino.dto";
import { LazySelect, LazySelectItem } from "../lazy-select";
import { isset } from "../../utils/isset";
import { multipleUploadMutation } from "../../dtos/media.dto";
import { balanceMessage } from "../../utils/balance";
import ModalLoader from "../modal-loader";
import PostModalProcessing, {
  PostModalProcessingStatusEnum,
} from "../post-modal-processing";
import CancelIcon from "../cancel-icon";
import axios from "axios";
import { usePostModalContext } from "./post-modal.context";
import { useOnBack } from "../../utils/history";
import { imageMimeTypes, videoMimeTypes } from "../../dtos/mime-types.dto";
import { converter } from "../../utils/showdown";

export enum ModalMode {
  Default,
  Minimal,
}

export enum PostModalMode {
  Post = "post",
  BigWin = "big-win",
  PartnerQuestion = "question",
  PartnerRegularPost = "regular post",
  PartnerPinnedPost = "pinned post",
}

export enum LoadingMode {
  LINEAR,
  PROGRESSIVE,
}

export interface File {
  id: string;
  mime: string;
}

export interface PostModalProps {
  post?: PostDto;
  mode?: PostModalMode;

  title?: string;
  disableHighlights?: boolean;
  disableListing?: boolean;

  onCreated?: (id: string) => void | Promise<void>;
  onUpdated?: (post: PostDto) => void | Promise<void>;
}

export const PostModal: React.FC<PostModalProps> = (props) => {
  const authContext = useContext(AuthContext);
  const linkInputRef = useRef<HTMLInputElement>(null);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Default);
  const { open, hide } = usePostModalContext();

  const [openSnackbar] = useSnackbar(snackbarConfig);

  const composeDefaultValue = () => ({
    content: "",
    owner: {
      id: authContext.userId as string,
    },
    showOnHomepage: true,
  });

  const [mode] = useState(
    props.mode ||
      (props.post
        ? props.post.showOnHomepage
          ? PostModalMode.Post
          : PostModalMode.BigWin
        : PostModalMode.Post)
  );

  const composeLink = (id: string) => {
    return `${window.location.origin}/post/${id}`;
  };

  const [link, setLink] = useState<string | undefined>(
    props.post?.id ? composeLink(props.post.id as string) : undefined
  );

  const [post, setPost] = useState<PostDto>(
    props.post || composeDefaultValue()
  );

  const [files, setFiles] = useState<any[]>([]);

  const [selectedCasino, setSelectedCasino] = useState<
    LazySelectItem | undefined
  >(casinoToSelectItem(post?.casino));

  const [selectedSlot, setSelectedSlot] = useState<LazySelectItem | undefined>(
    slotToSelectItem(post?.slot)
  );

  const toolbarMde = [["bold", "italic", "strikethrough"]];
  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write"
  );

  const [emoji, setEmoji] = useState(false);
  const selectEmoji = (emoji: EmojiData) => {
    setPost({
      ...post,
      content: `${post.content || ""}${(emoji as BaseEmoji).native}`,
    });
  };

  const [createPost, { loading: loadingCreate }] =
    useMutation(createPostMutation);

  const [updatePost, { loading: loadingUpdate }] =
    useMutation(updatePostMutation);

  const [multipleUploadProgress, setMultipleUploadProgress] =
    useState<number>();
  const cancelTokenSource = useMemo(() => axios.CancelToken.source(), []);

  const [multipleUpload, { loading: loadingUpload, error: errorUpload }] =
    useMutation(multipleUploadMutation, {
      context: {
        fetchOptions: {
          cancelToken: cancelTokenSource.token,
          onUploadProgress: (progress: ProgressEvent) => {
            setMultipleUploadProgress(
              Math.round((progress.loaded * 100) / progress.total)
            );
          },
        },
      },
    });

  const canSubmitMultiplier = () => {
    if (isset(post.win) || isset(post.bet)) {
      return (post.win || 0) > 0 && (post.bet || 0) > 0;
    }

    return true;
  };

  const hasCorrectMultiplier = () => {
    if (isset(post.win) || isset(post.bet)) {
      return (post.win || 0) > 0 && (post.bet || 0) > 0;
    }

    return false;
  };

  const showLink = () => mode === PostModalMode.BigWin && !!link;
  const allowImageUpload = () => isNew();

  const showHighlights = () =>
    !props.disableHighlights &&
    (mode === PostModalMode.Post || !showLink()) &&
    mode !== PostModalMode.PartnerQuestion &&
    mode !== PostModalMode.PartnerRegularPost &&
    mode !== PostModalMode.PartnerPinnedPost;

  const showSaveButtons = () =>
    mode === PostModalMode.Post ||
    mode === PostModalMode.PartnerQuestion ||
    mode === PostModalMode.PartnerRegularPost ||
    mode === PostModalMode.PartnerPinnedPost ||
    !showLink();

  const showContentSection = () =>
    mode === PostModalMode.Post ||
    mode === PostModalMode.PartnerQuestion ||
    mode === PostModalMode.PartnerRegularPost ||
    mode === PostModalMode.PartnerPinnedPost;

  const showCommentControls = () =>
    mode !== PostModalMode.PartnerQuestion &&
    mode !== PostModalMode.PartnerRegularPost &&
    mode !== PostModalMode.PartnerPinnedPost;

  const showHomepageControls = () =>
    mode !== PostModalMode.PartnerQuestion &&
    mode !== PostModalMode.PartnerRegularPost &&
    mode !== PostModalMode.PartnerPinnedPost;

  const isSubmitDisabled = () =>
    isLoading ||
    !canSubmit() ||
    ((mode === PostModalMode.Post ||
      mode === PostModalMode.PartnerQuestion ||
      mode === PostModalMode.PartnerRegularPost ||
      mode === PostModalMode.PartnerPinnedPost) &&
      !authContext.userId);

  const hasBorderBottom = () =>
    mode !== PostModalMode.PartnerQuestion &&
    mode !== PostModalMode.PartnerRegularPost &&
    mode !== PostModalMode.PartnerPinnedPost;

  const title = () => mode.toString().replace("-", " ");

  const createModel = (images?: string[], videos?: string[]) => ({
    input: {
      ...(isNew() ? {} : { where: { id: post.id } }),
      data: {
        content: post.content,

        owner: post?.owner?.id as string,
        casino: post.casino?.id as string,
        slot: post.slot?.id as string,

        ...(images && images.length ? { media: images } : {}),

        ...(videos && videos.length ? { videos } : {}),

        showOnHomepage: !props.disableListing ? post.showOnHomepage : false,
        showOnHighlights: !props.disableListing && hasCorrectMultiplier(),

        bet: post.bet,
        win: post.win,
        commentsLocked: post.commentsLocked,
        multiplier: postMultiplier({ win: post.win, bet: post.bet }),
      },
    },
  });

  const isNew = () => !post.id;

  const canSubmit = () => {
    return (
      ((isNew() ? files.length > 0 : true) || !!post.content?.length) &&
      canSubmitMultiplier()
    );
  };

  const [loadingMode, setLoadingMode] = useState<LoadingMode>(
    LoadingMode.LINEAR
  );

  const [processingPostId, setProcessingPostId] = useState<
    string | undefined
  >();

  const [processingStatus, setProcessingStatus] =
    useState<PostModalProcessingStatusEnum>();

  const submit = async () => {
    // Switch loading mode when uploading video's
    const loadingMode = files.some((file) => videoMimeTypes.includes(file.type))
      ? LoadingMode.PROGRESSIVE
      : LoadingMode.LINEAR;

    setLoadingMode(loadingMode);

    const { data: filesData } = files.length
      ? await multipleUpload({
          variables: {
            files: files,
          },
        })
      : { data: { multipleUpload: [] } };

    const images = filesData.multipleUpload
      ?.filter((file: File) => imageMimeTypes.includes(file.mime))
      .map((file: File) => file.id);

    const videos = filesData.multipleUpload
      ?.filter((file: File) => videoMimeTypes.includes(file.mime))
      .map((file: File) => ({ media: file.id }));

    const isNewPost = isNew();
    const { data } = await (isNewPost ? createPost : updatePost)({
      variables: createModel(images, videos),
    });

    const newId = isNewPost ? data.createPost.post.id : data.updatePost.post.id;

    if (isNewPost && loadingMode === LoadingMode.PROGRESSIVE)
      return setProcessingPostId(newId);

    setLink(composeLink(newId));

    let message = isNewPost ? "Posted. " : "Updated. ";

    const update = await authContext.update?.();

    if ((update?.balanceChange || 0) > 0) {
      message += balanceMessage(update?.balanceChange);
    }

    openSnackbar(message);

    if (isNewPost) {
      await props?.onCreated?.(newId);
    } else {
      await props?.onUpdated?.({
        /** TODO: must be optimized */
        content: post.content,

        slot: selectedSlot
          ? {
              id: selectedSlot?.value,
              name: selectedSlot?.label,
            }
          : undefined,

        casino: selectedCasino
          ? {
              id: selectedCasino?.value,
              name: selectedCasino?.label,
            }
          : undefined,

        bet: post.bet,
        win: post.win,

        multiplier: postMultiplier({ win: post.win, bet: post.bet }),

        commentsLocked: post.commentsLocked,
      });
    }

    if (
      mode === PostModalMode.Post ||
      mode === PostModalMode.PartnerQuestion ||
      mode === PostModalMode.PartnerRegularPost ||
      mode === PostModalMode.PartnerPinnedPost
    ) {
      hide();

      if (isNewPost) {
        navigate(`/post/${data.createPost.post.id}/`);
      }
    }
  };

  const isLoading = loadingCreate || loadingUpdate || loadingUpload;

  const copyLink = () => {
    linkInputRef.current?.select();
    document.execCommand("copy");

    openSnackbar("Link copied to clipboard.");
  };

  useEffect(() => {
    if (loadingMode === LoadingMode.PROGRESSIVE)
      setModalMode(ModalMode.Minimal);
  }, [isLoading]);

  useEffect(() => {
    const listener = (error: any) => {
      if (loadingMode !== LoadingMode.PROGRESSIVE) return;

      if (
        processingStatus !== PostModalProcessingStatusEnum.ERROR &&
        processingStatus !== PostModalProcessingStatusEnum.CANCELLED &&
        processingStatus !== PostModalProcessingStatusEnum.DONE &&
        processingStatus !== PostModalProcessingStatusEnum.REMOVED
      ) {
        error.preventDefault();
        error.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", listener);
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [loadingMode, processingStatus]);

  useEffect(() => {
    // Switch to error state, when upload error is present
    if (errorUpload?.message)
      setProcessingStatus(PostModalProcessingStatusEnum.ERROR);
  }, [errorUpload]);

  useEffect(() => {
    // Cancel request and close modal on cancel
    if (processingStatus === PostModalProcessingStatusEnum.CANCELLED) {
      cancelTokenSource.cancel();
      hide();
    }

    // Close modal on error (by timeout)
    if (
      processingStatus === PostModalProcessingStatusEnum.ERROR ||
      processingStatus === PostModalProcessingStatusEnum.REMOVED
    ) {
      setTimeout(hide, 30000);
    }

    // Close modal and show snackbar when processing is done
    if (processingStatus === PostModalProcessingStatusEnum.DONE) {
      openSnackbar(
        <Link to={`/post/${processingPostId}`}>
          Post published! Click to view it
        </Link>,
        10000
      );
      hide();
    }
  }, [processingStatus]);

  useEffect(() => {
    if (modalMode === ModalMode.Minimal) {
      document.querySelector("body")?.classList.remove("modal-open");
      document.querySelector("body")?.classList.add("modal-minimal");
      document.getElementsByClassName("modal-backdrop")[0]?.remove();
    }
  }, [modalMode]);

  useOnBack(() => modalMode === ModalMode.Default && hide(), [modalMode]);

  return (
    <Modal
      show={open}
      onHide={hide}
      backdrop={modalMode === ModalMode.Default ? "static" : false}
      className={classNames({
        "modal-minimal": modalMode === ModalMode.Minimal,
      })}
      dialogClassName="modal-dialog-centered"
      contentClassName=""
    >
      {loadingMode === LoadingMode.PROGRESSIVE ? (
        <>
          {processingPostId && (
            <PostModalProcessing
              id={processingPostId}
              onStatusUpdate={setProcessingStatus}
            />
          )}
          <div className="modal-content">
            <ModalLoader
              style={{ flex: 3 }}
              status={processingStatus}
              progress={multipleUploadProgress}
              error={errorUpload?.message}
            />

            <button
              className={classNames(
                "btn-modal",
                processingStatus !== undefined ? "btn-modal-minimized" : null
              )}
              onClick={() => {
                setProcessingStatus(PostModalProcessingStatusEnum.CANCELLED);
              }}
              style={{ flex: 1 }}
            >
              <CancelIcon />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="modal-header">
            <h5 className="modal-title">
              {props.title ||
                (isNew() ? `CREATE ${title()}` : `EDIT ${title()}`)}
            </h5>
            <button
              type="button"
              className="modal-close"
              disabled={isLoading}
              onClick={hide}
            >
              cancel <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {allowImageUpload() && (
            <div className="modal-upload">
              <Dropzone
                accept={[...imageMimeTypes, ...videoMimeTypes]}
                disabled={!showSaveButtons()}
                multiple={mode === PostModalMode.Post}
                maxFiles={10} // Soft limit
                onDrop={(acceptedFiles) => {
                  setFiles(
                    acceptedFiles.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    )
                  );
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" id="demo-upload">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {files?.length === 0 && (
                        <div className="dz-message">
                          <i className="icon-photo"></i>
                          <button type="button" className="dz-button">
                            DROP MEDIA HERE
                          </button>
                          <small className="note">OR BROWSE MEDIA</small>
                        </div>
                      )}
                      {files.map((file, index) => (
                        <div key={index} className="dz-image">
                          <img
                            data-dz-thumbnail
                            className={
                              videoMimeTypes.includes(file.type)
                                ? "video-thumbnail"
                                : ""
                            }
                            src={
                              videoMimeTypes.includes(file.type)
                                ? "/images/video.svg"
                                : file.preview
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )}

          {showLink() && (
            <div className="modal-body" style={{ marginBottom: 16 }}>
              <form className="modal-form">
                <div className="modal-form__inner">
                  <div className="input-text__group">
                    <input
                      type="text"
                      id="link"
                      readOnly
                      placeholder="Link"
                      value={link}
                      ref={linkInputRef}
                    />
                    <label htmlFor="link">Link</label>
                  </div>
                  <button
                    type="button"
                    style={{ float: "right", marginRight: 0 }}
                    className="btn btn--medium"
                    onClick={() => copyLink()}
                  >
                    Copy link
                  </button>
                </div>
              </form>
            </div>
          )}

          {showContentSection() && (
            <div
              className={classNames({
                "modal-body": true,
                "modal-body--border-bottom": hasBorderBottom(),
              })}
            >
              <>
                <div className="input-textarea__group">
                  <i
                    style={{ marginTop: 16, marginRight: 8 }}
                    className="icon-smiley clickable"
                    onClick={() => setEmoji(!emoji)}
                  ></i>

                  {emoji && (
                    <Picker
                      useButton={true}
                      title="Pick your emoji…"
                      emoji=":point_up:"
                      onSelect={(emoji) => selectEmoji(emoji)}
                      style={{
                        position: "absolute",
                        top: "72px",
                        zIndex: 100,
                        right: "12px",
                        transition: "none",
                        animation: "none",
                      }}
                    />
                  )}

                  <label htmlFor="your-message" style={{ marginBottom: 12 }}>
                    Your message
                  </label>

                  <ReactMde
                    value={post.content}
                    onChange={(value) => setPost({ ...post, content: value })}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    toolbarCommands={toolbarMde}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                </div>

                {showCommentControls() && (
                  <div className="input-switch__group">
                    <input
                      type="checkbox"
                      name="comment-block"
                      id="comment-block"
                      value="comment-block"
                      checked={!post.commentsLocked}
                      onChange={(event) =>
                        setPost({
                          ...post,
                          commentsLocked: !event.target.checked,
                        })
                      }
                    />
                    <label htmlFor="comment-block"></label>
                    <p>
                      <b>Comments</b>
                      <br />
                      Allow commenting on this post
                    </p>
                  </div>
                )}

                {showHomepageControls() && (
                  <div className="input-switch__group">
                    <input
                      type="checkbox"
                      name="location-homepage"
                      id="location-homepage"
                      value="location-homepage"
                      checked={!!post.showOnHomepage}
                      onChange={(event) =>
                        setPost({
                          ...post,
                          showOnHomepage: event.target.checked,
                        })
                      }
                    />
                    <label htmlFor="location-homepage"></label>
                    <p>
                      <b>Location</b>
                      <br />
                      Show on homepage
                    </p>
                  </div>
                )}
              </>
            </div>
          )}

          {showHighlights() && (
            <div className="modal-body">
              <form className="modal-form">
                <div className="modal-form__inner">
                  <h5
                    className="modal-form__title"
                    style={{ marginBottom: 12 }}
                  >
                    TO SHOW RESULT IN COMMUNITY HIGHLIGHTS (OPTIONAL):
                  </h5>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="input-text__group"
                      style={{ flex: 1, maxWidth: "100%" }}
                    >
                      <LazySelect
                        placeholder="SEARCH CASINO"
                        selected={selectedCasino}
                        limit={1000}
                        query={casinoSelectorQuery}
                        openMenuOnClick={true}
                        onSelect={(casinoId) =>
                          setPost({ ...post, casino: { id: casinoId } })
                        }
                        onSelectItem={(casino) => setSelectedCasino(casino)}
                      />
                    </div>

                    <div
                      className="input-text__group"
                      style={{ flex: 1, maxWidth: "100%" }}
                    >
                      <LazySelect
                        placeholder="SEARCH SLOT"
                        selected={selectedSlot}
                        query={slotSelectorQuery}
                        onSelect={(slotId) =>
                          setPost({ ...post, slot: { id: slotId } })
                        }
                        onSelectItem={(slot) => setSelectedSlot(slot)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="input-text__group" style={{ flex: 1 }}>
                      <input
                        type="text"
                        id="betsize-modal"
                        placeholder="0"
                        pattern="[0-9]+([,\.][0-9]+)?"
                        onChange={(event) => {
                          const value = parseFloat(
                            event.target.value.replace(/,/g, ".")
                          );

                          setPost({
                            ...post,
                            bet: isNaN(value) ? 0 : value,
                          });
                        }}
                      />
                      <label htmlFor="betsize-modal">BETSIZE</label>
                    </div>

                    <div className="input-text__group" style={{ flex: 1 }}>
                      <input
                        type="number"
                        id="win-modal"
                        placeholder="0"
                        min={0.01}
                        step={0.01}
                        value={post.win}
                        onChange={(event) =>
                          setPost({
                            ...post,
                            win: event.target.valueAsNumber,
                          })
                        }
                      />
                      <label htmlFor="win-modal">WIN</label>
                    </div>

                    <p
                      className="display-2"
                      style={{
                        flex: 1,
                        textAlign: "center",
                        verticalAlign: "center",
                      }}
                    >
                      {postMultiplier(post)}x
                    </p>
                  </div>
                </div>
              </form>
            </div>
          )}

          {showSaveButtons() && (
            <div className="modal-footer">
              <button
                style={{ float: "right", marginRight: 16 }}
                className="btn btn--medium"
                disabled={isSubmitDisabled()}
                onClick={submit}
              >
                {isNew() ? "Create" : "Update"}
              </button>
            </div>
          )}

          <div style={{ paddingBottom: 16 }}>
            {isLoading && <Loading title="Saving..." />}
          </div>
        </>
      )}
    </Modal>
  );
};
