import React from "react";
import { ApolloError } from "@apollo/client";
import { ErrorResponse } from "../context/auth.context";
import { prettyError, prettyErrorForResponse } from "../utils/pretty-errors";
import Alert from "./alert";

interface AlertErrorProps {
  error: ApolloError | ErrorResponse;
}

const AlertError: React.FC<AlertErrorProps> = ({ error }) => {
  return (
    <Alert
      message={
        error instanceof ApolloError
          ? prettyError(error)
          : prettyErrorForResponse(error)
      }
      style="danger"
    />
  );
};

export default AlertError;
