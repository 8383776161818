import React from "react";
import { UserDto, UserDtoType } from "../dtos/user.dto";
import { Tooltip } from "./tooltip";

interface Properties {
  user?: UserDto;
  tooltip?: boolean;
}

const VerifiedBadge: React.FC<Properties> = ({ user, tooltip = true }) => {
  const className = () => {
    if (user?.type === UserDtoType.Streamer) return "badge badge-streamer";
    if (
      user?.type === UserDtoType.Partner ||
      user?.id === "5fa59965d85e3a0011fee7b9"
    )
      return "badge";
  };

  const content = () => {
    if (user?.id === "5fa59965d85e3a0011fee7b9")
      return "Official community account";
    return `Verified ${
      user?.type === UserDtoType.Streamer ? "streamer" : "partner"
    } account`;
  };

  const render = () => {
    const badge = (
      <div className={className()}>
        <i className="icon-verified" />
      </div>
    );

    if (tooltip) return <Tooltip text={content()}>{badge}</Tooltip>;
    return badge;
  };

  return !!user?.isVerified && className() ? render() : <></>;
};

export default VerifiedBadge;
