import { DemoGameReview } from "../../../dtos/demo-games.dto";
import React, { useContext } from "react";
import { AuthContext } from "../../../context/auth.context";
import { Link } from "gatsby";
import MediaImage from "../../media-image";
import VerifiedBadge from "../../verified-badge";
import Moment from "react-moment";
import { DemoGamesReviewRating } from "./rating";
import ReactMarkdown from "react-markdown";

interface Props {
  gameId: string;
  review: DemoGameReview;
}

export const DemoGamesReviewPreview = ({ gameId, review }: Props) => {
  const { user, userId } = useContext(AuthContext);
  const { id, profileName, avatar } = review?.user || {}
  const { content, createdAt } = review
  const isModerator = user?.role?.type === "moderator";

  return (
    <div className="entry">
      <div className="controls">
        <Link to={`/profile/${id}`} className="profile">
          <div className="avatar">
            <MediaImage
              alt={profileName}
              src={avatar}
              format="thumbnail"
            />
          </div>
          <div className="meta">
            <div className="clickable">
              {profileName}
              <VerifiedBadge user={review.user} />
            </div>
            {isModerator ? (
              <Moment format="MMMM Do YYYY, h:mm a">{createdAt}</Moment>
            ) : (
              <Moment fromNow>{createdAt}</Moment>
            )}
          </div>
        </Link>
        {id !== userId ?
          <DemoGamesReviewRating gameId={gameId} reviewId={review.id!} /> :
          <span className="notice">Your review</span>
        }
      </div>

      <div className="content">
        <ReactMarkdown source={content} />
      </div>
    </div>
  )
}