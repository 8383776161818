import * as React from "react";
import Post from "../components/post";
import Loading from "../components/loading";
import Infinity from "../components/infinity";
import { useQuery, gql } from "@apollo/client";
import { PostDto, postFields } from "../dtos/post.dto";
import { RouteProps } from ".";
import { mediaFields } from "../dtos/media.dto";
import { LayoutContext } from "../context/layout.context";
import { arrayWithout } from "../utils/array";
import Alert, { somethingWentWrong } from "../components/alert";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { ContentContext } from "../context/refresh.context";
import { generateVersion } from "../utils/generate-version";
import InactivityTimer from "../components/inactivity-timer";
import { Dto } from "../dtos/banner.dto";
import MarketingBanner, {
  MarketingBannerContextProvider,
} from "../components/marketing-banner";
import { AboutMenu } from "../components/about-menu";
import { isHome } from "../utils/location";

const HomePageQueryPageSize = 10;
const HomePageQuery = gql`
  query HomePageQuery($start: Int, $currentTime: String) {
    posts(
      limit: ${HomePageQueryPageSize}, 
      start: $start,
      sort: "createdAt:DESC",
      where: {
        showOnHomepage: true,
        isPinned_ne: true,
        active_ne: false,
        processed_ne: false,
        approved_ne: false
      }
    ) { ${postFields} }
    postsPinned: posts(
      limit: 1,
      sort: "publishedAt:ASC",
      where: {
        publishedAt_lte: $currentTime,
        expiresAt_gt: $currentTime,
        showOnHomepage: true,
        isPinned: true,
        active_ne: false,
        processed_ne: false,
        approved_ne: false
      }
    ) {
      ${postFields}
      isPinned
    }
    banners(
      where: {
        type: "default",
        approved: true,
        active: true,
        publishedAt_lte: $currentTime,
        expiresAt_gt: $currentTime
      }
    ) {
      id
      url
      image { ${mediaFields} }
    }
    marketingSetting {
      bannerFrequency
    }
  }
`;

const HomePageUpdateQuery = gql`
  query HomePageUpdateQuery($currentTime: String) {
    posts(
      limit: 1
      sort: "createdAt:DESC"
      where: {
        showOnHomepage: true
        isPinned_ne: true
        active_ne: false
        processed_ne: false
        approved_ne: false
      }
    ) {
      id
      content
    }
    postsPinned: posts(
      limit: 1
      sort: "publishedAt:ASC"
      where: {
        publishedAt_lte: $currentTime
        expiresAt_gt: $currentTime
        showOnHomepage: true
        isPinned: true
        active_ne: false
        processed_ne: false
        approved_ne: false
      }
    ) {
      id
    }
  }
`;

export interface HomePageInterface {
  posts: PostDto[];
  postsPinned: PostDto[];
  banners: Dto[];
  marketingSetting?: {
    bannerFrequency: number;
  };
}

export interface HomePageUpdateInterface {
  posts: PostDto[];
  postsPinned: PostDto[];
}

export enum RefreshModeEnum {
  USER,
  INACTIVITY,
}

let lastBannerId = null;

const HomePage: React.FC<RouteProps> = () => {
  const [version, setVersion] = useState<string>(generateVersion());
  const layoutContext = useContext(LayoutContext);
  const { requestedRefresh, refreshed } = useContext(ContentContext);
  const [hasNewContent, setHasNewContent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<HomePageInterface>({
    posts: [],
    postsPinned: [],
    banners: [],
  });
  const [updateData, setUpdateData] = useState<HomePageUpdateInterface>({
    posts: [],
    postsPinned: [],
  });
  const renderCount = useRef<number>(0);
  const getCurrentTime = () => new Date().toISOString(); // Use UTC for server time

  // Fetches data, execution disabled on entry
  const query = useQuery<HomePageInterface>(HomePageQuery, { skip: true });

  // Update query, used for manual polling
  const updateQuery = useQuery<HomePageUpdateInterface>(HomePageUpdateQuery, {
    skip: true,
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await updateQuery.refetch({
        currentTime: getCurrentTime(),
      });
      setUpdateData(response.data);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Check if content id mismatch
    if (
      data.posts[0]?.id !== updateData.posts[0]?.id ||
      data.postsPinned[0]?.id !== updateData.postsPinned[0]?.id
    ) {
      // Allow the use to refresh by indicator
      setHasNewContent(true);
    }
  }, [updateData]);

  // Sets loading state to active
  const startLoading = async () => setLoading(true);

  // Sets loading state to inactive, after a fixed timeout
  const stopLoading = async () =>
    setTimeout(() => {
      setLoading(false);
    }, 500);

  // Fetches content, shows component based loading indicator when required
  const fetch = async (start = 0, loading = false) => {
    if (loading) await startLoading();

    return query.fetchMore({
      variables: { start, currentTime: getCurrentTime() },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (loading) stopLoading();

        if (!fetchMoreResult) return prev;

        setData({
          posts: [
            ...(start === 0 || !data.posts ? [] : data.posts),
            ...fetchMoreResult.posts,
          ],
          postsPinned: fetchMoreResult.postsPinned,
          banners: fetchMoreResult.banners,
          marketingSetting: fetchMoreResult.marketingSetting,
        });

        return prev;
      },
    });
  };

  // Extends current content list
  const loadMore = async () => fetch(data.posts?.length);

  // Refreshes content
  const refresh = async (mode = RefreshModeEnum.USER) => {
    if (mode === RefreshModeEnum.USER) window.scrollTo(0, 0);
    await fetch(0, mode === RefreshModeEnum.INACTIVITY);
    setVersion(generateVersion());
    setHasNewContent(false);
    renderCount.current = 0;
  };

  // Refresh content when requested by context
  useEffect(() => {
    if (!requestedRefresh) return;
    refresh();
    refreshed();
  }, [requestedRefresh]);

  // Set layout props and fetch content on entry
  useEffect(() => {
    fetch(0, true);
  }, []);

  useEffect(() => {
    layoutContext.updateProps({ showTopBanner: true });
    return () => layoutContext.updateProps({ showTopBanner: false });
  }, []);

  // Show loading animation or error message, depending on state
  if (loading) return <Loading />;
  if (query.error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <MarketingBannerContextProvider>
        <InactivityTimer
          refresh={() => {
            refresh(RefreshModeEnum.INACTIVITY);
          }}
          timeout={300}
        />

        {hasNewContent && (
          <a className="content-indicator" onClick={() => refresh()}>
            <i className="icon-up" />
            <span>New posts</span>
          </a>
        )}

        {data.postsPinned.map((post) => (
          <Post
            key={`${version}-posts-pinned`}
            post={post}
            onDeleted={() =>
              setData({
                ...data,
                postsPinned: arrayWithout(post, data.postsPinned),
              })
            }
          />
        ))}

        <Infinity
          data={data.posts}
          pageSize={HomePageQueryPageSize}
          key={`${version}-posts`}
          fetchMore={() => loadMore()}
          disableEndMessage={data.posts.length <= HomePageQueryPageSize}
          disableInfinite={true}
        >
          {data.posts.map((post) => {
            const renderPost = (
              <Post
                key={post.id}
                post={post}
                onDeleted={() =>
                  setData({ ...data, posts: arrayWithout(post, data.posts) })
                }
              />
            );
            renderCount.current++;

            const frequency = data?.marketingSetting?.bannerFrequency
              ? data.marketingSetting.bannerFrequency
              : 5;

            const shouldRenderBanner =
              data.banners.length !== 0 &&
              (renderCount.current / frequency) % 1 === 0;

            return (
              <Fragment key={post.id}>
                {renderPost}
                {shouldRenderBanner && <MarketingBanner banners={data.banners} />}
              </Fragment>
            );
          })}
        </Infinity>
      </MarketingBannerContextProvider>
      {isHome() && <AboutMenu />}
    </>
  );
};

export default HomePage;
