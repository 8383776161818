import { BehaviorSubject, Subject } from "rxjs";

export abstract class Bloc<TEvent, TState> {
  events: Subject<TEvent>;
  state: BehaviorSubject<TState>;

  constructor(initial: TState) {
    this.events = new Subject<TEvent>();
    this.events.subscribe((event) => this.processEvent(event));

    this.state = new BehaviorSubject<TState>(initial);
  }

  abstract mapEventToState(event: TEvent): AsyncIterable<TState>;

  add(event: TEvent): this {
    this.events.next(event);

    return this;
  }

  protected async processEvent(event: TEvent): Promise<void> {
    const states = this.mapEventToState(event);

    for await (const item of states) {
      this.state.next(item);
    }
  }
}
