import * as React from "react";
import { Link } from "gatsby";
import { useQuery, gql } from "@apollo/client";
import { useEffect, useContext, useState } from "react";
import { navigate } from "gatsby";
import { AuthContext } from "../context/auth.context";
import { scrollLock } from "../utils/scroll-lock";
import { aggregateFields } from "../dtos/aggregate.dto";
import { DisplayMode, getDisplayMode } from "../utils/display-mode";
import { LayoutContext } from "../context/layout.context";
import Notifications from "./notifications";
import { usePostModalContext } from "./modals/post-modal.context";
import { PostModal, PostModalMode } from "./modals/post-modal";
import { useLoginModal } from "./modals/login-modal.context";
import { MarketingModal } from "./modals/marketing-modal";
import { MarketingModalButton } from "./marktering-modal-button";
import { Tooltip } from "./tooltip";
import classNames from "classnames";
import { FullScreenContext } from "../context/full-screen.context";

const Toolbar: React.FC = () => {
  const { userId, isPartner } = useContext(AuthContext);
  const layoutContext = useContext(LayoutContext);
  const { allowMainMenu, showMainMenu } = useContext(FullScreenContext);
  const { open, render } = usePostModalContext();
  const { openLoginModal, runOrOpenLoginModal } = useLoginModal();

  const { data } = useQuery(gql`
    query ToolbarQuery {
      usersConnection(where: { blocked: false }) {
        ${aggregateFields}
      }
    }
  `);

  const [menuText, setMenuText] = useState(`closed`);
  const [displayModeText, setDisplayModeText] = useState(
    getDisplayMode().toString()
  );
  const [memberSearch, setMemberSearch] = useState(``);

  const getOppositeMode = (mode: DisplayMode): DisplayMode => {
    return mode === DisplayMode.Light ? DisplayMode.Dark : DisplayMode.Light;
  };

  const setDisplayMode = (value: DisplayMode): void => {
    const body = document.querySelector(`body`);
    const html = document.querySelector(`html`);

    switch (value) {
      case DisplayMode.Dark:
        body?.classList.add(`dark-mode`);
        html?.classList.add(`dark-mode`);
        break;
      case DisplayMode.Light:
        body?.classList.remove(`dark-mode`);
        html?.classList.remove(`dark-mode`);
        break;
    }

    layoutContext.updateProps({ displayMode: getDisplayMode() });
    setDisplayModeText(getOppositeMode(getDisplayMode()).toString());
  };

  const toggleDarkMode = (): void => {
    const current = getDisplayMode();
    const next = getOppositeMode(current);

    setDisplayMode(next);

    localStorage.setItem(`display-mode`, next);
  };

  const searchMembers = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return navigate(`/members/${memberSearch}`);
  };

  const toggleMenu = (): void => {
    const newValue = !layoutContext.props.menuIsOpen;
    layoutContext.updateProps({ menuIsOpen: newValue });
  };

  useEffect(() => {
    setDisplayMode(
      (localStorage.getItem(`display-mode`) as DisplayMode) || DisplayMode.Dark
    );
  }, []);

  useEffect(() => {
    if (layoutContext.props.menuIsOpen === undefined) return;
    scrollLock(layoutContext.props.menuIsOpen);
    setMenuText(layoutContext.props.menuIsOpen ? `opened` : `closed`);
  }, [layoutContext.props.menuIsOpen]);

  return (
    <>
      <nav className="navigation" role="navigation">
        <div className="container-fluid">
          <Link to="/">
            <div className="navigation__logo">
              <img
                className="navigation__logo__light-mode"
                src="/images/logo-blue.svg"
              />
              <img
                className="navigation__logo__dark-mode"
                src="/images/logo-gold.svg"
              />
            </div>
          </Link>

          <div className={"navigation__inner"}>
            <div
              className={classNames("navigation-logo", {
                "navigation-logo_hidden": !allowMainMenu || showMainMenu,
              })}
              onClick={() => navigate("/")}
            >
              <img
                src={`/images/logo-${
                  getDisplayMode() === DisplayMode.Dark ? "gold" : "blue"
                }.svg`}
              />
            </div>

            <form
              className="navigation__search"
              onSubmit={(event): Promise<void> => searchMembers(event)}
            >
              <input
                className="navigation__input"
                type="text"
                placeholder="FIND MEMBER"
                value={memberSearch}
                onChange={(event): void => setMemberSearch(event.target.value)}
              />
              <button className="navigation__button" type="submit">
                <i className="icon-search"></i>
              </button>
            </form>

            <ul className="navigation__list">
              <li
                className={`navigation__list-item navigation__list-item--mobile navigation__toggler--${menuText}`}
                onClick={(): void => toggleMenu()}
              >
                <button className="navigation__toggler">
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <span
                  className={`navigation__toggler navigation__toggler--${menuText} navigation__list-item__link`}
                >
                  <span className="navigation__list-item__label">Menu</span>
                </span>
              </li>
              <li className="navigation__list-item navigation__list-item--desktop clickable">
                <Link to="/members" className="navigation__list-item__link">
                  <span className="navigation__list-item__label">
                    {(
                      data?.usersConnection?.aggregate?.count || 0
                    ).toLocaleString()}{" "}
                    members
                  </span>
                </Link>
              </li>
              <li className="navigation__list-item navigation__list-item--dark-mode clickable">
                <span
                  className="navigation__list-item__link"
                  onClick={(): void => toggleDarkMode()}
                >
                  <span className="navigation__list-item__label--desktop">
                    {displayModeText} mode
                  </span>
                  <span className="navigation__list-item__switch"></span>
                </span>
              </li>

              {userId && <Notifications />}

              <li className="navigation__list-item navigation__list-item--featured">
                {userId ? (
                  <Link
                    to={`/profile/${userId}/`}
                    className="navigation__list-item__link btn"
                  >
                    <span className="navigation__list-item__label">ME</span>
                  </Link>
                ) : (
                  <a
                    className="navigation__list-item__link"
                    onClick={() => openLoginModal()}
                  >
                    <span className="navigation__list-item__label">
                      Join now
                    </span>
                  </a>
                )}
              </li>
            </ul>

            <ul className="buttons__list">
              <li
                className={classNames("buttons__list-item", { disabled: open })}
              >
                <Tooltip text={open && "Your post is being uploaded."}>
                  {isPartner ? (
                    <MarketingModalButton responsive={true} />
                  ) : (
                    <a
                      className="buttons__list-item__link"
                      onClick={() =>
                        runOrOpenLoginModal(() =>
                          render(
                            <PostModal
                              mode={PostModalMode.Post}
                              key={new Date().getTime()}
                            />
                          )
                        )
                      }
                    >
                      CREATE <br /> NEW POST
                    </a>
                  )}
                </Tooltip>
              </li>
              <li className="buttons__list-item">
                <Link
                  to="/members"
                  className="buttons__list-item__link buttons__list-item__link--icon"
                >
                  <i className="icon-search"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Toolbar;
