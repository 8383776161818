import * as React from "react";
import { Link } from "gatsby";

const SingleContentBecomePartnerBanner: React.FC = () => (
  <section className="banner-container">
    <div className="banner-container_content">
      <h3>Looking to become a Partner?</h3>
      <p>
        Become a CasinoRing-partner and use our platform for promotion of your
        company. Interested? Please submit your details at the following page.
      </p>
    </div>
    <div className="banner-container_action">
      <Link to="/become-partner" className="btn btn-medium">
        REQUEST INQUIRY
      </Link>
    </div>
  </section>
);

export default SingleContentBecomePartnerBanner;
