import React from "react";
import { gql, useQuery } from "@apollo/client";

const GiveawayActiveCount: React.FC = () => {
  const { data } = useQuery(
    gql`
      query giveawaysActiveCount {
        giveawaysActiveCount
      }
    `,
    { pollInterval: 15000 }
  );

  return data?.giveawaysActiveCount && data.giveawaysActiveCount > 0 ? (
    <span className="header-menu__list-item__badge">
      {data.giveawaysActiveCount}
    </span>
  ) : null;
};

export default GiveawayActiveCount;
