import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { formatOrUrl, MediaDto } from "../dtos/media.dto";
import { videoPreview, VideoDto, isVideo } from "../dtos/video.dto";
import { Img } from "react-image";

interface MediaImageProps {
  alt?: string;
  format: "thumbnail" | "small" | "medium" | "large" | "extraLarge";
  isClickable?: boolean;
  isTransparent?: boolean;
  className?: string;
  src?: MediaDto | VideoDto | string;
  style?: React.CSSProperties;
  link?: string;
  externalLink?: boolean;
}

const MediaImage: React.FC<MediaImageProps> = ({
  alt,
  format,
  src,
  style,
  className,
  isClickable,
  isTransparent,
  link,
  externalLink,
}) => {
  const isUrl = typeof src === `string`;
  const media = src as MediaDto;
  const [animated, setAnimated] = useState<boolean>(false);

  const toggleAnimated = (enabled?: boolean) => {
    if (isUrl || !isVideo(src)) return;
    setAnimated(typeof enabled !== "undefined" ? enabled : !animated);
  };

  const source = (animated?: boolean) => {
    if (isUrl) return src;
    if (!isVideo(src))
      return media?.stagingPreview || formatOrUrl(format, media);
    return videoPreview(src, animated);
  };

  const Image = () => (
    <Img
      className={`media-image ${isClickable ? `media-image--clickable` : ``} ${
        className || ``
      }`}
      src={source(animated)}
      alt={alt}
      container={(children) => (
        <Fragment>
          {!isUrl && isVideo(src) ? (
            <img className="video-preview" src="/images/play.svg" />
          ) : null}
          {children}
        </Fragment>
      )}
    />
  );

  return source() ? (
    <div
      style={style}
      className={`media-image--container ${
        isTransparent ? `media-image--container__transparent` : ``
      }`}
      onMouseEnter={() => {
        toggleAnimated();
      }}
      onMouseLeave={() => {
        toggleAnimated();
      }}
      onClick={() => {
        toggleAnimated(false);
      }}
    >
      {link ? (
        externalLink ? (
          <a href={link} className="external-link">
            <Image />
          </a>
        ) : (
          <Link to={link}>
            <Image />
          </Link>
        )
      ) : (
        <Image />
      )}
    </div>
  ) : (
    <></>
  );
};

export default MediaImage;
