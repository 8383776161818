import React from "react";
import { NotificationsContext } from "../context/notifications.context";

interface NotificationsCountProps {
  className: string;
}

const NotificationsCount: React.FC<NotificationsCountProps> = ({
  className,
}) => {
  const notificationsContext = React.useContext(NotificationsContext);

  return notificationsContext.count && notificationsContext.count > 0 ? (
    <span className={className}>{notificationsContext.count}</span>
  ) : null;
};

export default NotificationsCount;
