import { MediaDto } from "./media.dto";
import { CasinoDetailDto } from "./casino.dto";

export interface StreamersLobbyOffer {
  id: string;
  payment: StreamersLobbyOfferPayment;
  title: string;
  description: string;
  url: string;
  logo?: MediaDto;
  slug?: string;
  detail?: CasinoDetailDto;
}

export interface SortedStreamersLobbyOfferDto {
  streamers_lobby_offer: StreamersLobbyOffer;
}

export interface SortableStreamersLobbyOffersDto {
  SortedStreamersLobbyOffers: SortedStreamersLobbyOfferDto[];
}

export enum StreamersLobbyOfferPayment {
  CRYPTO = "crypto",
  FIAT = "fiat",
  BOTH = "both"
}

export interface StreamersLobbyStreamerDto {
  id: string;
  title: string;
  description: string;
  twitchUrl?: string;
  instagramUrl?: string;
  youtubeUrl?: string;
  discordUrl?: string;
  kickUrl?: string;
  dliveUrl?: string;
  avatar: MediaDto;
  countries?: { countryCode: string }[]
}

export interface SortedStreamersLobbyStreamerDto {
  streamers_lobby_streamer: StreamersLobbyStreamerDto;
}

export interface SortableStreamersLobbyStreamersDto {
  SortedStreamersLobbyStreamers: SortedStreamersLobbyStreamerDto[];
}