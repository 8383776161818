import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useOpenSnackbar } from "../utils/snackbar";
import { SnackbarEnum } from "../dtos/snackbar.dto";
import { useState } from "react";
import { EmailRegex } from "../utils/regex";
import { ValidationRules } from "react-hook-form/dist/types/validator";

interface FormField {
  key: string;
  name: string;
  hint: string;
}

const SingleContentBecomePartnerForm: React.FC = () => {
  const { handleSubmit, register, errors } = useForm();
  const openSnackbar = useOpenSnackbar();
  const [submitted, setSubmitted] = useState(false);

  const [mutate] = useMutation(gql`
    mutation becomePartnerContact(
      $companyName: String!
      $contactPerson: String!
      $emailAddress: String!
      $skypeId: String
    ) {
      becomePartnerContact(
        companyName: $companyName
        contactPerson: $contactPerson
        emailAddress: $emailAddress
        skypeId: $skypeId
      )
    }
  `);

  const formFields: FormField[] = [
    {
      key: "companyName",
      name: "Company name",
      hint: "The name of the corresponding company",
    },
    {
      key: "contactPerson",
      name: "Contact person",
      hint: "Name of the person to contact",
    },
    {
      key: "emailAddress",
      name: "Email address",
      hint: "Email address for communication",
    },
    {
      key: "skypeId",
      name: "Skype ID",
      hint: "The ID of your Skype account",
    },
  ];

  const onSubmit = async ({
    companyName,
    contactPerson,
    emailAddress,
    skypeId,
  }: Record<string, string>): Promise<void> => {
    try {
      await mutate({
        variables: {
          companyName,
          contactPerson,
          emailAddress,
          skypeId,
        },
      });

      setSubmitted(true);
    } catch (e) {
      openSnackbar({
        status: SnackbarEnum.ERROR,
        content: "Could not submit request. Please try again.",
      });
    }
  };

  const getInputRules = (key: string): ValidationRules => {
    const defaults = {
      required: "Field mandatory",
      minLength: { value: 2, message: "Minimum of  2 characters" },
      maxLength: { value: 150, message: "Maximum of 150 characters" },
    };

    if (key === "emailAddress") {
      return {
        pattern: {
          value: EmailRegex,
          message: "Invalid email",
        },
        ...defaults,
      };
    }

    if (key === "skypeId") {
      return {
        minLength: defaults.minLength,
        maxLength: defaults.maxLength,
      };
    }

    return defaults;
  };

  const renderForm = () => (
    <section className="form-container">
      <h3>Start a partnership</h3>
      <p>
        Please supply your details below. We will get in touch for available
        partnership options.
      </p>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        {formFields.map((field) => (
          <div key={field.key} className="input-text__group">
            <input
              type="text"
              id={field.key}
              name={field.key}
              placeholder={field.name}
              className={errors[field.key] && "validation-error"}
              ref={register(getInputRules(field.key))}
            />
            <label htmlFor={field.key}>
              {field.name}
              {getInputRules(field.key).required ? "*" : ""}
            </label>
            {errors[field.key] && (
              <p className="validation-error-message">
                {errors[field.key].message}
              </p>
            )}
            {field.hint && (
              <small id={`${field.key}-help`} className="hint">
                {field.hint}
              </small>
            )}
          </div>
        ))}

        <button type="submit" className="btn">
          Send request
        </button>
      </form>
    </section>
  );

  const renderSubmission = () => (
    <section className="form-container">
      <h3>Request submitted</h3>
      <p>
        Your request has been submitted. We will get in touch with you shortly.
      </p>
    </section>
  );

  return submitted ? renderSubmission() : renderForm();
};

export default SingleContentBecomePartnerForm;
