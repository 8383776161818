import "./prize-drop-settings.scss";
import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import { PrizeDropDto } from "../../dtos/prize-drop.dto";

const QUERY_FETCH_PRIZE_DROPS = gql`
  query FetchPrizeDrops($streamerId: String!) {
    prizeDrops(where: { type: "streamer", streamer: $streamerId }) {
      id
      title
      type
      contents
      timer
      minimumViewerCount
      winnerPageTitle
      winnerPageDescription
      winnerPageImage {
        url
        formats
      }
    }
  }
`;

export const usePrizeDropQuery = () => {
  const { userId } = useContext(AuthContext);
  const { data, loading, error, refetch } = useQuery<{
    prizeDrops: PrizeDropDto[];
  }>(QUERY_FETCH_PRIZE_DROPS, {
    variables: {
      streamerId: userId,
    },
  });

  return { data: data && data.prizeDrops, loading, error, refetch };
};
