import { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { AuthContext } from "../../context/auth.context";

const MUTATION_CREATE_PRIZE_DROP = gql`
  mutation CreatePrizeDrop($input: PrizeDropInput!) {
    createPrizeDrop(input: { data: $input }) {
      prizeDrop {
        id
      }
    }
  }
`;

const MUTATION_UPDATE_PRIZE_DROP = gql`
  mutation UpdatePrizeDrop($id: ID!, $input: editPrizeDropInput!) {
    updatePrizeDrop(input: { where: { id: $id }, data: $input }) {
      prizeDrop {
        id
      }
    }
  }
`;

const MUTATION_DELETE_PRIZE_DROP = gql`
  mutation DeletePrizeDrop($id: ID!) {
    deletePrizeDrop(input: { where: { id: $id } }) {
      prizeDrop {
        id
      }
    }
  }
`;

const MUTATION_UPLOAD = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      id
    }
  }
`;

interface SavePrizeDropInput {
  id?: string;
  title: string;
  contents: string;
  timer: number;
  minimumViewerCount?: number;
  winnerPageTitle?: string;
  winnerPageDescription?: string;
  winnerPageImage?: File;
}

export const useDeletePrizeDropMutation = () => {
  const [mutate] = useMutation(MUTATION_DELETE_PRIZE_DROP);

  return {
    mutate: (id: string) => mutate({ variables: { id } }),
  };
};

export const useSavePrizeDropMutation = () => {
  const { userId } = useContext(AuthContext);
  const [mutateCreate] = useMutation(MUTATION_CREATE_PRIZE_DROP);
  const [mutateUpdate] = useMutation(MUTATION_UPDATE_PRIZE_DROP);
  const [mutateUpload] = useMutation(MUTATION_UPLOAD);

  return {
    save: async (data: SavePrizeDropInput) => {
      if (data.id) {
        return await mutateUpdate({
          variables: {
            id: data.id,
            input: {
              title: data.title,
              contents: data.contents,
              timer: data.timer,
              minimumViewerCount: data.minimumViewerCount || 1,
              winnerPageTitle: data.winnerPageTitle,
              winnerPageDescription: data.winnerPageDescription,
            },
          },
        });
      } else {
        const { data: result } = await mutateUpload({
          variables: {
            file: data.winnerPageImage,
          },
        });

        return await mutateCreate({
          variables: {
            input: {
              title: data.title,
              contents: data.contents,
              timer: data.timer,
              type: "streamer",
              streamer: userId,
              minimumViewerCount: data.minimumViewerCount || 1,
              winnerPageImage: result.upload.id,
              winnerPageTitle: data.winnerPageTitle,
              winnerPageDescription: data.winnerPageDescription,
            },
          },
        });
      }
    },
  };
};
