import React, { createContext, useContext, useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useWindowSize } from "react-use";
import { useNoScrollBody } from "../../hooks/useNoScrollBody";
import "./prize-drop.scss";

const useCenteredSquareCalc = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  // Responsive size factor
  const factor = windowWidth <= 768 ? 100 : 90;

  // Extra padding
  const padding = 40;

  const width = (windowWidth / 100) * factor;
  const height = (windowHeight / 100) * factor;
  const calculatedWidth = width > windowWidth ? windowWidth : width;
  const calculatedHeight = height > windowHeight ? windowHeight : height;
  const calculatedSize = Math.min(
    calculatedWidth - padding,
    calculatedHeight - padding
  );

  const calculatedLeft = ((windowWidth - calculatedSize) / 100) * 50;
  const calculatedTop = ((windowHeight - calculatedSize) / 100) * 50;

  return {
    size: calculatedSize,
    left: calculatedLeft,
    top: calculatedTop,
  };
};

export interface PrizeDropPopupData {
  text?: string;
  duration?: number;
  onClickCollect?: () => void;
  onClose?: () => void;
}

export interface PrizeDropPopupProps {
  isOpen?: boolean;
  data?: PrizeDropPopupData | null;
}

export const PrizeDropPopup: React.FC<PrizeDropPopupProps> = ({
  isOpen,
  data,
}) => {
  useNoScrollBody(isOpen);

  const [isExpired, setIsExpired] = useState(false);
  const { size, left, top } = useCenteredSquareCalc();
  const sparklesSize = (size / 100) * 60;
  const titleFontSize = ((size / 100) * 10) / 2;

  useEffect(() => setIsExpired(false), [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="prize-drop">
      <div className="backdrop" />
      <div
        className="sparkles"
        style={{
          left: left + size / 2 - sparklesSize,
          top: top + size - sparklesSize,
          width: sparklesSize,
          height: sparklesSize,
        }}
      />
      <div
        className="sparkles"
        style={{
          left: left + size / 2,
          width: sparklesSize,
          height: sparklesSize,
          top,
        }}
      />

      <div
        className="content"
        style={{
          width: size,
          height: size,
          padding: (size / 100) * 15,
          top,
          left,
        }}
      >
        <img
          className="winner"
          src="/images/prize-drop/winner.svg"
          draggable={false}
        />
        <div
          className="title"
          style={{ fontSize: titleFontSize <= 20 ? 20 : titleFontSize }}
        >
          {data?.text || "Prize drop text"}
        </div>

        {!isExpired && (
          <div className="btn-wrapper">
            <div className="countdown">
              {data?.duration && (
                <CountdownCircleTimer
                  isPlaying
                  duration={data.duration}
                  size={60}
                  strokeWidth={6}
                  colors="#ccab52"
                  onComplete={() => {
                    setIsExpired(true);
                    return [false, 0];
                  }}
                >
                  {({ remainingTime }) => (
                    <span className="countdown-label">{remainingTime}</span>
                  )}
                </CountdownCircleTimer>
              )}
            </div>

            <div>
              <button className="btn" onClick={data?.onClickCollect}>
                Collect prize
              </button>
            </div>
          </div>
        )}
        {isExpired && (
          <div
            className="expired"
            style={{ fontSize: titleFontSize <= 20 ? 20 : titleFontSize }}
          >
            <span className="line1">SORRY!</span>
            <span
              className="line2"
              style={{
                fontSize: titleFontSize <= 20 ? 15 : (titleFontSize / 4) * 3,
              }}
            >
              You did not collect me in time
            </span>
          </div>
        )}
      </div>

      <img
        className="logo"
        src="/images/prize-drop/logo.svg"
        draggable={false}
        style={{
          width: (size / 100) * 20,
          height: (size / 100) * 20,
          top,
          left,
        }}
      />
      <div
        className="close-btn"
        style={{
          left: left + size - 15,
          top: top + 15,
        }}
        onClick={data?.onClose}
      />
    </div>
  );
};

export interface PrizeDropContextProps {
  isOpen?: boolean;
  showPrizeDropPopup: (data: PrizeDropPopupData) => void;
}

export const PrizeDropContext = createContext<PrizeDropContextProps>(
  undefined as any
);

export const PrizeDropProvider: React.FC = ({ children }) => {
  const [
    prizeDropPopupData,
    setPrizeDropPopupData,
  ] = useState<PrizeDropPopupData | null>();

  const isOpen = !!prizeDropPopupData;

  return (
    <PrizeDropContext.Provider
      value={{ isOpen, showPrizeDropPopup: setPrizeDropPopupData }}
    >
      <PrizeDropPopup
        isOpen={!!prizeDropPopupData}
        data={{
          ...prizeDropPopupData,
          onClickCollect: () => {
            setPrizeDropPopupData(null);
            prizeDropPopupData?.onClickCollect?.();
          },
          onClose: () => {
            setPrizeDropPopupData(null);
            prizeDropPopupData?.onClose?.();
          },
        }}
      />
      {children}
    </PrizeDropContext.Provider>
  );
};

export const usePrizeDrop = () => useContext(PrizeDropContext);
