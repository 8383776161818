import * as React from "react";
import { useContext, useState } from "react";
import Title from "../components/title";
import { useMutation } from "@apollo/client";
import { ForgotPasswordMutation } from "../dtos/forgot-password.dto";
import Loading from "../components/loading";
import AlertError from "../components/alert-error";
import Alert from "../components/alert";
import { SeoContext } from "../context/seo.context";

const ForgotPasswordPage: React.FC = () => {
  const seoContext = useContext(SeoContext);
  const [email, setEmail] = useState(``);

  const [request, { loading, error, data }] = useMutation(
    ForgotPasswordMutation
  );

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await request({
      variables: { email },
    });
  };

  React.useEffect(() => {
    seoContext.setData({
      title: `Reset your password`,
      description: `Forgot your password? Request a new one to get access to your account again.`,
    });
  }, []);

  const form = (
    <div className="account__inner__group">
      <div className="input-text__group">
        <input
          type="email"
          id="email"
          placeholder="Your email"
          autoComplete="email"
          onChange={(event) => setEmail(event.target.value)}
        />
        <label htmlFor="email">Your email</label>
      </div>

      <div className="input-button__group">
        <button type="submit" className="btn btn--medium" disabled={!email}>
          Reset
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Title title="RESET YOUR PASSWORD" />

      <section className="account">
        <form className="account__form" onSubmit={(event) => submit(event)}>
          <div className="account__inner">
            <p>
              Please fill in the email that is linked to your account and click
              the reset button. After this you will receive an email with a
              unique link, that can be used to reset the password of your
              account.
            </p>

            {loading && <Loading />}
            {data && (
              <Alert
                message={`Password reset instructions sent`}
                style="info"
              />
            )}
            {error && <AlertError error={error} />}
            {!loading && !data && form}
          </div>
        </form>
      </section>
    </>
  );
};

export default ForgotPasswordPage;
