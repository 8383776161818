import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth.context";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FollowerDto } from "../dtos/follower.dto";
import { first } from "lodash";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";

interface FollowButtonProps {
  userId?: string;
  className?: string;
  size?: string;
  update?: (following: boolean) => void;
}

const createFollowerMutation = gql`
  mutation createFollowerMutation($input: FollowerInput!) {
    createFollower(input: { data: $input }) {
      follower {
        id
      }
    }
  }
`;

const deleteFollowerMutation = gql`
  mutation deleteFollowerMutation($id: ID!) {
    deleteFollower(input: { where: { id: $id } }) {
      follower {
        id
      }
    }
  }
`;

const followerQuery = gql`
  query followerQuery($authUserId: ID!, $userId: ID!) {
    match: followers(
      where: { user: $userId, owner: $authUserId, active_ne: false }
      limit: 1
      sort: "createdAt:DESC"
    ) {
      id
    }
  }
`;

const FollowButton: React.FC<FollowButtonProps> = ({
  userId,
  className,
  size,
  update,
}) => {
  const authContext = useContext(AuthContext);

  const [openSnackbar] = useSnackbar(snackbarConfig);

  const [createFollower] = useMutation(createFollowerMutation);
  const [deleteFollower] = useMutation(deleteFollowerMutation);

  const { data, loading, refetch } = useQuery<{ match: FollowerDto[] }>(
    followerQuery,
    {
      variables: {
        userId,
        authUserId: authContext.userId,
      },
      skip: !authContext?.userId,
    }
  );

  const isFollowing = (data?.match?.length || 0) > 0;

  useEffect(() => {
    if (update) update(isFollowing);
  }, [isFollowing]);

  const toggle = async () => {
    if (isFollowing) {
      const { data: response } = await deleteFollower({
        variables: {
          id: first(data?.match)?.id,
        },
      });

      if (response) {
        openSnackbar("Unfollowed");
      }
    } else {
      const { data: response } = await createFollower({
        variables: {
          input: {
            user: userId,
            owner: authContext.userId,
          },
        },
      });

      if (response) {
        openSnackbar("Following");
      }
    }

    await refetch();
  };

  const disabled = () => {
    return loading || !authContext.userId || authContext.userId == userId;
  };

  const label = () => {
    if (!authContext.userId) {
      ("Follow");
    }

    return !isFollowing ? "Follow" : "UnFollow";
  };

  return (
    <button
      onClick={() => toggle()}
      className={`btn btn--${size || "medium"} ${
        disabled() ? "disabled" : ""
      } ${className}`}
    >
      {label()}
    </button>
  );
};

export default FollowButton;
