import React from "react";
import { Modal } from "react-bootstrap";
import { useOnBack } from "../../utils/history";

export interface ConfirmModalProps {
  show: boolean;
  title?: string;
  message?: string;
  onClose: (result: boolean) => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show,
  title,
  message,
  onClose,
}) => {
  useOnBack(() => onClose(false));

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={() => onClose(false)}
      dialogClassName="confirm-modal modal-dialog-centered"
    >
      <div className="modal-body">
        <h5 className="modal-title">{title || "Are you sure?"}</h5>
        <p>{message || "You can't undo this action."}</p>
      </div>

      <div className="modal-footer">
        <button
          style={{ float: "right", marginRight: 16 }}
          className="btn btn btn--medium"
          onClick={() => onClose(true)}
        >
          Yes
        </button>
        <button
          style={{ float: "right", marginRight: 16 }}
          className="btn btn--border btn--medium btn--danger"
          onClick={() => onClose(false)}
        >
          No
        </button>
      </div>
    </Modal>
  );
};
