import React, { useEffect, useState } from "react";
import ReactDropzone from "react-dropzone";
import { imageMimeTypes, videoMimeTypes } from "../../../dtos/mime-types.dto";
import classNames from "classnames";

interface Properties {
  title: string;
  onFileChange: (files: any[]) => void;
  initialFiles?: any[];
  maxFiles?: number;
  hint?: string;
  allowVideos?: boolean;
}

export const Dropzone: React.FC<Properties> = ({
  title,
  onFileChange,
  initialFiles,
  maxFiles,
  hint,
  allowVideos,
}) => {
  const [files, setFiles] = useState<any[]>(initialFiles ? initialFiles : []);
  useEffect(() => onFileChange(files), [files]);

  return (
    <div className="modal-input modal-upload">
      <ReactDropzone
        accept={
          allowVideos ? [...imageMimeTypes, ...videoMimeTypes] : imageMimeTypes
        }
        multiple={!maxFiles || maxFiles > 1}
        maxFiles={maxFiles ? maxFiles : 0}
        onDrop={(files) => {
          setFiles(
            files.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            )
          );
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={classNames({ dropzone: true, "dropzone--hint": !!hint })}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              {files?.length === 0 && (
                <div className="dz-message">
                  <i className="icon-photo" />
                  <button type="button" className="dz-button">
                    DROP {title} HERE
                  </button>
                  <small className="note">OR BROWSE LOCAL</small>
                </div>
              )}

              {files?.length !== 0 && (
                <div>
                  {files.map((file, index) => (
                    <div key={index} className="dz-image">
                      <img
                        data-dz-thumbnail
                        className={
                          videoMimeTypes.includes(file.type)
                            ? "video-thumbnail"
                            : ""
                        }
                        src={
                          videoMimeTypes.includes(file.type)
                            ? "/images/video.svg"
                            : file.preview
                        }
                        alt="Preview"
                      />
                    </div>
                  ))}
                </div>
              )}

              {hint && <small className="hint">{hint}</small>}
            </div>
          </div>
        )}
      </ReactDropzone>
    </div>
  );
};
