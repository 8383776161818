export const imageMimeTypes = ["image/jpeg", "image/png"];

export const videoMimeTypes = [
  // MP4
  "video/mp4",
  // Quicktime
  "video/quicktime",
  // WMV
  "video/x-ms-wmv",
  "video/x-ms-asf",
  // AVI
  "video/avi",
  "video/msvideo",
  "video/x-msvideo",
  "video/vnd.avi",
  // FLV
  "video/x-flv",
];
