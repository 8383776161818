import React from "react";

interface NotificationImageProps {
  url?: string;
  description?: string;
  custom?: boolean;
}

const NotificationImage: React.FC<NotificationImageProps> = ({
  url,
  description,
  custom,
}) => {
  return (
    <div
      className={[
        "image-container",
        custom ? "image-container-custom" : null,
      ].join(" ")}
    >
      {url && description && (
        <img className="content" src={url} alt={description} />
      )}
      {!url && <div className="placeholder" />}
    </div>
  );
};

export default NotificationImage;
