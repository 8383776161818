import * as React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "gatsby";
import { RouteProps } from ".";
import Moment from "react-moment";
import Alert, { somethingWentWrong } from "../components/alert";
import Loading from "../components/loading";
import MediaImage from "../components/media-image";
import Title from "../components/title";
import {
  giveawayExpired,
  giveawayOverviewQuery,
  SortableGiveawaysDto,
  SortedGiveawayDto,
} from "../dtos/giveaway.dto";
import { orderBy } from "lodash";
import { SeoContext } from "../context/seo.context";
import { useContext } from "react";

type GiveawaysPageProps = RouteProps;

const GiveawaysPage: React.FC<GiveawaysPageProps> = () => {
  const seoContext = useContext(SeoContext);
  const { data, loading, error } = useQuery<{
    sortedGiveaway: SortableGiveawaysDto;
  }>(giveawayOverviewQuery);

  const getOrderedGiveaways = (): SortedGiveawayDto[] => {
    return orderBy(
      data?.sortedGiveaway?.SortedGiveaways || [],
      [(entry): number => (giveawayExpired(entry.giveaway) ? 0 : 1)],
      [`desc`]
    );
  };

  React.useEffect(() => {
    seoContext.setData({
      title: `Giveaways`,
      description: "All the current active giveaways",
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title="GIVEAWAYS" />

      <section className="block block-giveaway">
        <div className="block__list">
          {getOrderedGiveaways().map(({ giveaway }) => (
            <Link key={giveaway.id} to={`/giveaway-results/${giveaway.id}`}>
              <div className="block__list-item" key={giveaway.id}>
                <div className="block__list__inner">
                  <div className="media-auto-sized">
                    <MediaImage src={giveaway.banner} format="medium" />

                    {giveawayExpired(giveaway) && (
                      <div className="giveaway--expired">
                        <img src="images/GiveawayExpired.svg" />
                      </div>
                    )}
                  </div>
                  <div className="block__list-item__content">
                    <h2 className="block__list-item__title clickable">
                      {giveaway.title}
                    </h2>
                    <p>{giveaway.descriptionShort}</p>

                    {giveawayExpired(giveaway) && (
                      <p>
                        <strong>Closed</strong>
                      </p>
                    )}

                    {!giveawayExpired(giveaway) && (
                      <p>
                        <strong>
                          Closes <Moment fromNow>{giveaway.expiresAt}</Moment>
                        </strong>
                        {giveaway.entryCost && (
                          <>
                            <br />
                            {giveaway.entryCost} points entry fee
                          </>
                        )}
                      </p>
                    )}

                    <div className="block__list-item__btn block__list-item__btn--auto btn btn--large">
                      MORE INFO
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};

export default GiveawaysPage;
