import React, { useState } from "react";
import { StreamerLiveStreamOfferDto as Dto } from "../../../dtos/user.dto";
import ReactMde from "react-mde";
import { converter } from "../../../utils/showdown";
import StreamerLiveStreamOffer from "../streamer-live-stream-offer";
import Alert from "../../alert";

interface Properties {
  offers?: Dto[];
  onCreate: (title: string, content: string) => void | Promise<void>;
  onUpdate: (original: Dto, update: Dto) => void | Promise<void>;
  onRemove: (offer: Dto) => void | Promise<void>;
}

const LiveStreamOffers: React.FC<Properties> = ({
  offers,
  onCreate,
  onUpdate,
  onRemove,
}) => {
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [tab, setTab] = React.useState<"write" | "preview">(`write`);
  const [error, setError] = useState<string>();

  const toolbar = [["bold", "italic", "strikethrough", "quote", "link"]];

  const submit = () => {
    setError(undefined);

    // Validate inputs
    const error = (field: string, requiredCharacters: number) =>
      `Invalid ${field} (at least ${requiredCharacters} characters required)`;
    if (!title || title.length < 2) return setError(error("title", 2));
    if (!content || content.length < 5) return setError(error("content", 5));

    // Create entry through parent element
    onCreate(title, content);

    // Reset field values
    setTitle("");
    setContent("");
  };

  return (
    <>
      <div className="input-textarea__group">
        <label>Add offer</label>

        <div className="input-text__group">
          <input
            type="text"
            id="live-stream-offer__title"
            placeholder="Title"
            value={title}
            onChange={(event): void => setTitle(event.target.value)}
          />
          <label htmlFor="live-stream-offer__title">Title</label>
        </div>

        <ReactMde
          value={content}
          onChange={setContent}
          selectedTab={tab}
          onTabChange={setTab}
          toolbarCommands={toolbar}
          minEditorHeight={100}
          generateMarkdownPreview={(markdown): Promise<string> =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      </div>

      {error && <Alert style="danger" message={error} />}

      <button
        type="button"
        className="btn"
        style={{ width: `25%` }}
        onClick={() => {
          submit();
        }}
      >
        Add
      </button>

      {offers?.map((offer, index) => (
        <div key={`${offers?.length}-${index}`} className="input-text__group">
          <StreamerLiveStreamOffer
            offer={offer}
            converter={converter}
            toolbar={toolbar}
            onUpdate={(updatedOffer) => onUpdate(offer, updatedOffer)}
            onRemove={() => onRemove(offer)}
          />
          {offers?.[index + 1] ? <hr /> : null}
        </div>
      ))}
    </>
  );
};

export default LiveStreamOffers;
