import React from "react";
import classNames from "classnames";
import Menu from "./menu";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";
import ToolbarLogin from "./toolbar-login";
import DailyPoints from "./daily-points";
import { Helmet } from "react-helmet";
import { ReactSEOMetaTags } from "@comceptum-software/react-seo-meta-tags";
import { LayoutContext } from "../context/layout.context";
import { useContext, useEffect, useState } from "react";
import { globalHistory } from "@reach/router";
import { ConfirmModalContextProvider } from "./modals/confirm-modal.context";
import { LoginModalContextProvider } from "./modals/login-modal.context";
import { PostModalContextProvider } from "./modals/post-modal.context";
import { DisplayMode } from "../utils/display-mode";
import { AuthContext } from "../context/auth.context";
import { TopBannerSlider } from "./top-banner-slider/top-banner-slider";
import { FullScreenContext } from "../context/full-screen.context";
import { PrizeDropProvider } from "./prize-drop/prize-drop";
import { SeoContext } from "../context/seo.context";

export interface AdditionalSidebar {
  top: React.ReactNode;
  bottom?: React.ReactNode;
}

export interface LayoutProps {
  additionalSidebar?: AdditionalSidebar;
  menuIsOpen?: boolean;
  notificationsIsOpen?: boolean;
  displayMode?: DisplayMode;
  showTopBanner?: boolean;
  streamersLobbyOffers?: boolean;
}

const origin =
  typeof window !== "undefined" ? location.origin : "http://localhost:8000";

const metaDefaults = {
  organization: "CasinoRing",
  title: "By the players, For the players",
  url: origin,
  description:
    "Central hub for our community, a place to have interesting discussions, show off your latest wins and to get those exclusive bonusses from your favourite streamers",
  images: {
    banner: `${origin}/images/meta/banner.png`,
    logo: `${origin}/images/meta/logo.png`,
  },
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const layoutContext = React.useContext(LayoutContext);
  const { data: seo } = useContext(SeoContext);
  const { showMainMenu, showSidebarMenu } = useContext(FullScreenContext);
  const { isPartner } = React.useContext(AuthContext);

  const seoTitle = seo.title || metaDefaults.title;
  const seoDescription = seo.description || metaDefaults.description;
  const seoImage =
    seo?.images && seo?.images.banner
      ? seo?.images.banner
      : metaDefaults.images.banner;

  const social = {
    title: seoTitle,
    description: seoDescription,
    seoImage,
  };

  const containsUndefinedSeo = () => {
    return (
      seoTitle.includes("undefined") || seoDescription.includes("undefined")
    );
  };
  const website = {
    title: seo.titleOverride
      ? seoTitle
      : `${metaDefaults.organization} - ${seoTitle}`,
    description: seoDescription,
    image: seoImage,
    site: {
      siteName: metaDefaults.organization,
    },
  };

  return (
    <>
      <ReactSEOMetaTags
        render={(el: React.ReactNode) => (
          <Helmet>
            {el}
            {seo?.noIndex || containsUndefinedSeo() ? (
              <meta name="robots" content="noindex" />
            ) : null}
            {window?.location?.href ? (
              <link rel="canonical" href={window.location.href.split("?")[0]} />
            ) : null}
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/images/meta/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/images/meta/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/images/meta/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/images/meta/safari-pinned-tab.svg"
              color="#121729"
            />
            <meta name="msapplication-TileColor" content="#121729" />
            <meta name="theme-color" content="#121729" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            {seo?.languageAlternatives?.map(alternative =>
              <link
                rel="alternate"
                hrefLang={!alternative.locale ? 'x-default' : alternative.locale}
                href={`${location.origin}${alternative.uri}`}
              />
            )}
          </Helmet>
        )}
        website={website}
        organization={{
          "@type": "NewsMediaOrganization",
          description: metaDefaults.description,
          name: metaDefaults.organization,
          logo: metaDefaults.images.logo,
          url: `${location.origin}/`,
        }}
        facebook={social}
        twitter={social}
        structuredData={
          Array.isArray(seo.structuredData) ? seo.structuredData : undefined
        }
      />

      <PrizeDropProvider>
        <PostModalContextProvider>
          <ConfirmModalContextProvider>
            <LoginModalContextProvider>
              <Menu />
              <main
                className={classNames("main", {
                  "main-expanded": !showMainMenu,
                })}
              >
                <Toolbar />
                <div className="wrapper">
                  <div className="container-fluid">
                    {layoutContext.props.showTopBanner && <TopBannerSlider />}

                    <div className="row">
                      <div
                        className={classNames(
                          "col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                          "content",
                          { "content-expanded": !showSidebarMenu }
                        )}
                      >
                        {!isPartner && <DailyPoints />}
                        <ToolbarLogin />
                        {children}
                      </div>

                      <Sidebar
                        collapsed={!showSidebarMenu}
                        topChildren={
                          layoutContext?.props?.additionalSidebar?.top
                        }
                        bottomChildren={
                          layoutContext?.props?.additionalSidebar?.bottom
                        }
                      />
                    </div>
                  </div>
                </div>

                <div id="tooltips" />
              </main>
            </LoginModalContextProvider>
          </ConfirmModalContextProvider>
        </PostModalContextProvider>
      </PrizeDropProvider>
    </>
  );
};

Layout.defaultProps = {
  meta: {},
};

export default Layout;
