import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import ReactSlider from "react-slider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrizeDropDto } from "../../dtos/prize-drop.dto";
import Loading from "../loading";
import { useSavePrizeDropMutation } from "./prize-drop-settings-mutations";
import { useOpenSnackbar } from "../../utils/snackbar";
import { usePrizeDrop } from "../prize-drop/prize-drop";
import { RichTextarea } from "../../ui/form/RichTextarea";
import MediaImage from "../media-image";

export interface EditPrizeDropModalProps {
  prizeDrop?: PrizeDropDto;
  show?: boolean;
  onClose?: (committed: boolean) => void;
}

interface FormFields {
  timer: number;
  minimumViewerCount?: number;
  title: string;
  contents: string;
  winnerPageImage?: File;
  winnerPageDescription: string;
  winnerPageTitle: string;
}

const schema = Yup.object().shape({
  timer: Yup.number().min(0).max(60),
  minimumViewerCount: Yup.number().min(1).optional(),
  title: Yup.string().required(),
  contents: Yup.string().required(),
  winnerPageImage: Yup.mixed().optional(),
  winnerPageDescription: Yup.string().optional(),
  winnerPageTitle: Yup.string().optional(),
});

export const EditPrizeDropModal: React.FC<EditPrizeDropModalProps> = ({
  prizeDrop,
  show,
  onClose,
}) => {
  const { save } = useSavePrizeDropMutation();
  const { showPrizeDropPopup } = usePrizeDrop();
  const openSnackbar = useOpenSnackbar();

  const formik = useFormik<FormFields>({
    validationSchema: schema,
    initialValues: {
      timer: 0,
      minimumViewerCount: 1,
      title: "",
      contents: "",
      winnerPageDescription: "",
      winnerPageTitle: "",
    },
    onSubmit: async (values) => {
      try {
        await save({
          id: prizeDrop && prizeDrop.id,
          winnerPageImage: values.winnerPageImage,
          ...values,
        });
        onClose?.(true);
        openSnackbar({
          content: prizeDrop
            ? "Changes saved."
            : "Prize drop created successfully.",
        });
      } catch (error) {
        console.error(error);
        openSnackbar({
          content: "Something went wrong.",
        });
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        timer: prizeDrop?.timer || 0,
        minimumViewerCount: prizeDrop?.minimumViewerCount,
        title: prizeDrop?.title || "",
        contents: prizeDrop?.contents || "",
        winnerPageDescription: prizeDrop?.winnerPageDescription || "",
        winnerPageTitle: prizeDrop?.winnerPageTitle || "",
        winnerPageImage: undefined,
      },
    });
    formik.validateForm();
  }, [show]);

  const isNew = !prizeDrop;
  const isValid = !prizeDrop
    ? formik.isValid && formik.values.winnerPageImage
    : formik.isValid;
  const imagePreview = useMemo(
    () =>
      !isNew
        ? prizeDrop?.winnerPageImage?.url
        : formik.values.winnerPageImage &&
          URL.createObjectURL(formik.values.winnerPageImage),
    [prizeDrop, formik.values.winnerPageImage]
  );

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onClose}
      dialogClassName="prize-drop-modal log-centered"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {isNew ? "CREATE NEW PRIZE DROP" : "EDITING PRIZE DROP"}
          </h5>
          <button
            type="button"
            className="modal-close"
            onClick={() => onClose?.(false)}
          >
            cancel <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="modal-body">
            <div className="input-text__group">
              <input
                id="title"
                type="text"
                style={{ maxWidth: "100%" }}
                disabled={formik.isSubmitting}
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="title">Prize drop title</label>
            </div>
            <div className="input-text__group">
              <input
                id="contents"
                type="text"
                style={{ maxWidth: "100%" }}
                className="form-control"
                disabled={formik.isSubmitting}
                value={formik.values.contents}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="contents">Prize drop content description</label>
            </div>

            <div className="input-switch__group timer-switch">
              <input
                type="checkbox"
                id="switch"
                checked={formik.values.timer > 0}
                onChange={(event) =>
                  formik.setFieldValue("timer", event.target.checked ? 1 : 0)
                }
              />
              <label htmlFor="switch"></label>
              <div className="description">
                <strong>Enable timer</strong>
                <br />
                Automatically activate the prize drop when livestreaming on a
                set interval (e.g. every 10 minutes)
                {formik.values.timer > 0 && (
                  <>
                    <div className="timer-slider">
                      <div className="label">TIMER INTERVAL (IN MINUTES)</div>
                      <div className="input-group">
                        <div className="range-wrap" style={{ width: "240px" }}>
                          <ReactSlider
                            max={60}
                            min={5}
                            value={formik.values.timer}
                            thumbClassName="slider-thumb"
                            trackClassName="slider-dark-track"
                            renderThumb={(props, state) => (
                              <div {...props}>{state.valueNow}</div>
                            )}
                            onChange={(value) =>
                              formik.setFieldValue("timer", +(value || 0))
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {formik.values.timer > 0 && (
              <div className="timer-viewers" style={{ marginLeft: 70 }}>
                <div className="label">MINIMUM AMOUNT OF VIEWERS</div>
                <span className="helper-text">
                  Setting a minimum amount of viewers prevents the prize drop
                  from activating too often.
                </span>
                <div className="input-text__group">
                  <input
                    id="minimumViewerCount"
                    type="number"
                    style={{ width: "100px", paddingTop: 5 }}
                    className="form-control"
                    disabled={formik.isSubmitting}
                    value={formik.values.minimumViewerCount || 1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              borderBottom: "1px solid #121729",
              width: "100%",
              marginTop: 30,
              marginBottom: 30,
            }}
          />
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <h5
              className="modal-form__title"
              style={{ marginTop: 24, marginBottom: 12 }}
            >
              WINNER PAGE SETTINGS
            </h5>

            {(isNew || imagePreview) && (
              <div className="input-upload__group">
                <div className="input-upload__image input-upload-placeholder-image">
                  <MediaImage src={imagePreview} format="small" />
                </div>
                <div className="input-upload__file">
                  <label>WINNER PAGE IMAGE</label>

                  {isNew && (
                    <>
                      <input
                        id="winnerPageImage"
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={(event) =>
                          event.target.files &&
                          formik.setFieldValue(
                            "winnerPageImage",
                            event.target.files[0]
                          )
                        }
                      />
                      <label htmlFor="winnerPageImage">BROWSE IMAGE</label>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="input-text__group">
              <input
                id="winnerPageTitle"
                type="text"
                style={{ maxWidth: "100%" }}
                className="form-control"
                disabled={formik.isSubmitting}
                value={formik.values.winnerPageTitle || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="winnerPageTitle">Winner page title</label>
            </div>

            <label htmlFor="your-message" style={{ marginBottom: 12 }}>
              Winner page description
            </label>
            <div className="input-text__group">
              <RichTextarea
                value={formik.values.winnerPageDescription}
                disabled={formik.isSubmitting}
                onChange={(content) =>
                  formik.setFieldValue("winnerPageDescription", content)
                }
              />
            </div>
          </div>
        </form>

        <div className="modal-footer">
          <button
            type="submit"
            style={{ float: "right", marginRight: 16 }}
            className="btn btn--medium"
            disabled={formik.isSubmitting || !isValid}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </button>
          <button
            style={{ float: "right", marginRight: 16 }}
            className="btn btn--medium"
            disabled={formik.isSubmitting || !formik.values.contents}
            onClick={() =>
              showPrizeDropPopup({
                text: formik.values.contents,
                duration: 60,
                onClickCollect: () =>
                  openSnackbar({
                    content: "You clicked the collect button.",
                  }),
              })
            }
          >
            Show preview
          </button>
        </div>

        {formik.isSubmitting && (
          <div style={{ paddingBottom: 16 }}>
            <Loading title="Saving..." />
          </div>
        )}
      </div>
    </Modal>
  );
};
