import React, { useState } from "react";
import { LazySelect, LazySelectItem } from "../../components/lazy-select";
import { slotSelectorQuery } from "../../dtos/slot.dto";
import { BonushuntBonusWriteDto } from "./bonushunt.dto";

export interface BonushuntCreateComponentProps {
  onAdd: (model: BonushuntBonusWriteDto) => any;
}

export const BonushuntCreateComponent: React.FC<BonushuntCreateComponentProps> =
  ({ onAdd }) => {
    const [model, setModel] = useState<BonushuntBonusWriteDto>({});
    const [selectedSlot, setSelectedSlot] = useState<LazySelectItem>();

    return (
      <tr>
        <td width="50%" style={{ paddingTop: 12, height: 38 }}>
          <LazySelect
            placeholder="SEARCH SLOT"
            query={slotSelectorQuery}
            selected={selectedSlot}
            onSelect={(slotId): void => setModel({ ...model, slot: slotId })}
            onSelectItem={(slot): void => setSelectedSlot(slot)}
          />
        </td>
        <td width="50%" style={{ paddingTop: 12 }}>
          <input
            style={{ width: 100, height: 38 }}
            type="number"
            className="with-design"
            placeholder="BET SIZE"
            value={model.betSize || ""}
            onChange={(event): void =>
              setModel({
                ...model,
                betSize: event.target.valueAsNumber,
              })
            }
          />

          <button
            type="button"
            disabled={model.betSize == null || model.slot == null}
            onClick={async (): Promise<void> => {
              await onAdd(model);

              setSelectedSlot(undefined);
              setModel({});
            }}
            className="btn"
            style={{
              height: 36,
              margin: 0,
              marginLeft: 12,
              border: "none",
            }}
          >
            Add
          </button>
        </td>
      </tr>
    );
  };
