import React, { useContext } from "react";
import { AuthContext } from "../context/auth.context";
import { UserDtoType } from "../dtos/user.dto";
import ErrorPage from "../pages/error";
import { navigate } from "gatsby";

interface ConditionalRouteProps {
  path: string;
  component: any;
  auth?: boolean;
  privileged?: boolean;
  redirect?: boolean;
}

const ConditionalRoute: React.FC<ConditionalRouteProps> = ({
  component: Component,
  auth,
  privileged,
  redirect,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const isPrivileged =
    authContext.user?.type === UserDtoType.Streamer ||
    authContext.user?.role?.type === "moderator";
  const hasAuthToken = !!authContext.jwt;

  if (typeof auth !== "undefined" && auth !== hasAuthToken && redirect) {
    navigate("/");
  }

  if (
    (typeof auth !== "undefined" && auth !== hasAuthToken) ||
    (privileged && !isPrivileged)
  ) {
    return <ErrorPage />;
  }

  return <Component {...rest} />;
};

export default ConditionalRoute;
