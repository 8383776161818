import * as React from "react";
import Post from "../components/post";
import Loading from "../components/loading";
import Infinity from "../components/infinity";
import { useQuery } from "@apollo/client";
import { PostDto } from "../dtos/post.dto";
import { useContext, useState } from "react";
import { slotSelectorQuery } from "../dtos/slot.dto";
import { RouteProps } from ".";
import { first } from "lodash";
import { LazySelect } from "../components/lazy-select";
import Alert, { somethingWentWrong } from "../components/alert";
import Title from "../components/title";
import {
  CommunityHighlightsQuery,
  CommunityHighlightsQueryPageSize,
} from "../graphql/queries/community-highlight-query";
import Slider from "../components/slider";
import { arrayWithout } from "../utils/array";
import { SeoContext } from "../context/seo.context";
import {
  ModeratorFilters,
  QueryFilter,
} from "../components/community-highlights/moderator-filters";
import { AuthContext } from "../context/auth.context";

interface CommunityHighlightsPageProps extends RouteProps {}

const CommunityHighlightsPage: React.FC<CommunityHighlightsPageProps> = () => {
  const seoContext = useContext(SeoContext);
  const authContext = useContext(AuthContext);
  const isModerator = authContext.user?.role?.type === "moderator";

  const [posts, setPosts] = React.useState<PostDto[]>([]);

  const [won, setWon] = useState<number>(0);
  const [wonMax, setWonCap] = useState(100);

  const [slotId, setSlotId] = useState<string>();

  const getQueryVariables = (start?: number) => ({
    start,
    where: {
      active_ne: false,
      showOnHighlights: true,
      multiplier_gte: won,
      processed_ne: false,
      ...(slotId ? { slot: slotId } : {}),
    },
    limit: CommunityHighlightsQueryPageSize,
    sort: "multiplier:DESC,createdAt:DESC",
  });

  const { data, loading, error, fetchMore, refetch } = useQuery<{
    posts: PostDto[];
    highest: PostDto[];
  }>(CommunityHighlightsQuery, {
    variables: getQueryVariables(),
    onCompleted: (data) => {
      setPosts(data?.posts || []);
    },
  });

  const loadMore = () => {
    return fetchMore({
      variables: getQueryVariables(posts.length),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setPosts([...posts, ...fetchMoreResult.posts]);

        return prev;
      },
    });
  };

  const [moderatorMode, setModeratorMode] = useState<boolean>(false);

  const handleModeratorFilter = async (filter: QueryFilter) => {
    setModeratorMode(true);
    const query = getQueryVariables();

    const { data } = await refetch({
      ...query,
      start: undefined,
      limit: filter.videoOnly ? 1000 : 500, // Hard limit
      where: {
        ...query.where,
        createdAt_gte: filter.dateFrom,
        createdAt_lte: filter.dateTill,
        videos_null: filter.videoOnly ? false : undefined,
      },
      sort: filter.sort ? filter.sort : query.sort,
    });

    setPosts(
      data?.posts?.filter((post) =>
        filter.videoOnly ? post?.videos?.length !== 0 : true
      ) || []
    );
  };

  React.useEffect(() => {
    if (data?.highest) {
      const cap = first(data.highest)?.multiplier || 100;

      setWonCap(Math.min(cap, 5000));
    }
  }, [data]);

  React.useEffect(() => {
    seoContext.setData({
      title: "Community Highlights",
      description: "All the highlights of our community",
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title="COMMUNITY HIGHLIGHTS" />

      <section className="header">
        {isModerator && <ModeratorFilters onUpdate={handleModeratorFilter} />}

        <div
          className="form-inline"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ flex: 1 }}>
            <label htmlFor="members">AMOUNT X WON</label>
            <div className="input-group">
              <Slider
                value={won}
                max={wonMax}
                onChange={(newValue) => setWon(newValue)}
              />
            </div>
          </div>

          <div style={{ width: 32 }}></div>
          <div style={{ flex: 1, maxWidth: "50%", paddingTop: 28 }}>
            <LazySelect
              query={slotSelectorQuery}
              placeholder="SEARCH SLOT"
              onSelect={(slotId) => setSlotId(slotId)}
            />
          </div>
        </div>
      </section>

      {moderatorMode ? (
        posts.map((post) => (
          <Post
            key={post.id}
            post={post}
            onDeleted={() => setPosts(arrayWithout(post, posts))}
          />
        ))
      ) : (
        <Infinity
          data={posts}
          pageSize={CommunityHighlightsQueryPageSize}
          fetchMore={() => loadMore()}
        >
          {posts.map((post) => (
            <Post
              key={post.id}
              post={post}
              onDeleted={() => setPosts(arrayWithout(post, posts))}
            />
          ))}
        </Infinity>
      )}
    </>
  );
};

export default CommunityHighlightsPage;
