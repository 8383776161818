import React from "react";
import { DemoGamesReviewForm } from "./review/form";
import { DemoGamesReviewEntries } from "./review/entries";

interface Props {
  gameId: string;
}

export const DemoGamesReview = ({ gameId }: Props) => (
  <div className="reviews">
    <DemoGamesReviewForm gameId={gameId} />
    <DemoGamesReviewEntries gameId={gameId} />
  </div>
)