import React, { FC, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RouteProps } from "../index";
import { SeoContext } from "../../context/seo.context";
import Loading from "../../components/loading";
import { CasinoCTA, casinoDetailQuery, CasinoDto } from "../../dtos/casino.dto";
import WysiwygContent from "../../components/wysiwyg-content";
import MediaImage from "../../components/media-image";
import { first } from "lodash";
import ErrorPage from "../error";
import Dropdown from 'react-dropdown';
import { navigate } from "@reach/router";

interface CTAProperties {
  data: CasinoCTA;
}

const CasinoDetailPageCTA: FC<CTAProperties> = ({ data }) => (
  <section className="banner-container">
    <div className="banner-container_content">
      {data.title && <h2>{data.title}</h2>}
      {data.content && <p>{data.content}</p>}
    </div>
    {data.url && data.button && (
      <div className="banner-container_action">
        <a href={data.url!} className="btn btn-medium">
          {data.button}
        </a>
      </div>
    )}
  </section>
);

const CasinoDetailPageLanguageSelectorLocale = ({ code }: { code: string }) => (
  <img
    style={{
      height: 25
    }}
    className="modal-header__logo"
    src={`/images/localisation/flags/${code.toLowerCase()}.svg`}
  />
)

const CasinoDetailPageLanguageSelector = ({ current, update }: { current: string, update: (value) => void }) => {
  const options = [
    { value: 'en', label: <CasinoDetailPageLanguageSelectorLocale code="gb" /> },
    { value: 'nl', label: <CasinoDetailPageLanguageSelectorLocale code="nl" /> }
  ]

  return (
    <div className="language-selector">
      <span>{current === 'nl' ? 'Selecteer taal' : 'Select language'}</span>
      <Dropdown options={options} value={current} onChange={({ value }) => update(value) } />
    </div>
  )
}

interface Properties extends RouteProps {
  slug?: string;
  locale?: string;
}

const CasinoDetailPage: FC<Properties> = ({ slug, locale }) => {
  const seoContext = useContext(SeoContext);

  const { data, loading, error } = useQuery<{
    casinos: CasinoDto[];
  }>(casinoDetailQuery, {
    variables: { slug, locale },
  });

  const { slug: slugEN, slugNL } = first(data?.casinos) || {}
  const current = first(data?.casinos)?.[`detail${locale ? locale.toUpperCase() : ''}`];
  const isMultiLanguage = !!first(data?.casinos)?.detailNL?.id

  const {
    title,
    intro,
    teaser,
    content,
    structuredData,
    metaTitle,
    metaDescription,
    topCTA,
    bottomCTA
  } = current || {};

  const setLocale = (locale: string) => navigate(locale === 'nl' ? `/nl/reviews/${slugNL}` : `/reviews/${slugEN}`)

  useEffect(() => {
    if (current) {
      seoContext.setData({
        title: metaTitle || title,
        titleOverride: true,
        description: metaDescription || intro,
        structuredData,
        languageAlternatives: isMultiLanguage ? [
          {
            uri: `/reviews/${slugEN}`
          },
          {
            locale: 'en',
            uri: `/reviews/${slugEN}`
          },
          {
            locale: 'nl',
            uri: `/nl/reviews/${slugNL}`
          },
        ] : []
      });
    }
  }, [current]);

  if (loading) return <Loading />;
  if (error || !current) return <ErrorPage />;

  return (
    <>
      <section className="page-content page-content-single">
        <div className="page-content__title-wrapper">
          <h1 className="page-content__extratitle">{title || ""}</h1>
          {
            isMultiLanguage &&
            <CasinoDetailPageLanguageSelector
              current={locale ? locale : 'en'}
              update={setLocale}
            />
          }
        </div>
        <div className="page-content__text">
          {intro && <p className="page-content__subtitle">{intro}</p>}
          {topCTA && <CasinoDetailPageCTA data={topCTA} />}
          {teaser && (
            <div className="media-auto-sized media-auto-sized-margin">
              <MediaImage
                src={teaser}
                alt={teaser?.alternativeText || undefined}
                format="large"
              />
            </div>
          )}
          {content && <WysiwygContent html={content} />}
          {bottomCTA && <CasinoDetailPageCTA data={bottomCTA} />}
        </div>
      </section>
    </>
  );
};

export default CasinoDetailPage;
