import * as React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { AuthContext } from "../context/auth.context";
import { useContext, useEffect } from "react";
import StreamerCarousel from "./streamer-carousel";
import { LayoutContext } from "../context/layout.context";
import { ContentContext } from "../context/refresh.context";
import { useLocation, navigate, globalHistory } from "@reach/router";
import GiveawayActiveCount from "./giveaway-active-count";
import { usePostModalContext } from "./modals/post-modal.context";
import { Tooltip } from "./tooltip";
import { PostModal, PostModalMode } from "./modals/post-modal";
import { useLoginModal } from "./modals/login-modal.context";
import { MarketingModalButton } from "./marktering-modal-button";
import { FullScreenContext } from "../context/full-screen.context";
import { DemoGamesNewIndicator } from "./demo-games/new-indicator";
import { StreamersLobbyMenuSlider } from "./streamerslobby/menu-slider";

const Menu: React.FC = () => {
  const { isPartner, userId, logout, isStreamer } = useContext(AuthContext);
  const { showMainMenu, allowMainMenu } = useContext(FullScreenContext);
  const layoutContext = useContext(LayoutContext);
  const { requestRefresh } = useContext(ContentContext);
  const { open, render } = usePostModalContext();
  const { openLoginModal, runOrOpenLoginModal } = useLoginModal();
  const location = useLocation();

  useEffect(() => {
    return globalHistory.listen(() => {
      layoutContext.updateProps({ menuIsOpen: false });
    });
  }, []);

  return (
    <>
      <header
        className={classNames({
          "header--opened": layoutContext.props.menuIsOpen,
          "header-collapsed": !showMainMenu && allowMainMenu,
        })}
      >
        <div className="header-logo">
          <a
            className="header-logo-link"
            onClick={() =>
              location.pathname === "/" ? requestRefresh() : navigate("/")
            }
          >
            <img
              className="header-logo-link__light-mode"
              src="/images/logo-blue.svg"
            />
            <img
              className="header-logo-link__dark-mode"
              src="/images/logo-gold.svg"
            />
          </a>
        </div>

        {/*<div className="header-streamers">*/}
        {/*  <StreamersLobbyMenuSlider />*/}
        {/*</div>*/}
        <div style={{ height: 15 }} />

        <div className="header-buttons">
          <ul className="header-buttons__list">
            <li className="header-buttons__list-item">
              {isPartner ? (
                <MarketingModalButton />
              ) : (
                <Tooltip text={open && "Your post is being uploaded."}>
                  <a
                    className={classNames("btn", { disabled: open })}
                    onClick={() =>
                      runOrOpenLoginModal(() =>
                        render(
                          <PostModal
                            mode={PostModalMode.Post}
                            key={new Date().getTime()}
                          />
                        )
                      )
                    }
                  >
                    CREATE NEW POST
                  </a>
                </Tooltip>
              )}
            </li>
          </ul>
        </div>

        <div className="header-menu">
          <ul className="header-menu__list">
            <li className="header-menu__list-item clickable">
              <Link to="/reviews">CASINOS</Link>
            </li>
            {/*<li className="header-menu__list-item clickable">*/}
            {/*  <Link to="/streamerslobby">STREAMERSLOBBY</Link>*/}
            {/*</li>*/}
            <li className="header-menu__list-item clickable">
              <Link to="/demo-games">DEMO GAMES</Link>
              <DemoGamesNewIndicator />
            </li>
            <li className="header-menu__list-item clickable">
              <Link to="/giveaways">GIVEAWAYS</Link>
              <GiveawayActiveCount />
            </li>
            <li className="header-menu__list-item clickable">
              <Link to="/streamer-highlights">STREAMERS HIGHLIGHTS</Link>
            </li>
            <li className="header-menu__list-item clickable">
              <Link to="/community-highlights">COMMUNITY HIGHLIGHTS</Link>
            </li>
            {!isStreamer && (
              <li className="header-menu__list-item clickable">
                <Link to="/register/streamer">BECOME A STREAMER</Link>
              </li>
            )}
            <li className="header-menu__list-item clickable">
              <Link to="/help-advice">HELP & ADVICE</Link>
            </li>
            <li className="header-menu__list-item header-menu__list-item--divider"></li>
            {userId ? (
              <>
                {isPartner && (
                  <li className="header-menu__list-item clickable">
                    <Link to="/partnership">PARTNERSHIP</Link>
                  </li>
                )}
                <li className="header-menu__list-item clickable">
                  <Link to={`/profile/${userId}/`}>MY PROFILE</Link>
                </li>
                <li className="header-menu__list-item clickable">
                  <Link to={`/settings/`}>SETTINGS</Link>
                </li>
                {isStreamer && (
                  <li className="header-menu__list-item clickable">
                    <Link to={`/settings/streamer/`}>STREAMER SETTINGS</Link>
                  </li>
                )}
                <li className="header-menu__list-item clickable">
                  <a className="clickable" onClick={logout}>
                    LOGOUT
                  </a>
                </li>
              </>
            ) : (
              <>
                <li className="header-menu__list-item">
                  <a className="clickable" onClick={() => openLoginModal()}>
                    MY PROFILE
                    <span>LOG IN TO SEE YOU PROFILE</span>
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </header>
    </>
  );
};

export default Menu;
