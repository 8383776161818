import React from "react";
import DOMPurify from "dompurify";
import Video from "./video";

interface WysiwygContentProps {
  html?: string;
}

const WysiwygContentComponent: React.FC<WysiwygContentProps> = ({ html }) => {
  const getElements = () => {
    if (!html) return;

    const elements: JSX.Element[] = [];
    const parts = html.split(/(\|%=video\/.*?=%\|)/g);

    for (const part of parts) {
      const match = part.match(/\|%=video\/(.*?)=%\|/);

      if (match && match[1]) {
        elements.push(
          <Video url={window.location.href} data={{ providerId: match[1] }} />
        );
      } else {
        elements.push(
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }} />
        );
      }
    }

    return elements;
  };

  return <>{getElements()}</>;
};

export default WysiwygContentComponent;
