import { gql } from "@apollo/client";
import { bonushuntFindOneFields } from "./bonushunt.query";

export const bonushuntUpdate = gql`
  mutation bonushuntUpdate($input: updateUserInput!) {
    updateUser(input: $input) {
      user {
        ${bonushuntFindOneFields}
      }
    }
  }
`;
