import React, { useEffect, useState } from "react";
import { isBrowser } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "./alert";
import Loading from "./loading";

interface InfinityProps {
  data: any[];
  pageSize: number;
  fetchMore: () => Promise<any>;
  target?: string;
  disableEndMessage?: boolean;
  disableInfinite?: boolean;
}

const Infinity: React.FC<InfinityProps> = ({
  data,
  children,
  fetchMore,
  pageSize,
  target,
  disableEndMessage,
  disableInfinite
}) => {
  const [previousLength, setPreviousLength] = useState<number>();
  const [hasMore, setHasMore] = useState(true);
  const [allowInfinite] = useState(typeof disableInfinite === 'undefined' ? isBrowser : !disableInfinite);

  useEffect(() => {
    if (typeof previousLength === "number") {
      const added = data.length - previousLength;
      setHasMore((added || data.length) === pageSize);
    }

    setPreviousLength(data.length);
  }, [data]);

  return (
    <InfiniteScroll
      style={{ overflow: "hidden" }}
      scrollableTarget={target}
      dataLength={data.length}
      next={fetchMore}
      hasMore={allowInfinite && hasMore}
      endMessage={
        allowInfinite || !hasMore ? (
          !disableEndMessage && (
            <Alert message="You've reached the end" style="info" />
          )
        ) : (
          <Alert onClick={() => fetchMore()} message="LOAD MORE" style="info" />
        )
      }
      loader={<Loading />}
    >
      {children}
    </InfiniteScroll>
  );
};

export default Infinity;
