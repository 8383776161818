import React, { useState } from "react";
import ReactMde from "react-mde";
import Showdown from "showdown";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export interface RichTextareaProps {
  value: string;
  onChange: (value: string) => void;
  minHeight?: number;
  disabled?: boolean;
}

export const RichTextarea: React.FC<RichTextareaProps> = ({
  value,
  onChange,
  minHeight = 100,
  disabled,
}) => {
  const [tab, setTab] = useState<"write" | "preview">("write");

  return (
    <ReactMde
      value={value}
      onChange={onChange}
      selectedTab={tab}
      onTabChange={setTab}
      minEditorHeight={minHeight}
      readOnly={disabled}
      toolbarCommands={[
        [`header`, `bold`, `italic`, `strikethrough`],
        [`quote`, `code`],
        [`unordered-list`, `ordered-list`, `checked-list`],
      ]}
      generateMarkdownPreview={async (markdown) => converter.makeHtml(markdown)}
    />
  );
};
