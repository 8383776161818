import * as React from "react";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import Loading from "../../loading";
import { useMutation } from "@apollo/client";
import { createGiveawayAnswerMutation } from "../../../dtos/giveaway-answer.dto";
import { AuthContext } from "../../../context/auth.context";
import { usePollStepperContext } from "./context";
import { PollStepWrapper } from "./step-wrapper";

interface Props {
  giveawayId: string;
  rulesDispatcher: Dispatch<SetStateAction<boolean>>;
  onUpdate: () => void;
}

export const PollSubmitStep = ({
  giveawayId,
  rulesDispatcher,
  onUpdate,
}: Props) => {
  const { format } = usePollStepperContext();
  const { userId } = useContext(AuthContext);
  const [terms, setTerms] = useState(false);

  const [mutate, { loading }] = useMutation(createGiveawayAnswerMutation);

  const submit = async () => {
    const response = await mutate({
      variables: {
        input: {
          owner: userId,
          giveaway: giveawayId,
          answers: format(),
        },
      },
    });

    if (response) await onUpdate();
  };

  const disabled = loading || !terms;

  return (
    <PollStepWrapper>
      <h5>Submit answers</h5>
      <p>After submitting your answers you will participate in the giveaway</p>

      <div className="input-switch__group">
        <input
          type="checkbox"
          name="terms"
          id="terms"
          value="terms"
          checked={terms}
          onChange={(event) => setTerms(event.target.checked)}
        />
        <label htmlFor="terms" />
        <p onClick={() => rulesDispatcher(true)}>
          <strong>Rules</strong>
          <br />I HAVE READ AND ACCEPT THE{" "}
          <a style={{ cursor: "pointer", textDecoration: "underline" }}>
            RULES &amp; CONDITIONS
          </a>{" "}
          OF THE GIVEAWAY
        </p>

        <button
          type="submit"
          className="btn submit"
          disabled={disabled}
          onClick={submit}
        >
          JOIN GIVEAWAY
        </button>

        {loading && <Loading />}
      </div>
    </PollStepWrapper>
  );
};
