import * as React from "react";
import { navigate } from "gatsby";
import { AuthContext, ErrorResponse } from "../context/auth.context";
import Loading from "../components/loading";
import AlertError from "../components/alert-error";

interface AuthCallbackPageProps {
  path: string;
  type: string;
}

const AuthCallbackPage: React.FC<AuthCallbackPageProps> = ({ type }) => {
  const authContext = React.useContext(AuthContext);
  const [error, setError] = React.useState<ErrorResponse>();

  const redeem = async () => {
    const response = await authContext.callback?.(type);

    if (response?.jwt) {
      navigate("/");
    } else {
      setError(response);
    }
  };

  React.useEffect(() => {
    redeem();
  }, []);

  if (error) return <AlertError error={error} />;

  return <Loading />;
};

export default AuthCallbackPage;
