import React from "react";
import {Link} from "gatsby";

interface Properties {
    onHide: () => void
}

export const Quotation: React.FC<Properties> = ({ onHide }) => (
    <div className="modal-marketing-footer">
        <div className="modal-marketing-footer__content">
            <h2>
                Looking to upgrade your credits?
            </h2>
            <p>
                Upgrade your credits now by contacting CasinoRing for a customized quote
            </p>
        </div>

        <div className="modal-marketing-footer__action">
            <Link to="/partnership" className="btn btn-medium" onClick={() => onHide()}>
                CUSTOM QUOTE
            </Link>
        </div>
    </div>
);
