import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

interface ProgressButtonProps {
  onClick: () => void | Promise<void>;
  disabled?: boolean;
  disableLoader?: boolean;
  progress?: number | null;
  style?: any;
  error?: string;
  children?: ReactNode;
}

const ProgressButton: React.FC<ProgressButtonProps> = ({
  onClick,
  disabled,
  disableLoader,
  progress,
  style,
  error,
  children,
}) => {
  const [internalProgress, setInternalProgress] = useState<number | null>();
  const [internalError, setInternalError] = useState<string | null>();

  const generateRandomUpdatePointer = () => Math.floor(Math.random() * 15) + 5;

  useEffect(() => {
    setInternalError(error);
    setInternalProgress(null);
    setTimeout(() => {
      setInternalError(null);
    }, 5000);
  }, [error]);

  useEffect(() => {
    if (disableLoader) return;
    if (!progress) {
      setInternalProgress(null);
      return;
    }

    if (!internalProgress || progress === 100) {
      setInternalProgress(progress);
      return;
    }

    const difference = progress - internalProgress;
    if (difference > generateRandomUpdatePointer())
      setInternalProgress(progress);
  }, [progress]);

  return (
    <button
      className={classNames([
        "btn",
        "btn--medium",
        "btn--progress",
        internalProgress ? "btn--progress-working" : null,
        internalError ? "btn--progress-error" : null,
      ])}
      style={{
        ...style,
        ...{
          backgroundPositionX: internalProgress
            ? `${100 - internalProgress}%`
            : "",
        },
      }}
      disabled={disabled}
      onClick={() => {
        if (internalError || internalProgress) return;
        return onClick();
      }}
    >
      <div className="btn--progress-animation" />
      {internalProgress && internalProgress !== 100 && !internalError
        ? `${internalProgress}%`
        : null}
      {!internalProgress && !internalError ? children : null}
      {internalProgress === 100 && !internalError ? "PLEASE WAIT" : null}
      {internalError ? internalError : null}
    </button>
  );
};

export default ProgressButton;
