import * as React from "react";
import { useWizard } from "react-use-wizard";
import { GiveawayQuestion } from "../../../dtos/giveaway.dto";
import { usePollStepperContext } from "./context";
import { useEffect, useState } from "react";
import { PollStepWrapper } from "./step-wrapper";

interface Props {
  question: GiveawayQuestion;
  count: number;
}

export const PollStep = ({ question, count }: Props) => {
  const { submit } = usePollStepperContext();
  const [value, setValue] = useState<string>();
  const { activeStep, previousStep, nextStep } = useWizard();

  const answers = question.answers || [];

  useEffect(() => {
    if (!value) return;
    submit(activeStep, { question: question.content, value });
    nextStep();
  }, [value]);

  return (
    <PollStepWrapper>
      <p className="count">
        {activeStep + 1} / {count}
      </p>
      <h5>{question.content}</h5>
      <p>Select a answer below based on your opinion</p>
      <select onChange={(event) => setValue(event.target.value)}>
        <option value="" disabled selected>
          Your answer
        </option>
        {answers.map((answer) => (
          <option value={answer.content}>{answer.content}</option>
        ))}
      </select>

      {activeStep !== 0 && (
        <div className="buttons">
          <button onClick={previousStep}>Previous</button>
        </div>
      )}
    </PollStepWrapper>
  );
};
