import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { demoGameLatestQuery } from "../../dtos/demo-games.dto";
import { first } from "lodash";
import moment from "moment";
import { globalHistory } from "@reach/router";

export const DemoGamesNewIndicator = () => {
  const key = 'demo-games:latest'
  const { data } = useQuery(demoGameLatestQuery, { pollInterval: 15000 });
  const { id, createdAt } = first(data?.entries) || {};
  const local = localStorage.getItem(key);
  const valid = (id && createdAt) && (id !== local) && moment().diff(moment(createdAt), 'days') < 2

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      if (id && location.pathname === '/demo-games') localStorage.setItem(key, id)
    });
  }, [id]);

  return valid ? (
    <span className="header-menu__list-item__badge header-menu__list-item__badge-new">
      NEW
    </span>
  ) : null;
};