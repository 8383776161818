import { gql } from "@apollo/client";

export const ResetPasswordMutation = gql`
  mutation resetPasswordMutation(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
      user {
        id
      }
    }
  }
`;

export interface ResetPasswordUserDto {
  jwt: string;
  user: {
    id: string;
  };
}
