import React, {useEffect, useState} from "react";

interface Properties {
    onContentChange: (content: string) => void;
    initialContent?: string;
}

export const Link: React.FC<Properties> = ({ onContentChange, initialContent }) => {
    const [content, setContent] = useState(initialContent ? initialContent : '');
    useEffect(() => onContentChange(content), [content]);

    return (
        <div className="modal-input input-text__group">
            <input
                type="text"
                id="link"
                placeholder="Link"
                value={content}
                onChange={(event): void =>
                    setContent(event.target.value)
                }
            />
            <label htmlFor="link">Link (e.g. company-name.com)</label>
        </div>
    )
}
