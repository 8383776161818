import React, { useContext } from "react";
import StreamerBonusOffer from "./streamer-bonus-offer";
import { gql, useQuery } from "@apollo/client";
import { SortableCasinosDto } from "../../dtos/casino.dto";
import { mediaFields } from "../../dtos/media.dto";
import { LocalisationFlag } from "../localisation-flag";
import { LocationContext } from "../../context/location.context";
import Loading from "../loading";

export const GlobalOffers = () => {
  const location = useContext(LocationContext);

  const { data, loading } = useQuery<{ sortedCasino: SortableCasinosDto }>(gql`
      query GlobalOffersQuery {
          sortedCasino {
              SortedCasinos {
                  casino {
                      id
                      affiliate {
                          slug
                      }
                      bonusOffer
                      logo {
                          ${mediaFields}
                      }
                      hideFromHomepage
                  }
              }
          }
      }
  `);

  const getBonusOffers = () => {
    if (!data?.sortedCasino?.SortedCasinos) return [];
    return data.sortedCasino.SortedCasinos.filter(({ casino }) => {
      return casino.affiliate?.slug && casino.bonusOffer && !casino.hideFromHomepage;
    });
  };

  return (
    <section className="profile-bonus-offers">
      <h3 style={{ marginBottom: 7.5, display: "flex" }}>
        STREAMER BONUS OFFERS
        {location.data && (
          <LocalisationFlag data={location.data.geoContentLocation} />
        )}
      </h3>
      {loading && <Loading />}
      {getBonusOffers().length < 1 ? (
        <p>
          There are no bonus offers available yet for your location
        </p>
      ):  <div className="row">
        {getBonusOffers().map(({ casino }) => (
          <StreamerBonusOffer key={casino.id} data={{ id: casino.id, bonusOffer: casino.bonusOffer, casino, url: casino.affiliate?.slug }} />
        ))}
      </div>}

    </section>
  )
}