import { ApolloClient, ApolloError } from "@apollo/client";
import { first } from "lodash";
import { Bloc } from "../../bloc/bloc";
import { UserDto } from "../../dtos/user.dto";
import { BonushuntReadDto, BonushuntWriteDto } from "./bonushunt.dto";
import {
  BonushuntAddBonusEvent,
  BonushuntEditEvent,
  BonushuntEvent,
  BonushuntLoadRequestEvent,
  BonushuntRemoveBonusEvent,
} from "./bonushunt.event";
import { bonushuntUpdate } from "./bonushunt.mutation";
import { bonushuntFindOne } from "./bonushunt.query";
import {
  BonushuntInitialState,
  BonushuntLoadFailureState,
  BonushuntLoadSuccessState,
  BonushuntState,
} from "./bonushunt.state";

export class BonushuntBloc extends Bloc<BonushuntEvent, BonushuntState> {
  constructor(protected readonly apollo: ApolloClient<object>) {
    super(new BonushuntInitialState());
  }

  async *mapEventToState(event: BonushuntEvent): AsyncIterable<BonushuntState> {
    if (event instanceof BonushuntLoadRequestEvent) {
      yield* this._mapLoadRequestToState(event);
    }

    if (event instanceof BonushuntAddBonusEvent) {
      yield* this._mapAddBonusToState(event);
    }

    if (event instanceof BonushuntRemoveBonusEvent) {
      yield* this._mapRemoveBonusToState(event);
    }

    if (event instanceof BonushuntEditEvent) {
      yield* this._mapEditToState(event);
    }
  }

  protected async *_mapLoadRequestToState(
    event: BonushuntLoadRequestEvent
  ): AsyncIterable<BonushuntState> {
    const { data, error } = await this.apollo.query<{
      users: UserDto[];
    }>({
      query: bonushuntFindOne,
      variables: { slug: event.props.slug.toLowerCase() },
    });

    if (error instanceof ApolloError || data.users.length === 0) {
      yield new BonushuntLoadFailureState();
    } else if (data) {
      const user = first(data.users) as UserDto;

      user.bonushuntPage = user.bonushuntPage || { bonuses: [] };

      yield new BonushuntLoadSuccessState({ user });
    }
  }

  protected async *_mapAddBonusToState(
    event: BonushuntAddBonusEvent
  ): AsyncIterable<BonushuntState> {
    const state = this.state.value as BonushuntLoadSuccessState;
    const bonushunt = state.props.user.bonushuntPage as BonushuntReadDto;

    const { data } = await this.apollo.mutate<{
      updateUser: { user: UserDto };
    }>({
      mutation: bonushuntUpdate,
      variables: {
        input: {
          where: { id: state.props.user.id },
          data: {
            bonushuntPage: {
              ...this.props(bonushunt),
              bonuses: [
                ...(state.props.user.bonushuntPage?.bonuses || []).map(
                  (bonus) => ({
                    slot: bonus.slot?.id,
                    betSize: bonus.betSize,
                  })
                ),
                event.props.bonus,
              ],
            },
          },
        },
      },
    });

    if (data && data.updateUser && data.updateUser.user) {
      yield new BonushuntLoadSuccessState({
        user: data.updateUser.user,
      });
    } else {
      yield new BonushuntLoadFailureState();
    }
  }

  protected async *_mapRemoveBonusToState(
    event: BonushuntRemoveBonusEvent
  ): AsyncIterable<BonushuntState> {
    const state = this.state.value as BonushuntLoadSuccessState;
    const bonushunt = state.props.user.bonushuntPage as BonushuntReadDto;

    const { data } = await this.apollo.mutate<{
      updateUser: { user: UserDto };
    }>({
      mutation: bonushuntUpdate,
      variables: {
        input: {
          where: { id: state.props.user.id },
          data: {
            bonushuntPage: {
              ...this.props(bonushunt),
              bonuses: [
                ...(state.props.user.bonushuntPage?.bonuses || [])
                  .filter((_, index) => event.props.index !== index)
                  .map((bonus) => ({
                    slot: bonus.slot?.id,
                    betSize: bonus.betSize,
                  })),
              ],
            },
          },
        },
      },
    });

    if (data && data.updateUser && data.updateUser.user) {
      yield new BonushuntLoadSuccessState({
        user: data.updateUser.user,
      });
    } else {
      yield new BonushuntLoadFailureState();
    }
  }

  protected async *_mapEditToState(
    event: BonushuntEditEvent
  ): AsyncIterable<BonushuntState> {
    const state = this.state.value as BonushuntLoadSuccessState;
    const bonushunt = state.props.user.bonushuntPage as BonushuntReadDto;

    const { data } = await this.apollo.mutate<{
      updateUser: { user: UserDto };
    }>({
      mutation: bonushuntUpdate,
      variables: {
        input: {
          where: { id: state.props.user.id },
          data: {
            bonushuntPage: {
              ...this.props(bonushunt),
              bonuses: (state.props.user.bonushuntPage?.bonuses || []).map(
                (bonus) => ({
                  slot: bonus.slot?.id,
                  betSize: bonus.betSize,
                })
              ),
              ...event.props.data,
            },
          },
        },
      },
    });

    if (data && data.updateUser && data.updateUser.user) {
      yield new BonushuntLoadSuccessState({
        user: data.updateUser.user,
      });
    } else {
      yield new BonushuntLoadFailureState();
    }
  }

  protected propOrNothing(value: any, key: string): any {
    return value == null ? {} : { [key]: value };
  }

  protected props(bonushunt: BonushuntReadDto): BonushuntReadDto {
    return {
      ...this.propOrNothing(bonushunt.date, "date"),
      ...this.propOrNothing(bonushunt.startingBalance, "startingBalance"),
      ...this.propOrNothing(bonushunt.casinoName, "casinoName"),
      ...this.propOrNothing(bonushunt.casinoUrl, "casinoUrl"),
    };
  }
}
