import React from "react";
import { Link } from "gatsby";
import { UserDto } from "../../../dtos/user.dto";
import FollowButton from "../../follow-button";
import MediaImage from "../../media-image";

interface ProfileFollowerSidebarProps {
  user?: UserDto;
  followerCount?: number;
  followingCount?: number;
}

const ProfileFollowerSidebar: React.FC<ProfileFollowerSidebarProps> = ({
  user,
  followerCount,
  followingCount,
}) => {
  return (
    <>
      <div className="account-friends">
        <h4 className="account-friends__title">Followers</h4>
        <div className="account-friends__list">
          {user?.followers?.map((follower) => (
            <div key={follower.id} className="account-friends__list-item">
              <Link
                to={`/profile/${follower.owner?.id}`}
                className="account-friends__list-item__image"
              >
                <MediaImage
                  src={follower.owner?.avatar}
                  format="small"
                  alt={follower.owner?.profileName}
                  isClickable
                />
              </Link>
              <div className="account-friends__list-item__content">
                <Link to={`/profile/${follower.owner?.id}`}>
                  {follower?.owner?.profileName}
                </Link>
                <br />
                <FollowButton
                  size="small"
                  userId={follower?.owner?.id}
                ></FollowButton>
              </div>
            </div>
          ))}
        </div>

        <div className="account-socials">
          <div className="account-friends__more">
            <Link
              style={{ display: "block", marginBottom: 8 }}
              className="clickable highlight-text"
              to={`/profile/${user?.id}/followers/`}
            >
              SHOW ALL FOLLOWERS [{followerCount || 0}]
            </Link>
            <Link
              style={{ display: "block", marginBottom: 8 }}
              className="clickable highlight-text"
              to={`/profile/${user?.id}/following/`}
            >
              FOLLOWING [{followingCount || 0}]
            </Link>
          </div>

          <br />
        </div>
      </div>
    </>
  );
};

export default ProfileFollowerSidebar;
