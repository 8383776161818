import { gql, useMutation } from "@apollo/client";
import { BaseEmoji, EmojiData, Picker } from "emoji-mart";
import { Link } from "gatsby";
import { AuthContext } from "../context/auth.context";
import { CommentDto, commentFields } from "../dtos/comment.dto";
import Loading from "./loading";
import * as React from "react";
import MediaImage from "./media-image";
import { getDisplayMode } from "../utils/display-mode";

interface CommentCreatorProps {
  postId?: string;
  partnerId?: string;
  commentId?: string;
  allowed?: boolean;
  focusOn?: string;
  onCreated: (comment: CommentDto) => void | Promise<any>;
}

const commentCreatorMutation = gql`
  mutation commentCreatorMutation($data: CommentInput!) {
    createComment(input: { data: $data }) {
      comment {
        ${commentFields}
      }
    }
  }
`;

const CommentCreator: React.FC<CommentCreatorProps> = ({
  allowed,
  focusOn,
  postId,
  partnerId,
  commentId,
  onCreated,
}) => {
  const input = React.createRef<HTMLInputElement>();
  const authContext = React.useContext(AuthContext);

  const [commentText, setCommentText] = React.useState("");
  const [createComment, { loading: savingComment }] = useMutation(
    commentCreatorMutation
  );

  const [emoji, setEmoji] = React.useState(false);
  const selectEmoji = (emoji: EmojiData) => {
    setCommentText(`${commentText || ""}${(emoji as BaseEmoji).native} `);
  };

  const isVisible = () => {
    return authContext.userId && allowed;
  };

  const store = async () => {
    const { data } = await createComment({
      variables: {
        data: {
          post: postId,
          comment: commentId,
          owner: authContext.userId,
          partner: partnerId,
          content: commentText,
        },
      },
    });

    setEmoji(false);
    setCommentText("");
    onCreated(data.createComment.comment);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      store();
    }
  };

  React.useEffect(() => {
    const htmlElement = input.current as HTMLInputElement;

    if (htmlElement && isVisible() && focusOn) {
      htmlElement.scrollTo({ behavior: "smooth" });
      htmlElement.focus();
    }
  }, [focusOn]);

  return (
    <>
      {isVisible() && (
        <div
          className="article-comment slide-in-animation-fast"
          style={{ border: 0, paddingLeft: 0 }}
        >
          <div
            className={`article-comment__image ${
              authContext.user?.avatar ? "" : "avatar-invisible--comment"
            }`}
          >
            <Link to={`/profile/${authContext.userId}/`}>
              <MediaImage
                src={authContext.user?.avatar}
                format="thumbnail"
                isClickable
              />
            </Link>
          </div>
          <div className="article-comment__content">
            <div
              className="article-comment__smiley clickable"
              onClick={() => setEmoji(!emoji)}
            >
              <i className="icon-smiley"></i>
            </div>

            {emoji && (
              <Picker
                useButton={true}
                title="Pick your emoji…"
                emoji=":point_up:"
                theme={getDisplayMode()}
                onSelect={(emoji) => selectEmoji(emoji)}
                style={{
                  position: "absolute",
                  bottom: "72px",
                  zIndex: 100,
                  right: "0px",
                  transition: "none",
                  animation: "none",
                }}
              />
            )}

            <input
              ref={input}
              value={commentText}
              onChange={(event) => setCommentText(event.target.value)}
              onKeyDown={(event) => onKeyDown(event)}
              className="article-comment__textarea comment-creator-input light-border"
              disabled={savingComment}
              placeholder="Write comment ..."
              type="text"
            />

            <button
              className="article-comment__btn btn"
              disabled={savingComment || commentText.length === 0}
              onClick={() => store()}
            >
              Post
            </button>

            {savingComment && <Loading title="Posting..." />}
          </div>
        </div>
      )}
    </>
  );
};

export default CommentCreator;
