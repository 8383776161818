import React, { useContext, useEffect } from "react";
import { MatomoContext } from "../context/matomo.context";

interface LoadingProps {
  title?: string;
  inline?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ title }) => {
  const matomoContext = useContext(MatomoContext);

  useEffect(() => {
    return matomoContext.enableHeatmapSessionRecording;
  }, []);

  return (
    <section className="loader">
      <div
        className="spinner-grow"
        style={{
          width: "1rem",
          height: "1rem",
          margin: "auto",
        }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      {title && <p className="spinner-grow-title">{title}</p>}
    </section>
  );
};

export default Loading;
