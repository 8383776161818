import React, { createContext, useCallback, useContext, useState } from "react";
import { useEvent } from "react-use";
import { ConfirmModal } from "./confirm-modal";

export interface OpenConfirmModalOptions {
  title?: string;
  message?: string;
  onClose: (result: boolean) => void;
}

export interface ConfirmModalContextProps {
  openConfirmModal: (options: OpenConfirmModalOptions) => void;
}

export const ConfirmModalContext = createContext<ConfirmModalContextProps>(
  undefined as any
);

export const ConfirmModalContextProvider: React.FC = ({ children }) => {
  const [{ show, title, message, onClose }, setState] = useState<
    OpenConfirmModalOptions & { show: boolean }
  >({
    show: false,
    onClose: () => null,
  });

  const openConfirmModal = useCallback((options: OpenConfirmModalOptions) => {
    setState({
      show: true,
      ...options,
    });
  }, []);
  const closeConfirmModal = useCallback(
    () =>
      setState({
        show: false,
        title,
        message,
        onClose: () => null,
      }),
    []
  );

  useEvent("popstate", closeConfirmModal);

  return (
    <ConfirmModalContext.Provider value={{ openConfirmModal }}>
      {children}
      <ConfirmModal
        show={show}
        onClose={(result) => {
          onClose(result);
          closeConfirmModal();
        }}
        title={title}
        message={message}
      />
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmModal = () => useContext(ConfirmModalContext);
