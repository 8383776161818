import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
import ReactTooltip from "react-tooltip";
import Lightbox from "@comceptum-software/react-image-lightbox";
import CommentCreator from "./comment-creator";
import Comment from "./comment";
import Loading from "./loading";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";
import { Link } from "gatsby";
import { deletePostMutation, PostDto, postSticker } from "../dtos/post.dto";
import { prettyCounter } from "../utils/counter";
import { scrollLock } from "../utils/scroll-lock";
import { CommentDto, commentFields } from "../dtos/comment.dto";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AggregateDto, AggregateTypedDto } from "../dtos/aggregate.dto";
import {
  createReactionMutation,
  deleteReactionMutation,
  ReactionDto,
  reactionFields,
} from "../dtos/reaction.dto";
import { AuthContext } from "../context/auth.context";
import { first } from "lodash";
import { copyTextToClipboard } from "../utils/clipboard";
import { PermissionContext } from "../context/permission.context";
import MediaImage from "./media-image";
import { isMinWidth } from "../utils/screen-width";
import { balanceCheck } from "../utils/balance";
import { arrayWithout } from "../utils/array";
import { generateVersion } from "../utils/generate-version";
import { isVideo, VideoDto } from "../dtos/video.dto";
import Video from "./video";
import { usePostModalContext } from "./modals/post-modal.context";
import { PostModal, PostModalMode } from "./modals/post-modal";
import { useConfirmModal } from "./modals/confirm-modal.context";
import { useLoginModal } from "./modals/login-modal.context";
import VerifiedBadge from "./verified-badge";
import { UserDtoType } from "../dtos/user.dto";
import { formatOrUrl, MediaDto } from "../dtos/media.dto";

interface PostProps {
  post: PostDto;
  isModal?: boolean;
  onDeleted?: () => void | Promise<void>;
}

const postQuery = gql`
  query postQuery($id: ID!, $start: Int!, $limit: Int!, $isGuest: Boolean!, $userId: ID) {
    commentsConnection(start: $start, limit: $limit, where: { post: $id, comment_null: true, active_ne: false }, sort:"createdAt:ASC") {
      values {
        ${commentFields}
      }
      aggregate {
        count
      }
    }
    reactionsConnection(where: { post: $id, comment_null: true, active_ne: false }) {
      aggregate {
        count
      }
    }
    reaction: reactions(limit: 1, where: { owner: $userId, post: $id, active_ne: false }) @skip(if: $isGuest) {
      ${reactionFields}
    }
  }
`;

interface LightboxSource {
  image?: {
    main?: string;
    next?: string;
    previous?: string;
  };
  custom?: {
    main?: JSX.Element;
    next?: JSX.Element;
    previous?: JSX.Element;
  };
}

const Post: React.FC<PostProps> = ({ isModal, onDeleted, ...props }) => {
  const commentBatchCount = 10;
  const authContext = React.useContext(AuthContext);
  const permissionContext = React.useContext(PermissionContext);
  const { open, render } = usePostModalContext();
  const [post, setPost] = useState(props.post);
  const { openConfirmModal } = useConfirmModal();
  const { runOrOpenLoginModal } = useLoginModal();

  const media = [
    ...(post.media ? post.media : []),
    ...(post.videos ? post.videos : []),
  ];

  const sticker = postSticker(post.multiplier || 0);

  const makeVariables = (start: number, limit: number) => ({
    start,
    limit,
    id: post.id,
    isGuest: !authContext.userId,
    userId: authContext.userId,
  });

  const defaultLimit = 1;

  const {
    data: interactionsData,
    loading: interationsLoading,
    fetchMore,
  } = useQuery<{
    commentsConnection: AggregateTypedDto<CommentDto>;
    reactionsConnection: AggregateDto;
    reaction: ReactionDto[];
  }>(postQuery, {
    variables: makeVariables(0, defaultLimit),
    skip: !post,
  });

  const [comments, setComments] = React.useState<CommentDto[]>([]);
  const [creatorFocusOn, setCreatorFocusOn] = React.useState<string>();
  const [createReaction] = useMutation(createReactionMutation);
  const [deleteReaction] = useMutation(deleteReactionMutation);
  const [deletePost] = useMutation(deletePostMutation);

  const mediaLength = media.length || 0;

  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const [lightbox, setLightbox] = React.useState<boolean>();
  const [menu, setMenu] = React.useState<boolean>();
  const [downloadMenu, setDownloadMenu] = React.useState<boolean>();
  const [openSnackbar] = useSnackbar(snackbarConfig);
  const [emulatedReaction, setEmulatedReaction] = useState(false);

  const hasMedia = () => mediaLength > 0;
  const hasMediaCount = (valueFrom: number, valueTo: number) =>
    hasMedia() &&
    (media.length || 0) >= valueFrom &&
    (media.length || 0) <= valueTo;

  const hasPrevious = () => lightboxIndex > 0;
  const hasNext = () => lightboxIndex + 1 < mediaLength;

  const openMedia = (index: number) => {
    setLightboxIndex(index);
    setLightbox(true);
    scrollLock(true);
  };

  const closeMedia = () => {
    setLightbox(false);
    scrollLock(false);
  };

  const composeUrl = () => {
    return `${window.location.origin}/post/${post.id}`;
  };

  const share = () => {
    copyTextToClipboard(composeUrl());

    openSnackbar("Link copied to clipboard");
  };

  const stickers = () => (
    <div className="article-images__buttons">
      {sticker && (
        <div className="article-images__button">
          <img src={`/images/${sticker}.svg`} />
        </div>
      )}
      {post?.casino?.logo && (
        <div className="article-images__button">
          <MediaImage src={post.casino.logo} format="thumbnail" />
        </div>
      )}
    </div>
  );

  const viewMoreComments = () => {
    return fetchMore({
      variables: makeVariables(comments.length || 0, commentBatchCount),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setComments([
          ...comments,
          ...(fetchMoreResult.commentsConnection.values || []),
        ]);

        return prev;
      },
    });
  };

  const refetchReactions = () => {
    return fetchMore({
      variables: makeVariables(0, 1),
      updateQuery: (prev, { fetchMoreResult }) => {
        setEmulatedReaction(false);

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          reaction: fetchMoreResult.reaction,
          reactionsConnection: fetchMoreResult.reactionsConnection,
        });
      },
    });
  };

  const startComposing = () => {
    setCreatorFocusOn(Math.random().toString());
  };

  const hasReaction = () => {
    return emulatedReaction || (interactionsData?.reaction?.length || 0) > 0;
  };

  const reactionCount = () => {
    return interactionsData?.reactionsConnection.aggregate.count || 0;
  };

  const commentCount = () => {
    return Math.max(
      interactionsData?.commentsConnection.aggregate.count || 0,
      comments.length
    );
  };

  const commentsLeft = () => {
    return commentCount() - comments.length;
  };

  // const matomoContext = useContext(MatomoContext);

  const toggleReaction = async () => {
    if (!hasReaction()) {
      setEmulatedReaction(true);

      await createReaction({
        variables: {
          input: {
            owner: authContext.userId,
            post: post.id,
          },
        },
      });

      await balanceCheck(authContext, openSnackbar);

      // matomoContext.trackEvent(MatomoEventCategoryEnum.POST, MatomoEventActionEnum.LIKE, post.id ? `https://casinoring.com/post/${post.id}`: '');
    } else {
      await deleteReaction({
        variables: {
          id: first(interactionsData?.reaction)?.id,
        },
      });
    }

    await refetchReactions();
  };

  const performDelete = async () => {
    const { data } = await deletePost({ variables: { id: post.id } });

    if (data) {
      await onDeleted?.();
    }
  };

  const onPopState = () => {
    closeMedia();
  };

  const isCommentsLimited = comments.length === defaultLimit;

  const viewMoreCommentsLimited = () => {
    if (!isCommentsLimited) return;
    return viewMoreComments();
  };

  React.useEffect(() => {
    if (interactionsData) {
      setComments(interactionsData?.commentsConnection?.values || []);
    }
  }, [interactionsData]);

  React.useEffect(() => {
    window.addEventListener("popstate", onPopState, false);
    return () => window.removeEventListener("popstate", onPopState);
  }, []);

  React.useEffect(() => {
    if (lightbox) {
      history.pushState(generateVersion(), "Post");
    }
  }, [lightbox]);

  const showDotMenu = () =>
    permissionContext.can(post, "post.update") ||
    permissionContext.can(post, "post.delete");

  const allowInteractions =
    !authContext.isPartner ||
    post?.owner?.id === authContext.userId ||
    post?.partner?.id === authContext.userId;

  const getModalMode = () => {
    if (post?.partner?.id) return PostModalMode.PartnerQuestion;
    if (post?.owner?.type === UserDtoType.Partner && post?.isPinned)
      return PostModalMode.PartnerPinnedPost;
    if (post?.owner?.type === UserDtoType.Partner)
      return PostModalMode.PartnerRegularPost;
  };

  const mediaUrl = (media: MediaDto) => formatOrUrl("large", media) || "";

  const renderVideo = (video: VideoDto) => (
    <Video url={composeUrl()} data={video} />
  );

  const [lightboxSource, setLightboxSource] = React.useState<LightboxSource>();

  const hasVideo = post.videos?.length !== 0;
  const isModerator = authContext.user?.role?.type === "moderator";

  useEffect(() => {
    const current = media[lightboxIndex];
    const previous = media[lightboxIndex - 1];
    const next = media[lightboxIndex + 1];

    setLightboxSource({
      image: {
        main: !isVideo(current) ? mediaUrl(current) || "" : undefined,
        previous:
          previous && !isVideo(previous) ? mediaUrl(previous) || "" : undefined,
        next: next && !isVideo(next) ? mediaUrl(next) || "" : undefined,
      },
      custom: {
        main: isVideo(current) ? renderVideo(current) : undefined,
        previous:
          previous && isVideo(previous) ? renderVideo(previous) : undefined,
        next: next && isVideo(next) ? renderVideo(next) : undefined,
      },
    });
  }, [lightboxIndex]);

  const videoSources = useMemo(
    () =>
      post.videos?.map((video) => ({
        url: video.media?.url,
        name: video.media?.url.split("/").pop(),
      })),
    [post.videos]
  );

  return (
    <>
      {hasMedia() && lightbox && !isModal && (
        <>
          <Lightbox
            reactModalStyle={{
              overlay: {
                right: isMinWidth(720) ? "30%" : "0",
                bottom: isMinWidth(720) ? "0" : "50%",
              },
            }}
            animationDisabled={true}
            imageTitle={`${lightboxIndex + 1}/${mediaLength}`}
            onCloseRequest={() => closeMedia()}
            onMovePrevRequest={() =>
              setLightboxIndex((lightboxIndex + mediaLength - 1) % mediaLength)
            }
            onMoveNextRequest={() =>
              setLightboxIndex((lightboxIndex + 1) % mediaLength)
            }
            enableZoom={!isVideo(media[lightboxIndex])}
            {...{
              mainCustomContent: lightboxSource?.custom?.main,
              nextCustomContent: lightboxSource?.custom?.next,
              prevCustomContent: lightboxSource?.custom?.previous,
              mainSrc: lightboxSource?.image?.main,
              nextSrc: lightboxSource?.image?.next,
              prevSrc: lightboxSource?.image?.previous,
            }}
          />
          <div className="post-modal">
            <Post post={post} isModal={true} />
          </div>
        </>
      )}

      <article
        key={post.id}
        style={{ display: "flex", flexDirection: "column" }}
        className={`article light-border ${isModal ? "article-modal" : ""}`}
        data-aos="fade-up"
      >
        <a
          style={{ display: "hidden" }}
          title="seo-anchor"
          href={composeUrl()}
        ></a>

        <ReactTooltip
          id="counter"
          className="tooltip"
          backgroundColor="#008ca5"
          textColor="white"
        />
        <div className="article-header">
          <Link
            to={`/profile/${post.owner?.id}/`}
            className={`article-header__image ${
              post.owner?.avatar ? "" : "avatar-invisible"
            }`}
          >
            <MediaImage
              src={post.owner?.avatar}
              format="thumbnail"
              isClickable
            />
          </Link>
          <div className="article-header__user">
            {post.owner?.id && (
              <p className="article-header__name clickable">
                <Link to={`/profile/${post.owner?.id}/`}>
                  {post.owner?.profileName}
                </Link>
                <VerifiedBadge user={post.owner} />
              </p>
            )}
            {!post.owner?.id && <p className="article-header__name">Guest</p>}
            <ul className="article-header__meta">
              <li title={post.createdAt?.toLocaleString()}>
                {isModerator ? (
                  <Moment format="MMMM Do YYYY, h:mm a">
                    {post.createdAt}
                  </Moment>
                ) : (
                  <Moment fromNow>{post.createdAt}</Moment>
                )}
              </li>
            </ul>
          </div>

          <div className="article-header__slot">
            {post.isPinned && (
              <img src="/images/Pinned.svg" className="pinned" />
            )}

            {post.slot && (
              <div className="article-header__label">{post.slot?.name}</div>
            )}

            {(post.multiplier || 0) > 1 && (
              <div className="article-header__number">{post.multiplier}x</div>
            )}
          </div>
        </div>
        {post.content && (
          <div className="article-content">
            <div className="truncated">
              <ReactMarkdown source={post.content} />
            </div>
          </div>
        )}
        {!isModal && (
          <>
            {hasMediaCount(1, 1) && (
              <div className="article-images article-images--1">
                <div
                  className="article-images__image1"
                  onClick={() => openMedia(0)}
                >
                  <MediaImage
                    src={media[0]}
                    format="thumbnail"
                    className="blur"
                  />

                  <MediaImage src={media[0]} format="medium" isTransparent />

                  {stickers()}
                </div>
              </div>
            )}
            {hasMediaCount(2, 2) && (
              <div className="article-images article-images--2">
                <div className="row no-gutters">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="article-images__image1"
                      onClick={() => openMedia(0)}
                    >
                      <MediaImage
                        src={media[0]}
                        format="thumbnail"
                        className="blur"
                      />

                      <MediaImage src={media[0]} format="small" isTransparent />

                      {stickers()}
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="article-images__image2"
                      onClick={() => openMedia(1)}
                    >
                      <MediaImage
                        src={media[1]}
                        format="thumbnail"
                        className="blur"
                      />

                      <MediaImage src={media[1]} format="small" isTransparent />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {hasMediaCount(3, Number.MAX_VALUE) && (
              <div className="article-images article-images--3">
                <div className="row no-gutters">
                  <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                    <div
                      className="article-images__image1"
                      onClick={() => openMedia(0)}
                    >
                      <MediaImage
                        src={media[0]}
                        format="thumbnail"
                        className="blur"
                      />

                      <MediaImage
                        src={media[0]}
                        format="medium"
                        isTransparent
                      />

                      {stickers()}
                    </div>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div
                      className="article-images__image2"
                      onClick={() => openMedia(1)}
                    >
                      <MediaImage
                        src={media[1]}
                        format="thumbnail"
                        className="blur"
                      />

                      <MediaImage src={media[1]} format="small" isTransparent />
                    </div>
                    <div
                      className="article-images__image3"
                      onClick={() => openMedia(2)}
                    >
                      <MediaImage
                        src={media[2]}
                        format="thumbnail"
                        className="blur"
                      />

                      <MediaImage src={media[2]} format="small" isTransparent />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {(reactionCount() > 0 || commentCount() > 0) && (
          <div className="article-actions counters">
            <div className="article-actions__counter">
              {reactionCount() > 0 && (
                <>
                  <i className="icon-thumbs-up scale-in-animation-fast"></i>

                  <div className="article-actions__counter--number">
                    {prettyCounter(reactionCount())}
                  </div>
                </>
              )}
            </div>
            {commentCount() > 0 && (
              <div
                className={[
                  "article-actions__counter",
                  "right",
                  isCommentsLimited ? "article-actions-comments" : null,
                ].join(" ")}
              >
                <>
                  <div
                    className="article-actions__counter--number"
                    onClick={viewMoreCommentsLimited}
                  >
                    {prettyCounter(commentCount())}
                  </div>
                  <div
                    className="article-actions__counter--text"
                    onClick={viewMoreCommentsLimited}
                  >
                    comments
                  </div>
                </>
              </div>
            )}
          </div>
        )}
        <div className="article-actions">
          {allowInteractions && (
            <div
              onClick={() => runOrOpenLoginModal(() => toggleReaction())}
              className={`article-actions__item ${
                emulatedReaction || hasReaction()
                  ? "reaction-active reaction-active-text"
                  : ""
              }`}
            >
              <div className="article-actions__icon">
                <i className="icon-thumbs-up" />
              </div>
              <div className="article-actions__item--text">Like</div>
            </div>
          )}
          {!post.commentsLocked && allowInteractions && (
            <div
              className="article-actions__item"
              onClick={() => runOrOpenLoginModal(() => startComposing())}
            >
              <div className="article-actions__icon">
                <i className="icon-comment"></i>
              </div>
              <div className="article-actions__item--text">Comment</div>
            </div>
          )}

          {isModerator && hasVideo && videoSources?.length !== 0 && (
            <div
              className={`article-actions__item ${
                downloadMenu ? "activated" : ""
              }`}
              onClick={() => setDownloadMenu(!downloadMenu)}
            >
              <div className="article-actions__icon">
                <i className="icon-files" />
              </div>
              <div className="article-actions__item--text">Downloads</div>

              {downloadMenu && (
                <div className="article-menu">
                  {videoSources?.map(({ url, name }) => (
                    <a className="btn" href={url} target="_blank">
                      <i className="icon-video" />
                      {name && name.length > 25
                        ? `...${name?.slice(-25)}`
                        : name}
                    </a>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="article-actions__item" onClick={() => share()}>
            <div className="article-actions__icon">
              <i className="icon-share" />
            </div>
            <div className="article-actions__item--text">Share</div>
          </div>

          {showDotMenu() && (
            <div
              className={`article-actions__item article-actions__item-small ${
                menu ? "activated" : ""
              }`}
              onClick={() => setMenu(!menu)}
            >
              <div className="article-actions__item--text">
                &bull;&bull;&bull;
              </div>

              {menu && (
                <div className="article-menu">
                  {permissionContext.can(post, "post.update") && (
                    <button
                      className="btn"
                      disabled={open}
                      onClick={() => {
                        !open &&
                          render(
                            <PostModal
                              mode={getModalMode()}
                              post={post}
                              onUpdated={(updatedPost: PostDto) =>
                                setPost({ ...post, ...updatedPost })
                              }
                            />
                          );
                      }}
                    >
                      <i className="icon-write"></i> Edit
                    </button>
                  )}
                  {permissionContext.can(post, "post.delete") && (
                    <button
                      className="btn"
                      disabled={open}
                      onClick={() =>
                        openConfirmModal({
                          title: "Are you sure you want to delete this post?",
                          onClose: (result) => result && performDelete(),
                        })
                      }
                    >
                      <i className="icon-delete"></i> Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        {creatorFocusOn && (
          <>
            <div className="article-divider"></div>

            <div
              style={{
                padding: 10,
                display: "sticky",
                bottom: 0,
              }}
            >
              <CommentCreator
                allowed={!!creatorFocusOn}
                focusOn={creatorFocusOn}
                postId={post.id}
                partnerId={post?.partner?.id}
                onCreated={(newComment) => {
                  setComments([...comments, newComment]);
                }}
              />
            </div>
          </>
        )}

        {!post.commentsLocked && !!comments?.length && (
          <div className="article-comments" style={{ flex: 3 }}>
            {comments.map((comment) => (
              <React.Fragment key={comment.id}>
                <Comment
                  comment={comment}
                  postId={post.id}
                  partnerId={post?.partner?.id}
                  hasChilds={true}
                  onDeleted={() => setComments(arrayWithout(comment, comments))}
                  allowInteractions={allowInteractions}
                />
              </React.Fragment>
            ))}

            {commentsLeft() > 0 && (
              <>
                <div className="more-comment-wrapper">
                  <p
                    className="more-comments"
                    onClick={() => viewMoreComments()}
                  >
                    VIEW{" "}
                    {commentsLeft() > commentBatchCount ? "" : commentsLeft()}{" "}
                    MORE COMMENTS
                  </p>
                  <p className="more-comments-count">
                    {prettyCounter(comments.length)} of{" "}
                    {prettyCounter(commentCount())}
                  </p>
                </div>
              </>
            )}

            {interationsLoading && interactionsData && <Loading />}
          </div>
        )}
      </article>
    </>
  );
};

export default Post;
