import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "gatsby";
import { orderBy, take } from "lodash";
import { AuthContext } from "../../context/auth.context";
import { PermissionContext } from "../../context/permission.context";
import {
  createGiveawayAnswerMutation,
  deleteGiveawayAnswerMutation,
  GiveawayAnswerDto,
} from "../../dtos/giveaway-answer.dto";
import { GiveawayDto, giveawayExpired } from "../../dtos/giveaway.dto";
import Loading from "../loading";
import { useLoginModal } from "../modals/login-modal.context";
import { useConfirmModal } from "../modals/confirm-modal.context";
import { PostModal, PostModalMode } from "../modals/post-modal";
import { usePostModalContext } from "../modals/post-modal.context";
import InsufficientCredits from "./insufficient-credits";
import { GiveawayRulesModal } from "../modals/giveaway-rules-modal";
import PartnerParticipationNotice from "./partner-participation-notice";
import BeGambleAwareNotice from "./be-gamble-aware-notice";

interface MultiplierCompetitionProps {
  giveaway: GiveawayDto;
  onUpdate: () => void | Promise<any>;
  creditable?: boolean;
}

const MultiplierCompetition: React.FC<MultiplierCompetitionProps> = ({
  giveaway,
  onUpdate,
  creditable,
}) => {
  const authContext = useContext(AuthContext);
  const permissionContext = useContext(PermissionContext);
  const { open, render } = usePostModalContext();
  const [terms, setTerms] = useState(false);
  const expired = giveawayExpired(giveaway);
  const { openConfirmModal } = useConfirmModal();
  const { runOrOpenLoginModal, openLoginModal } = useLoginModal();
  const [rulesOpen, setRulesOpen] = useState<boolean>(false);

  const [createGiveawayAnswer, { loading: savingAnswer }] = useMutation(
    createGiveawayAnswerMutation
  );

  const [deleteGiveawayAnswer] = useMutation(deleteGiveawayAnswerMutation);

  const participated = () => {
    return !!giveaway.giveaway_answers?.find(
      (answer) => answer.owner?.id === authContext.userId
    );
  };

  const onPostCreated = async (id: string) => {
    const response = await createGiveawayAnswer({
      variables: {
        input: {
          owner: authContext.userId,
          giveaway: giveaway.id,
          post: id,
        },
      },
    });

    if (response) {
      await onUpdate();
    }
  };

  const performDelete = async (answer: GiveawayAnswerDto) => {
    const { data } = await deleteGiveawayAnswer({
      variables: { id: answer.id },
    });

    if (data) {
      await onUpdate();
    }
  };

  const renderUploadForm = () => {
    return (
      <>
        <GiveawayRulesModal
          show={rulesOpen}
          onClose={() => setRulesOpen(false)}
          rules={giveaway.rules}
        />

        <h4 style={{ color: "#008ca5" }}>Participate</h4>
        {giveaway.entryCost && (
          <p>Entry cost are {giveaway.entryCost} CasinoRing-points</p>
        )}

        <form className="page-content__form form-inline">
          <div className="input-switch__group" style={{ width: "100%" }}>
            <input
              type="checkbox"
              name="terms"
              id="terms"
              value="terms"
              checked={terms}
              onChange={(event) => setTerms(event.target.checked)}
            />
            <label htmlFor="terms"></label>
            <p onClick={() => setRulesOpen(true)}>
              <strong>Rules</strong>
              <br />I HAVE READ AND ACCEPT THE{" "}
              <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                RULES &amp; CONDITIONS
              </a>{" "}
              OF THE GIVEAWAY
            </p>
          </div>

          <button
            type="button"
            disabled={!terms || open}
            className="btn btn--medium"
            onClick={() =>
              runOrOpenLoginModal(() =>
                render(
                  <PostModal
                    mode={PostModalMode.BigWin}
                    onCreated={async (id: string) => await onPostCreated(id)}
                    disableListing
                  />
                )
              )
            }
          >
            ENTER GIVEAWAY
          </button>
        </form>

        {savingAnswer && <Loading />}
      </>
    );
  };

  const renderParticipation = () => {
    if (expired) {
      return <i>The giveaway is expired or closed.</i>;
    }

    if (!authContext.userId) {
      return (
        <button className="btn btn--medium" onClick={() => openLoginModal()}>
          Login to participate
        </button>
      );
    }

    if (authContext.isPartner) {
      return <PartnerParticipationNotice />;
    }

    if (participated()) {
      return <i>You already participated.</i>;
    }

    if (!creditable) {
      return <InsufficientCredits requiredCredits={giveaway.entryCost} />;
    }

    return renderUploadForm();
  };

  const winners = () => {
    const ordered = orderBy(
      giveaway.giveaway_answers,
      (d) => d.post?.multiplier,
      "desc"
    );

    return take(ordered, giveaway.winnerAmount);
  };

  const renderParticipations = () => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Participants ({giveaway?.giveaway_answers?.length})
      </h4>
      <table>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "50%" }}>Win (X)</th>
          </tr>
        </thead>
        <tbody>
          {orderBy(
            giveaway?.giveaway_answers,
            (a) => a.post?.multiplier,
            "desc"
          ).map((answer) => (
            <tr key={answer.id}>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
              <td>
                {!!answer.post?.id && (
                  <Link to={`/post/${answer.post?.id}`}>
                    {answer.post?.multiplier}x
                  </Link>
                )}
                {!answer.post?.id && <i>Removed</i>}

                {!expired &&
                  permissionContext.can(answer, "giveaway-answer.delete") && (
                    <>
                      {" "}
                      &bull;{" "}
                      <span
                        className="clickable"
                        onClick={() =>
                          openConfirmModal({
                            title:
                              "Are you sure you want to delete this guess?",
                            onClose: (result) =>
                              result && performDelete(answer),
                          })
                        }
                      >
                        Delete
                      </span>
                    </>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  const renderWinners = () => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Winners ({giveaway.winnerAmount || 0}){" "}
      </h4>

      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "50%" }}>Win (X)</th>
          </tr>
        </thead>
        <tbody>
          {winners().map((answer, i) => (
            <tr key={answer.id}>
              <td>{i + 1}. </td>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
              <td>
                {!!answer.post?.id && (
                  <Link to={`/post/${answer.post?.id}`}>
                    {answer.post?.multiplier}x
                  </Link>
                )}
                {!answer.post?.id && <i>Removed</i>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
    </>
  );

  return (
    <>
      <BeGambleAwareNotice />
      {renderParticipation()}
      {giveawayExpired(giveaway) && !giveaway.disabledAutomaticWinner && renderWinners()}
      {renderParticipations()}
    </>
  );
};

export default MultiplierCompetition;
