export enum DisplayMode {
  Light = "light",
  Dark = "dark",
}

export const getDisplayMode = () => {
  const body = document.querySelector("body");

  return body?.classList.contains("dark-mode")
    ? DisplayMode.Dark
    : DisplayMode.Light;
};
