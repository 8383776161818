import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/loading";
import { RouteProps } from ".";
import { FaqDto } from "../dtos/faq.dto";
import { Link } from "gatsby";
import Alert, { somethingWentWrong } from "../components/alert";
import getYouTubeID from "get-youtube-id/index";
import { Youtube } from "../utils/youtube";
import { formatOrUrl, mediaFields } from "../dtos/media.dto";
import MediaImage from "../components/media-image";
import WysiwygContent from "../components/wysiwyg-content";
import { SeoContext } from "../context/seo.context";

interface HelpAdviceProps extends RouteProps {
  id?: string;
}

const query = gql`
  query Faq($id: ID!) {
    faq(id: $id) {
      id
      title
      intro
      content
      youtubeVideo
      image {
        ${mediaFields}
      }
    }
  }
`;

const HelpAdvicePage: React.FC<HelpAdviceProps> = ({ id }) => {
  const youtubeUtil = new Youtube();
  const seoContext = useContext(SeoContext);

  const { data, error, loading } = useQuery<{
    faq: FaqDto;
  }>(query, { variables: { id }, skip: !id });

  React.useEffect(() => {
    if (data) {
      seoContext.setData({
        title: data?.faq?.title,
        description: data?.faq.intro,
        images: {
          banner: data?.faq?.youtubeVideo
            ? youtubeUtil.thumbnail(data.faq.youtubeVideo)
            : formatOrUrl(`medium`, data?.faq?.image),
        },
      });
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <section className="page-content">
      <h3 className="page-content__extratitle">HELP & ADVICE</h3>
      <div className="page-content__text">
        <h2 className="page-content__subtitle">{data?.faq.title}</h2>
        {data?.faq.intro && <p>{data?.faq.intro}</p>}
      </div>
      <div className="media-auto-sized media-auto-sized-margin">
        {data?.faq.youtubeVideo && (
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube-nocookie.com/embed/${getYouTubeID(
              data?.faq.youtubeVideo
            )}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}

        {!data?.faq.youtubeVideo && data?.faq.image && (
          <MediaImage
            src={data.faq?.image}
            format="medium"
            alt={data.faq.title}
          />
        )}
      </div>
      <div className="page-content__text">
        <WysiwygContent html={data?.faq?.content} />
        <Link to="/help-advice" className="page-content__back btn btn--medium">
          BACK TO OVERVIEW PAGE
        </Link>
      </div>
    </section>
  );
};

export default HelpAdvicePage;
