import { MediaDto } from "./media.dto";

export interface VideoDto {
  filtered?: boolean;
  encoded?: boolean;
  providerId: string;
  media?: MediaDto;
}

export const videoPreview = (video: VideoDto, animated = false) => {
  return `https://videodelivery.net/${video.providerId}/thumbnails/thumbnail.${
    animated ? "gif" : "jpg"
  }?width=600`;
};

export const isVideo = (src: any): src is VideoDto =>
  src && "providerId" in src;
