import React from "react";
import Alert from "./alert";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../context/auth.context";
import { updateUserMutation } from "../dtos/user.dto";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";
import { balanceCheck } from "../utils/balance";

interface DailyPointsProps {}

const DailyPoints: React.FC<DailyPointsProps> = ({}) => {
  const authContext = React.useContext(AuthContext);
  const [updateUser] = useMutation(updateUserMutation);
  const [openSnackbar] = useSnackbar(snackbarConfig);

  const getConstraint = () => {
    return (
      authContext.user?.balanceConstraints?.find(
        (constraint) => constraint.key === "signIn"
      ) || {
        lastRedeemed: null,
      }
    );
  };

  const isEligible = () => {
    const constraint = getConstraint();

    return (
      authContext?.user?.confirmed === true &&
      (!constraint.lastRedeemed ||
        moment(constraint.lastRedeemed).format("L") !== moment().format("L"))
    );
  };

  const claim = async () => {
    const { data } = await updateUser({
      variables: {
        input: {
          where: { id: authContext.userId },
          data: {},
        },
      },
    });

    if (data) {
      await balanceCheck(authContext, openSnackbar);
    }
  };

  if (!authContext.userId) return <></>;

  return isEligible() ? (
    <Alert
      message="Daily CasinoRing points."
      style="info"
      actionMessage="Claim"
      actionOnClick={() => claim()}
    />
  ) : (
    <></>
  );
};

export default DailyPoints;
