import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import { PostDto, postFields } from "../dtos/post.dto";
import Loading from "../components/loading";
import Post from "../components/post";
import Alert, { somethingWentWrong } from "../components/alert";
import { formatOrUrl } from "../dtos/media.dto";
import { navigate } from "gatsby";
import { SeoContext } from "../context/seo.context";
import { useContext, useEffect } from "react";

interface PostPageProps {
  path: string;
  id?: string;
}

const PostPageQuery = gql`
  query PostPageQuery($id: ID!) {
    post(id: $id) {
      ${postFields}
    }
  }
`;

const PostPage: React.FC<PostPageProps> = ({ id }) => {
  const seoContext = useContext(SeoContext);

  const { data, error, loading } = useQuery<{
    post: PostDto;
  }>(PostPageQuery, { variables: { id }, skip: !id });

  useEffect(() => {
    if (data) {
      seoContext.setData({
        /** TODO: make this more interesting */
        title: `${data?.post?.owner?.profileName} ${data?.post?.multiplier}x`,
        description: data?.post.content,
        images: {
          banner: formatOrUrl("thumbnail", data?.post?.media?.[0]),
        },
      });
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return <Post post={data?.post as PostDto} onDeleted={() => navigate("/")} />;
};

export default PostPage;
