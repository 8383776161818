import React, { useEffect, useState } from "react";
import { StreamerBonusOfferDto } from "../../dtos/user.dto";
import ReactMde from "react-mde";
import { Converter } from "showdown";
import MediaImage from "../media-image";

interface StreamerBonusOfferContentProps {
  bonusOffer: StreamerBonusOfferDto;
  converter: Converter;
  toolbar: string[][];
  onUpdate: (bonusOffer: StreamerBonusOfferDto) => void | Promise<void>;
  onRemove: () => void | Promise<void>;
}

const StreamerBonusOfferContent: React.FC<StreamerBonusOfferContentProps> = (
  props
) => {
  const [contentSelectedTab, setContentSelectedTab] = useState<
    "write" | "preview"
  >(props.bonusOffer.id ? `preview` : "write");
  const [data, setData] = useState<StreamerBonusOfferDto>(props.bonusOffer);

  const getTitle = () => {
    if (data?.casino?.name) {
      if (!data?.title) return data.casino.name;
      return `${data.casino.name} (${data.title})`;
    }
    return data?.title;
  };

  useEffect(() => {
    if (data === props.bonusOffer) return;
    props.onUpdate?.(data);
  }, [data]);

  return (
    <>
      <div
        className="input-text__group"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <h6 style={{ flex: 1, fontSize: 15 }}>{getTitle()}</h6>

        <a
          style={{
            fontSize: 12.5,
            color: "#008ca5",
            textDecoration: "underline",
          }}
          onClick={() => props.onRemove?.()}
        >
          Remove
        </a>
      </div>

      {data.image && (
        <div className="input-image__preview">
          <MediaImage src={data.image} format="small" />
        </div>
      )}

      <div className="input-text__group">
        <input
          type="text"
          id={`offer--link-${data.id}`}
          value={data.url}
          onChange={(event): void => {
            setData({ ...data, url: event.target.value });
          }}
        />

        <label htmlFor={`offer--link-${data.id}`}>
          Url (e.g. https://casinoring.com)
        </label>
      </div>

      <div className="input-textarea__group">
        <ReactMde
          value={data.bonusOffer}
          onChange={(value): void => {
            setData({ ...data, bonusOffer: value });
          }}
          selectedTab={contentSelectedTab}
          onTabChange={setContentSelectedTab}
          toolbarCommands={props.toolbar}
          generateMarkdownPreview={(markdown): Promise<string> =>
            Promise.resolve(props.converter.makeHtml(markdown))
          }
        />
      </div>
    </>
  );
};

export default StreamerBonusOfferContent;
