import React from "react";
import { Tooltip } from "./tooltip";

interface Properties {
  active: boolean;
  subject: string;
  onClick: () => void;
  reversed?: boolean;
}

enum IconDirection {
  LEFT = "left",
  RIGHT = "right",
}

const BannerControl: React.FC<Properties> = ({
  active,
  subject,
  onClick,
  reversed,
}) => {
  const getIconDirection = () => {
    if (reversed) return active ? IconDirection.RIGHT : IconDirection.LEFT;
    return active ? IconDirection.LEFT : IconDirection.RIGHT;
  };

  return (
    <button className="banner-control" onClick={onClick}>
      <Tooltip text={`${active ? "Hide" : "Show"} ${subject}`}>
        <i className={`icon-expand-${getIconDirection()}`} />
      </Tooltip>
    </button>
  );
};

export default BannerControl;
