import React, { useEffect, useState } from "react";
import { BaseEmoji, EmojiData, Picker } from "emoji-mart";
import ReactMde from "react-mde";
import { converter } from "../../../utils/showdown";

interface Properties {
  onContentChange: (content: string) => void;
  initialContent?: string;
}

export const MarkdownEditor: React.FC<Properties> = ({
  onContentChange,
  initialContent,
}) => {
  const [content, setContent] = useState(initialContent ? initialContent : "");
  useEffect(() => onContentChange(content), [content]);

  const [emoji, setEmoji] = useState(false);
  const addEmoji = (emoji: EmojiData) =>
    setContent(`${content || ""}${(emoji as BaseEmoji).native}`);
  const toolbarMde = [["bold", "italic", "strikethrough"]];
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  return (
    <div className="modal-input input-textarea__group">
      <i className="icon-smiley clickable" onClick={() => setEmoji(!emoji)} />

      {emoji && (
        <Picker
          useButton={true}
          title="Pick your emoji…"
          emoji=":point_up:"
          onSelect={(emoji) => addEmoji(emoji)}
        />
      )}

      <label>Your message</label>

      <ReactMde
        value={content}
        onChange={(value) => setContent(value)}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={toolbarMde}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
      />
    </div>
  );
};
