import * as React from "react";
import Loading from "../components/loading";
import { useQuery } from "@apollo/client";
import Alert, { somethingWentWrong } from "../components/alert";
import Title from "../components/title";
import { useContext, useEffect, useMemo } from "react";
import { SeoContext } from "../context/seo.context";
import { DemoGame, demoGamesQuery } from "../dtos/demo-games.dto";
import { DemoGamesPreview } from "../components/demo-games/preview";
import { first } from "lodash";

export const DemoGamesPage = () => {
  const seoContext = useContext(SeoContext);

  const { data, loading, error } = useQuery<{
    entries: DemoGame[];
    pinned: DemoGame[];
  }>(demoGamesQuery);

  const filter = (games: DemoGame[]) =>
    games.filter((entry) => {
      const { id, slug, title, embedUrl, preview } = entry;
      return id && slug && title && embedUrl && preview?.url;
    });

  const entries = useMemo<DemoGame[]>(
    () => (data?.entries ? filter(data.entries) : []),
    [data]
  );

  const pinned = useMemo<DemoGame | undefined>(
    () => (data?.pinned ? first(filter(data.pinned)) : undefined),
    [data]
  );

  useEffect(() => {
    seoContext.setData({
      title: `Demo games`,
      description: `Early access to newly developed games`,
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title
        title="Demo games"
        subtitle="Early access to newly developed games"
      />

      <section className="block">
        <div className="block__list row small-gutters">
          {pinned && <DemoGamesPreview data={pinned} pinned />}
          {entries?.map((entry) => (
            <DemoGamesPreview key={entry.slug} data={entry} />
          ))}
        </div>
      </section>
    </>
  );
};
