export const copyTextToClipboard = (text: string) => {
  const copyText = document.createElement("input");
  document.body.appendChild(copyText);
  copyText.value = text;

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
  copyText.remove();
};
