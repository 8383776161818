import { gql } from "@apollo/client";
import { postFields } from "../../dtos/post.dto";

export const CommunityHighlightsQueryPageSize = 5;
export const CommunityHighlightsQuery = gql`
  query CommunityHighlightsQuery($start: Int, $where: JSON!, $sort: String!, $limit: Int!) {
    highest: posts(limit: 1, sort: "multiplier:DESC,createdAt:DESC", where: {
      showOnHighlights: true,
      processed_ne: false
    }) {
      multiplier
    }
    posts(
      limit: $limit
      start: $start
      where: $where
      sort: $sort
    ) {
      ${postFields}
    }
  }
`;
