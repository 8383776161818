import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import MediaImage from "../media-image";
import { UserDto } from "../../dtos/user.dto";
import { BaseEmoji, EmojiData, Picker } from "emoji-mart";
import { getDisplayMode } from "../../utils/display-mode";
import { useLoginModal } from "../modals/login-modal.context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackbarEnum } from "../../dtos/snackbar.dto";
import { useOpenSnackbar } from "../../utils/snackbar";
import { gql, useMutation } from "@apollo/client";
import classNames from "classnames";

interface Properties {
  onCreate: () => void | Promise<void>;
  owner?: UserDto | null;
  partnerId?: string;
}

interface MutationInput {
  content?: string;
}

const mutation = gql`
  mutation createPostMutation($input: createPostInput!) {
    createPost(input: $input) {
      post {
        id
      }
    }
  }
`;

const PartnerQuestionForm: React.FC<Properties> = ({
  onCreate,
  owner,
  partnerId,
}) => {
  const { openLoginModal } = useLoginModal();
  const [emoji, setEmoji] = useState(false);
  const openSnackbar = useOpenSnackbar();
  const [create, { loading }] = useMutation(mutation);

  const {
    errors,
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    setErrors,
  } = useFormik<MutationInput>({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      content: Yup.string()
        .min(5, "Your question is to short")
        .max(250, "Your question is to long")
        .required(),
    }),
    onSubmit: async (values, { setStatus, resetForm }) => {
      try {
        await create({
          variables: {
            input: {
              data: {
                content: values.content,
                owner: owner?.id,
                partner: partnerId,
              },
            },
          },
        });

        onCreate();
        openSnackbar({ content: "Your question has been submitted" });
        resetForm({});
        setStatus({ success: true });
      } catch (error) {
        openSnackbar({
          status: SnackbarEnum.ERROR,
          content: "Could not submit your question",
        });
      }
    },
  });

  const selectEmoji = (emoji: EmojiData) =>
    setFieldValue(
      "content",
      `${values.content || ""}${(emoji as BaseEmoji).native} `
    );

  useEffect(() => {
    if (!errors.content) return;
    openSnackbar({ status: SnackbarEnum.ERROR, content: errors.content });
    setErrors({}); // TODO: fix multiple calls + color emoij picker error + add form toggle
  }, [errors.content]);

  return (
    <section className="profile-partner-question">
      <h3 className="profile-partner-question__title">Ask question</h3>
      <div className="profile-partner-question__inner">
        {owner?.id && (
          <Link
            to={`/profile/${owner?.id}/`}
            className="profile-partner-question__avatar"
          >
            <MediaImage src={owner?.avatar} format="thumbnail" isClickable />
          </Link>
        )}

        <form
          onSubmit={handleSubmit}
          className="profile-partner-question__form"
        >
          <div className="profile-partner-question__form-input">
            <div
              className="profile-partner-question__form-input-emoji"
              onClick={() => setEmoji(!emoji)}
            >
              <i className="icon-smiley" />
            </div>

            {emoji && (
              <Picker
                useButton={true}
                title="Pick your emoji…"
                emoji=":point_up:"
                theme={getDisplayMode()}
                onSelect={(emoji) => selectEmoji(emoji)}
                style={{
                  position: "absolute",
                  bottom: "20px",
                  zIndex: 100,
                  right: "0px",
                  transition: "none",
                  animation: "none",
                }}
              />
            )}

            <input
              type="text"
              name="content"
              placeholder="Type your question ..."
              value={values.content || ""}
              onChange={handleChange}
              className={classNames({
                "profile-partner-question__form-input-text": true,
                "profile-partner-question__form-input-error": errors.content,
              })}
            />
          </div>

          {owner?.id ? (
            <button className="btn" disabled={loading || !values.content}>
              Submit
            </button>
          ) : (
            <button
              className="btn"
              type="button"
              onClick={() => openLoginModal()}
            >
              Login to ask
            </button>
          )}
        </form>
      </div>
    </section>
  );
};

export default PartnerQuestionForm;
