import React, { useEffect, useState } from "react";

interface InactivityTimerProps {
  refresh: Function;
  timeout: number;
}

enum BrowserDocumentEnum {
  MS_HIDDEN = "msHidden",
  WEBKIT_HIDDEN = "webkitHidden",
}

enum EventEnum {
  VISIBILITY = "visibilitychange",
  MS_VISIBILITY = "msvisibilitychange",
  WEBKIT_VISIBILITY = "webkitvisibilitychange",
}

enum HiddenPropEnum {
  HIDDEN = "hidden",
  MS_HIDDEN = "msHidden",
  WEBKIT_HIDDEN = "webkitHidden",
}

const InactivityTimer: React.FC<InactivityTimerProps> = ({
  refresh,
  timeout,
}) => {
  const getEvent = (): EventEnum => {
    if (BrowserDocumentEnum.MS_HIDDEN in document)
      return EventEnum.MS_VISIBILITY;
    if (BrowserDocumentEnum.WEBKIT_HIDDEN in document)
      return EventEnum.WEBKIT_VISIBILITY;
    return EventEnum.VISIBILITY;
  };

  const getHiddenProp = (): HiddenPropEnum => {
    if (BrowserDocumentEnum.MS_HIDDEN in document)
      return HiddenPropEnum.MS_HIDDEN;
    if (BrowserDocumentEnum.WEBKIT_HIDDEN in document)
      return HiddenPropEnum.WEBKIT_HIDDEN;
    return HiddenPropEnum.HIDDEN;
  };

  const getIsDocumentHidden = () => {
    const prop = getHiddenProp();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return !document[prop];
  };

  const [isVisible, setIsVisible] = useState<boolean>(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());
  const [inactiveTime, setInactiveTime] = useState<Date | null>();

  useEffect(() => {
    // Save inactive start time
    if (!isVisible) setInactiveTime(new Date());

    if (isVisible && inactiveTime) {
      // Calculate time difference in seconds
      const difference = (new Date().getTime() - inactiveTime.getTime()) / 1000;

      // Re-fetch data when exceeding timeout
      if (difference >= timeout) refresh();

      // Reset inactive timer
      setInactiveTime(null);
    }
  }, [isVisible]);

  useEffect(() => {
    const event = getEvent();
    document.addEventListener(event, onVisibilityChange, false);
    return () => {
      document.removeEventListener(event, onVisibilityChange);
    };
  }, []);

  return null;
};

export default InactivityTimer;
