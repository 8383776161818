import React from "react";
import { UserDto, UserDtoType } from "../../../dtos/user.dto";
import Balance from "./../balance";
import Socials from "./../socials";

interface ProfileInfoSidebarProps {
  user?: UserDto;
}

const ProfileInfoSidebar: React.FC<ProfileInfoSidebarProps> = ({ user }) => (
  <>
    {user?.type !== UserDtoType.Partner && <Balance balance={user?.balance} />}
    <Socials user={user} />
  </>
);

export default ProfileInfoSidebar;
