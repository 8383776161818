import { MediaDto } from "./media.dto";
import { UserDto } from "./user.dto";

export interface PrizeDropDto {
  id?: string;

  title?: string;
  contents: string;
  timer: number;
  minimumViewerCount?: number;
  winnerPageTitle?: string;
  winnerPageDescription?: string;

  /*
   * Relations
   */
  streamer?: UserDto;
  winnerPageImage?: MediaDto;
}

export interface PrizeDropWinnerDto {
  id?: string;

  expiresAt: Date;
  claimed?: boolean;

  /*
   * Relations
   */
  prize_drop?: PrizeDropDto;
  user?: UserDto;
}

export enum CollectPrizeDropWinnerResult {
  UNKNOWN_ID = "UNKNOWN_ID",
  ALREADY_CLAIMED = "ALREADY_CLAIMED",
  EXPIRED = "EXPIRED",
  SUCCESS = "SUCCESS",
}

export enum PerformPrizeDropResult {
  SUCCESS = "SUCCESS",
  WINNER_UNDETERMINED = "WINNER_UNDETERMINED",
}
