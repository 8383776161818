import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

export interface TooltipProps {
  id?: string;
  text?: string | boolean | undefined;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  id: idProp,
  text,
  className,
  children,
}) => {
  const id = useMemo(() => idProp || `${new Date().getTime()}`, [idProp]);
  const container = document.querySelector("#tooltips");

  return (
    <>
      {container &&
        ReactDOM.createPortal(
          text && (
            <ReactTooltip
              id={id}
              className="tooltip"
              backgroundColor="#008ca5"
              textColor="white"
            />
          ),
          container
        )}

      <div data-for={id} data-tip={text} className={className}>
        {children}
      </div>
    </>
  );
};
