import React, { createContext, useContext, useState } from "react";

export interface PostModalContextProps {
  open: boolean;
  hide: () => void;
  render: (component: React.ReactNode) => void;
}

export const PostModalContext = createContext<PostModalContextProps>(
  undefined as any
);

interface PostModalContextState {
  open: boolean;
  component: React.ReactNode;
}

export const PostModalContextProvider: React.FC = ({ children }) => {
  const [{ open, component }, setState] = useState<PostModalContextState>({
    open: false,
    component: null,
  });

  const hide = (): void => {
    setState((s) => ({ ...s, open: false }));
    setTimeout(() => setState((s) => ({ ...s, component: null })), 150);
  };

  const render = (component: React.ReactNode): void => {
    setState({ open: true, component });
  };

  return (
    <PostModalContext.Provider value={{ open, hide, render }}>
      {children}
      {component}
    </PostModalContext.Provider>
  );
};

export const usePostModalContext = (): PostModalContextProps =>
  useContext(PostModalContext);
