import React, { useEffect } from "react";
import { Tooltip } from "../../tooltip";

interface Properties {
  title: string;
  description: string;
  credits?: number;
  requiresApproval?: boolean;
  requiresScheduling?: boolean;
  onClick: () => void;
}

export const Option: React.FC<Properties> = ({
  title,
  description,
  credits,
  requiresApproval,
  requiresScheduling,
  onClick,
}) => {
  const isCreditable = credits && credits > 0;

  const creationButton = () => (
    <button
      className="btn btn--small"
      disabled={!isCreditable}
      onClick={onClick}
    >
      {requiresApproval ? "Request" : "Create"}
    </button>
  );

  return (
    <div className="modal-marketing-option">
      <h2>{title}</h2>
      <p>{description}</p>

      <div className="modal-marketing-option__info">
        <div className="modal-marketing-option__credits">
          <span>{credits}</span> {credits === 1 ? "credit" : "credits"}
        </div>

        <div className="modal-marketing-option__actions">
          {!!isCreditable && requiresApproval && (
            <Tooltip
              className="modal-marketing-option__tooltip"
              text={`Item requires approval${
                requiresScheduling ? " and scheduling " : " "
              }by the CasinoRing-team`}
            >
              <i className="icon-info" />
            </Tooltip>
          )}

          {isCreditable ? (
            creationButton()
          ) : (
            <Tooltip
              text={`You have insufficient credits to setup this type of marketing`}
            >
              {creationButton()}
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
