import React, { useEffect } from "react";
import ReactSlider from "react-slider";

interface SliderProps {
  value: number;
  max: number;
  onChange: (value: number) => void | Promise<void>;
}

let setValueTimeout: NodeJS.Timeout | null = null;

const Slider: React.FC<SliderProps> = ({ value, max, onChange }) => {
  const [label, setLabel] = React.useState(value);

  const handleChange = (newValue: number) => {
    setLabel(newValue);

    if (setValueTimeout) {
      clearTimeout(setValueTimeout);
    }

    setValueTimeout = setTimeout(() => onChange(newValue), 250);
  };

  useEffect(() => {
    setLabel(value);
  }, [value]);

  return (
    <div className="range-wrap" style={{ width: "100%" }}>
      <ReactSlider
        max={max + 1}
        value={label}
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        renderThumb={(props, state) => (
          <div {...props}>{state.valueNow > max ? "MORE" : state.valueNow}</div>
        )}
        onChange={(value) => handleChange(value as number)}
      />
    </div>
  );
};

export default Slider;
