import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DemoGameReview, demoGameReviewsQuery, demoGameReviewsQueryLimit } from "../../../dtos/demo-games.dto";
import Infinity from "../../infinity";
import Loading from "../../loading";
import Alert, { somethingWentWrong } from "../../alert";
import { DemoGamesReviewPreview } from "./preview";
import { DemoGamesReviewPlaceholder } from "./placeholder";
import { DemoGamesReviewSorting } from "./sorting";

interface Props {
  gameId: string;
}

export const DemoGamesReviewEntries = ({ gameId }: Props) => {
  const [entries, setEntries] = useState<DemoGameReview[]>([])
  const [sort, setSort] = useState<string>('createdAt:DESC')
  const getVars = (start?: number) => ({ gameId, start, sort });

  const { data, loading, error, fetchMore } = useQuery<{
    reviews: DemoGameReview[];
  }>(demoGameReviewsQuery, {
    variables: getVars()
  });

  useEffect(() => {
    setEntries(data?.reviews || []);
  }, [data?.reviews]);

  const fetch = () => fetchMore({
    variables: getVars(entries.length),
    updateQuery: (current, { fetchMoreResult: response }) => {
      if (!response) return current;
      setEntries([...entries, ...(response.reviews || [])]);
      return current;
    },
  })

  const shouldRender = !loading && !error
  const hasEntries = entries.length > 0;

  return (
    <div className="entries">
      <div className="filters">
        <h3>Reviews by others</h3>
        <DemoGamesReviewSorting update={setSort} />
      </div>

      {loading && <Loading />}
      {error && <Alert message={somethingWentWrong} style="danger" />}

      {shouldRender && !hasEntries && <DemoGamesReviewPlaceholder />}
      {shouldRender && hasEntries &&
        <Infinity
          data={entries}
          pageSize={demoGameReviewsQueryLimit}
          fetchMore={fetch}
          disableEndMessage
        >
          {entries.map(entry => <DemoGamesReviewPreview key={entry.id} gameId={gameId} review={entry} />)}
      </Infinity>}
    </div>
  )
}