export const arrayWithout = <T extends { id?: string }>(
  item: T,
  array: T[]
) => {
  return array.filter((_item) => _item.id !== item.id);
};

export const arrayShuffle = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const arrayRandomEntry = (array: any[]) => {
  return array[Math.floor(Math.random() * array.length)];
};
