export enum SnackbarEnum {
  SUCCESS,
  ERROR,
}

export interface SnackbarMessage {
  status?: SnackbarEnum;
  duration?: number; // doesn't work currently
  content: string;
}
