import React, { useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { mediaFields } from "../dtos/media.dto";
import { Dto as Banner } from "../dtos/banner.dto";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Controller } from "swiper/core";
import "swiper/swiper.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import classNames from "classnames";

SwiperCore.use([Autoplay, Controller]);

const queryInstruction = gql`
  query SideBanner($currentTime: String) {
    banners(
      sort: "createdAt:DESC,expiresAt:ASC"
      where: {
        type: "side"
        approved: true
        active: true
        publishedAt_lte: $currentTime
        expiresAt_gt: $currentTime
      }
    ) {
      id
      url
      image {
          ${mediaFields}
      }
    }
  }
`;

const useData = (): Banner[] => {
  const query = useQuery<{ banners?: Banner[] }>(queryInstruction, { skip: true });
  const [data, setData] = useState<Banner[]>();
  const getCurrentTime = () => new Date().toISOString(); // Use UTC for server time

  useEffect(() => {
    const fetch = async () => {
      const response = await query.refetch({ currentTime: getCurrentTime() });
      setData(response.data.banners);
    };

    fetch();
    const interval = setInterval(fetch, 15000);

    return () => clearInterval(interval);
  }, []);


  if (!data) return [];
  return data;
};

const NavButton = ({ placement, onClick }) => {
  const Icon = placement === "left" ? FaChevronLeft : FaChevronRight;

  return (
    <div
      className={classNames("navigation-button", `${placement}`)}
      onClick={onClick}
    >
      <Icon />
    </div>
  );
};

const Slide = ({ banner }: { banner: Banner }) => {
  return (
    <a href={banner.url!}>
      <img
        src={banner.image?.formats?.large?.url || ""}
        draggable={false}
      />
    </a>
  );
};

export const SideBanner = () => {
  const data = useData();
  const key = useMemo(() => Math.random(), [data.length]);
  const [controller, setController] = useState<SwiperCore>();

  if (data.length === 0) return null;

  return (
    <div className="side-banner">
      <Swiper
        key={key}
        spaceBetween={25}
        autoplay={{
          delay: 7500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        onSwiper={setController}
        enabled={data.length > 1}
        loop
        grabCursor
        autoHeight
      >
        {data.map((entry) => (
          <SwiperSlide key={entry.id}>
            <Slide banner={entry} />
          </SwiperSlide>
        ))}
      </Swiper>

      {data.length > 1 && (
        <>
          <NavButton placement="left" onClick={() => controller?.slidePrev()} />
          <NavButton
            placement="right"
            onClick={() => controller?.slideNext()}
          />
        </>
      )}
    </div>
  );
}