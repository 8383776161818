import { UserDto } from "../../dtos/user.dto";

export abstract class BonushuntState {}

export class BonushuntInitialState extends BonushuntState {}

export class BonushuntLoadFailureState extends BonushuntState {}

export class BonushuntLoadSuccessState extends BonushuntState {
  constructor(
    readonly props: {
      readonly user: UserDto;
    }
  ) {
    super();
  }
}
