import { BonushuntBonusWriteDto, BonushuntWriteDto } from "./bonushunt.dto";

export abstract class BonushuntEvent {}

export class BonushuntLoadRequestEvent extends BonushuntEvent {
  constructor(
    readonly props: {
      readonly slug: string;
    }
  ) {
    super();
  }
}

export class BonushuntAddBonusEvent extends BonushuntEvent {
  constructor(
    readonly props: {
      readonly bonus: BonushuntBonusWriteDto;
    }
  ) {
    super();
  }
}

export class BonushuntRemoveBonusEvent extends BonushuntEvent {
  constructor(
    readonly props: {
      readonly index: number;
    }
  ) {
    super();
  }
}

export class BonushuntEditEvent extends BonushuntEvent {
  constructor(
    readonly props: {
      readonly data: BonushuntWriteDto;
    }
  ) {
    super();
  }
}
