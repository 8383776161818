export interface SinglePagePartnershipInfoDto {
  title: string;
  intro: string;
  content: string;
}

export const SinglePagePartnershipInfoFields = `
  title
  intro
  content
`;
