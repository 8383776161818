import React from "react";
import Notification from "./notification";
import Infinity from "./infinity";
import { NotificationDto } from "../dtos/notification.dto";
import Loading from "./loading";
import Alert, { somethingWentWrong } from "./alert";
import { NotificationsContext } from "../context/notifications.context";

const NotificationsContent: React.FC = () => {
  const { data, loading, error, loadMore, markReadAll, version } =
    React.useContext(NotificationsContext);
  const pageSize = 10;

  return (
    <div
      className={[
        "notifications",
        error || loading || (data && data.length === 0)
          ? "notifications-small"
          : null,
      ].join(" ")}
    >
      {loading && <Loading />}
      {error && <Alert message={somethingWentWrong} style="danger" />}
      {!loading && !error && data && data.length === 0 && (
        <Alert message="No notifications have been found" style="info" />
      )}

      {!loading && !error && data && data.length > 0 && (
        <>
          <div className="controls">
            <a className="control" onClick={markReadAll}>
              Mark all as read
            </a>
          </div>
          <div id="notifications-container">
            <Infinity
              key={version}
              data={data}
              pageSize={pageSize}
              disableEndMessage={data.length <= pageSize}
              fetchMore={() => loadMore()}
              target="notifications-container"
            >
              {data.map((item: NotificationDto) => (
                <Notification key={item.id} data={item} />
              ))}
            </Infinity>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationsContent;
