import React from "react";

interface Props {
  update: (query: string) => void
}

export const DemoGamesReviewSorting = ({ update }: Props) => (
  <select
    name="sorting"
    className="sorting"
    placeholder="Sorting"
    onChange={(event) => update(event.target.value)}
  >
    <option value="createdAt:DESC">
      Posted (newest first)
    </option>
    <option value="createdAt:ASC">
      Posted (oldest first)
    </option>
    <option value="averageRating:DESC">
      Community rating (highest first)
    </option>
    <option value="averageRating:ASC">
      Community rating (lowest first)
    </option>
  </select>
)