import React, { createContext, useContext, useEffect, useState } from "react";
import { MediaDto } from "../dtos/media.dto";
import MediaImage from "./media-image";
import { Dto } from "../dtos/banner.dto";
import { arrayRandomEntry } from "../utils/array";

const MarketingBannerContext = createContext<{
  lastBannerId: string;
  setLastBannerId: (id: string) => void;
}>(undefined as any);

export const MarketingBannerContextProvider = ({ children }) => {
  const [lastBannerId, setLastBannerId] = useState<string>("");
  return (
    <MarketingBannerContext.Provider value={{ lastBannerId, setLastBannerId }}>
      {children}
    </MarketingBannerContext.Provider>
  );
};

const MarketingBanner: React.FC<{ banners: Dto[] }> = ({ banners }) => {
  const [banner, setBanner] = useState<Dto>();
  const { lastBannerId, setLastBannerId } = useContext(MarketingBannerContext);

  useEffect(() => {
    if (banners.length === 1) {
      setBanner(banners[0]);
    } else {
      const banner = arrayRandomEntry(
        banners.filter((x) => x.id !== lastBannerId)
      );
      setLastBannerId(banner.id);
      setBanner(banner);
    }
  }, []);

  return (
    <div className="banner banner-marketing">
      <MediaImage
        src={banner?.image}
        link={banner?.url}
        format="medium"
        isClickable
        externalLink
      />
    </div>
  );
};

export default MarketingBanner;
