import * as React from "react";
import { useApolloClient } from "@apollo/client";
import AsyncSelect from "react-select/async";

export interface LazySelectItem {
  value: string;
  label: string;
}

interface LazySelectProps {
  query: any;
  selected?: LazySelectItem | null;
  placeholder?: string;
  openMenuOnClick?: boolean;
  limit?: number;

  onSelect?: (value: any) => void | Promise<void>;
  onSelectItem?: (item: LazySelectItem) => void | Promise<void>;
  className?: string;
}

export const LazySelect: React.FC<LazySelectProps> = ({
  query,
  selected,
  limit,
  placeholder,
  openMenuOnClick,
  onSelect,
  onSelectItem,
  className,
}) => {
  const apollo = useApolloClient();
  const [inputText, setInputText] = React.useState("");

  const fetch = async (input: string) => {
    const { data } = await apollo.query<{ items: LazySelectItem[] }>({
      query,
      variables: {
        input,
        limit: limit || 5,
      },
    });

    return data?.items;
  };

  React.useEffect(() => {
    setInputText("");
  }, []);

  React.useEffect(() => {
    setInputText("");
  }, [selected]);

  return (
    <div style={{ maxWidth: "100%" }} className={className}>
      <AsyncSelect
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: "black",
            fontWeight: "normal",
          }),
          menu: (styles) => ({ ...styles, borderRadius: 0, border: 0 }),
          dropdownIndicator: (styles) => ({
            ...styles,
            display: openMenuOnClick === true ? undefined : "none",
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            display: openMenuOnClick === true ? undefined : "none",
          }),
          clearIndicator: (styles) => ({ ...styles, color: "#008ca5" }),
          control: (styles) => ({
            ...styles,
            borderRadius: 2,
            border: "1px solid rgb(118, 118, 118)",
            padding: 0,
          }),
          option: (styles, { isSelected }) => ({
            ...styles,
            color: "black",
            backgroundColor: isSelected ? "#008ca5" : "",
            borderBottom: "1px solid #eeeeee",
          }),
        }}
        value={selected ?? null}
        inputValue={inputText}
        onInputChange={(val) => setInputText(val)}
        placeholder={placeholder || "SEARCH"}
        onChange={(value, action) => {
          onSelect?.((value as any)?.value);
          onSelectItem?.(value as LazySelectItem);
        }}
        className="select-autocomplete"
        isClearable={true}
        openMenuOnClick={openMenuOnClick === true}
        defaultOptions
        loadOptions={fetch}
      />
    </div>
  );
};
