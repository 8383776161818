import React from "react";

export const RequestInformation: React.FC = () => (
  <div className="modal-marketing-information">
    <h2>Your request has been submitted</h2>
    <p>
      The details of your request have been forwarded to the CasinoRing-team.
      They will check you contents for approval and will setup any details with
      you.
    </p>
  </div>
);
