import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import { orderBy, take } from "lodash";
import { AuthContext } from "../../context/auth.context";
import {
  giveawayClosed,
  GiveawayDto,
  giveawayExpired,
} from "../../dtos/giveaway.dto";
import { useLoginModal } from "../modals/login-modal.context";
import InsufficientCredits from "./insufficient-credits";
import { GiveawayRulesModal } from "../modals/giveaway-rules-modal";
import PartnerParticipationNotice from "./partner-participation-notice";
import BeGambleAwareNotice from "./be-gamble-aware-notice";
import moment from "moment";
import { PollStepper } from "./poll/stepper";
import { PollStepperProvider } from "./poll/context";
import {
  deleteGiveawayAnswerMutation,
  GiveawayAnswerDto,
} from "../../dtos/giveaway-answer.dto";
import { PermissionContext } from "../../context/permission.context";
import { useMutation } from "@apollo/client";
import { useConfirmModal } from "../modals/confirm-modal.context";

interface Props {
  giveaway: GiveawayDto;
  onUpdate: () => void | Promise<any>;
  creditable?: boolean;
}

export const Poll = ({ giveaway, onUpdate, creditable }: Props) => {
  const authContext = useContext(AuthContext);
  const { openLoginModal } = useLoginModal();
  const [rulesOpen, setRulesOpen] = useState<boolean>(false);

  const isExpired = (giveaway?: GiveawayDto): boolean => {
    const isTimeExpired = moment().isAfter(moment(giveaway?.expiresAt));
    return !!giveaway && (isTimeExpired || giveawayClosed(giveaway));
  };

  const winners = () => {
    const result = giveaway.winnerSeed || 0;
    const ordered = orderBy(giveaway.giveaway_answers, (d) =>
      Math.abs(result - (d.winnerSeed || 0))
    );
    return take(ordered, giveaway.winnerAmount);
  };

  const expired = isExpired(giveaway);

  const participated = () => {
    return !!giveaway?.giveaway_answers?.find(
      (answer) => answer.owner?.id === authContext.userId
    );
  };

  const renderForm = () => {
    return (
      <>
        <GiveawayRulesModal
          show={rulesOpen}
          onClose={() => setRulesOpen(false)}
          rules={giveaway.rules}
        />

        <h4 style={{ color: "#008ca5" }}>Participate</h4>
        <p>In order to participate the following questions must be answered</p>

        {giveaway.entryCost && (
          <p>Entry cost are {giveaway.entryCost} CasinoRing-points</p>
        )}

        <form
          className="page-content__form form-inline"
          onSubmit={(e) => e.preventDefault()}
        >
          {giveaway?.id && (
            <PollStepperProvider>
              <PollStepper
                giveawayId={giveaway.id}
                rulesDispatcher={setRulesOpen}
                onUpdate={onUpdate}
              />
            </PollStepperProvider>
          )}
        </form>
      </>
    );
  };

  const renderParticipation = () => {
    if (expired) {
      return <i>The giveaway is expired or closed.</i>;
    }

    if (!authContext.userId) {
      return (
        <button className="btn btn--medium" onClick={() => openLoginModal()}>
          Login to participate
        </button>
      );
    }

    if (authContext.isPartner) {
      return <PartnerParticipationNotice />;
    }

    if (participated()) {
      return <i>You already participated.</i>;
    }

    if (!creditable) {
      return <InsufficientCredits requiredCredits={giveaway.entryCost} />;
    }

    return renderForm();
  };

  const renderWinners = () => (
    <>
      <GiveawayRulesModal
        show={rulesOpen}
        onClose={() => setRulesOpen(false)}
        rules={giveaway.rules}
      />

      <h4 style={{ color: "#008ca5" }}>
        Winners ({giveaway.winnerAmount || 0}){" "}
      </h4>

      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "100%" }}>Name</th>
          </tr>
        </thead>
        <tbody>
          {winners().map((answer, i) => (
            <tr key={answer.id}>
              <td>{i + 1}. </td>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
    </>
  );

  const { openConfirmModal } = useConfirmModal();
  const permissionContext = useContext(PermissionContext);
  const [deleteGiveawayAnswer] = useMutation(deleteGiveawayAnswerMutation);

  const performDelete = async (answer: GiveawayAnswerDto) => {
    const { data } = await deleteGiveawayAnswer({
      variables: { id: answer.id },
    });

    if (data) {
      await onUpdate();
    }
  };

  const renderParticipations = () => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Participants ({giveaway?.giveaway_answers?.length})
      </h4>
      <table>
        <thead>
          <tr>
            <th style={{ width: "100%" }}>Name</th>
          </tr>
        </thead>
        <tbody>
          {giveaway?.giveaway_answers?.map((answer) => (
            <tr key={answer.id}>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
                {!expired &&
                  permissionContext.can(answer, "giveaway-answer.delete") && (
                    <>
                      {" "}
                      &bull;{" "}
                      <span
                        className="clickable"
                        onClick={() =>
                          openConfirmModal({
                            title:
                              "Are you sure you want to delete this guess?",
                            onClose: (result) =>
                              result && performDelete(answer),
                          })
                        }
                      >
                        Delete
                      </span>
                    </>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      <BeGambleAwareNotice />
      {renderParticipation()}
      {giveawayExpired(giveaway) && !giveaway.disabledAutomaticWinner && renderWinners()}
      {renderParticipations()}
    </>
  );
};
