import React from "react";
import { MediaDto } from "../../dtos/media.dto";
import MediaImage from "../media-image";
import classNames from "classnames";

interface Props {
  title: string;
  provider?: string;
  logo?: MediaDto;
}

export const DemoGamesSlot = ({ title, provider, logo }: Props) => {
  const hasBranding = provider && logo;

  return (
    <section className={classNames('slot', { 'slot-minimal': !hasBranding })}>
      <h1>{title}</h1>
      {hasBranding && <MediaImage src={logo} format="medium" alt={provider} />}
    </section>
  );
};
