import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  CollectPrizeDropWinnerResult,
  PrizeDropWinnerDto,
} from "../../../dtos/prize-drop.dto";
import { useOpenSnackbar } from "../../../utils/snackbar";
import { usePrizeDrop } from "../../prize-drop/prize-drop";

const QUERY_CHECK_PRIZE_DROP_WINNERS = gql`
  query CheckPrizeDropWinners {
    checkPrizeDropWinners {
      id
      prize_drop {
        id
        title
        contents
      }
    }
  }
`;

const MUTATION_COLLECT_PRIZE_DROP_WINNER = gql`
  mutation CollectPrizeDropWinner($id: ID!) {
    collectPrizeDropWinner(id: $id)
  }
`;

export const usePrizeDropPollingQuery = () => {
  const { isOpen, showPrizeDropPopup } = usePrizeDrop();
  const openSnackbar = useOpenSnackbar();
  const { data } = useQuery<{ checkPrizeDropWinners: PrizeDropWinnerDto[] }>(
    QUERY_CHECK_PRIZE_DROP_WINNERS,
    {
      pollInterval: 5000,
    }
  );
  const [mutate] = useMutation<{
    collectPrizeDropWinner: CollectPrizeDropWinnerResult;
  }>(MUTATION_COLLECT_PRIZE_DROP_WINNER);

  useEffect(() => {
    if ((data?.checkPrizeDropWinners?.length || 0) > 0 && !isOpen) {
      const prizeDropWinner = data?.checkPrizeDropWinners[0];

      showPrizeDropPopup({
        duration: 60,
        text: prizeDropWinner?.prize_drop?.contents,
        onClickCollect: async () => {
          try {
            const { data } = await mutate({
              variables: {
                id: prizeDropWinner?.id,
              },
            });

            if (
              data?.collectPrizeDropWinner !==
              CollectPrizeDropWinnerResult.SUCCESS
            )
              throw new Error(`Result unsuccessful ('${data}')`);

            openSnackbar({
              content: "You've collected the prize!",
              duration: 10,
            });
          } catch (error) {
            console.error(error);
            openSnackbar({
              content: "Something went wrong.",
            });
          }
        },
      });
    }
  }, [data?.checkPrizeDropWinners]);
};
