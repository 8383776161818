import React from "react";

const BeGambleAwareNotice: React.FC = () => (
  <>
    <h4 style={{ color: "#008ca5" }}>Gamble Responsibly!</h4>
    <p>
      Gambling is not a way to make money, Only gamble with money you can afford
      to lose! If you think you have a gambling problem or addiction please
      visit{" "}
      <a
        href="http://www.begambleaware.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        http://www.begambleaware.com
      </a>{" "}
      for help and support!
    </p>
    <hr />
  </>
);

export default BeGambleAwareNotice;
