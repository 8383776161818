import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import ProfilePage from "./profile";
import HomePage from "./home";
import MembersPage from "./members";
import CasinosPage from "./casinos";
import RegisterPage from "./register";
import { UserDto, UserDtoType } from "../dtos/user.dto";
import ConditionalRoute from "../components/conditional-route";
import SettingsPage from "./settings";
import StreamerHighlightsPage from "./streamer-highlights";
import ErrorPage from "./error";
import CommunityHighlightsPage from "./community-highlights";
import PostPage from "./post";
import FollowersPage, { FollowersPageMode } from "./followers";
import SingleContentPage from "./single-content";
import { SinglePageDto } from "../dtos/single-page.dto";
import HelpAdvicePage from "./help-advice";
import HelpAdvicesPage from "./help-advices";
import AuthCallbackPage from "./auth-callback";
import GiveawaysPage from "./giveaways";
import GiveawayResultsPage from "./giveaway-results";
import Layout from "../components/layout";
import PhotosPage from "./photos";
import ForgotPasswordPage from "./forgot-password";
import ResetPasswordPage from "./reset-password";
import AffiliatePage from "./affiliate";
import BonushuntPage from "./bonushunt/bonushunt.page";
import { MatomoContext } from "../context/matomo.context";
import PrizeWinnerPage from "./prize-winner";
import CasinoDetailPage from "./casinos/detail";
import { DemoGamesPage } from "./demo-games";
import { DemoGamesDetailPage } from "./demo-games/detail";
import StreamersLobbyPage from "./streamerslobby";
import StreamersLobbyStreamersPage from "./streamerslobbystreamers";
import StreamersLobbyDetailPage from "./streamerslobby-detail";

export interface RouteProps {
  path: string;
}

const App: React.FC = () => {
  const matomoContext = useContext(MatomoContext);

  useEffect(() => {
    matomoContext.enableLinkTracking();
  }, []);

  return (
    <Layout>
      <Router basepath="/">
        <HomePage path="/" />
        <PostPage path="/post/:id/" />
        <AuthCallbackPage path="/auth/google/callback" type="google" />
        <AuthCallbackPage path="/auth/twitch/callback" type="twitch" />
        <ProfilePage path="/profile/:id/" />
        <FollowersPage
          path="/profile/:id/followers/"
          mode={FollowersPageMode.Followers}
        />
        <FollowersPage
          path="/profile/:id/following/"
          mode={FollowersPageMode.Following}
        />
        <PhotosPage path="/profile/:id/photos/" />
        <PhotosPage path="/profile/:id/photos/:imageIndex" />
        <MembersPage path="/streamers" type={UserDtoType.Streamer} />
        <MembersPage
          path="/members/:filterText/"
          type={UserDtoType.Everyone}
          filterAllowed={true}
        />
        <MembersPage
          path="/members/"
          type={UserDtoType.Everyone}
          filterAllowed={true}
        />
        <CasinoDetailPage path="/:locale/reviews/:slug/" />
        <CasinoDetailPage path="/reviews/:slug/" />
        <CasinosPage path="/reviews/" />
        <DemoGamesDetailPage path="/demo-games/:slug/" />
        <DemoGamesPage path="/demo-games/" />
        <GiveawaysPage path="/giveaways/" />
        <GiveawayResultsPage path="/giveaway-results/:id/" />
        <PrizeWinnerPage path="/prize-winner/:id/" />
        <StreamerHighlightsPage path="/streamer-highlights/" />
        <CommunityHighlightsPage path="/community-highlights/" />
        <MembersPage
          path="/hunt/"
          type={UserDtoType.Streamer}
          filterAllowed={false}
          titleOverride="WHOSE BONUSHUNT ARE YOU WATCHING?"
          memberLinkProps={{
            linkBuilder: (user: UserDto): string =>
              user.slug ? `/hunt/${user.slug}` : `/profile/${user.id}`,
            label: "SEE BONUSHUNT LIST",
          }}
        />
        <BonushuntPage path="/hunt/:slug" />
        <SingleContentPage path="/contact/" type={SinglePageDto.CONTACT} />
        <SingleContentPage
          path="/become-partner/"
          type={SinglePageDto.BECOME_PARTNER}
        />
        <SingleContentPage
          path="/partnership/"
          type={SinglePageDto.PARTNERSHIP_INFO}
        />
        <SingleContentPage
          path="/terms-and-conditions/"
          type={SinglePageDto.TERMS_AND_CONDITIONS}
        />
        <SingleContentPage
          path="/privacy-statement/"
          type={SinglePageDto.PRIVACY_STATEMENT}
        />
        <SingleContentPage
          path="/cookie-policy/"
          type={SinglePageDto.COOKIE_POLICY}
        />
        <HelpAdvicesPage path="/help-advice/" />
        <HelpAdvicePage path="/help-advice/:id/" />
        {/*<StreamersLobbyPage path="/streamerslobby/" />*/}
        {/*<StreamersLobbyStreamersPage path="/streamerslobby/streamers/" />*/}
        {/*<StreamersLobbyDetailPage path="/streamerslobby/:slug/" />*/}
        <RegisterPage path="/register/" />
        <RegisterPage path="/register/:type/" />
        <ConditionalRoute
          path="/forgot-password/"
          auth={false}
          component={ForgotPasswordPage}
        />
        <ConditionalRoute
          path="/reset-password/:code/"
          auth={false}
          redirect={true}
          component={ResetPasswordPage}
        />
        <ConditionalRoute
          path="/reset-password/:code/:legacy/"
          auth={false}
          redirect={true}
          component={ResetPasswordPage}
        />
        <ConditionalRoute
          path="/settings/streamer/prize-drops/"
          privileged={true}
          component={SettingsPage}
          isStreamer={true}
          scrollTo={"prize-drops"}
        />
        <ConditionalRoute
          path="/settings/streamer/"
          privileged={true}
          component={SettingsPage}
          isStreamer={true}
        />
        <ConditionalRoute
          path="/settings/"
          auth={true}
          component={SettingsPage}
        />
        <ErrorPage default />
      </Router>
    </Layout>
  );
};
export default App;
