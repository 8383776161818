import React, { useContext, useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "gatsby";
import { flatten, take } from "lodash";
import ReactMarkdown from "react-markdown";
import ReactTooltip from "react-tooltip";
import Post from "../components/post";
import Loading from "../components/loading";
import Alert, { somethingWentWrong } from "../components/alert";
import Infinity from "../components/infinity";
import FollowButton from "../components/follow-button";
import {
  StreamerLiveSourceEnum,
  UserDto,
  UserLiveDto,
  UserDtoExtentions,
  UserDtoType,
  userSidebarFields,
} from "../dtos/user.dto";
import { AggregateDto, aggregateFields } from "../dtos/aggregate.dto";
import { isYoutubeChannelId, Youtube, YoutubeVideo } from "../utils/youtube";
import { formatOrUrl, mediaFields } from "../dtos/media.dto";
import { PostDto, postFields } from "../dtos/post.dto";
import { RouteProps } from ".";
import MediaImage from "../components/media-image";
import { LayoutContext } from "../context/layout.context";
import { arrayWithout } from "../utils/array";
import ProfileFollowerSidebar from "../components/profile/sidebar/follower";
import ProfileInfoSidebar from "../components/profile/sidebar/info";
import PartnerQuestionForm from "../components/profile/partner-question-form";
import { AuthContext } from "../context/auth.context";
import VerifiedBadge from "../components/verified-badge";
import { generateVersion } from "../utils/generate-version";
import StreamerBonusOffer from "../components/profile/streamer-bonus-offer";
import { FullScreenContext } from "../context/full-screen.context";
import BannerControl from "../components/banner-control";
import OfflineBanner from "../components/offline-banner";
import LiveNotificationToggle from "../components/profile/live-notification-toggle";
import { LiveStream } from "../components/live-stream/LiveStream";
import { getRandomStreamerAvatar } from "../utils/avatar";
import { SeoContext } from "../context/seo.context";
import { GlobalOffers } from "../components/profile/global-offers";

interface ProfilePageProps extends RouteProps {
  id?: string;
}
const ProfilePageQueryPostPageSize = 3;
const ProfilePageQuery = gql`
  query ProfilePageQuery($userId: ID!, $start: Int) {
    user(id: $userId) {
      id
      profileName
      lastOnline
      type
      aboutMe
      views
      websiteUrl

      ${userSidebarFields}

      streamSchedule
      highlightsPlaylistId
      avatar {
        ${mediaFields}
      } 
      avatarPersonal {
        ${mediaFields}
      }
      banner {
        ${mediaFields}
      }
      live
      liveSource
      liveId
      allowQuestions

      streamerLiveStreamOffers {
        id
        title
        content
      }
      offlineBanner {
        ${mediaFields}
      }
    }
    posts: profilePosts(owner: $userId, limit: ${ProfilePageQueryPostPageSize}, start: $start) {
      ${postFields}
    }
    photos: posts(limit: 4, start: 0, sort: "createdAt:DESC", where: {
      owner: $userId
      hasMedia: true,
      processed_ne: false
    }) {
      media {
        ${mediaFields}
      }
    }
    postCount: postsConnection(where: { owner: $userId, processed_ne: false }) {
      ${aggregateFields}
    }
    commentCount: commentsConnection(where: { owner: $userId }) {
      ${aggregateFields}
    }
    followerCount: followersConnection(where: { user: $userId }) {
      ${aggregateFields}
    }
    followingCount: followersConnection(where: { owner: $userId }) {
      ${aggregateFields}
    }
  }
`;

const LiveQuery = gql`
  query StreamerLive($id: ID!) {
    user(id: $id) {
      live
      liveSource
      liveId
      streamerLiveStreamOffers {
        id
        title
        content
      }
      offlineBanner {
        ${mediaFields}
      }
      twitchUsername
    }
  }
`;

const useAvatar = (user?: UserDto, isStreamer = false, personal = false) => {
  const property = personal ? user?.avatarPersonal : user?.avatar;

  return useMemo(
    () => (isStreamer && !property ? getRandomStreamerAvatar() : property),
    [property]
  );
};

const ProfilePage: React.FC<ProfilePageProps> = ({ id }) => {
  const layoutContext = useContext(LayoutContext);
  const seoContext = useContext(SeoContext);
  const { user } = React.useContext(AuthContext);

  const getQueryVariables = (start?: number) => ({ start, userId: id });

  const [highlights, setHighlights] = useState<YoutubeVideo[]>();
  const [postsVersion, setPostsVersion] = useState<string>(generateVersion());
  const [posts, setPosts] = useState<PostDto[]>([]);

  const { error, loading, data, fetchMore, refetch } = useQuery<{
    user: UserDto;
    posts: PostDto[];
    photos: PostDto[];
    postCount?: AggregateDto;
    followerCount?: AggregateDto;
    followingCount?: AggregateDto;
    commentCount?: AggregateDto;
  }>(ProfilePageQuery, {
    skip: !id,
    variables: getQueryVariables(),
    fetchPolicy: "no-cache",
  });

  const userLiveQuery = useQuery<{ user: UserLiveDto }>(LiveQuery, {
    skip: true,
  });

  const [streamerData, setStreamerData] = useState<UserLiveDto>();

  useEffect(() => setStreamerData(undefined), [id]);

  useEffect(() => {
    if (data?.user?.type !== "streamer") return;

    const fetch = async () => {
      const response = await userLiveQuery.refetch({ id });
      setStreamerData(response.data.user);
    };

    fetch();
    const interval = setInterval(fetch, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [data?.user?.id]);

  const update = async () => {
    await refetch();
    setPostsVersion(generateVersion());
  };

  useEffect(() => {
    setPosts(data?.posts || []);
  }, [data?.posts]);

  const loadMore = () => {
    return fetchMore({
      variables: getQueryVariables(posts.length),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setPosts([...posts, ...(fetchMoreResult.posts || [])]);
        return prev;
      },
    });
  };

  const userExtensions = new UserDtoExtentions(data?.user);

  const fetchHighlights = async () => {
    if (userExtensions.isStreamer() && data?.user?.highlightsPlaylistId) {
      setHighlights(
        await new Youtube().playlist(data?.user?.highlightsPlaylistId, 2)
      );
    }
  };

  const images = () => {
    return take(flatten(data?.photos?.map((p) => p.media || [])), 4);
  };

  const getLiveStreamSourceId = (
    source: StreamerLiveSourceEnum
  ): string | undefined => {
    if (source === StreamerLiveSourceEnum.TWITCH)
      return streamerData?.twitchUsername;
    return streamerData?.liveId;
  };

  React.useEffect(() => {
    if (data && !data.user.blocked) {
      const followerSidebar = (
        <ProfileFollowerSidebar
          user={data.user}
          followingCount={data?.followingCount?.aggregate?.count}
          followerCount={data?.followerCount?.aggregate?.count}
        />
      );

      seoContext.setData({
        title: data?.user?.profileName,
        description:
          data?.user?.aboutMe ||
          `${data?.user?.profileName} has nothing to share with the world.`,
        images: {
          banner: formatOrUrl("small", data?.user?.avatar),
        },
      });

      layoutContext.updateProps({
        additionalSidebar: {
          top: (
            <>
              <ProfileInfoSidebar user={data.user} />
              {data.user?.type !== UserDtoType.Streamer
                ? followerSidebar
                : null}
            </>
          ),
          bottom:
            data.user?.type === UserDtoType.Streamer ? followerSidebar : null,
        },
      });

      fetchHighlights();
    }
  }, [data]);

  const isPartner = data?.user?.type === UserDtoType.Partner;
  const authIsOwner = data?.user?.id === user?.id;
  const authIsPartner = user?.type === UserDtoType.Partner;
  const isLive = streamerData?.live;
  const liveSource = streamerData?.liveSource;
  const [isFollowing, setIsFollowing] = useState<boolean>(false);

  const {
    allowMainMenu,
    showMainMenu,
    allowSidebarMenu,
    showSidebarMenu,
    toggleMainMenu,
    toggleSidebarMenu,
  } = useContext(FullScreenContext);

  useEffect(() => {
    if (isLive && showSidebarMenu) {
      toggleSidebarMenu();
    }

    if (!isLive && !showSidebarMenu) {
      toggleSidebarMenu();
    }
  }, [isLive]);

  const renderAboutMe = () => {
    if (isPartner && !data?.user?.aboutMe) return;

    return (
      <section className="profile-about">
        <h2 className="profile-about__title">
          {isPartner ? `About ${data?.user?.profileName}` : "About me"}
        </h2>
        <div className="profile-about__inner light-border">
          {!isPartner && (
            <div className="profile-about__image">
              <MediaImage src={personalAvatar} format="small" />
            </div>
          )}
          <div className="profile-about__content">
            {!isPartner && <h3>{data?.user?.profileName}</h3>}

            <ReactMarkdown
              source={
                data?.user?.aboutMe ||
                `*${data?.user?.profileName} has nothing to share with the world.*`
              }
            />
          </div>
        </div>
        {data?.user?.websiteUrl && (
          <div className="profile-about__website">
            <a
              href={userExtensions.absoluteWebsitUrl()}
              target="_blank"
              className="btn btn--medium"
              rel="noreferrer noopener"
            >
              {data?.user?.websiteUrl}
            </a>
          </div>
        )}
      </section>
    );
  };

  const renderSocials = () =>
    data?.user?.streamSchedule ? (
      <section className="profile-socials">
        <h3 className="profile-socials__title">SCHEDULE</h3>
        <div className="profile-socials__block light-border">
          <ReactMarkdown source={data?.user?.streamSchedule} />
        </div>
      </section>
    ) : null;

  const personalAvatar = useAvatar(
    data?.user,
    userExtensions.isStreamer(),
    true
  );
  const avatar = useAvatar(data?.user, userExtensions.isStreamer(), false);

  const globalOffersUserIds = ['5f479cc856c88f00119dfb88', '5fa584a7e1c6c600119ecba0', '5f479cc856c88f00119dfb88']
  const showGlobalOffers = id && globalOffersUserIds.includes(id);

  if (loading) return <Loading />;
  if (error || data?.user?.blocked)
    return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <ReactTooltip
        id="counter-mini"
        className="tooltip"
        backgroundColor="#008ca5"
        textColor="white"
      />
      <section className="banner light-border">
        <div className="banner-image banner--cover banner--blur">
          <MediaImage
            className="banner--profile"
            src={data?.user.banner}
            format="medium"
          />

          <div className="banner-user">
            <div className="banner-user__image">
              <MediaImage src={avatar} format="small" />
            </div>
            <div className="banner-user__content">
              <h1 className="banner-user__title">{data?.user?.profileName}</h1>
              <VerifiedBadge user={data?.user} />
            </div>
          </div>
        </div>

        <div className="banner-controls">
          {allowMainMenu && (
            <BannerControl
              active={showMainMenu}
              subject="main menu"
              onClick={toggleMainMenu}
            />
          )}

          <div className="banner-meta">
            <div
              className="banner-meta__item"
              data-tip="Posts"
              data-for="counter-mini"
            >
              <i className="banner-meta__item-icon icon-write" />
              {data?.postCount?.aggregate?.count}
            </div>
            <div
              className="banner-meta__item"
              data-tip="Views"
              data-for="counter-mini"
            >
              <i className="banner-meta__item-icon icon-watch" />
              {data?.user?.views}
            </div>

            <div
              className="banner-meta__item"
              data-tip="Followers"
              data-for="counter-mini"
            >
              <i className="banner-meta__item-icon icon-user" />
              {data?.followerCount?.aggregate?.count}
            </div>

            <div
              className="banner-meta__item"
              data-tip="Comments"
              data-for="counter-mini"
            >
              <i className="banner-meta__item-icon icon-comment" />
              {data?.commentCount?.aggregate?.count}
            </div>
            {userExtensions.isStreamer() && (
              <Link
                to={`/hunt/${data?.user?.slug}`}
                className="banner-meta__item"
                data-tip="Go to Bonushunt"
                data-for="counter-mini"
              >
                <i className="banner-meta__item-icon icon-hunt" />
              </Link>
            )}

            {userExtensions.isStreamer() && authIsOwner && (
              <Link
                to={`/settings/streamer/prize-drops/`}
                className="banner-meta__item"
                data-tip="Go to your prize drop settings"
                data-for="counter-mini"
              >
                <i className="banner-meta__item-icon icon-prize-drop" />
              </Link>
            )}

            <div className="banner-meta-divider" />

            {userExtensions.isStreamer() && id && isFollowing && (
              <div className="banner-meta__item banner-meta__control">
                <LiveNotificationToggle profileId={id} />
              </div>
            )}
            <div className="banner-meta__item banner-meta__control">
              <FollowButton size="none" userId={id} update={setIsFollowing} />
            </div>
          </div>

          {allowSidebarMenu && (
            <BannerControl
              active={showSidebarMenu}
              subject="sidebar"
              onClick={toggleSidebarMenu}
              reversed={true}
            />
          )}
        </div>
      </section>

      {userExtensions.isStreamer() && isLive && liveSource && (
        <LiveStream
          source={liveSource}
          id={getLiveStreamSourceId(liveSource)}
          user={data?.user}
          offers={streamerData?.streamerLiveStreamOffers}
        />
      )}

      {!isLive && renderAboutMe()}

      {userExtensions.isStreamer() && isLive === false && (
        <OfflineBanner
          banner={streamerData?.offlineBanner}
          offers={streamerData?.streamerLiveStreamOffers}
        />
      )}

      {userExtensions.isStreamer() && (
        <>
          {!isLive && renderSocials()}

          {showGlobalOffers && <GlobalOffers />}
          {!showGlobalOffers && data?.user?.streamerBonusOffers &&
            data.user.streamerBonusOffers.length > 0 && (
              <section className="profile-bonus-offers">
                <h3 style={{ marginBottom: 7.5 }}>STREAMER BONUS OFFERS</h3>
                <div className="row">
                  {data?.user?.streamerBonusOffers?.map((offer) => (
                    <StreamerBonusOffer key={offer.id} data={offer} />
                  ))}
                </div>
              </section>
            )}

          {isLive && renderAboutMe()}

          {isLive && renderSocials()}

          {data?.user?.highlightsPlaylistId && (
            <section className="profile-highlight">
              <h3 className="profile-highlight__title">LATEST HIGHLIGHTS</h3>
              <div className="profile-highlight__list row small-gutters">
                {highlights?.map((video) => (
                  <div
                    key={video.id}
                    className="profile-highlight__list-item col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  >
                    <a
                      className="clickable"
                      href={`https://youtu.be/${video.snippet.resourceId.videoId}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <div className="profile-highlight__list__inner">
                        <div className="profile-highlight__list-item__image profile-highlight__list-item__image--youtube">
                          <img src={video.snippet.thumbnails.high.url} />
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </section>
          )}
        </>
      )}

      <section className="profile-photos">
        <h3 className="profile-photos__title">PHOTOS</h3>
        <div className="profile-photos__list row small-gutters">
          {images().map((image, i) => (
            <Link
              key={i}
              to={`/profile/${id}/photos/${i}`}
              className="clickable profile-photos__list-item col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3"
            >
              <div className="profile-photos__list-item__image light-border">
                <MediaImage src={image} format="small" />
              </div>
            </Link>
          ))}

          <Link
            style={{ marginLeft: 8, width: "100%" }}
            to={`/profile/${id}/photos`}
            className="clickable highlight-text"
          >
            VIEW ALL PHOTOS
          </Link>
        </div>
      </section>

      {isPartner &&
        !authIsOwner &&
        !authIsPartner &&
        data?.user?.allowQuestions && (
          <PartnerQuestionForm
            owner={user}
            partnerId={data?.user?.id}
            onCreate={() => {
              update();
            }}
          />
        )}

      <section
        className="profile-photos"
        style={{
          marginBottom: 0,
        }}
      >
        <h3 className="profile-photos__title">POSTS</h3>
      </section>

      <Infinity
        key={postsVersion}
        data={posts}
        pageSize={ProfilePageQueryPostPageSize}
        fetchMore={() => loadMore()}
      >
        {posts.map((post) => (
          <Post
            key={post.id}
            post={post}
            onDeleted={() => setPosts(arrayWithout(post, posts))}
          />
        ))}
      </Infinity>
    </>
  );
};

export default ProfilePage;
