import { SnackbarEnum, SnackbarMessage } from "../dtos/snackbar.dto";
import { useSnackbar } from "react-simple-snackbar";

export const snackbarStyle = {
  backgroundColor: "#008ca5",
  fontWeight: "bold",
  zIndex: 3000,
  width: "100%",
  height: "35px",
  maxWidth: "400px",
  borderRadius: 0,
  boxShadow: "0 5px 5px rgba(0, 0, 0, .25)",
};

export const snackbarCloseStyle = {
  width: "25%",
  height: "100%",
  margin: 0,
};

export const snackbarConfig = {
  style: snackbarStyle,
  closeStyle: snackbarCloseStyle,
  position: "bottom-center",
};

export const useOpenSnackbar = () => {
  const [success] = useSnackbar(snackbarConfig);
  const [error] = useSnackbar({
    ...snackbarConfig,
    style: { ...snackbarStyle, backgroundColor: "#ED4337" },
  });

  return (message: SnackbarMessage) =>
    message.status === SnackbarEnum.ERROR
      ? error(message.content)
      : success(message.content);
};
