import React from "react";

interface TitleProps {
  title?: string;
  inline?: boolean;
  subtitle?: string;
}

const Title: React.FC<TitleProps> = ({ title, inline, subtitle, children }) => {
  const baseNode = () => (
    <>
      <h1 className="header__title">
        {title}
        {children}
      </h1>
      {subtitle && <h4 className="header__subtitle">{subtitle}</h4>}
    </>
  );

  return inline ? (
    baseNode()
  ) : (
    <section className="header">{baseNode()}</section>
  );
};

export default Title;
