import { gql } from "@apollo/client";
import { MediaDto, mediaFields } from "./media.dto";
import { UserDto, userFields } from "./user.dto";

export interface DemoGameProvider {
  name?: string;
  logo?: MediaDto
}

export interface DemoGameCTA {
  title?: string;
  content?: string;
  button?: string;
  url?: string;
}

export interface DemoGame {
  id?: string;
  slug?: string;
  title?: string;
  provider?: DemoGameProvider;
  content?: string;
  embedUrl?: string;
  pinned?: boolean;
  preview?: MediaDto;
  cta?: DemoGameCTA;
  allowReview?: boolean;
  requiresVerification?: boolean;
}

export interface DemoGameRating {
  id?: string;
  demo_game?: string;
  user?: string;
  value: number;
}

export interface DemoGameReview {
  id?: string;
  content?: string;
  user?: UserDto;
  createdAt?: Date;
}

// TODO: cleanup required fields
export const demoGamesQuery = gql`
  query {
    entries: demoGames(sort: "createdAt:DESC", where: { pinned_ne: true }) {
      id
      slug
      title
      provider {
        name
        logo {
          ${mediaFields}
        }
      }
      content
      embedUrl
      pinned
      preview {
        ${mediaFields}
      }
    }
    pinned: demoGames(sort: "createdAt:DESC", limit: 1, where: { pinned: true }) {
      id
      slug
      title
      provider {
        name
        logo {
          ${mediaFields}
        }
      }
      content
      embedUrl
      pinned
      preview {
        ${mediaFields}
      }
    }
  }
`;

export const demoGamesDetailQuery = gql`
  query ($slug: String!) {
    entries: demoGames(limit: 1, where: { slug: $slug }) {
      id
      slug
      title
      provider {
        name
        logo {
          ${mediaFields}
        }
      }
      content
      embedUrl
      pinned
      preview {
        ${mediaFields}
      }
      cta {
        title
        content
        button
        url
      }
      allowReview
      requiresVerification
    }
  }
`;

export const demoGameRatingsQuery = gql`
  query($gameId: String!, $userId: String!) {
    ratings: demoGameRatings(
      limit: 1
      where: { demo_game: $gameId, user: $userId, review_null: true }
    ) {
      value
    }
  }
`;

export const demoGameRatingsAverageQuery = gql`
  query($gameId: ID!, $reviewId: ID) {
    demoGameRatingAverage(gameId: $gameId, reviewId: $reviewId)
  }
`;

export const demoGameRatingsMutation = gql`
  mutation demoGameRating($gameId: ID!, $value: Float!, $reviewId: ID) {
    demoGameRating(gameId: $gameId, value: $value, reviewId: $reviewId)
  }
`;

export const demoGameReviewsQueryLimit = 5;

export const demoGameReviewsQuery = gql`
  query($gameId: String!, $sort: String, $start: Int) {
    reviews: demoGameReviews(
      limit: ${demoGameReviewsQueryLimit},
      start: $start,
      sort: $sort,
      where: { demo_game: $gameId }
    ) {
      id
      user {
        ${userFields}
      }
      content
      createdAt
    }
  }
`

export const demoGamePersonalReviewQuery = gql`
  query($gameId: String!, $userId: String!) {
    reviews: demoGameReviews(
      limit: 1
      where: { demo_game: $gameId, user: $userId }
    ) {
      id
      content
    }
  }
`

export const demoGameReviewsMutation = gql`
  mutation demoGameReview($gameId: ID!, $content: String!) {
    demoGameReview(gameId: $gameId, content: $content)
  }
`;

export const demoGameLatestQuery = gql`
  query {
    entries: demoGames(sort: "createdAt:DESC", limit: 1) {
      id
      createdAt
    }
  }
`