import React from "react";
import { Rating } from "react-simple-star-rating";
import { useQuery } from "@apollo/client";
import { demoGameRatingsAverageQuery } from "../../dtos/demo-games.dto";

interface Props {
  gameId: string;
}

export const DemoGamesRatingSummary = ({ gameId }: Props) => {
  const { data } = useQuery<{ demoGameRatingAverage: number }>(
    demoGameRatingsAverageQuery,
    {
      variables: { gameId },
    }
  );

  return (
    <div className="rating">
      <Rating
        ratingValue={data?.demoGameRatingAverage || 0}
        size={25}
        fillColor="#008ca5"
        emptyColor="#bdbdbd"
        readonly={true}
      />
    </div>
  );
};
