import { IAuthContext } from "../context/auth.context";

export const balanceMessage = (points?: number) => {
  points = points || 0;
  return `You have earned ${points} point${points > 1 ? "s" : ""}!`;
};

export const balanceCheck = async (
  authContext: IAuthContext,
  openSnackbar: any
) => {
  if (authContext.isPartner) return;

  const update = await authContext.update?.();

  if ((update?.balanceChange || 0) > 0) {
    openSnackbar(balanceMessage(update?.balanceChange));
  }
};
