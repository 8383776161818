import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { PostDto } from "../dtos/post.dto";

export enum PostModalProcessingStatusEnum {
  CANCELLED,
  ERROR,
  FILTERING,
  REMOVED,
  ENCODING,
  PUBLISHING,
  DONE,
}

export interface PostModalProcessingInterface {
  id: string;
  onStatusUpdate: (
    status: PostModalProcessingStatusEnum
  ) => void | Promise<void>;
}

const PostModalProcessing: React.FC<PostModalProcessingInterface> = ({
  id,
  onStatusUpdate,
}) => {
  const [status, setStatus] = useState<
    PostModalProcessingStatusEnum | undefined
  >();

  const updateStatus = (status: PostModalProcessingStatusEnum) => {
    setStatus(status);
    onStatusUpdate(status);
  };

  const { data } = useQuery<{ post: PostDto }>(
    gql`
      query processingPostQuery($id: ID!) {
        post(id: $id) {
          id
          videos {
            encoded
            filtered
          }
          processed
        }
      }
    `,
    {
      variables: { id },
      pollInterval:
        status === PostModalProcessingStatusEnum.PUBLISHING ? 10000 : 30000,
    }
  );

  useEffect(() => {
    // Handle filtering status
    if (!status) updateStatus(PostModalProcessingStatusEnum.FILTERING);

    // Handle removed status
    if (data?.post === null)
      updateStatus(PostModalProcessingStatusEnum.REMOVED);

    // Handle encoding status
    if (
      status === PostModalProcessingStatusEnum.FILTERING &&
      data?.post?.videos?.every((file) => file.filtered)
    ) {
      updateStatus(PostModalProcessingStatusEnum.ENCODING);
    }

    // Handle publishing status
    if (
      status === PostModalProcessingStatusEnum.ENCODING &&
      data?.post?.videos?.every((file) => file.encoded)
    ) {
      updateStatus(PostModalProcessingStatusEnum.PUBLISHING);
    }

    // Handle done status
    if (
      status === PostModalProcessingStatusEnum.PUBLISHING &&
      data?.post?.processed
    ) {
      updateStatus(PostModalProcessingStatusEnum.DONE);
    }
  }, [data]);

  return <></>;
};

export default PostModalProcessing;
