import { gql } from "@apollo/client";
import { userFields } from "../../dtos/user.dto";

export const bonushuntFindOneFields = `
  ${userFields}
  twitchUsername
  youtubeUsername
  bonushuntPage {
    startingBalance
    casinoName
    casinoUrl
    date
    bonuses {
      slot {
        id
        name
      }
      betSize
    }
  }
`;

export const bonushuntFindOne = gql`
  query bonushuntFindOne($slug: String!) {
    users(where: { slug: $slug, type:"streamer", blocked: false }, limit: 1) {
      ${bonushuntFindOneFields}
    }
  }
`;
