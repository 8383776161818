import React from "react";
import { Link } from "gatsby";

export const AboutMenu = () => (
  <div className="about-menu">
    <div className="about-divider" />

    <div className="links">
      <div className="links-left">
        <ul>
          <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-statement">Privacy Statement</Link>
          </li>
        </ul>
      </div>
      <div className="links-right">
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/become-partner">Become a Partner</Link>
        </li>
      </div>
    </div>

    <div className="branding">
      <p className="copyright">
        Copyright © {new Date().getFullYear()} CasinoRing <br />
        All rights reserved
      </p>

      <div className="logos">
        <a
          href="https://gamstop.co.uk/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <i className="icon-logo-gamstop"></i>
        </a>

        <a
          href="https://www.begambleaware.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <i className="icon-logo-begambleaware"></i>
        </a>
      </div>
    </div>
  </div>
)
