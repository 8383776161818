import React from "react";
import { StreamerBonusOfferDto } from "../../dtos/user.dto";
import ReactMarkdown from "react-markdown";
import { formatOrUrl } from "../../dtos/media.dto";

interface StreamerBonusOfferContentProps {
  data: StreamerBonusOfferDto;
}

const StreamerBonusOffer: React.FC<StreamerBonusOfferContentProps> = ({
  data,
}) => {
  const getTitle = () => (data.casino?.name ? data.casino.name : data.title);

  const getImageUrl = () => {
    if (data.casino?.logo?.url) return data.casino.logo.url;
    if (data.image) return formatOrUrl("small", data.image);
  };

  return (
    <div key={data.id} className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <a
        className="profile-bonus-offer"
        target="_blank"
        rel="noreferrer noopener"
        href={data.url}
      >
        {getImageUrl() && (
          <img
            className="profile-bonus-offer-image"
            src={getImageUrl()}
            alt={getTitle()}
          />
        )}
        <div className="profile-bonus-offer-content">
          <ReactMarkdown source={data.bonusOffer} />
        </div>
        <div className="profile-bonus-offer-button">Play now</div>
      </a>
    </div>
  );
};

export default StreamerBonusOffer;