export interface SinglePageContactMailDto {
  title: string;
  address: string;
}

export interface SinglePageContactInfoDto {
  content: string;
}

export interface SinglePageContactDto {
  title: string;
  content: string;
  mail: SinglePageContactMailDto[];
  info: SinglePageContactInfoDto[];
}

export interface ConcatSinglePageDto
  extends SinglePageContactMailDto,
    SinglePageContactDto,
    SinglePageContactInfoDto {
  [key: string]: any;
}

export const SinglePageContactFields = `
  title
  intro
  content
  mail {
    title
    address
  }
  info {
    content
  }
`;
