import { useQuery } from "@apollo/client";
import * as React from "react";
import Alert, { somethingWentWrong } from "../components/alert";
import GuessingCompetition from "../components/giveaways/guessing-competition";
import MultiplierCompetition from "../components/giveaways/multiplier-competition";
import RandomCompetition from "../components/giveaways/random-competition";
import RandomMediaCompetition from "../components/giveaways/random-media-competition";
import Loading from "../components/loading";
import MediaImage from "../components/media-image";
import {
  GiveawayDto,
  giveawaySingleQuery,
  GiveawayType,
} from "../dtos/giveaway.dto";
import { formatOrUrl } from "../dtos/media.dto";
import WysiwygContent from "../components/wysiwyg-content";
import { AuthContext } from "../context/auth.context";
import { SeoContext } from "../context/seo.context";
import { useContext } from "react";
import { GiveawayDemoGamePreview } from "../components/giveaways/demo-game/preview";
import { Poll } from "../components/giveaways/poll";

interface GiveawayResultsPageProps {
  path: string;
  id?: string;
}

const GiveawayResultsPage: React.FC<GiveawayResultsPageProps> = ({ id }) => {
  const seoContext = useContext(SeoContext);
  const { user, isPartner } = React.useContext(AuthContext);

  const { data, loading, error, refetch } = useQuery<{ giveaway: GiveawayDto }>(
    giveawaySingleQuery,
    { variables: { id }, skip: !id }
  );

  const isCreditable = (
    balance: number | undefined,
    entryCost: number | undefined
  ) => {
    if (!entryCost) return true;
    if (!balance) return false;
    return balance - entryCost > 0;
  };

  React.useEffect(() => {
    if (data) {
      seoContext.setData({
        /**
         * TODO: corresponding to type..
         */
        title: data.giveaway.title,
        description: data.giveaway.descriptionShort,
        images: {
          banner: formatOrUrl("medium", data.giveaway.banner),
        },
      });
    }
  }, [data]);

  const renderComponent = () => {
    const creditable = isCreditable(user?.balance, data?.giveaway?.entryCost);

    switch (data?.giveaway.type) {
      case GiveawayType.GuessingCompetition:
        return (
          <GuessingCompetition
            giveaway={data.giveaway}
            onUpdate={async () => await refetch()}
            creditable={creditable}
          />
        );
      case GiveawayType.BiggestMultiplier:
        return (
          <MultiplierCompetition
            giveaway={data.giveaway}
            onUpdate={async () => await refetch()}
            creditable={creditable}
          />
        );
      case GiveawayType.Random:
        return (
          <RandomCompetition
            giveaway={data.giveaway}
            onUpdate={async () => await refetch()}
            creditable={creditable}
          />
        );
      case GiveawayType.RandomWithMedia:
        return (
          <RandomMediaCompetition
            giveaway={data.giveaway}
            onUpdate={async () => await refetch()}
            creditable={creditable}
          />
        );
      case GiveawayType.Poll:
        return (
          <Poll
            giveaway={data.giveaway}
            onUpdate={async () => await refetch()}
            creditable={creditable}
          />
        );
    }
  };

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <section className="page-content page-content--background">
      <div className="media-auto-sized">
        <MediaImage src={data?.giveaway.banner} format="medium" />
      </div>

      <div className="page-content__text">
        <h1 className="page-content__title">{data?.giveaway?.title}</h1>
        <WysiwygContent html={data?.giveaway.description} />

        <hr />

        {data?.giveaway?.demo_game?.id && (
          <GiveawayDemoGamePreview
            gameId={data?.giveaway?.demo_game?.id}
            content={data?.giveaway?.demoGameContent}
          />
        )}

        {renderComponent()}
      </div>
    </section>
  );
};

export default GiveawayResultsPage;
