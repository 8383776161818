import React, { useEffect, useRef } from "react";
import { NotificationsContext } from "../context/notifications.context";
import NotificationsCount from "./notifications-count";
import NotificationsContent from "./notifications-content";

const Notifications: React.FC = () => {
  const ref = useRef<HTMLLIElement>();
  const { menu, toggleMenu } = React.useContext(NotificationsContext);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") menu && toggleMenu();
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      menu && toggleMenu();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <li ref={ref} className="navigation__list-item list-item__notifications">
      <a className="navigation__list-item__link" onClick={toggleMenu}>
        <span className="navigation__list-item__label--desktop">
          Notifications
        </span>
        <span className="navigation__list-item__label--mobile">
          <img src="/images/bel.svg" />
        </span>
        <NotificationsCount className="navigation__list-item__badge" />
      </a>
      {menu && <NotificationsContent />}
    </li>
  );
};

export default Notifications;
