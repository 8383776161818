import { useEffect } from "react";

export const useNoScrollBody = (noScroll?: boolean) =>
  useEffect(
    () =>
      noScroll
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll"),
    [noScroll]
  );
