/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { ChangeEvent, useState } from "react";

export interface BonushuntFieldComponentProps<
  T extends string | number | Date
> {
  style?: React.CSSProperties;
  className?: string;
  onBlur: (model: T) => any;
  type: string;
  placeholder: string;
  model: T;
}

export function BonushuntFieldComponent<T extends string | number>(
  props: BonushuntFieldComponentProps<T>
): JSX.Element {
  const [model, setModel] = useState<T>(props.model);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    switch (props.type) {
      default:
      case "text":
        setModel(event.target.value as T);
        break;
      case "number":
        setModel(event.target.valueAsNumber as T);
        break;
      case "date":
        setModel(moment(event.target.valueAsDate).format("YYYY-MM-DD") as T);
        break;
    }
  };

  return (
    <input
      style={props.style || {}}
      className={props.className}
      type={props.type}
      value={model as any}
      placeholder={props.placeholder}
      onChange={(event): void => onChange(event)}
      onBlur={(): any => props.onBlur(model)}
    />
  );
}
