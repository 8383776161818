import React from "react";
import { useLoginModal } from "../../modals/login-modal.context";

interface Props {
  status?: 'unauthorized' | 'submitted' | 'declined' | 'resubmit'
}

export const DemoGamesVerificationNotice = ({ status }: Props) => {
  const { openLoginModal } = useLoginModal();

  const getMessage = () => {
    switch(status) {
      case 'unauthorized':
        return 'Please login or create a CasinoRing.com account in order to start the verification.';
      case 'submitted':
        return 'Your submission is being review. Please check back later.';
      case 'declined':
        return 'Your submission has been declined. Please contact us for more details.';
      case 'resubmit':
        return 'Your submission could not be validated. Please try again.';
    }
  }

  return (
    <>
      <p className="notice">
        {getMessage()}
      </p>

      {
        status === 'unauthorized' &&
        <button className="btn" onClick={() => openLoginModal()}>
          Login or join now
        </button>
      }
    </>
  )
}
