import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

export interface SmallButtonProps {
  icon?: string;
}

export const SmallButton: React.FC<
  SmallButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon, className, children, type = "button", ...rest }) => (
  <button
    className={classNames("btn small-button", className)}
    type={type}
    {...rest}
  >
    <span>{children}</span>
    {icon && <i className={icon} />}
  </button>
);
