import React from "react";
import MediaImage from "./media-image";
import { CasinoDto, CasinoFeatureTypeEnum } from "../dtos/casino.dto";
import classNames from "classnames";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

interface CasinoProps {
  data: CasinoDto;
}

const Casino: React.FC<CasinoProps> = ({ data }) => {
  const [flipped, setFlipped] = React.useState(false);
  const toggle = () => setFlipped(!flipped);
  const hasDetail = data.detail?.id && data.slug;

  return (
    <div
      className={classNames(
        "block__list-item",
        "col-12",
        "col-sm-12",
        "col-md-6",
        "col-lg-4",
        "casino-cards",
        { "casino-card-flipped": flipped }
      )}
    >
      <div
        className="casino-card casino-front"
        onClick={() => {
          if (hasDetail) navigate(`/reviews/${data.slug}`);
        }}
      >
        <div
          className="block__list__inner"
          style={{
            height: `100%`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <div
            className="block__list-item__image block__list-item__image--casino"
            style={{ flex: 3 }}
          >
            <MediaImage src={data.logo} format="large" />
            {data.features && data.features.length > 0 && (
              <div className="features">
                {data.features.map((feature) => (
                  <div
                    key={feature.type}
                    className={classNames("feature", {
                      "feature-animated": !flipped,
                    })}
                  >
                    <img
                      src={`/images/${
                        feature.type === CasinoFeatureTypeEnum.EXCLUSIVE
                          ? "exclusive"
                          : "new"
                      }.svg`}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="block__list-item__content"
            style={{
              height: `100%`,
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <h3
              className="block__list-item__subtitle"
              style={{ flex: 1, margin: 0, fontSize: `1.2rem` }}
            >
              {data.name}
            </h3>
            <h3
              className="block__list-item__title"
              style={{ flex: 3, fontSize: `1rem` }}
            >
              {data.content}
            </h3>

            {data.affiliate?.slug && <a
              className="block__list-item__btn btn btn--medium"
              onClick={(e) => {
                e.stopPropagation();
              }}
              href={data.affiliate.slug!}
            >
              GET BONUS
            </a>}

            {hasDetail && (
              <Link
                to={`/reviews/${data.slug}`}
                className="block__list-item__link"
              >
                MORE INFO
              </Link>
            )}

            {!hasDetail && data.contentMoreInfo && (
              <a className="block__list-item__link" onClick={toggle}>
                MORE INFO
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="casino-card casino-back">
        <div
          className="block__list__inner"
          style={{
            height: `100%`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <div
            className="block__list-item__content"
            style={{
              height: `100%`,
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            <h3
              className="block__list-item__subtitle"
              style={{ flex: 1, margin: 0, fontSize: `1.2rem` }}
            >
              {data.name}
            </h3>
            <h3
              className="block__list-item__title"
              style={{ flex: 2, fontSize: `1rem` }}
            >
              {data.contentMoreInfo}
            </h3>
            {data.contentMoreInfo && (
              <a className="block__list-item__link" onClick={toggle}>
                CLOSE
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Casino;
