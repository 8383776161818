export interface AggregateDto {
  aggregate: {
    count?: number;
    totalCount?: number;
  };
}

export interface AggregateTypedDto<T> extends AggregateDto {
  values?: T[];
}

export const aggregateFields = `
  aggregate {
    count
    totalCount
  }
`;
