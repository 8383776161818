import React, { FC } from "react";
import { MetaDto } from "../dtos/location.dto";
import { Tooltip } from "./tooltip";
import classNames from "classnames";

export const LocalisationFlag: FC<{
  data: MetaDto;
  size?: "normal" | "large";
}> = ({ data, size }) => {
  if (!data.countryCode || !data.country || !data.configured) return null;

  return (
    <Tooltip
      className="localisation-flag_tooltip"
      text={`Best selection based on your location (${data.country}) and regulations`}
    >
      <img
        className={classNames("localisation-flag", {
          "localisation-flag_large": size === "large",
        })}
        src={`/images/localisation/flags/${data.countryCode.toLowerCase()}.svg`}
        alt={`Your current location (${data.country})`}
      />
    </Tooltip>
  );
};
