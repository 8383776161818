import React, { useContext, useState } from "react";
import Moment from "react-moment";
import {
  NotificationDto,
  NotificationPrimaryEnum,
  NotificationSecondaryEnum,
} from "../dtos/notification.dto";
import { NotificationsContext } from "../context/notifications.context";
import { navigate } from "@reach/router";
import NotificationImage from "./notification-image";
import { DisplayMode, getDisplayMode } from "../utils/display-mode";

interface NotificationProps {
  data: NotificationDto;
}

const Notification: React.FC<NotificationProps> = ({ data }) => {
  const {
    id,
    date,
    primary,
    secondary,
    content,
    targetRef,
    target,
    user,
    count,
  } = data;

  const getAvatar = () => {
    if (user?.avatar?.formats?.thumbnail?.url)
      return user.avatar.formats.thumbnail.url;

    if (user?.avatar?.url) return user.avatar.url;
  };

  const notificationsContext = useContext(NotificationsContext);
  const [read, setRead] = useState<boolean>(data.read);

  const open = () => {
    notificationsContext.markRead(id, !isScheduled());
    setRead(true);
    if (target) {
      navigate(`/${targetRef ? `${targetRef}/` : ""}${target}`);
    }

    if (isStreamer()) {
      // Related FAQ-item (on production site)
      navigate(`/help-advice/611f42135ac94c00137208d6`);
    }
  };

  const formatTrigger = (profileName: string, count: number) => {
    if (count === 1) return profileName;
    if (count === 2) return `${profileName} and 1 other`;
    return `${profileName} and ${count - 1} others`;
  };

  const formatAction = (
    count: number,
    primary: NotificationPrimaryEnum,
    secondary: NotificationSecondaryEnum
  ) => {
    const verb = count === 1 ? "has" : "have";

    switch (primary) {
      case NotificationPrimaryEnum.REACTION:
        return `${verb} liked your post`;
      case NotificationPrimaryEnum.COMMENT:
        return `${verb} replied to your ${
          secondary === NotificationSecondaryEnum.REPLY ? "comment" : "post"
        }`;
      case NotificationPrimaryEnum.FOLLOWER:
        return `is now following you`;
    }
  };

  const isScheduled = () => primary === NotificationPrimaryEnum.SCHEDULED;

  const isMarketingItem = () =>
    primary === NotificationPrimaryEnum.MARKETING_ITEM;

  const isLiveStream = () => primary === NotificationPrimaryEnum.LIVE_STREAM;

  const isPrizeWinner = () => primary === NotificationPrimaryEnum.PRIZE_WINNER;

  const isGiveawayPrizeWinner = () =>
    isPrizeWinner() && secondary === NotificationSecondaryEnum.GIVEAWAY;

  const isPrizeDropPrizeWinner = () =>
    isPrizeWinner() && secondary === NotificationSecondaryEnum.PRIZE_DROP;

  const isStreamer = () => primary === NotificationPrimaryEnum.STREAMER;

  const isStreamerInactive = () =>
    isStreamer() && secondary === NotificationSecondaryEnum.INACTIVE;

  const isStreamerCancelled = () =>
    isStreamer() && secondary === NotificationSecondaryEnum.CANCELLED;

  const imageUrl = () => {
    if (isScheduled() || isMarketingItem() || isPrizeWinner() || isStreamer())
      return `/images/logo-${
        getDisplayMode() === DisplayMode.Dark ? "gold" : "blue"
      }.svg`;
    return getAvatar();
  };

  const imageDescription = () => {
    if (primary === NotificationPrimaryEnum.SCHEDULED)
      return "Community notification";
    if (
      primary === NotificationPrimaryEnum.PRIZE_WINNER &&
      secondary === NotificationSecondaryEnum.GIVEAWAY
    )
      return "You have won a giveaway!";
    if (
      primary === NotificationPrimaryEnum.MARKETING_ITEM ||
      primary === NotificationPrimaryEnum.STREAMER
    )
      return "CasinoRing team notification";
    return user?.profileName;
  };

  return (
    <>
      <a className="notification" onClick={open}>
        <NotificationImage
          url={imageUrl()}
          description={imageDescription()}
          custom={
            isScheduled() ||
            isMarketingItem() ||
            isPrizeWinner() ||
            isStreamer()
          }
        />

        <div className="notification-content">
          {!isScheduled() &&
            !isPrizeWinner() &&
            !isMarketingItem() &&
            !isLiveStream() &&
            !isStreamer() &&
            user &&
            count && (
              <p>
                <b>{formatTrigger(user.profileName, count)}</b>{" "}
                {formatAction(count, primary, secondary)}
              </p>
            )}

          {isScheduled() && (
            <p>
              <b>{content}</b>
            </p>
          )}

          {isMarketingItem() && (
            <p>
              <b>Your marketing item has been {secondary}. </b>
            </p>
          )}

          {isLiveStream() && (
            <p>
              <b>{user?.profileName}</b> is now live on stream
            </p>
          )}

          {(isGiveawayPrizeWinner() || isPrizeDropPrizeWinner()) && (
            <p>
              <b>
                You have won a{" "}
                {isGiveawayPrizeWinner() ? "giveaway" : "price drop"}!
              </b>{" "}
              Claim your prize
            </p>
          )}

          {isStreamerInactive() && (
            <p>
              <b>Your stream is inactive</b> <br />
              We are sorry to see that you have not been streaming in the past
              21 days. If you wish to retain your streamer account, please go
              live (at least once) in the next 7 days
            </p>
          )}

          {isStreamerCancelled() && (
            <p>
              <b>Streamer account cancelled</b> <br />
              You have unfortunately not been live in the past 30 days,
              resulting in a cancellation of your streamer account. You can
              always re-apply, if you wish to become a streamer again
            </p>
          )}

          <Moment className="date" fromNow>
            {date}
          </Moment>
        </div>

        <div className="status">{!read && <div className="indicator" />}</div>
      </a>

      <hr className="divider" />
    </>
  );
};

export default Notification;
