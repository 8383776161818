import { useEffect, useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

const Mutation = gql`
  mutation streamView($streamerId: ID!, $identifier: String!) {
    streamView(streamerId: $streamerId, identifier: $identifier)
  }
`;

const storageKey = "stream:view:identifier";

const getOrSetIdentifier = () => {
  const candidate = localStorage.getItem(storageKey);
  if (candidate) return candidate;

  const generated = uuidv4();
  localStorage.setItem(storageKey, generated);
  return generated;
};

export const useStreamView = (streamerId?: string) => {
  const debug = false;
  const identifier = useMemo(() => getOrSetIdentifier(), []);
  const [mutate] = useMutation<{ streamView: number }>(Mutation);
  const [count, setCount] = useState<number>();

  /**
   * Sync view entry based on interval
   * Does not sync when parameters are missing or tab is inactive
   * Updates view count based on response value
   */
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!identifier || !streamerId || !document.hasFocus()) return;

      const response = await mutate({
        variables: { streamerId, identifier },
      });

      setCount(
        debug ? Math.floor(Math.random() * 100 + 1) : response.data?.streamView
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return { count };
};
