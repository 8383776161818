import React, { FC, useContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { RouteProps } from "../index";
import { SeoContext } from "../../context/seo.context";
import Loading from "../../components/loading";
import WysiwygContent from "../../components/wysiwyg-content";
import { first } from "lodash";
import ErrorPage from "../error";
import { DemoGame, demoGamesDetailQuery } from "../../dtos/demo-games.dto";
import { DemoGamesSlot } from "../../components/demo-games/slot";
import { DemoGamesEmbed } from "../../components/demo-games/embed";
import { DemoGamesCta } from "../../components/demo-games/cta";
import { DemoGamesReview } from "../../components/demo-games/review";
import { DemoGamesOffers } from "../../components/demo-games/offers";
import { AuthContext } from "../../context/auth.context";

interface Properties extends RouteProps {
  slug?: string;
}

export const DemoGamesDetailPage: FC<Properties> = ({ slug }) => {
  const seoContext = useContext(SeoContext);
  const { user } = useContext(AuthContext);

  const { data, loading, error } = useQuery<{
    entries: DemoGame[];
  }>(demoGamesDetailQuery, {
    variables: { slug },
  });

  const { id, title, content, provider, embedUrl, cta, allowReview, requiresVerification } =
    first(data?.entries) || {};
  const isValid = id && title && content && embedUrl;
  const verification = !!requiresVerification && user?.verificationStatus !== 'approved'

  useEffect(() => {
    if (id) {
      seoContext.setData({
        title: title,
        description: `Play the new ${title} demo game ${
          provider?.name ? `from ${provider?.name}` : ""
        }`,
      });
    }
  }, [id]);

  const slot = useMemo(() => {
    return title ? (
      <DemoGamesSlot title={title} provider={provider?.name} logo={provider?.logo} />
    ) : null;
  }, [title, provider]);

  if (loading) return <Loading />;
  if (error || !isValid) return <ErrorPage />;

  return (
    <>
      <section className="page-content page-content-single demo-game-detail">
        <div className="page-content__text">
          {slot}
          <DemoGamesEmbed gameId={id!} url={embedUrl!} verification={verification} />
          <DemoGamesOffers />
          {cta && <DemoGamesCta data={cta} />}
          {content && <WysiwygContent html={content} />}
          {!!allowReview && <DemoGamesReview gameId={id!} />}
        </div>
      </section>
    </>
  );
};
