import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { StreamerLiveStreamOfferDto } from "../../dtos/user.dto";
import { arrayRandomEntry } from "../../utils/array";

interface Properties {
  offers?: StreamerLiveStreamOfferDto[];
  expanded?: boolean;
}

const Offers: React.FC<Properties> = ({ offers, expanded }) => {
  const timeout = useRef<NodeJS.Timeout>();

  const linkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noreferrer noopener">
      {children}
    </a>
  );

  const [current, setCurrent] = useState<StreamerLiveStreamOfferDto>();

  const update = () => {
    if (!offers) return;
    if (offers.length === 1) return setCurrent(offers[0]);
    setCurrent(arrayRandomEntry(offers.filter((x) => x.id !== current?.id)));
  };

  useEffect(() => {
    if (offers && offers.length > 0) update();
  }, [offers]);

  useEffect(() => {
    if (!current) return;

    const min = 30000;
    const max = 60000;

    timeout.current = setTimeout(
      () => update(),
      Math.random() * (max - min) + min
    );

    return () => timeout.current && clearTimeout(timeout.current);
  }, [current]);

  const render = (offer: StreamerLiveStreamOfferDto) => {
    const title = <h4>{offer.title}</h4>;
    const content = (
      <ReactMarkdown
        renderers={{ link: linkRenderer }}
        source={offer.content}
      />
    );

    if (expanded) {
      return (
        <div className="profile-offer-expanded">
          <div className="profile-offer-expanded_content">
            {title}
            {content}
          </div>
          <img src="/images/exclusive.svg" alt="Exclusive offer" />
        </div>
      );
    }

    return (
      <div className="profile-offer">
        {title}
        {content}
      </div>
    );
  };

  return current ? render(current) : null;
};

export default Offers;
