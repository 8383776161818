import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useQuery, gql } from "@apollo/client";
import { UserDto, UserDtoType, userFields } from "../dtos/user.dto";
import Loading from "../components/loading";
import { RouteProps } from ".";
import Member, { MemberLinkProps } from "../components/member";
import Infinity from "../components/infinity";
import Title from "../components/title";
import Alert, { somethingWentWrong } from "../components/alert";
import { SeoContext } from "../context/seo.context";

interface MembersPageProps extends RouteProps {
  type?: UserDtoType;
  filterText?: string;
  filterAllowed?: boolean;

  titleOverride?: string;
  memberLinkProps?: MemberLinkProps;
}

const MembersPageQueryPageSize = 18;
const MembersPageQuery = gql`
  query MembersPageQuery($type: String!, $filterText: String!, $start: Int) {
    members: users(
      limit: ${MembersPageQueryPageSize}
      start: $start
      where: { type_contains: $type, profileName_contains: $filterText, blocked: false }
      sort: "profileName:ASC,lastOnline:DESC"
    ) {
      ${userFields}
      live
    }
  }
`;

const MembersPage: React.FC<MembersPageProps> = ({
  type,
  filterText,
  filterAllowed,

  titleOverride,
  memberLinkProps,
}) => {
  const seoContext = useContext(SeoContext);

  const [members, setMembers] = React.useState<UserDto[]>([]);
  const [memberSearch, setMemberSearch] = useState<string>(filterText || "");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getQueryVariables = (start?: number): any => ({
    start,
    type: filterText ? "" : type || "",
    filterText: filterText || "",
  });

  const { loading, error, fetchMore } = useQuery<{
    members: UserDto[];
  }>(MembersPageQuery, {
    variables: getQueryVariables(),
    onCompleted: (data) => {
      setMembers(data.members || []);
    },
  });

  const loadMore = (): Promise<{}> => {
    return fetchMore({
      variables: getQueryVariables(members.length),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setMembers([...members, ...fetchMoreResult.members]);

        return prev;
      },
    });
  };

  const searchMembers = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    navigate(`/members/${memberSearch}`);
  };

  useEffect(() => {
    seoContext.setData({
      title: titleOverride
        ? titleOverride
        : `${type || "member"}s`.charAt(0).toUpperCase() +
          `${type || "member"}s`.slice(1),
      description: `All the ${type || "member"}s of our community`,
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <section className="header">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <Title
              title={
                titleOverride ||
                (filterText
                  ? `${members?.length || "0"} RESULTS`
                  : `${type || "member"}s`)
              }
              inline
            />
            {filterText && (
              <p className="header__text">
                FOR SEARCH &apos;{filterText}&apos;
              </p>
            )}
          </div>
          {filterAllowed && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <form
                className="header__search"
                onSubmit={(event): void => searchMembers(event)}
              >
                <input
                  className="header__input"
                  type="text"
                  name="s"
                  value={memberSearch}
                  onChange={(event): void =>
                    setMemberSearch(event.target.value)
                  }
                  placeholder="FIND MEMBER"
                />
                <button className="header__button disabled" type="submit">
                  <i className="icon-search"></i>
                </button>
              </form>
            </div>
          )}
        </div>
      </section>

      <Infinity
        pageSize={MembersPageQueryPageSize}
        data={members}
        fetchMore={(): Promise<{}> => loadMore()}
      >
        <section className="members light-border">
          <div className="members__list row small-gutters">
            {members?.map((member) => (
              <Member key={member.id} user={member} link={memberLinkProps} />
            ))}
          </div>
        </section>
      </Infinity>
    </>
  );
};

export default MembersPage;
