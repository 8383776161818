import React, { useState } from "react";
import { MarketingModal } from "./modals/marketing-modal";

interface Properties {
  responsive?: boolean;
}

export const MarketingModalButton: React.FC<Properties> = ({ responsive }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <a
        className={responsive ? "buttons__list-item__link" : "btn"}
        onClick={() => setOpen(true)}
      >
        MANAGE {responsive ? <br /> : null} YOUR ACCOUNT
      </a>
      <MarketingModal show={open} onHide={() => setOpen(false)} />
    </>
  );
};
