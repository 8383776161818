import React from "react";

interface InsufficientCreditsProps {
  requiredCredits?: number;
}

const InsufficientCredits: React.FC<InsufficientCreditsProps> = ({
  requiredCredits,
}) => {
  return (
    <>
      <h4 style={{ color: "#008ca5" }}>Participate</h4>
      <p>
        You cannot participate since you have insufficient CasinoRing-points
        (total of {requiredCredits} required).
      </p>
    </>
  );
};

export default InsufficientCredits;
