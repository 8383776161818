import React, { useContext, useEffect, useMemo, useState } from "react";
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { AuthContext } from "../../context/auth.context";
import { DemoGamesVerificationNotice } from "./verification/notice";

const getPublicKey = () => {
  const host = typeof window !== 'undefined' ? window.location.hostname : null;
  if (host === 'casinoring.com') return '5d30c851-6d18-4ac3-b565-60e482998999' // Production
  if (host === 'casinoring.comceptum.dev') return '6b13ada5-fe31-4f09-9a78-20fd73148d95' // Acceptance
  return '5efe5227-fe94-4ddb-be41-14db824cb73f' // Development
}

export const DemoGamesVerification = () => {
  const { userId, user } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState<boolean>(user?.verificationStatus === 'submitted')
  const allowed = userId &&  !submitted && user?.verificationStatus !== 'declined';

  useEffect(() => {
    if (!allowed) return;

    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: getPublicKey(),
      parentId: 'veriff-root',
      onSession: function(err, response) {
        createVeriffFrame({
          url: response.verification.url,
          onEvent: function(msg) {
            switch(msg) {
              case MESSAGES.FINISHED:
                setSubmitted(true)
                break;
            }
          }
        })
      }
    });

    veriff.setParams({
      person: {
        givenName: user?.profileName,
        lastName: ' '
      },
      vendorData: userId
    });

    veriff.mount();
  }, [allowed])

  const notice = useMemo(() => {
    let status = user?.verificationStatus;
    if (!userId) status = 'unauthorized'
    if (submitted) status = 'submitted'

    if (!status) return null;
    return <DemoGamesVerificationNotice status={status!} />
  }, [submitted, user?.verificationStatus])

  return (
    <div className="verification">
      <h3>Identify verification required</h3>
      <p>Your country requires us to verify your identify before giving access to the demo. This is a one time verification that needs to be performed. Afterwards you can always access restricted content, using your CasinoRing.com-account. Verification is performed by our trusted party Veriff.</p>

      {notice}
      {allowed && <div id='veriff-root'></div>}
    </div>
  )
}