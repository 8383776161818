import React, { ReactNode, useContext, useEffect } from "react";
import { once } from "lodash";
import { Bloc } from "./bloc";

type BlocSpecimen = Bloc<any, any>;

export interface BlocContext<TBloc extends BlocSpecimen> {
  bloc: TBloc;
}

const createStateContext = once(<TBloc extends BlocSpecimen>() =>
  React.createContext({} as BlocContext<TBloc>)
);

export const useStateContext = <
  TBloc extends BlocSpecimen
>(): BlocContext<TBloc> => useContext(createStateContext<TBloc>());

export interface BlocProviderProps<TBloc extends BlocSpecimen> {
  create: () => TBloc;
  children?: ReactNode;
}

export function BlocProvider<TBloc extends BlocSpecimen>(
  props: BlocProviderProps<TBloc>
): JSX.Element {
  const [bloc] = React.useState(props.create());
  const context = createStateContext<TBloc>();

  return <context.Provider value={{ bloc }}>{props.children}</context.Provider>;
}

export interface BlocBuilderProps<TBloc extends Bloc<{}, TState>, TState> {
  builder: (context: BlocContext<TBloc>, state: TState) => ReactNode;
}

export function BlocBuilder<TBloc extends Bloc<{}, TState>, TState>(
  props: BlocBuilderProps<TBloc, TState>
): JSX.Element {
  const context = useStateContext<TBloc>();
  const [state, setState] = React.useState<TState>(context.bloc.state.value);

  useEffect(() => {
    const subscription = context.bloc.state.subscribe((val) => setState(val));

    return (): void => subscription.unsubscribe();
  }, []);

  return <>{props.builder(context, state)}</>;
}
