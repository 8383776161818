import * as React from "react";
import { Wizard } from "react-use-wizard";
import {
  GiveawayDto,
  giveawayQuestionsQuery,
} from "../../../dtos/giveaway.dto";
import { useQuery } from "@apollo/client";
import Loading from "../../loading";
import Alert, { somethingWentWrong } from "../../alert";
import { PollStep } from "./step";
import { PollSubmitStep } from "./submit-step";
import { Dispatch, SetStateAction } from "react";

interface Props {
  giveawayId: string;
  rulesDispatcher: Dispatch<SetStateAction<boolean>>;
  onUpdate: () => void;
}

export const PollStepper = ({
  giveawayId,
  rulesDispatcher,
  onUpdate,
}: Props) => {
  const { data, loading, error } = useQuery<{ giveaway: GiveawayDto }>(
    giveawayQuestionsQuery,
    {
      variables: { giveawayId },
    }
  );

  const questions = data?.giveaway?.questions || [];

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <div className="giveaway-stepper">
      <Wizard>
        {questions.map((question) => (
          <PollStep question={question} count={questions.length} />
        ))}
        <PollSubmitStep
          giveawayId={giveawayId}
          rulesDispatcher={rulesDispatcher}
          onUpdate={onUpdate}
        />
      </Wizard>
    </div>
  );
};
