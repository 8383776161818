import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { DemoGame } from "../../../dtos/demo-games.dto";
import { giveawayDemoGameQuery } from "../../../dtos/giveaway.dto";
import WysiwygContent from "../../wysiwyg-content";
import MediaImage from "../../media-image";
import { Link } from "gatsby";
import { DemoGamesRatingSummary } from "../../demo-games/rating-summary";

interface Props {
  gameId: string;
  content?: string;
}

export const GiveawayDemoGamePreview = ({ gameId, content }: Props) => {
  const { data } = useQuery<{
    demoGame: DemoGame;
  }>(giveawayDemoGameQuery, {
    variables: { gameId },
  });

  const { slug, title, preview, provider } = data?.demoGame || {};

  const isValid = () => slug && title && preview?.id;

  const branding = useMemo(
    () =>
      provider?.logo && provider?.name ? (
        <MediaImage src={provider.logo} format="medium" alt={provider.name} />
      ) : null,
    [provider?.logo, provider?.name]
  );

  if (!isValid()) return null;

  return (
    <>
      <h4 style={{ color: "#008ca5" }}>Demo Game</h4>
      {content && <WysiwygContent html={content} />}
      <div className="giveaway-demo-game">
        <div className="info">
          <DemoGamesRatingSummary gameId={gameId} />
          <Link className="play" to={`/demo-games/${slug}`}>
            PLAY NOW
          </Link>
        </div>
        <MediaImage src={preview} format="large" />
        <div className="branding">
          {branding}
          <h2>{title}</h2>
        </div>
      </div>
      <hr />
    </>
  );
};
