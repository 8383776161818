import React from "react";
import { MediaDto } from "../dtos/media.dto";
import MediaImage from "./media-image";
import { StreamerLiveStreamOfferDto } from "../dtos/user.dto";
import Offers from "./profile/offers";

interface Properties {
  banner?: MediaDto;
  offers?: StreamerLiveStreamOfferDto[];
}

const OfflineBanner: React.FC<Properties> = ({ banner, offers }) => {
  return (
    <section className="profile-offline-banner">
      <h3>Stream</h3>
      <div className="profile-offline-banner-container light-border">
        <MediaImage
          src={banner ? banner : "/images/livestream/banner.jpg"}
          format="extraLarge"
        />
        <Offers offers={offers} />
      </div>
    </section>
  );
};

export default OfflineBanner;
