import { gql } from "@apollo/client";

export interface SlotDto {
  id?: string;
  name?: string;
}

export const slotToSelectItem = (slot?: SlotDto) =>
  slot
    ? {
        value: slot.id as string,
        label: slot.name as string,
      }
    : undefined;

export const slotSelectorQuery = gql`
  query slotSelectorQuery($input: String!) {
    items: slots(where: { name_contains: $input }, limit: 5, sort: "name:ASC") {
      value: id
      label: name
    }
  }
`;
