import getYouTubeID from "get-youtube-id";

export interface YoutubeVideo {
  id: string;
  snippet: {
    title: string;
    playlistId: string;
    publishedAt: string;
    channelTitle: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
    resourceId: {
      videoId: string;
    };
  };
}

export class Youtube {
  private readonly mapping: { [key: string]: string } = {
    localhost: `https://cache-proxy.casinoring.com`,
    "casinoring.comceptum.dev": `https://casinoring-cache-proxy.comceptum.dev`,
    "staging.casinoring.com": `https://cache-proxy.casinoring.com`,
    "casinoring.com": `https://cache-proxy.casinoring.com`,
  };

  protected readonly endpoint = this.mapping[window.location.hostname];

  async playlist(id: string, maxVideos = 20) {
    const response = await await fetch(
      `${this.endpoint}/youtube/v3/playlistItems?part=id&part=snippet&maxResults=${maxVideos}&playlistId=${id}`
    );
    if (!response.ok) return [] as YoutubeVideo[];

    const videos = (await response.json()).items as YoutubeVideo[];
    return videos.filter(
      (video) => Object.keys(video.snippet.thumbnails).length !== 0
    );
  }

  thumbnail(id: string) {
    return `https://img.youtube.com/vi/${getYouTubeID(id)}/sddefault.jpg`;
  }
}

export const isYoutubeChannelId = (id: string) =>
  /[0-9A-Za-z_-]{21}[AQgw]/.test(id);
