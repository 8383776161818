import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "gatsby";
import { orderBy, take } from "lodash";
import { AuthContext } from "../../context/auth.context";
import { PermissionContext } from "../../context/permission.context";
import {
  createGiveawayAnswerMutation,
  deleteGiveawayAnswerMutation,
  GiveawayAnswerDto,
} from "../../dtos/giveaway-answer.dto";
import { GiveawayDto, giveawayExpired } from "../../dtos/giveaway.dto";
import Alert from "../alert";
import Loading from "../loading";
import { useConfirmModal } from "../modals/confirm-modal.context";
import { useLoginModal } from "../modals/login-modal.context";
import InsufficientCredits from "./insufficient-credits";
import { GiveawayRulesModal } from "../modals/giveaway-rules-modal";
import PartnerParticipationNotice from "./partner-participation-notice";
import BeGambleAwareNotice from "./be-gamble-aware-notice";

interface GuessingCompetitionProps {
  giveaway: GiveawayDto;
  onUpdate: () => void | Promise<{}>;
  creditable?: boolean;
}

const GuessingCompetition: React.FC<GuessingCompetitionProps> = ({
  giveaway,
  onUpdate,
  creditable,
}) => {
  const authContext = useContext(AuthContext);
  const permissionContext = useContext(PermissionContext);
  const { openConfirmModal } = useConfirmModal();
  const { openLoginModal } = useLoginModal();
  const [terms, setTerms] = useState(false);
  const [answer, setAnswer] = useState<number>();
  const [rulesOpen, setRulesOpen] = useState<boolean>(false);

  const expired = giveawayExpired(giveaway);

  const [createGiveawayAnswer, { loading: savingAnswer }] = useMutation(
    createGiveawayAnswerMutation
  );

  const [deleteGiveawayAnswer] = useMutation(deleteGiveawayAnswerMutation);

  const participated = (): boolean => {
    return !!giveaway?.giveaway_answers?.find(
      (answer) => answer.owner?.id === authContext.userId
    );
  };

  const isAnswerOccupied = (): boolean => {
    return !!giveaway?.giveaway_answers?.find(
      (_answer) => _answer.value === answer
    );
  };

  const submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const response = await createGiveawayAnswer({
      variables: {
        input: {
          owner: authContext.userId,
          giveaway: giveaway.id,
          value: answer,
        },
      },
    });

    if (response) {
      await onUpdate();
    }
  };

  const performDelete = async (answer: GiveawayAnswerDto): Promise<void> => {
    const { data } = await deleteGiveawayAnswer({
      variables: { id: answer.id },
    });

    if (data) {
      await onUpdate();
    }
  };

  const winners = (): GiveawayAnswerDto[] => {
    const result = giveaway.result || 0;
    const ordered = orderBy(giveaway.giveaway_answers, (d) =>
      Math.abs(result - (d.value || 0))
    );

    return take(ordered, giveaway.winnerAmount);
  };

  const renderGuessingForm = (): React.ReactNode => {
    const isSubmitDisabled =
      (answer || 0) <= 0 || savingAnswer || isAnswerOccupied() || !terms;

    return (
      <>
        <GiveawayRulesModal
          show={rulesOpen}
          onClose={() => setRulesOpen(false)}
          rules={giveaway.rules}
        />

        <h4 style={{ color: "#008ca5" }}>Participate</h4>
        {giveaway.entryCost && (
          <p>Entry cost are {giveaway.entryCost} CasinoRing-points</p>
        )}

        <form
          className="page-content__form form-inline"
          onSubmit={(event): Promise<void> => submit(event)}
        >
          <div className="input-switch__group" style={{ width: "100%" }}>
            <input
              type="checkbox"
              name="terms"
              id="terms"
              value="terms"
              checked={terms}
              onChange={(event): void => setTerms(event.target.checked)}
            />
            <label htmlFor="terms"></label>
            <p onClick={() => setRulesOpen(true)}>
              <strong>Rules</strong>
              <br />I HAVE READ AND ACCEPT THE{" "}
              <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                RULES &amp; CONDITIONS
              </a>{" "}
              OF THE GIVEAWAY
            </p>
          </div>

          <input
            type="number"
            id="answer"
            className="form-control giveaway-answer"
            value={answer}
            placeholder="0"
            style={{ height: 40, borderRadius: 0 }}
            onChange={(event): void => setAnswer(event.target.valueAsNumber)}
          />
          <button type="submit" className="btn" disabled={isSubmitDisabled}>
            GUESS
          </button>
        </form>

        {isAnswerOccupied() && (
          <Alert message={"Your guess has to be unique."} style="danger" />
        )}

        {savingAnswer && <Loading />}
      </>
    );
  };

  const renderParticipation = (): React.ReactNode => {
    if (expired) {
      return <i>The giveaway is expired or closed.</i>;
    }

    if (!authContext.userId) {
      return (
        <button className="btn btn--medium" onClick={() => openLoginModal()}>
          Login to participate
        </button>
      );
    }

    if (authContext.isPartner) {
      return <PartnerParticipationNotice />;
    }

    if (participated()) {
      return <i>You already participated.</i>;
    }

    if (!creditable) {
      return <InsufficientCredits requiredCredits={giveaway.entryCost} />;
    }

    return renderGuessingForm();
  };

  const renderWinners = (): React.ReactNode => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Winners ({giveaway.winnerAmount || 0}){" "}
      </h4>

      <i>{`The correct answer is: ${giveaway.result || "unknown"}`}</i>

      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "50%" }}>Answer</th>
          </tr>
        </thead>
        <tbody>
          {winners().map((answer, i) => (
            <tr key={answer.id}>
              <td>{i + 1}. </td>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
              <td>{answer?.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
    </>
  );

  const renderParticipations = (): React.ReactNode => (
    <>
      <h4 style={{ color: "#008ca5" }}>
        Participants ({giveaway?.giveaway_answers?.length})
      </h4>
      <table>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "50%" }}>Answer</th>
          </tr>
        </thead>
        <tbody>
          {giveaway?.giveaway_answers?.map((answer) => (
            <tr key={answer.id}>
              <td>
                <Link to={`/profile/${answer.owner?.id}`}>
                  {answer?.owner?.profileName}
                </Link>
              </td>
              <td>
                {answer?.value}
                {!expired &&
                  permissionContext.can(answer, "giveaway-answer.delete") && (
                    <>
                      {" "}
                      &bull;{" "}
                      <span
                        className="clickable"
                        onClick={() =>
                          openConfirmModal({
                            title:
                              "Are you sure you want to delete this guess?",
                            onClose: (result) =>
                              result && performDelete(answer),
                          })
                        }
                      >
                        Delete
                      </span>
                    </>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      <BeGambleAwareNotice />
      {renderParticipation()}
      {giveawayExpired(giveaway) && !giveaway.disabledAutomaticWinner && renderWinners()}
      {renderParticipations()}
    </>
  );
};

export default GuessingCompetition;
