import { gql } from "@apollo/client";
import { IOwnableDto } from "../context/permission.context";
import { CommentDto } from "./comment.dto";
import { PostDto } from "./post.dto";

enum ReactionDtoKind {
  Like = "like",
}

export interface ReactionDto extends IOwnableDto {
  id?: string;
  kind?: ReactionDtoKind;
  comment?: CommentDto;
  post?: PostDto;
}

export const reactionFields = `
    id
`;

export const createReactionMutation = gql`
  mutation createReactionMutation($input: ReactionInput!) {
    createReaction(input: { data: $input }) {
      reaction {
        ${reactionFields}
      }
    }
  }
`;

export const deleteReactionMutation = gql`
  mutation deleteReactionMutation($id: ID!) {
    deleteReaction(input: { where: { id: $id } }) {
      reaction {
        ${reactionFields}
      }
    }
  }
`;
