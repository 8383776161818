import React, { useMemo } from "react";
import MediaImage from "../media-image";
import { Link } from "gatsby";
import { DemoGame } from "../../dtos/demo-games.dto";
import classNames from "classnames";
import { DemoGamesRatingSummary } from "./rating-summary";

interface Props {
  data: DemoGame;
  pinned?: boolean;
}

export const DemoGamesPreview = ({ data, pinned }: Props) => {
  const { id, slug, title, preview, provider } = data;

  const branding = useMemo(
    () =>
      provider?.logo && provider?.name ? (
        <MediaImage src={provider.logo} format="medium" alt={provider.name} />
      ) : null,
    [provider?.logo, provider?.name]
  );

  return (
    <div
      className={classNames("block__list-item demo-game col-12", {
        "col-sm-12 col-md-6 col-lg-6 col-xl-6": !pinned,
        pinned: !!pinned,
      })}
    >
      <div className="block__list__inner">
        <Link to={`/demo-games/${slug}`}>
          <div className="block__list-item__image">
            <MediaImage src={preview} format="large" />
            <DemoGamesRatingSummary gameId={id!} />
          </div>
        </Link>
        <div className="block__list-item__content">
          <Link to={`/demo-games/${slug}`}>
            {branding}
            <h2 className="block__list-item__title clickable">{title}</h2>
          </Link>
        </div>
      </div>
    </div>
  );
};
