import { ApolloError } from "@apollo/client";
import { ErrorResponse } from "../context/auth.context";

export const prettyError = (apolloError?: ApolloError) => {
  return Array.from(errorLines(apolloError)).join("\n");
};

export const prettyErrorForData = (data: any[]) => {
  return Array.from(errorLinesFromData(data)).join("\n");
};

export const prettyErrorForResponse = (response: ErrorResponse) => {
  return Array.from(errorLinesFromResponse(response)).join("\n");
};

function* errorLines(error?: ApolloError) {
  for (const graphError of error?.graphQLErrors || []) {
    const data = graphError.extensions?.exception.data.data || [];

    yield* errorLinesFromData(data);
  }
}

function* errorLinesFromData(data: any[]) {
  for (const dataItem of data) {
    for (const messageItem of dataItem.messages) {
      yield messageItem.message;
    }
  }
}

function* errorLinesFromResponse(response: ErrorResponse) {
  for (const dataItem of response.message || []) {
    for (const messageItem of dataItem.messages || []) {
      yield messageItem.message ||
        translationTable[messageItem.id as string] ||
        messageItem.id;
    }
  }
}

const translationTable: any = {
  "Auth.form.error.email.taken": "E-mail is already taken.",
};
