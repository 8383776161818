import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/loading";
import { RouteProps } from ".";
import { mediaFields } from "../dtos/media.dto";
import Alert, { somethingWentWrong } from "../components/alert";
import MediaImage from "../components/media-image";
import { SeoContext } from "../context/seo.context";
import { SortableStreamersLobbyOffersDto, StreamersLobbyOfferPayment } from "../dtos/streamerslobby.dto";
import { FullScreenContext } from "../context/full-screen.context";
import WysiwygContent from "../components/wysiwyg-content";
import { StreamersLobbyFilter } from "../components/streamerslobby/filter";
import { StreamersLobbyTopSlider } from "../components/streamerslobby/top-slider";
import { AboutMenu } from "../components/about-menu";
import { Link } from "gatsby";

export const streamersLobbyOffersQuery = gql`
  query StreamersLobbyOffers {
    sortedStreamersLobbyOffer {
      SortedStreamersLobbyOffers {
        streamers_lobby_offer {
          id
          payment
          title
          description
          url
          logo {
            ${mediaFields}
          }
          slug
          detail {
            id
          }
        }
      }
    }
  }
`;

export const streamersLobbyLoginModalContent = {
  title: 'Want to enjoy the full experience?',
  description: <ul>
    <li>Free giveaways</li>
    <li>Share your wins</li>
    <li>Exclusive offers</li>
  </ul>
}

const StreamersLobbyPage: React.FC<RouteProps> = () => {
  const seoContext = useContext(SeoContext);

  useEffect(() => {
    seoContext.setData({
      title: `StreamersLobby`,
      description: `The best casino deals and our streamers.`,
    });
  }, []);

  const {
    showSidebarMenu,
    toggleSidebarMenu,
  } = useContext(FullScreenContext);

  useEffect(() => {
    if (showSidebarMenu) toggleSidebarMenu();

    return () => {
      if (!showSidebarMenu) toggleSidebarMenu();
    }
  }, [showSidebarMenu]);

  const { loading, data, error } = useQuery<{ sortedStreamersLobbyOffer: SortableStreamersLobbyOffersDto }>(
    streamersLobbyOffersQuery
  );

  const [filter, setFilter] = useState<StreamersLobbyOfferPayment>(StreamersLobbyOfferPayment.BOTH);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <StreamersLobbyTopSlider />
      <div className="streamerslobby-title">
        <Link to="/streamerslobby/streamers">SHOW ALL STREAMERS</Link>
      </div>

      <img
        className="streamerslobby-banner"
        alt="Show all our streamers"
        src="/images/streamerslobby.jpg"
      />

      <p style={{ textAlign: "center" }}>
        Welcome to the Streamers lobby!
        <br />
        Streamerslobby act as an independent section inside CasinoRing.com, dedicated to streamers.
        <br />
        StreamersLobby are prone to their own decisions based on the growth and development of the streaming world and the needs of their communities.
        <br />
        Streamerslobby holds the right to allow each streamer to pursue his streaming dream as he / she / They sees fits,
        <br />
        As long as they are honest and transparent in the way they do so.
      </p>

      <StreamersLobbyFilter callback={(filter) => setFilter(filter)}/>

      <div className="streamerslobby-list">
        {data?.sortedStreamersLobbyOffer?.SortedStreamersLobbyOffers
          .filter(
            ({ streamers_lobby_offer: offer }) =>
              filter === StreamersLobbyOfferPayment.BOTH ||
              offer.payment === StreamersLobbyOfferPayment.BOTH ||
              offer.payment === filter)
          .map(({ streamers_lobby_offer: offer }) =>(
          <div
            key={offer.id}
            className="streamerslobby-offer"
          >
            {
              offer.logo &&
              <MediaImage
                alt={offer.title}
                src={offer.logo}
                format="medium"
              />
            }

            <div className="streamerslobby-offer-content">
              <h2>{offer.title}</h2>
              <WysiwygContent html={offer.description} />
            </div>

            <div className="streamerslobby-offer-actions">
              <a className="streamerslobby-offer-button" href={offer.url} target="_blank">
                Play now!
              </a>

              {
                offer.detail?.id &&
                offer.slug &&
                <Link
                  to={`/streamerslobby/${offer.slug}`}
                  className="streamerslobby-offer-link"
                >
                  MORE INFO
                </Link>
              }
            </div>
          </div>
        ))}
      </div>
      <AboutMenu />
    </>
  );
};

export default StreamersLobbyPage;
