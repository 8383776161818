import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { PostModalProcessingStatusEnum } from "./post-modal-processing";

interface ModalLoaderProps {
  style?: any;
  progress?: number;
  status?: PostModalProcessingStatusEnum;
  error?: string;
}

const ModalLoader: React.FC<ModalLoaderProps> = ({
  style,
  progress,
  status,
  error,
}) => {
  const [internalProgress, setInternalProgress] = useState<number | null>();
  const generateRandomUpdatePointer = () => Math.floor(Math.random() * 15) + 5;

  const getMessage = () => {
    if (status === PostModalProcessingStatusEnum.FILTERING)
      return "VALIDATING, TAKES SEVERAL MINUTES";
    if (status === PostModalProcessingStatusEnum.REMOVED)
      return "REJECTED, CONTAINS BAD CONTENT";
    if (status === PostModalProcessingStatusEnum.ENCODING)
      return "PROCESSING, TAKES SEVERAL MINUTES";
    if (status === PostModalProcessingStatusEnum.PUBLISHING)
      return "PUBLISHING, JUST A FEW MOMENTS!";
    if (status === PostModalProcessingStatusEnum.ERROR)
      return error ? error : "ERROR OCCURRED, PLEASE TRY AGAIN!";
    return "PLEASE WAIT, SAVING UPLOAD";
  };

  useEffect(() => {
    if (!progress) return;

    if (!internalProgress || progress === 100) {
      setInternalProgress(progress);
      return;
    }

    const difference = progress - internalProgress;
    if (difference > generateRandomUpdatePointer())
      setInternalProgress(progress);
  }, [progress]);

  return (
    <div
      className={classNames([
        "modal-loader",
        internalProgress ? "modal-loader-progress" : null,
        status === PostModalProcessingStatusEnum.ERROR ||
        status === PostModalProcessingStatusEnum.REMOVED
          ? "modal-loader-error"
          : null,
      ])}
      style={{
        ...style,
        ...{
          backgroundPositionX: internalProgress
            ? `${100 - internalProgress}%`
            : "",
        },
      }}
    >
      <div className="modal-loader-animation" />
      <p className="modal-loader-text">
        {!error && internalProgress && internalProgress !== 100
          ? `${internalProgress}%`
          : getMessage()}
      </p>
    </div>
  );
};

export default ModalLoader;
