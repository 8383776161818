import React, { useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/loading";
import { RouteProps } from ".";
import { SortableFaqDto } from "../dtos/faq.dto";
import { Youtube } from "../utils/youtube";
import { Link } from "gatsby";
import { mediaFields } from "../dtos/media.dto";
import Alert, { somethingWentWrong } from "../components/alert";
import Title from "../components/title";
import MediaImage from "../components/media-image";
import { SeoContext } from "../context/seo.context";

const query = gql`
  query {
    sortedFaq {
      SortedFAQS {
        faq {
          id
          title
          youtubeVideo
          image {
            ${mediaFields}
          }
        }
      }
    }
  }
`;

const HelpAdvicesPage: React.FC<RouteProps> = () => {
  const youtubeUtil = new Youtube();
  const seoContext = useContext(SeoContext);
  const { loading, data, error } = useQuery<{ sortedFaq: SortableFaqDto }>(
    query
  );

  React.useEffect(() => {
    seoContext.setData({
      title: `Help & Advice`,
      description: `Help and advice for community members using the platform. Answers for frequently asked questions.`,
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title="Help & advice" />

      <section className="block">
        <div className="block__list row small-gutters">
          {data?.sortedFaq?.SortedFAQS.map(({ faq }) => (
            <div
              key={faq.id}
              className="block__list-item col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
              <Link to={`/help-advice/${faq.id}`}>
                <div className="block__list__inner light-border">
                  <div className="block__list-item__image">
                    <MediaImage
                      src={
                        faq.youtubeVideo
                          ? youtubeUtil.thumbnail(faq.youtubeVideo)
                          : faq.image
                      }
                      format="small"
                    />
                  </div>
                  <div className="block__list-item__content">
                    <h3 className="block__list-item__title clickable">
                      {faq.title}
                    </h3>
                    <div className="block__list-item__btn btn btn--medium">
                      More info
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default HelpAdvicesPage;
