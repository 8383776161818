import React, { useEffect, useState, useMemo } from "react";
import { Link } from "gatsby";
import { usePrevious } from "react-use";
import { gql, useQuery } from "@apollo/client";
import isEqual from "react-fast-compare";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Controller } from "swiper/core";
import "swiper/swiper.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import classNames from "classnames";
import { GiveawayDto, giveawayExpired } from "../../dtos/giveaway.dto";
import { MediaDto, mediaFields } from "../../dtos/media.dto";
import { Dto } from "../../dtos/banner.dto";
import { arrayShuffle } from "../../utils/array";
import { SlideCountdown } from "./top-banner-slider-countdown";

SwiperCore.use([Autoplay, Controller]);

const QUERY_TOP_BANNER_SLIDER = gql`
    query TopBannerSlider($currentTime: String) {
        giveaways(
            sort: "createdAt:DESC,expiresAt:ASC"
            where: {
                result_null: true,
                isOpen: true,
                opensAt_lte: $currentTime,
                expiresAt_gt: $currentTime
            }
        ) {
            id
            isOpen
            opensAt
            expiresAt
            banner {
                ${mediaFields}
            }
        }
        banners(
            sort: "createdAt:DESC,expiresAt:ASC"
            where: {
                type: "top"
                approved: true
                active: true
                publishedAt_lte: $currentTime
                expiresAt_gt: $currentTime
            }
        ) {
            id
            url
            image {
                ${mediaFields}
            }
        }
    }
`;

interface Slide {
  id?: string;
  type: "banner" | "giveaway";
  data: GiveawayDto | Dto;
  image: MediaDto;
  url?: string;
  isExternalUrl?: boolean;
}

interface Data {
  giveaways: GiveawayDto[];
  banners: Dto[];
}

const useSlideData = (): Slide[] => {
  const query = useQuery<Data>(QUERY_TOP_BANNER_SLIDER, { skip: true });
  const [data, setData] = useState<Data>();
  const [slides, setSlides] = useState<Slide[]>([]);
  const previousData = usePrevious(data);
  const getCurrentTime = () => new Date().toISOString(); // Use UTC for server time

  useEffect(() => {
    const fetch = async () => {
      const response = await query.refetch({ currentTime: getCurrentTime() });
      setData(response.data);
    };

    fetch();
    const interval = setInterval(fetch, 15000);

    return () => clearInterval(interval);
  }, []);

  useEffect(
    () =>
      setSlides((prev) => {
        if (isEqual(data, previousData)) return prev;
        if (!data) return [];

        return arrayShuffle([
          ...data?.giveaways
            .filter(
              (giveaway) => !!giveaway.banner && !giveawayExpired(giveaway)
            )
            .map((giveaway) => ({
              id: giveaway.id,
              type: "giveaway",
              data: giveaway,
              image: giveaway.banner,
              url: `/giveaway-results/${giveaway.id}`,
            })),
          ...data?.banners
            .filter((banner) => !!banner.image)
            .map((banner) => ({
              id: banner.id,
              type: "banner",
              data: banner,
              image: banner.image,
              url: banner.url,
              isExternalUrl: true,
            })),
        ]);
      }),
    [data]
  );

  return slides;
};

export const TopBannerSlider: React.FC = () => {
  const slides = useSlideData();
  const key = useMemo(() => Math.random(), [slides.length]);
  const [controller, setController] = useState<SwiperCore>();

  if (slides.length === 0) return null;

  return (
    <div
      className="top-banner-slider banner banner--homepage"
      data-aos="fade-up"
    >
      <Swiper
        key={key}
        spaceBetween={25}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        onSwiper={setController}
        enabled={slides.length > 1}
        loop
        grabCursor
        // autoHeight
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <Slide slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      {slides.length > 1 && (
        <>
          <NavButton placement="left" onClick={() => controller?.slidePrev()} />
          <NavButton
            placement="right"
            onClick={() => controller?.slideNext()}
          />
        </>
      )}
    </div>
  );
};

const NavButton = ({ placement, onClick }) => {
  const Icon = placement === "left" ? FaChevronLeft : FaChevronRight;

  return (
    <div
      className={classNames("navigation-button", `${placement}`)}
      onClick={onClick}
    >
      <Icon />
    </div>
  );
};

const Slide = ({ slide }) => {
  return (
    <SlideContent href={slide.url} isExternal={slide.isExternalUrl}>
      <img
        src={slide.image.formats?.large?.url}
        style={{ width: "100%", userSelect: "none" }}
        draggable={false}
      />

      {/*{slide.type === "giveaway" && <SlideCountdown data={slide.data} />}*/}
    </SlideContent>
  );
};

const SlideContent = ({ href, isExternal, children }) =>
  isExternal ? (
    <a className="slide-content" href={href!}>
      {children}
    </a>
  ) : (
    <Link to={href} className="slide-content">
      {children}
    </Link>
  );
