import { gql } from "@apollo/client";
import moment from "moment";
import { GiveawayAnswerDto, giveawayAnswerFields } from "./giveaway-answer.dto";
import { MediaDto, mediaFields } from "./media.dto";

export enum GiveawayType {
  GuessingCompetition = `guess`,
  BiggestMultiplier = `biggestMultiplier`,
  Random = `random`,
  RandomWithMedia = `randomWithMedia`,
  Poll = "poll",
}

export interface GiveawayAnswer {
  content: string;
}

export interface GiveawayQuestion {
  content: string;
  answers?: GiveawayAnswer[];
}

export interface GiveawayDto {
  id?: string;
  title?: string;
  description?: string;
  rules?: string;
  type?: GiveawayType;
  winnerSeed?: number;
  descriptionShort?: string;
  banner?: MediaDto;
  entryCost?: number;
  opensAt?: Date;
  expiresAt?: Date;
  result?: number;
  isOpen?: boolean;
  winnerAmount?: number;
  pointsCost?: number;
  giveaway_answers?: GiveawayAnswerDto[];
  questions?: GiveawayQuestion[];
  demo_game?: {
    id?: string;
  };
  demoGameContent?: string;
  dataRequest?: string;
  hideDataRequestAnswer?: boolean;
  disabledAutomaticWinner?: boolean;
}

export interface GiveawayWinnerContentDto {
  winnerTitle?: string;
  winnerDescription?: string;
  winnerAddress?: boolean;
}

export enum GiveawayWinnerStatusEnum {
  CREATED = "created",
  DETAILS = "details",
  READY = "ready",
  PREPARED = "prepared",
  SHIPPED = "shipped",
}

export interface GiveawayWinnerDto {
  id?: string;
  giveaway?: GiveawayWinnerContentDto;
  status?: GiveawayWinnerStatusEnum;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  country?: string;
}

export interface SortedGiveawayDto {
  giveaway: GiveawayDto;
}

export interface SortableGiveawaysDto {
  SortedGiveaways: SortedGiveawayDto[];
}

export const giveawayClosed = (giveaway?: GiveawayDto): boolean => {
  return !!giveaway && giveaway?.isOpen === false;
};

export const giveawayExpired = (giveaway?: GiveawayDto): boolean => {
  const isTimeExpired = moment().isAfter(moment(giveaway?.expiresAt));
  return !!giveaway && (isTimeExpired || giveawayClosed(giveaway));
};

export const giveawayOverviewQuery = gql`
    query giveawayOverviewQuery {
        sortedGiveaway {
            SortedGiveaways {
                giveaway {
                    id
                    title
                    isOpen
                    description
                    descriptionShort
                    banner {
                        ${mediaFields}
                    }
                    entryCost
                    expiresAt
                }
            }
        }
    }
`;

export const giveawaySingleQuery = gql`
    query giveawaySingleQuery($id: ID!) {
        giveaway(id: $id) {
            id
            title
            description
            descriptionShort
            rules
            type
            isOpen
            banner {
                ${mediaFields}
            }
            result
            winnerAmount
            winnerSeed
            entryCost
            expiresAt
            giveaway_answers(limit: 1500, sort: "value:ASC", where: { active_ne: false }) {
                ${giveawayAnswerFields}
            }
            demo_game {
              id
            }
            demoGameContent
            dataRequest
            hideDataRequestAnswer
            disabledAutomaticWinner
        }
    }
`;

export const giveawayDemoGameQuery = gql`
  query ($gameId: ID!) {
    demoGame(id: $gameId) {
      slug
      title
      provider {
        name
        logo {
          ${mediaFields}
        }
      }
      preview {
        ${mediaFields}
      }
    }
  }
`;

export const giveawayQuestionsQuery = gql`
  query($giveawayId: ID!) {
    giveaway(id: $giveawayId) {
      questions {
        content
        answers {
          content
        }
      }
    }
  }
`;
