import React, { useContext } from "react";
import BannerControl from "../banner-control";
import { FullScreenContext } from "../../context/full-screen.context";
import { useMeasure } from "react-use";
import { DemoGamesRating } from "./rating";
import { DemoGamesVerification } from "./verification";

interface Props {
  url: string;
  gameId: string;
  verification: boolean;
}

export const DemoGamesEmbed = ({ url, gameId, verification }: Props) => {
  const {
    allowMainMenu,
    showMainMenu,
    allowSidebarMenu,
    showSidebarMenu,
    toggleMainMenu,
    toggleSidebarMenu,
  } = useContext(FullScreenContext);

  const [embedRef, { width }] = useMeasure<HTMLIFrameElement>();

  return (
    <div className="embed">
      <DemoGamesRating gameId={gameId} />

      {
        verification ?
        <DemoGamesVerification /> :
        <iframe ref={embedRef} src={url} style={{ height: (width / 16) * 9 }} />
      }

      <div className="banner-controls">
        {allowMainMenu && (
          <BannerControl
            active={showMainMenu}
            subject="main menu"
            onClick={toggleMainMenu}
          />
        )}
        {allowSidebarMenu && (
          <BannerControl
            active={showSidebarMenu}
            subject="sidebar"
            onClick={toggleSidebarMenu}
            reversed={true}
          />
        )}
      </div>
    </div>
  );
};
