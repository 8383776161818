import * as React from "react";
import Loading from "../components/loading";
import { useQuery } from "@apollo/client";
import { casinoOverviewQuery, SortableCasinosDto } from "../dtos/casino.dto";
import { RouteProps } from ".";
import Alert, { somethingWentWrong } from "../components/alert";
import Title from "../components/title";
import Casino from "../components/casino";
import { LocalisationFlag } from "../components/localisation-flag";
import { useContext } from "react";
import { LocationContext } from "../context/location.context";
import { SeoContext } from "../context/seo.context";

const CasinosPage: React.FC<RouteProps> = () => {
  const seoContext = useContext(SeoContext);
  const location = useContext(LocationContext);

  const { data, loading, error } = useQuery<{
    sortedCasino: SortableCasinosDto;
  }>(casinoOverviewQuery);

  React.useEffect(() => {
    seoContext.setData({
      title: `Casinos`,
      description: `Casinos from inside the ring`,
    });
  }, []);

  const getSubtitle = () => {
    if (data?.sortedCasino?.SortedCasinos?.length === 0)
      return "There are no casinos available yet for your location";
  };

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title="Casinos" subtitle={getSubtitle()}>
        {location.data && (
          <LocalisationFlag
            data={location.data.geoContentLocation}
            size="large"
          />
        )}
      </Title>

      <section className="block">
        <div
          className="block__list row small-gutters"
          style={{ alignItems: `stretch` }}
        >
          {data?.sortedCasino?.SortedCasinos?.map(({ casino }) => (
            <Casino key={casino.id} data={casino} />
          ))}
        </div>
      </section>
    </>
  );
};

export default CasinosPage;
