import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ReactMde from "react-mde";
import Showdown from "showdown";
import Loading from "../components/loading";
import { AuthContext, ErrorResponse } from "../context/auth.context";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  PasswordStrengthPattern,
  StreamerBonusOfferDto,
  StreamerLiveStreamOfferDto,
  updateUserMutation,
  UserDto,
} from "../dtos/user.dto";
import { pick } from "lodash";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";
import {
  imagePreview,
  MediaDto,
  mediaFields,
  multipleUploadMutation,
} from "../dtos/media.dto";
import { RouteProps } from ".";
import Alert, { somethingWentWrong } from "../components/alert";
import Title from "../components/title";
import MediaImage from "../components/media-image";
import { balanceMessage } from "../utils/balance";
import AlertError from "../components/alert-error";
import { CasinoDto, casinoSelectorQuery } from "../dtos/casino.dto";
import { LazySelect, LazySelectItem } from "../components/lazy-select";
import StreamerBonusOfferContent from "../components/profile/streamer-bonus-offer-content";
import LiveStreamOffers from "../components/profile/settings/live-stream-offers";
import { generateVersion } from "../utils/generate-version";
import { PrizeDropSettings } from "../components/settings-page/PrizeDropSettings";
import { useRef } from "react";
import { SeoContext } from "../context/seo.context";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

interface SettingsPageProps extends RouteProps {
  scrollTo?: string;
  isStreamer?: boolean;
}

const UploadMutation = gql`
  mutation UploadMutation($id: ID!, $file: Upload!, $field: String!) {
    upload(
      refId: $id
      ref: "user"
      source: "users-permissions"
      field: $field
      file: $file
    ) {
      id
    }
  }
`;

const SettingsPageQuery = gql`
  query SettingsPageQuery($userId: ID!) {
    user(id: $userId) {
      id
      type
      provider
      profileName
      aboutMe
      websiteUrl
      twitchUsername
      youtubeUsername
      streamSchedule
      snapchatUsername
      twitterUsername
      facebookUsername
      instagramUsername
      notifyMentions
      notifyReplies
      notifyFollowers
      notifyScheduled
      notifyMarketingItems
      notifyPrizeWinner
      highlightsPlaylistId
      avatar {
        ${mediaFields}
      }
      avatarPersonal {
        ${mediaFields}
      }
      banner {
        ${mediaFields}
      }
      streamerBonusOffers {
        id
        title
        url
        bonusOffer
        casino {
          id
          name
        }
        image {
          ${mediaFields}
        }
      }
      streamerLiveStreamOffers {
        id
        title
        content
      }
      offlineBanner {
        ${mediaFields}
      }
    }
  }
`;

const SettingsPage: React.FC<SettingsPageProps> = ({
  scrollTo,
  isStreamer,
}) => {
  const { isPartner, userId, changePassword, update } = useContext(AuthContext);
  const seoContext = useContext(SeoContext);

  const toolbarMde = [
    [`header`, `bold`, `italic`, `strikethrough`],
    [`quote`, `code`],
    [`unordered-list`, `ordered-list`, `checked-list`],
  ];

  const { data, loading, error, refetch } = useQuery<{
    user: UserDto;
    casinos: CasinoDto[];
  }>(SettingsPageQuery, {
    variables: { userId: userId },
  });

  const [aboutMeSelectedTab, setAboutMeSelectedTab] = useState<
    "write" | "preview"
  >(`preview`);
  const [
    streamScheduleSelectedTab,
    setStreamScheduleSelectedTab,
  ] = React.useState<"write" | "preview">(`preview`);

  const [selectedCasino, setSelectedCasino] = useState<LazySelectItem>();

  const [user, setUser] = useState<UserDto>({});
  const [submittedForm, setSubmittedForm] = useState<string>();

  const [formPasswordSaving, setFormPasswordSaving] = useState<boolean>(false);
  const [formPasswordError, setFormPasswordError] = useState<
    undefined | ErrorResponse
  >();
  const [currentPassword, setCurrentPassword] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const [confirmNewPassword, setConfirmNewPassword] = useState(``);

  const [openSnackbar] = useSnackbar(snackbarConfig);

  const setImage = async (key: string, image?: File): Promise<void> => {
    if (image) {
      const preview = await imagePreview(image);

      if (preview) {
        setUser({
          ...user,
          [key]: {
            staging: image,
            stagingPreview: preview,
          },
        });
      }
    }
  };

  const canAddBonusOffer = (): boolean => {
    if (!bonusOffer?.title || bonusOffer.title.length < 2) return false;
    if (!bonusOffer?.url) return false;
    if (!bonusOffer?.bonusOffer || bonusOffer.bonusOffer.length < 5)
      return false;
    if (selectedCasino) {
      return !(user.streamerBonusOffers || []).find(
        (offer) => offer.casino?.id === selectedCasino?.value
      );
    }
    return true;
  };

  const removeOffer = (offer: StreamerBonusOfferDto): void => {
    setUser({
      ...user,
      streamerBonusOffers: [
        ...(user.streamerBonusOffers || []).filter((_offer) => _offer != offer),
      ],
    });
  };

  const removeLiveStreamOffer = (offer: StreamerLiveStreamOfferDto): void => {
    setUser({
      ...user,
      streamerLiveStreamOffers: [
        ...(user.streamerLiveStreamOffers || []).filter(
          (_offer) => _offer != offer
        ),
      ],
    });
  };

  const updateOffer = (
    offerOriginal: StreamerBonusOfferDto,
    offerUpdate: StreamerBonusOfferDto
  ): void => {
    setUser({
      ...user,
      streamerBonusOffers: [
        ...(user.streamerBonusOffers || []).map((_offer) =>
          _offer === offerOriginal ? offerUpdate : _offer
        ),
      ],
    });
  };

  const updateLiveStreamOffer = (
    offerOriginal: StreamerLiveStreamOfferDto,
    offerUpdate: StreamerLiveStreamOfferDto
  ): void => {
    setUser({
      ...user,
      streamerLiveStreamOffers: [
        ...(user.streamerLiveStreamOffers || []).map((_offer) =>
          _offer === offerOriginal ? offerUpdate : _offer
        ),
      ],
    });
  };

  const [bonusOfferTab, setBonusOfferTab] = useState<"write" | "preview">(
    "write"
  );

  const emptyBonusOffer = {
    title: "",
    url: "",
    bonusOffer: "",
    image: undefined,
  };

  const [bonusOffer, setBonusOffer] = useState<StreamerBonusOfferDto>(
    emptyBonusOffer
  );

  const [multipleUpload, { loading: savingFileMultiple }] = useMutation(
    multipleUploadMutation
  );

  const addBonusOffer = (): void => {
    setUser({
      ...user,
      streamerBonusOffers: [
        {
          title: bonusOffer?.title,
          ...(selectedCasino
            ? {
                casino: {
                  id: selectedCasino?.value as string,
                  name: selectedCasino?.label as string,
                },
              }
            : {}),
          bonusOffer: bonusOffer?.bonusOffer,
          url: bonusOffer?.url,
          ...(bonusOffer?.image ? { image: bonusOffer.image } : {}),
        },
        ...(user.streamerBonusOffers || []),
      ],
    });

    setSelectedCasino(undefined);
    setBonusOffer(emptyBonusOffer);
  };

  const addLiveStreamOffers = (title: string, content: string) => {
    setUser({
      ...user,
      streamerLiveStreamOffers: [
        { title, content },
        ...(user.streamerLiveStreamOffers || []),
      ],
    });
  };

  const areAllOffersSet = (): boolean => {
    const bonusOffers = !(user.streamerBonusOffers || []).find(
      (offer) =>
        (offer.bonusOffer || ``).length === 0 || (offer.url || ``).length === 0
    );

    const liveStreamOffers = !(user.streamerLiveStreamOffers || []).find(
      (offer) => (offer.content || ``).length === 0
    );

    return bonusOffers && liveStreamOffers;
  };

  const [upload, { loading: savingFile }] = useMutation(UploadMutation);
  const [updateUser, { loading: saving }] = useMutation(updateUserMutation);

  const isSaving = (form: string): boolean => {
    return (
      submittedForm === form && (saving || savingFile || savingFileMultiple)
    );
  };

  const uploadFile = (file: File, field: string) => {
    return upload({
      variables: {
        file,
        id: data?.user?.id,
        field,
      },
    });
  };

  const makeUserDelta = (fields: string[]): UserDto => {
    const properties = pick(user, fields) as any;

    if (fields.indexOf(`streamerBonusOffers`) >= 0) {
      properties.streamerBonusOffers = properties.streamerBonusOffers.map(
        (item: StreamerBonusOfferDto) => ({
          id: item.id,
          title: item.title,
          casino: item.casino?.id,
          bonusOffer: item.bonusOffer,
          url: item.url,
          image: item.image,
        })
      );
    }

    if (fields.indexOf(`streamerLiveStreamOffers`) >= 0) {
      properties.streamerLiveStreamOffers = properties.streamerLiveStreamOffers.map(
        (item: StreamerLiveStreamOfferDto) => ({
          id: item.id,
          title: item.title,
          content: item.content,
        })
      );
    }

    return properties;
  };

  const submitForm = async (
    event: React.FormEvent,
    fields: string[],
    formName: string
  ): Promise<void> => {
    event.preventDefault();

    setSubmittedForm(formName);

    if (user?.avatar?.staging) {
      await uploadFile(user?.avatar?.staging, `avatar`);
    }

    if (user?.avatarPersonal?.staging) {
      await uploadFile(user?.avatarPersonal?.staging, `avatarPersonal`);
    }

    if (user?.banner?.staging) {
      await uploadFile(user?.banner?.staging, `banner`);
    }

    if (user?.offlineBanner?.staging && formName === "streamer-settings_base") {
      await uploadFile(user?.offlineBanner?.staging, `offlineBanner`);
    }

    if (user.streamerBonusOffers) {
      const streamerBonusOffers = user.streamerBonusOffers;
      const customBanners: File[] = [];

      for (const [index, offer] of user.streamerBonusOffers.entries()) {
        if (offer.image?.id) delete streamerBonusOffers[index].image; // Do not update existing images
        if (!offer.image?.staging) continue;
        customBanners.push(offer.image.staging);
      }

      if (customBanners.length > 0) {
        const { data: filesData } = await multipleUpload({
          variables: { files: customBanners },
        });

        let fileIndex = 0;
        for (const [index, offer] of user.streamerBonusOffers.entries()) {
          if (!offer.image?.staging) continue;
          streamerBonusOffers[index].image =
            filesData.multipleUpload[fileIndex].id;
          fileIndex++;
        }
      }

      setUser({ ...user, streamerBonusOffers });
    }

    const response = await updateUser({
      variables: {
        input: {
          where: { id: user?.id },
          data: makeUserDelta(fields),
        },
      },
    });

    if (response) {
      let message = `Settings updated. `;

      const update = await update?.();

      if ((update?.balanceChange || 0) > 0) {
        message += balanceMessage(update?.balanceChange);
      }

      openSnackbar(message);

      await refetch();
    }
  };

  const submitPasswordForm = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setFormPasswordSaving(true);

    const response = await changePassword?.({
      currentPassword,
      newPassword,
      confirmNewPassword,
    });

    if (response && response.status === 200) {
      setFormPasswordError(undefined);
      openSnackbar(`Password updated.`);
    } else if (response) {
      setFormPasswordError(response as ErrorResponse);
    }

    setFormPasswordSaving(false);
    setCurrentPassword(``);
    setNewPassword(``);
    setConfirmNewPassword(``);
  };

  const [version, setVersion] = useState<string>(generateVersion());

  useEffect(() => {
    if (data?.user) {
      setUser(data.user);
      setVersion(generateVersion());
    }
  }, [data?.user]);

  useEffect(() => {
    seoContext.setData({
      title: `Settings`,
      description: `Your personal settings`,
    });
  }, []);

  const passwordForm = (
    <section className="account light-border">
      <form
        className="account__form"
        onSubmit={(event): Promise<void> => submitPasswordForm(event)}
      >
        <div className="account__inner">
          <h5 className="account__title">Change password</h5>

          <div className="input-text__group">
            <input
              type="password"
              id="password-current"
              autoComplete="password"
              placeholder="Current password"
              value={currentPassword}
              onChange={(event): void => setCurrentPassword(event.target.value)}
            />
            <label htmlFor="password-current">Current password</label>
          </div>

          <div className="input-text__group">
            <input
              type="password"
              id="password-new"
              pattern={PasswordStrengthPattern}
              autoComplete="password-new"
              placeholder="Choose a password"
              value={newPassword}
              onChange={(event): void => setNewPassword(event.target.value)}
            />
            <label htmlFor="password-new">Choose a password</label>
            <small id="password-help">
              A MINIMUM OF EIGHT CHARACTERS, AT LEAST ONE UPPERCASE LETTER, ONE
              LOWERCASE LETTER, ONE NUMBER AND ONE SPECIAL CHARACTER
            </small>
          </div>

          <div className="input-text__group">
            <input
              type="password"
              id="password-new-confirm"
              autoComplete="password-new-confirm"
              placeholder="Confirm password"
              value={confirmNewPassword}
              onChange={(event): void =>
                setConfirmNewPassword(event.target.value)
              }
            />
            <label htmlFor="password-new-confirm">Confirm password</label>
          </div>

          {formPasswordError && <AlertError error={formPasswordError} />}

          <div className="input-button__group">
            {!formPasswordSaving && (
              <button
                className="btn btn--medium"
                disabled={
                  !currentPassword ||
                  !newPassword ||
                  newPassword !== confirmNewPassword
                }
              >
                SAVE password
              </button>
            )}
            {formPasswordSaving && <Loading title="Saving..." />}
          </div>
        </div>
      </form>
    </section>
  );

  const streamerSave = (form: string) => (
    <div className="input-button__group">
      <button
        className="btn btn--medium"
        disabled={isSaving(form) || !areAllOffersSet()}
      >
        SAVE changes
      </button>
      {isSaving(form) && <Loading title="Saving..." />}
    </div>
  );

  const baseFields = [
    `profileName`,
    `aboutMe`,
    `twitchUsername`,
    `youtubeUsername`,
    `snapchatUsername`,
    `twitterUsername`,
    `facebookUsername`,
    `instagramUsername`,
    `notifyMentions`,
    `notifyReplies`,
    `notifyFollowers`,
    `notifyScheduled`,
  ];

  const prizeDropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!prizeDropRef.current) return;
    if (loading) return;
    if (scrollTo === "prize-drops") {
      setTimeout(() => prizeDropRef.current?.scrollIntoView(), 1500);
    }
  }, [prizeDropRef.current, loading, scrollTo]);

  /**
   * Validating if the user state has been set,
   * since it's required for controller input fields below.
   */
  if (loading || !user?.id) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title={isStreamer ? "Streamer settings" : "Settings"} />

      {!isStreamer && (
        <section className="account light-border">
          <form
            className="account__form"
            onSubmit={(event): Promise<void> =>
              submitForm(
                event,
                isPartner
                  ? [...baseFields, ...["websiteUrl", "notifyMarketingItems"]]
                  : [...baseFields, "notifyPrizeWinner"],
                `profile-details`
              )
            }
          >
            <div className="account__inner">
              <h5 className="account__title">
                {isPartner ? "PARTNER" : " PROFILE"} DETAILS
              </h5>

              <div className="input-text__group">
                <input
                  type="text"
                  id="profile-name"
                  placeholder="CHOOSE YOU PROFILENAME*"
                  value={user.profileName}
                  onChange={(event): void =>
                    setUser({ ...user, profileName: event.target.value })
                  }
                />
                <label htmlFor="profile-name">CHOOSE YOU PROFILENAME*</label>
                {!isPartner && (
                  <small id="profile-name-help">
                    We suggest your Twitch or YouTube username to be easily
                    found by friends.
                  </small>
                )}
              </div>

              <div className="input-upload__group">
                <div className="input-upload__image">
                  <MediaImage src={user.avatar} format="small" />
                </div>
                <div className="input-upload__file ">
                  <label>
                    {isPartner ? "YOUR COMPANY LOGO" : "YOUR PROFILE PICTURE"}
                  </label>
                  <input
                    type="file"
                    id="avatar"
                    accept="image/jpeg, image/png"
                    onChange={(event): Promise<void> =>
                      setImage(`avatar`, event.target?.files?.[0])
                    }
                  />
                  <label htmlFor="avatar">BROWSE IMAGE</label>
                </div>
              </div>

              <div className="input-upload__group">
                <div className="input-upload__image">
                  <MediaImage src={user.banner} format="small" />
                </div>
                <div className="input-upload__file ">
                  <label>
                    {isPartner ? "YOUR COMPANY BANNER" : "YOUR PROFILE BANNER"}
                  </label>
                  <input
                    type="file"
                    id="banner"
                    accept="image/jpeg, image/png"
                    onChange={(event): Promise<void> =>
                      setImage(`banner`, event.target?.files?.[0])
                    }
                  />
                  <label htmlFor="banner">BROWSE IMAGE</label>
                </div>
              </div>

              <div className="input-textarea__group">
                <label htmlFor="about-me">
                  {isPartner ? "ABOUT US" : "ABOUT ME"}
                </label>

                <ReactMde
                  value={user.aboutMe}
                  onChange={(value): void =>
                    setUser({ ...user, aboutMe: value })
                  }
                  selectedTab={aboutMeSelectedTab}
                  onTabChange={setAboutMeSelectedTab}
                  toolbarCommands={toolbarMde}
                  generateMarkdownPreview={(markdown): Promise<string> =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                />
              </div>

              {isPartner && (
                <div className="input-text__group">
                  <input
                    type="text"
                    id="website"
                    placeholder="Add website"
                    value={user.websiteUrl}
                    onChange={(event): void =>
                      setUser({
                        ...user,
                        websiteUrl: event.target.value,
                      })
                    }
                  />
                  <label htmlFor="website">
                    website (e.g. company-name.com)
                  </label>
                </div>
              )}

              {!isPartner && (
                <div className="input-upload__group">
                  <div className="input-upload__image">
                    <MediaImage src={user?.avatarPersonal} format="small" />
                  </div>
                  <div className="input-upload__file ">
                    <label>ABOUT ME PICTURE</label>
                    <input
                      type="file"
                      id="avatar-personal"
                      accept="image/jpeg, image/png"
                      onChange={(event): Promise<void> =>
                        setImage(`avatarPersonal`, event.target?.files?.[0])
                      }
                    />
                    <label htmlFor="avatar-personal">BROWSE IMAGE</label>
                  </div>
                </div>
              )}
            </div>

            {!isPartner && (
              <div className="account__inner">
                <h5 className="account__title">Channel settings</h5>
                <div className="input-text__group input-text__group--social input-text__group--twitch">
                  <input
                    type="text"
                    id="twitch"
                    placeholder="Username"
                    value={user.twitchUsername || ``}
                    onChange={(event): void =>
                      setUser({ ...user, twitchUsername: event.target.value })
                    }
                  />
                  <label htmlFor="twitch">Username</label>
                </div>
                <div className="input-text__group input-text__group--social input-text__group--youtube">
                  <input
                    type="text"
                    id="youtube"
                    placeholder="Username"
                    value={user.youtubeUsername || ``}
                    onChange={(event): void =>
                      setUser({ ...user, youtubeUsername: event.target.value })
                    }
                  />
                  <label htmlFor="youtube">Username</label>
                </div>
              </div>
            )}

            <div className="account__inner">
              <h5 className="account__title">SOCIAL SETTINGS</h5>
              <div className="input-text__group input-text__group--social input-text__group--snapchat">
                <input
                  type="text"
                  id="snapchat"
                  placeholder="Username"
                  value={user.snapchatUsername || ``}
                  onChange={(event): void =>
                    setUser({ ...user, snapchatUsername: event.target.value })
                  }
                />
                <label htmlFor="snapchat">Username</label>
              </div>
              <div className="input-text__group input-text__group--social input-text__group--twitter">
                <input
                  type="text"
                  id="twitter"
                  placeholder="Username"
                  value={user.twitterUsername || ``}
                  onChange={(event): void =>
                    setUser({ ...user, twitterUsername: event.target.value })
                  }
                />
                <label htmlFor="twitter">Username</label>
              </div>
              <div className="input-text__group input-text__group--social input-text__group--facebook">
                <input
                  type="text"
                  id="facebook"
                  placeholder="Username"
                  value={user.facebookUsername || ``}
                  onChange={(event): void =>
                    setUser({ ...user, facebookUsername: event.target.value })
                  }
                />
                <label htmlFor="facebook">Username</label>
              </div>
              <div className="input-text__group input-text__group--social input-text__group--instagram">
                <input
                  type="text"
                  id="instagram"
                  placeholder="Username"
                  value={user.instagramUsername || ``}
                  onChange={(event): void =>
                    setUser({
                      ...user,
                      instagramUsername: event.target.value,
                    })
                  }
                />
                <label htmlFor="instagram">Username</label>
              </div>
            </div>

            <div className="account__inner">
              <h5 className="account__title">NOTIFICATION SETTINGS</h5>
              {/*<div className="input-switch__group">*/}
              {/*  <input*/}
              {/*    type="checkbox"*/}
              {/*    id="mentions-notifications"*/}
              {/*    checked={user.notifyMentions}*/}
              {/*    onChange={(event): void => setUser({ ...user, notifyMentions: event.target.checked })}*/}
              {/*  />*/}
              {/*  <label htmlFor="mentions-notifications"></label>*/}
              {/*  <p style={{ marginLeft: 12 }}>*/}
              {/*    <b>Mentions Notifications</b>*/}
              {/*    <br />*/}
              {/*    Notify me when a member mentions me in a post*/}
              {/*  </p>*/}
              {/*</div>*/}
              <div className="input-switch__group">
                <input
                  type="checkbox"
                  id="replies-notifications"
                  checked={user.notifyReplies}
                  onChange={(event): void =>
                    setUser({ ...user, notifyReplies: event.target.checked })
                  }
                />
                <label htmlFor="replies-notifications"></label>
                <p style={{ marginLeft: 12 }}>
                  <b>Replies Notifications</b>
                  <br />
                  Notify me when a member replies to a post or comment I have
                  posted
                </p>
              </div>
              <div className="input-switch__group">
                <input
                  type="checkbox"
                  id="follow-notifications"
                  checked={user.notifyFollowers}
                  onChange={(event): void =>
                    setUser({
                      ...user,
                      notifyFollowers: event.target.checked,
                    })
                  }
                />
                <label htmlFor="follow-notifications"></label>
                <p style={{ marginLeft: 12 }}>
                  <b>Follower Notifications</b>
                  <br />
                  Notify me when a member starts following me
                </p>
              </div>

              <div className="input-switch__group">
                <input
                  type="checkbox"
                  id="scheduled-notifications"
                  checked={user.notifyScheduled}
                  onChange={(event): void =>
                    setUser({ ...user, notifyScheduled: event.target.checked })
                  }
                />
                <label htmlFor="scheduled-notifications"></label>
                <p style={{ marginLeft: 12 }}>
                  <b>Scheduled Notifications</b>
                  <br />
                  Notify me when the platform sends an update
                </p>
              </div>

              {!isPartner && (
                <div className="input-switch__group">
                  <input
                    type="checkbox"
                    id="prize-winner-notifications"
                    checked={user.notifyPrizeWinner}
                    onChange={(event): void =>
                      setUser({
                        ...user,
                        notifyPrizeWinner: event.target.checked,
                      })
                    }
                  />
                  <label htmlFor="prize-winner-notifications" />
                  <p style={{ marginLeft: 12 }}>
                    <b>Prize Notifications</b>
                    <br />
                    Notify me when I win a giveaway or drop
                  </p>
                </div>
              )}

              {isPartner && (
                <div className="input-switch__group">
                  <input
                    type="checkbox"
                    id="marketing-item-notifications"
                    checked={user.notifyMarketingItems}
                    onChange={(event): void =>
                      setUser({
                        ...user,
                        notifyMarketingItems: event.target.checked,
                      })
                    }
                  />
                  <label htmlFor="marketing-item-notifications"></label>
                  <p style={{ marginLeft: 12 }}>
                    <b>Marketing items Notifications</b>
                    <br />
                    Notify me when a marketing item gets approved or rejected
                  </p>
                </div>
              )}

              <div className="input-button__group">
                <button
                  className="btn btn--medium"
                  disabled={isSaving(`profile-details`)}
                >
                  SAVE CHANGES
                </button>
                {isSaving(`profile-details`) && <Loading title="Saving..." />}
              </div>
            </div>
          </form>
        </section>
      )}

      {!isStreamer && user?.provider === `local` && passwordForm}

      {isStreamer && (
        <>
          <section className="account light-border">
            <form
              className="account__form"
              onSubmit={(event): Promise<void> =>
                submitForm(
                  event,
                  [`streamSchedule`, `websiteUrl`, `highlightsPlaylistId`],
                  `streamer-settings_base`
                )
              }
            >
              <div className="account__inner">
                <h5 className="account__title">Profile details</h5>

                <div className="input-textarea__group">
                  <label htmlFor="schedule">Schedule</label>

                  <ReactMde
                    value={user.streamSchedule}
                    onChange={(value): void =>
                      setUser({ ...user, streamSchedule: value })
                    }
                    selectedTab={streamScheduleSelectedTab}
                    onTabChange={setStreamScheduleSelectedTab}
                    toolbarCommands={toolbarMde}
                    generateMarkdownPreview={(markdown): Promise<string> =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                </div>

                <div className="input-text__group">
                  <input
                    type="text"
                    id="website"
                    placeholder="Add website"
                    value={user.websiteUrl}
                    onChange={(event): void =>
                      setUser({
                        ...user,
                        websiteUrl: event.target.value,
                      })
                    }
                  />
                  <label htmlFor="website">
                    website (e.g. streamer-name.com)
                  </label>
                </div>

                <div className="input-text__group">
                  <input
                    type="text"
                    id="highlights-playlist-id"
                    placeholder="Youtube Highlights playlist-id"
                    value={user.highlightsPlaylistId}
                    onChange={(event): void =>
                      setUser({
                        ...user,
                        highlightsPlaylistId: event.target.value,
                      })
                    }
                  />
                  <label htmlFor="highlights-playlist-id">
                    Youtube Highlights playlist-id
                  </label>
                </div>

                <div className="input-upload__group">
                  <div className="input-upload__image input-upload-placeholder-image">
                    <MediaImage src={user.offlineBanner} format="small" />
                  </div>
                  <div className="input-upload__file">
                    <label>YOUR OFFLINE BANNER</label>
                    <input
                      type="file"
                      id="offline-banner"
                      accept="image/jpeg, image/png"
                      onChange={(event): Promise<void> =>
                        setImage(`offlineBanner`, event.target?.files?.[0])
                      }
                    />
                    <label htmlFor="offline-banner">BROWSE IMAGE</label>
                    <small>
                      For the best result, use the aspect ratio and dimensions
                      of at least 1920 x 1080 pixels
                    </small>
                  </div>
                </div>
                {streamerSave("streamer-settings_base")}
              </div>
            </form>
          </section>

          <section className="account light-border">
            <form
              className="account__form"
              onSubmit={(event): Promise<void> =>
                submitForm(
                  event,
                  [`streamerLiveStreamOffers`],
                  `streamer-settings_live-stream-offers`
                )
              }
            >
              <div className="account__inner">
                <h5 className="account__title">Live Stream Offers</h5>
                <LiveStreamOffers
                  offers={user.streamerLiveStreamOffers}
                  onCreate={addLiveStreamOffers}
                  onUpdate={updateLiveStreamOffer}
                  onRemove={removeLiveStreamOffer}
                />
                {streamerSave("streamer-settings_live-stream-offers")}
              </div>
            </form>
          </section>

          <section className="account light-border">
            <form
              className="account__form"
              onSubmit={(event): Promise<void> =>
                submitForm(
                  event,
                  [`streamerBonusOffers`],
                  `streamer-settings_bonus-offers`
                )
              }
            >
              <div className="account__inner">
                <h5 className="account__title">Bonus Offers</h5>

                <div className="input-text__group">
                  <input
                    type="text"
                    id="bonus-offer__title"
                    placeholder="Title"
                    value={bonusOffer?.title}
                    onChange={(event): void =>
                      setBonusOffer({
                        ...bonusOffer,
                        ...{ title: event.target.value },
                      })
                    }
                  />
                  <label htmlFor="bonus-offer__title">Title</label>
                </div>

                <div className="input-text__group">
                  <LazySelect
                    placeholder="SEARCH CASINO"
                    selected={selectedCasino}
                    query={casinoSelectorQuery}
                    onSelectItem={(casino): void => setSelectedCasino(casino)}
                  />
                </div>

                <div className="input-text__group">
                  <input
                    type="text"
                    id="bonus-offer__link"
                    value={bonusOffer?.url}
                    onChange={(event): void => {
                      setBonusOffer({ ...bonusOffer, url: event.target.value });
                    }}
                  />

                  <label htmlFor="bonus-offer__link">
                    Url (e.g. https://casinoring.com)
                  </label>
                </div>

                <div className="input-text__group">
                  <ReactMde
                    value={bonusOffer?.bonusOffer}
                    onChange={(content) =>
                      setBonusOffer({
                        ...bonusOffer,
                        ...{ bonusOffer: content },
                      })
                    }
                    selectedTab={bonusOfferTab}
                    onTabChange={setBonusOfferTab}
                    toolbarCommands={toolbarMde}
                    minEditorHeight={100}
                    generateMarkdownPreview={(markdown): Promise<string> =>
                      Promise.resolve(converter.makeHtml(markdown))
                    }
                  />
                </div>

                <div className="input-upload__group">
                  <div className="input-upload__image input-upload-placeholder-image">
                    <MediaImage src={bonusOffer?.image} format="small" />
                  </div>
                  <div className="input-upload__file">
                    <label>Custom banner</label>
                    <input
                      type="file"
                      id="bonus-offer-custom-banner"
                      accept="image/jpeg, image/png"
                      onChange={async (event) => {
                        const image = event.target?.files?.[0];
                        if (!image) return;

                        setBonusOffer({
                          ...bonusOffer,
                          ...{
                            image: {
                              staging: image,
                              stagingPreview: await imagePreview(image),
                            } as MediaDto,
                          },
                        });
                      }}
                    />
                    <label htmlFor="bonus-offer-custom-banner">
                      BROWSE IMAGE
                    </label>
                    <small>
                      For the best result, use the aspect ratio and dimensions
                      of at least 256 x 256 pixels
                    </small>
                  </div>
                </div>

                <button
                  type="button"
                  disabled={!canAddBonusOffer()}
                  style={{ height: 36, width: `100%`, margin: 0 }}
                  className="btn"
                  onClick={(): void => addBonusOffer()}
                >
                  Add
                </button>

                <div
                  key={`streamer-bonus-offers-${user.streamerBonusOffers?.length}-${version}`}
                  style={{ marginTop: 15 }}
                >
                  {user.streamerBonusOffers?.map((bonusOffer, index) => (
                    <div
                      key={`bonus-offer-${bonusOffer.casino?.id || index}`}
                      className="input-text__group"
                    >
                      <StreamerBonusOfferContent
                        bonusOffer={bonusOffer}
                        converter={converter}
                        toolbar={toolbarMde}
                        onRemove={() => removeOffer(bonusOffer)}
                        onUpdate={(offer) => updateOffer(bonusOffer, offer)}
                      />
                      {user.streamerBonusOffers?.[index + 1] ? <hr /> : null}
                    </div>
                  ))}
                </div>

                {streamerSave("streamer-settings_bonus-offers")}
              </div>
            </form>
          </section>

          <section ref={prizeDropRef} className="account light-border">
            <PrizeDropSettings />
          </section>
        </>
      )}
    </>
  );
};

export default SettingsPage;
