import React, { useContext, useState } from "react";
import { FaStopwatch } from "react-icons/fa";
import { AuthContext } from "../../context/auth.context";
import { UserDtoType } from "../../dtos/user.dto";
import {
  PerformPrizeDropResult,
  PrizeDropDto,
} from "../../dtos/prize-drop.dto";
import { usePrizeDropQuery } from "./prize-drop-settings-query";
import Loading from "../loading";
import { useConfirmModal } from "../modals/confirm-modal.context";
import { useDeletePrizeDropMutation } from "./prize-drop-settings-mutations";
import { useOpenSnackbar } from "../../utils/snackbar";
import { EditPrizeDropModal } from "./EditPrizeDropModal";
import { usePerformPrizeDropMutation } from "./hooks/usePerformPrizeDrop";
import { SmallButton } from "../../ui/SmallButton";

import "./prize-drop-settings.scss";

interface EditPrizeDropModalStateProps {
  show?: boolean;
  prizeDrop?: PrizeDropDto;
}

export const PrizeDropSettings: React.FC = () => {
  const [editModalState, setEditModalState] = useState<
    EditPrizeDropModalStateProps
  >({});
  const { user } = useContext(AuthContext);
  const { data, loading, refetch } = usePrizeDropQuery();
  const { mutate: deletePrizeDrop } = useDeletePrizeDropMutation();
  const openSnackbar = useOpenSnackbar();

  if (user?.type !== UserDtoType.Streamer) return null;

  return (
    <>
      <div className="account__inner prize-drop-settings">
        <div className="header">
          <div style={{ fontSize: 15 }}>
            <h5 className="account__title" style={{ margin: 0 }}>
              Prize drop settings
            </h5>
            Your configured prize drops are listed below.
          </div>
          <div>
            <button
              type="button"
              style={{ height: 36, width: `100%`, margin: 0 }}
              className="btn"
              onClick={() =>
                setEditModalState({
                  show: true,
                })
              }
            >
              CREATE NEW
            </button>
          </div>
        </div>

        {!loading && data?.length === 0 && (
          <div className="empty-placeholder">
            You haven&apos;t created any prize drops.
          </div>
        )}

        {loading && <Loading />}
        {data?.map((prizeDrop, i) => (
          <PrizeDropListItem
            key={prizeDrop.id}
            prizeDrop={prizeDrop}
            divider={i !== 0}
            onClickEdit={() =>
              setEditModalState({
                show: true,
                prizeDrop,
              })
            }
            onDelete={() => {
              deletePrizeDrop(prizeDrop.id!!)
                .then(() => {
                  refetch();
                  openSnackbar({
                    content: "Prize drop has been deleted.",
                  });
                })
                .catch(() =>
                  openSnackbar({
                    content:
                      "Something went wrong while deleting this prize drop.",
                  })
                );
            }}
          />
        ))}
      </div>

      <EditPrizeDropModal
        {...editModalState}
        onClose={(committed) => {
          setEditModalState({ show: false });
          committed && refetch();
        }}
      />
    </>
  );
};

interface PrizeDropListItemProps {
  prizeDrop: PrizeDropDto;
  divider: boolean;
  onClickEdit: () => void;
  onDelete: () => void;
}

const PrizeDropListItem: React.FC<PrizeDropListItemProps> = ({
  prizeDrop,
  divider,
  onClickEdit,
  onDelete,
}) => {
  const { openConfirmModal } = useConfirmModal();
  const performPrizeDrop = usePerformPrizeDropMutation();
  const openSnackbar = useOpenSnackbar();

  return (
    <>
      {divider && (
        <div style={{ width: "100%", borderBottom: "1px solid #121729" }} />
      )}
      <div key={prizeDrop.id} className="list-item">
        <div className="body">
          <div className="title">
            <h6>{prizeDrop.title}</h6>
            <div className="controls">
              <SmallButton
                className="mr-2"
                disabled={performPrizeDrop.loading}
                onClick={() =>
                  performPrizeDrop
                    .mutate(prizeDrop.id!!)
                    .then(({ data }) =>
                      openSnackbar({
                        content:
                          data?.performPrizeDrop ===
                          PerformPrizeDropResult.SUCCESS
                            ? "The prize drop has dropped successfully."
                            : data?.performPrizeDrop ===
                              PerformPrizeDropResult.WINNER_UNDETERMINED
                            ? "Could not determine a winner."
                            : "Something went wrong.",
                      })
                    )
                    .catch((error) => {
                      console.error(error);
                      openSnackbar({
                        content: "Something went wrong.",
                      });
                    })
                }
              >
                Drop now
              </SmallButton>
              <SmallButton className="mr-2" onClick={onClickEdit}>
                Edit
              </SmallButton>
              <a
                style={{
                  cursor: "pointer",
                  fontSize: 12.5,
                  color: "#008ca5",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  openConfirmModal({
                    title: "Delete prize drop",
                    message: "Are you sure you want to delete this prize drop?",
                    onClose: (result) => result && onDelete(),
                  })
                }
              >
                Delete
              </a>
            </div>
          </div>
          <div className="contents">
            <div>{prizeDrop.contents}</div>
            <div className="timer">
              {prizeDrop.timer > 0 ? (
                <>
                  <FaStopwatch className="icon" />
                  Timer enabled ({prizeDrop.timer} mins,{" "}
                  {prizeDrop.minimumViewerCount || 0} viewers)
                </>
              ) : (
                "No timer"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
