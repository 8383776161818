import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import ReactPlayer from "react-player/lazy";
import DigitRoll from "digit-roll-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useMeasure } from "react-use";
import {
  StreamerLiveSourceEnum,
  StreamerLiveStreamOfferDto,
  UserDto,
} from "../../dtos/user.dto";
import { DisplayMode, getDisplayMode } from "../../utils/display-mode";
import { LayoutContext } from "../../context/layout.context";
import MediaImage from "../media-image";
import VerifiedBadge from "../verified-badge";
import { useVideoTracking } from "../../utils/matomo";
import Offers from "../profile/offers";
import { useStreamView } from "./hooks/useStreamView";
import { usePrizeDropPollingQuery } from "./hooks/usePrizeDropPollingQuery";
import { PrizeDropContext } from "../prize-drop/prize-drop";

export interface LiveStreamProps {
  source: StreamerLiveSourceEnum;
  id?: string;
  user?: UserDto;
  offers?: StreamerLiveStreamOfferDto[];
}

export const LiveStream: React.FC<LiveStreamProps> = ({
  source,
  id,
  user,
  offers,
}) => {
  const layoutContext = useContext(LayoutContext);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(getDisplayMode());
  const [controlFocus, setControlFocus] = useState(true);
  const [fullScreenFocus, setFullScreenFocus] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const [chatEnabled, setChatEnabled] = useState<boolean>(
    window.innerHeight >= 512
  );
  const controlFocusTimeout = useRef<NodeJS.Timeout>();
  const fullscreen = useFullScreenHandle();
  const fullScreenFocusTimeout = useRef<NodeJS.Timeout>();
  const mouseMoved = useRef<boolean>(false);
  const timeout = 2500;
  const fullscreenAvailable = document.fullscreenEnabled;

  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>();
  const getPlayerHeight = () => (width / 16) * 9;

  const { setMetaData, onPlay, onPause, onSeek } = useVideoTracking(
    window.location.href
  );

  const onReady = () => {
    setMetaData({
      player:
        source === StreamerLiveSourceEnum.TWITCH ? "twitchLive" : "youtubeLive",
      title: `${user?.profileName} - Live Stream`,
      dimensions: {
        width,
        height,
      },
    });
  };

  const { count } = useStreamView(user?.id);
  const countRef = useRef<number>();
  const [renderCount, setRenderCount] = useState<number>();

  /**
   * Syncs count value in reference
   * Controls initial render
   */
  useEffect(() => {
    if (!count) return;
    countRef.current = count;
    if (!renderCount) setRenderCount(count);
  }, [count]);

  /**
   * Sync count based on interval
   * Delays visual updates for user
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (!countRef.current) return;
      setRenderCount(countRef.current);
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  usePrizeDropPollingQuery();

  const player = () => {
    const assemble = () => {
      if (source === StreamerLiveSourceEnum.TWITCH)
        return `https://www.twitch.tv/${id}`;
      return `https://www.youtube.com/watch?v=${id}`;
    };

    const thirdPartyConfig = {
      youtube: {
        playerVars: { modestbranding: 1, fs: fullscreenAvailable ? 0 : 1 },
      },
      twitch: { options: { allowfullscreen: !fullscreenAvailable } },
    };

    return (
      <div
        onMouseOver={() => {
          if (!fullscreen.active) setHover(true);
        }}
        onMouseOut={() => {
          if (!fullscreen.active) setHover(false);
        }}
        ref={containerRef}
        className="profile-livestream-player-container"
      >
        {renderCount && (
          <div
            className={classNames("profile-livestream-viewer-count", {
              "profile-livestream-minimal": !controlFocus,
              "profile-livestream-viewer-count__fullscreen":
                !fullScreenFocus && !hover,
            })}
          >
            <i className="icon-user" />
            <DigitRoll num={renderCount} delay={3} width={1} height={2} />
          </div>
        )}

        {fullscreenAvailable && !fullscreen.active && (
          <button
            className={classNames("btn", "profile-livestream-control", {
              "profile-livestream-minimal": !controlFocus,
            })}
            onClick={fullscreen.enter}
          >
            <span>Full screen</span>
            <i className="icon-expand" />
          </button>
        )}

        <ReactPlayer
          className="profile-livestream-player"
          url={assemble()}
          playing
          controls
          config={thirdPartyConfig}
          height={getPlayerHeight()}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onSeek={(position) => onSeek(position)}
        />
      </div>
    );
  };

  const chat = () => {
    const assemble = () => {
      const host = window.location.hostname;
      if (source === StreamerLiveSourceEnum.TWITCH) {
        return `https://www.twitch.tv/embed/${id}/chat?parent=${host}${
          displayMode === DisplayMode.Dark ? "&darkpopout" : ""
        }`;
      }
      return `https://www.youtube.com/live_chat?v=${id}&embed_domain=${host}&theme=${
        displayMode === DisplayMode.Dark ? "dark" : "light"
      }`;
    };

    return (
      <iframe
        className={classNames("profile-livestream-chat", {
          "profile-livestream-chat__hidden": !chatEnabled,
        })}
        src={assemble()}
      />
    );
  };

  useEffect(() => {
    if (fullscreen.active) return;
    if (hover) {
      if (controlFocusTimeout.current)
        clearTimeout(controlFocusTimeout.current);
      return setControlFocus(true);
    }
    controlFocusTimeout.current = setTimeout(
      () => setControlFocus(false),
      timeout
    );
  }, [hover]);

  useEffect(() => {
    const event = () => {
      if (mouseMoved.current) return;

      setFullScreenFocus(true);
      if (fullScreenFocusTimeout.current)
        clearTimeout(fullScreenFocusTimeout.current);
      fullScreenFocusTimeout.current = setTimeout(
        () => setFullScreenFocus(false),
        timeout
      );

      mouseMoved.current = true;
      setTimeout(() => (mouseMoved.current = false), timeout);
    };

    const removeListener = () =>
      window.removeEventListener("mousemove", event, false);

    if (!fullscreen.active) {
      removeListener();
      return;
    }

    window.addEventListener("mousemove", event, false);
    return removeListener;
  }, [fullscreen.active]);

  useEffect(() => {
    // Set meta data
    setMetaData({
      fullscreen: fullscreen.active,
      dimensions: {
        width,
        height,
      },
    });

    // Handle focus
    if (!fullscreen.active) {
      if (fullScreenFocusTimeout.current)
        clearTimeout(fullScreenFocusTimeout.current);
      setFullScreenFocus(true);
    } else {
      fullScreenFocusTimeout.current = setTimeout(
        () => setFullScreenFocus(false),
        timeout
      );
    }
  }, [fullscreen.active]);

  useEffect(() => {
    if (layoutContext.props.displayMode)
      setDisplayMode(layoutContext.props.displayMode);
  }, [layoutContext.props.displayMode]);

  const { isOpen } = useContext(PrizeDropContext);

  /**
   * Close fullscreen when price drop is presented
   */
  useEffect(() => {
    if (isOpen && fullscreen.active) fullscreen.exit();
  }, [isOpen]);

  return (
    <FullScreen handle={fullscreen}>
      {!fullscreen.active && (
        <section className="profile-livestream">
          <h3>Livestream</h3>
          <div className="profile-livestream-container light-border">
            {player()}
            <Offers offers={offers} />
            {chat()}
          </div>
        </section>
      )}

      {fullscreen.active && (
        <div className="profile-livestream__fullscreen">
          <div
            className={classNames("profile-livestream__fullscreen-title", {
              "profile-livestream__fullscreen-title-minimal":
                !fullScreenFocus && !hover,
            })}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <MediaImage
              alt={user?.profileName}
              src={user?.avatar}
              format="thumbnail"
            />

            <h1>{user?.profileName}</h1>

            <VerifiedBadge user={user} />

            <div className="profile-livestream__fullscreen-controls">
              <div className="input-switch__group input-switch__chat">
                <input
                  type="checkbox"
                  id="chat-enabled"
                  checked={chatEnabled}
                  onChange={(event): void =>
                    setChatEnabled(event.target.checked)
                  }
                />
                <label htmlFor="chat-enabled" />
              </div>

              <a className="btn" onClick={fullscreen.exit}>
                <i className="icon-cross" />
              </a>
            </div>
          </div>

          <img
            src="/images/logo-gold.svg"
            className={classNames("profile-livestream__fullscreen-logo", {
              "profile-livestream__fullscreen-logo-minimal":
                !fullScreenFocus && !hover,
            })}
            alt="CasinoRing"
          />

          {player()}

          {chat()}

          <Offers offers={offers} expanded={true} />
        </div>
      )}
    </FullScreen>
  );
};
