import React, { useMemo } from "react";
import { UserDto, UserDtoType } from "../dtos/user.dto";
import { Link } from "gatsby";
import FollowButton from "./follow-button";
import MediaImage from "./media-image";
import VerifiedBadge from "./verified-badge";
import { getRandomStreamerAvatar } from "../utils/avatar";

export type MemberLinkBuilder = (user: UserDto) => string;

export interface MemberLinkProps {
  label: string;
  linkBuilder: MemberLinkBuilder;
}

interface MemberProps {
  user?: UserDto;
  link?: MemberLinkProps;
}

const Member: React.FC<MemberProps> = ({ user, link }) => {
  link = link || {
    linkBuilder: (user: UserDto): string => `/profile/${user.id}/`,
    label: "SEE PROFILE",
  };

  const avatar = useMemo(
    () =>
      user?.type === UserDtoType.Streamer && !user.avatar
        ? getRandomStreamerAvatar()
        : user?.avatar,
    [user?.avatar]
  );

  return user ? (
    <div className="members__list-item col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
      <div className="members__list__inner  light-border">
        <Link to={link.linkBuilder(user)}>
          <div className="members__list-item__image">
            <MediaImage src={avatar} format="small" isClickable />

            <div className="members__list-item__buttons">
              {user.live && (
                <div className="members__list-item__button">
                  <i className="icon-user-stream"></i>
                </div>
              )}
            </div>
          </div>
          <div className="members__list-item__content">
            <div className="members__list-item__title-container">
              <h5 className="members__list-item__title clickable">
                {user?.profileName}
              </h5>
              <VerifiedBadge user={user} />
            </div>

            <span className="members__list-item__link">{link.label}</span>
          </div>
        </Link>
        <FollowButton
          className="members__list-item__footer btn"
          userId={user?.id}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Member;
