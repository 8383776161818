import * as React from "react";
import moment from "moment";
import { useQuery, gql } from "@apollo/client";
import { UserDto, userFields } from "../dtos/user.dto";
import { orderBy, flatten } from "lodash";
import { useContext, useState } from "react";
import { Youtube, YoutubeVideo } from "../utils/youtube";
import { RouteProps } from ".";
import Loading from "../components/loading";
import Alert, { somethingWentWrong } from "../components/alert";
import { Link } from "gatsby";
import Title from "../components/title";
import MediaImage from "../components/media-image";
import { SeoContext } from "../context/seo.context";

type StreamerHighlightsPageProps = RouteProps;

const StreamerHighlightsPageQuery = gql`
  query StreamerHighlightsPageQuery {
    streamers: users(where: { type: "streamer", blocked: false }, sort: "createdAt:ASC") {
      ${userFields}
      highlightsPlaylistId
    }
  }
`;

const StreamerHighlightsPage: React.FC<StreamerHighlightsPageProps> = () => {
  const youtube = new Youtube();
  const seoContext = useContext(SeoContext);

  const [items, setItems] = useState<YoutubeVideo[]>();
  const [streamerId, setStreamerId] = useState<string>(`all`);
  const { data, loading, error } = useQuery<{ streamers: UserDto[] }>(
    StreamerHighlightsPageQuery
  );

  const fetchAllVideos = async () => {
    setItems(undefined);

    const items = flatten(
      await Promise.all(
        data?.streamers
          .filter(
            (streamer) => streamerId === `all` || streamer.id == streamerId
          )
          .map((streamer) =>
            streamer.highlightsPlaylistId
              ? youtube.playlist(streamer.highlightsPlaylistId)
              : []
          ) || []
      )
    );

    const ordered = orderBy(
      items,
      (item) => moment(item.snippet.publishedAt).unix(),
      `desc`
    );

    setItems(ordered);
  };

  const getStreamer = (playlistId: string) => {
    return data?.streamers.find(
      (streamer) => streamer.highlightsPlaylistId === playlistId
    );
  };

  const renderStreamer = (streamer: UserDto) => {
    return (
      <Link to={`/profile/${streamer?.id}`}>
        <div className="block__list-item__user">
          <div className="block__list-item__user-image">
            <MediaImage src={streamer.avatar} format="thumbnail" />
          </div>
          <div className="block__list-item__user-content">
            <h4>{streamer?.profileName}</h4>
          </div>
        </div>
      </Link>
    );
  };

  React.useEffect(() => {
    if (data) {
      fetchAllVideos();

      seoContext.setData({
        title: "Streamers Highlights",
        description: "All the highlights of our community's streamers",
      });
    }
  }, [data, streamerId]);

  if (loading || !items) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <Title title="STREAMER HIGHLIGHTS" />

      <section className="header">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <form
              className="form-inline"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="input-group" style={{ flex: 1 }}>
                STREAMER
              </div>
              <div className="input-group" style={{ flex: 3 }}>
                <select
                  name="streamer"
                  id="streamer"
                  placeholder="STREAMER"
                  value={streamerId}
                  onChange={(event) => setStreamerId(event.target.value)}
                >
                  <option value="all">ALL</option>
                  {data?.streamers
                    ?.filter((streamer) => !!streamer.highlightsPlaylistId)
                    .map((streamer) => (
                      <option key={streamer.id} value={streamer.id}>
                        {streamer.profileName}
                      </option>
                    ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="block">
        <div className="block__list row small-gutters">
          {items?.map((item) => (
            <div
              key={item.id}
              className="block__list-item col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="block__list__inner">
                <a
                  href={`https://youtu.be/${item.snippet.resourceId.videoId}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className="block__list-item__image block__list-item__image--youtube">
                    <img src={item.snippet.thumbnails.high.url} />
                  </div>
                </a>
                <div className="block__list-item__content">
                  {renderStreamer(
                    getStreamer(item.snippet.playlistId) as UserDto
                  )}
                  <a
                    href={`https://youtu.be/${item.snippet.resourceId.videoId}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <h2
                      className="block__list-item__title clickable"
                      style={{ fontSize: "1rem" }}
                    >
                      {item.snippet.title}
                    </h2>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Alert message="you've reached the end" style="info" />
      </section>
    </>
  );
};

export default StreamerHighlightsPage;
