import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { UserDto, UserDtoType, userSidebarFields } from "../dtos/user.dto";
import { Link } from "gatsby";
import { RouteProps } from ".";
import Loading from "../components/loading";
import Alert, { somethingWentWrong } from "../components/alert";
import { LayoutContext } from "../context/layout.context";
import Infinity from "../components/infinity";
import { formatOrUrl, MediaDto, mediaFields } from "../dtos/media.dto";
import { PostDto } from "../dtos/post.dto";
import MediaImage from "../components/media-image";
import { flatten } from "lodash";
import { AggregateDto, aggregateFields } from "../dtos/aggregate.dto";
import ProfileInfoSidebar from "../components/profile/sidebar/info";
import ProfileFollowerSidebar from "../components/profile/sidebar/follower";
import Lightbox from "@comceptum-software/react-image-lightbox";
import { SeoContext } from "../context/seo.context";

interface PhotosPageProps extends RouteProps {
  id?: string;
  imageIndex?: string;
}

const PhotosPageQueryPageSize = 9;
const PhotosPageQuery = gql`
    query PhotosPageQuery($id: ID!, $start: Int) {
        user(id: $id) {
            ${userSidebarFields}
        }
        photos: posts(limit: ${PhotosPageQueryPageSize}, start: $start, where: {
            owner: $id
            hasMedia: true,
            processed_ne: false
        }, sort: "createdAt:DESC") {
            media {
                ${mediaFields}
            }
        }
        followerCount: followersConnection(where: { user: $id }) {
            ${aggregateFields}
        }
        followingCount: followersConnection(where: { owner: $id }) {
            ${aggregateFields}
        }
    }
`;

const PhotosPage: React.FC<PhotosPageProps> = ({ id, imageIndex }) => {
  const layoutContext = useContext(LayoutContext);
  const seoContext = useContext(SeoContext);
  const getQueryVariables = (start?: number): any => ({ id, start });

  const [photos, setPhotos] = React.useState<PostDto[]>([]);
  const [photoIndex, setPhotoIndex] = React.useState<number>(-1);

  const { data, loading, error, fetchMore } = useQuery<{
    user: UserDto;
    photos: PostDto[];
    followerCount: AggregateDto;
    followingCount: AggregateDto;
  }>(PhotosPageQuery, {
    skip: !id,
    variables: getQueryVariables(),
    onCompleted: (data) => {
      setPhotos(data.photos || []);

      if (imageIndex && photoIndex === -1) {
        setPhotoIndex(parseFloat(imageIndex));
      }
    },
  });

  const loadMore = (): Promise<{}> => {
    return fetchMore({
      variables: getQueryVariables(photos.length),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setPhotos([...photos, ...fetchMoreResult.photos]);

        return prev;
      },
    });
  };

  const title = (): string => `Photos of ${data?.user?.profileName}`;

  const images = (): MediaDto[] => {
    return flatten(photos.map((post) => post.media as MediaDto[]));
  };

  useEffect(() => {
    seoContext.setData({
      noIndex: true,
    });

    if (data && !data.user.blocked) {
      const followerSidebar = (
        <ProfileFollowerSidebar
          user={data.user}
          followingCount={data?.followingCount?.aggregate?.count}
          followerCount={data?.followerCount?.aggregate?.count}
        />
      );

      seoContext.setData({
        title: title(),
        noIndex: true,
      });

      layoutContext.updateProps({
        additionalSidebar: {
          top: (
            <>
              <ProfileInfoSidebar user={data.user} />
              {data.user?.type !== UserDtoType.Streamer
                ? followerSidebar
                : null}
            </>
          ),
          bottom:
            data.user?.type === UserDtoType.Streamer ? followerSidebar : null,
        },
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (photoIndex === photos.length - 1) {
      loadMore();
    }
  }, [photoIndex]);

  if (loading) return <Loading />;
  if (error || data?.user?.blocked)
    return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      {photoIndex >= 0 && (
        <Lightbox
          onCloseRequest={(): void => setPhotoIndex(-1)}
          animationDisabled={true}
          mainSrc={formatOrUrl(`medium`, images()[photoIndex])}
          mainSrcThumbnail={formatOrUrl(`thumbnail`, images()[photoIndex])}
          prevSrc={formatOrUrl(`medium`, images()[photoIndex - 1])}
          prevSrcThumbnail={formatOrUrl(`thumbnail`, images()[photoIndex - 1])}
          nextSrc={formatOrUrl(`medium`, images()[photoIndex + 1])}
          nextSrcThumbnail={formatOrUrl(`thumbnail`, images()[photoIndex + 1])}
          onMovePrevRequest={(): void => setPhotoIndex(photoIndex - 1)}
          onMoveNextRequest={(): void => setPhotoIndex(photoIndex + 1)}
        />
      )}

      <section className="header">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flex-align-start">
            <h2>{title()}</h2>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 flex-align-end">
            <Link to={`/profile/${id}`} className="header__link">
              BACK TO MEMBER PAGE
            </Link>
          </div>
        </div>
      </section>

      <Infinity
        data={photos}
        pageSize={PhotosPageQueryPageSize}
        fetchMore={(): Promise<{}> => loadMore()}
      >
        <section className="profile-photos">
          <div className="profile-photos__list row small-gutters">
            {images().map((media, i) => (
              <div
                key={media.id}
                className="profile-photos__list-item col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"
              >
                <div
                  className="profile-photos__list-item__image"
                  onClick={(): void => setPhotoIndex(i)}
                >
                  <MediaImage src={media} format="small" isClickable />
                </div>
              </div>
            ))}
          </div>
        </section>
      </Infinity>
    </>
  );
};

export default PhotosPage;
