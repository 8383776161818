import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { AuthContext, ErrorResponse } from "../context/auth.context";
import { navigate } from "gatsby";
import { RouteProps } from ".";
import { PasswordStrengthPattern, updateUserMutation } from "../dtos/user.dto";
import { useForm } from "react-hook-form";
import AlertError from "../components/alert-error";
import Title from "../components/title";
import ErrorPage from "./error";
import Alert from "../components/alert";
import { useMutation } from "@apollo/client";
import { useOpenSnackbar } from "../utils/snackbar";
import { SeoContext } from "../context/seo.context";
import { InputButtonShowHide } from "../components/input-button-show-hide";
import { RegistrationContext } from "../context/registration.context";

interface Properties extends RouteProps {
  type?: "streamer";
}

const RegisterPage: React.FC<Properties> = ({ type }) => {
  const seoContext = useContext(SeoContext);
  const authContext = useContext(AuthContext);
  const { data: registrationData, clear: clearRegistration } = useContext(RegistrationContext);

  const { handleSubmit, register, errors, watch } = useForm();
  const [error, setError] = useState<ErrorResponse>();
  const [streamerError, setStreamerError] = useState<string>();

  const [updateUser] = useMutation(updateUserMutation);

  const openSnackbar = useOpenSnackbar();

  const onSubmit = async ({
    username,
    password,
    email,
    profileName,
    twitchUsername,
    youtubeUsername,
    birthday
  }: Record<string, string>): Promise<void> => {
    if (isStreamerRequest && !twitchUsername && !youtubeUsername) {
      return setStreamerError(
        "Either a Twitch username or Youtube channel must be supplied, when applying for streamer"
      );
    }

    if (isStreamerRequest && hasSession) {
      const response = await updateUser({
        variables: {
          input: {
            where: { id: authContext.user?.id },
            data: {
              twitchUsername,
              youtubeUsername,
            },
          },
        },
      });

      if (response.errors)
        return setStreamerError("Something went wrong. Please try again.");

      openSnackbar({ content: "Request submitted" });
      return navigate("/");
    }

    const response = await authContext.register?.({
      username,
      email,
      password,
      profileName,
      twitchUsername,
      youtubeUsername,
      birthday
    });

    if (response?.jwt) {
      navigate("/");
      clearRegistration();
    } else {
      setError(response);
    }
  };

  const isStreamerRequest = type && type === "streamer";

  const hasSession = !!authContext.jwt;

  const [showPass, setShowPass] = useState(false);

  const togglePass = () => setShowPass(!showPass);

  const getMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18)
    return date.toISOString().substring(0, 10);
  }

  /**
   * ? can be used in the near future to quickly bounce off duplicates
   */
  // const apollo = useApolloClient();
  // const checkDuplicates = async (query: DocumentNode, value: string): Promise<boolean> => {
  //   const response = await apollo.query<{ users: UserDto[] }>({
  //     query,
  //     variables: {
  //       value,
  //     }
  //   });

  //   return response.data.users.length === 0;
  // };

  useEffect(() => {
    seoContext.setData({
      title: "Join now!",
      description: "Join now! Become a community member.",
    });
  }, []);

  const renderTermsAndConditionsInput = () => (
    <div className="input-switch__group">
      <input
        type="checkbox"
        name="terms"
        id="terms"
        value="terms"
        ref={register({
          required: "Required",
        })}
      />
      <label htmlFor="terms" className={errors.terms && "validation-error"} />
      <p style={{ marginLeft: 12 }}>
        <b>Terms and conditions</b>
        <br />I AGREE TO THE CASINORING{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="clickable highlight-text"
          href={`${window.origin}/terms-and-conditions/`}
        >
          TERMS AND CONDITIONS
        </a>{" "}
        AND{" "}
        <a
          target="_blank"
          rel="noreferrer"
          className="clickable highlight-text"
          href={`${window.origin}/privacy-statement/`}
        >
          PRIVACY STATEMENT
        </a>
      </p>
    </div>
  );

  const renderAccountDetails = () => (
    <div className="account__inner">
      <h5 className="account__title">ACCOUNT DETAILS</h5>
      <div className="input-text__group">
        <input
          type="text"
          id="username"
          placeholder="Username"
          autoComplete="username"
          name="username"
          className={errors.username && "validation-error"}
          ref={register({
            required: "Required",
            minLength: { value: 3, message: "Min 3 characters" },
            maxLength: { value: 24, message: "Max 24 characters" },
          })}
        />
        <label htmlFor="username">
          Username*
          {errors.username && (
            <p className="validation-error-message">
              {errors.username.message}
            </p>
          )}
        </label>
      </div>

      <div className="input-text__group">
        <input
          type="email"
          id="your-email"
          placeholder="Your email"
          autoComplete="email"
          name="email"
          className={errors.email && "validation-error"}
          defaultValue={registrationData?.username || ""}
          ref={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid",
            },
          })}
        />
        <label htmlFor="your-email">Your email*</label>
        {errors.email && (
          <p className="validation-error-message">{errors.email.message}</p>
        )}
      </div>

      <div className="input-text__group">
        <input
          type={showPass ? "text" : "password"}
          id="password"
          name="password"
          pattern={PasswordStrengthPattern}
          autoComplete="new-password"
          placeholder="Choose a password"
          className={errors.password && "validation-error"}
          defaultValue={registrationData?.password || ""}
          ref={register({
            required: "Required",
            pattern: {
              value: new RegExp(PasswordStrengthPattern),
              message: "Not strong enough",
            },
          })}
        />
        <label htmlFor="password">Choose a password*</label>
        <InputButtonShowHide mode={showPass ? 'show' : 'hide'} onClick={togglePass} />
        {errors.password && (
          <p className="validation-error-message">{errors.password.message}</p>
        )}

        <small id="password-help">
          A MINIMUM OF EIGHT CHARACTERS, AT LEAST ONE UPPERCASE LETTER, ONE
          LOWERCASE LETTER, ONE NUMBER AND ONE SPECIAL CHARACTER
        </small>
      </div>
    </div>
  );

  const renderProfileDetails = () => (
    <div className="account__inner">
      <h5 className="account__title">Profile DETAILS</h5>
      <div className="input-text__group">
        <input
          type="text"
          id="profile-name"
          placeholder="CHOOSE YOU PROFILENAME*"
          autoComplete="nickname"
          name="profileName"
          className={errors.profileName && "validation-error"}
          ref={register({
            required: "Required",
            minLength: { value: 3, message: "Min 3 characters" },
            maxLength: { value: 24, message: "Max 24 characters" },
          })}
        />
        <label htmlFor="profile-name">CHOOSE YOU PROFILENAME*</label>
        {errors.profileName && (
          <p className="validation-error-message">
            {errors.profileName.message}
          </p>
        )}

        {!isStreamerRequest && (
          <small id="profile-name-help">
            We suggest your Twitch or YouTube username to be easily found by
            friends.
          </small>
        )}
      </div>

      <div className="input-group-horizontal">
        <p>
          <b>Your birthday</b>

          <small>
            Enter a date (optional)
          </small>
        </p>
        <input
          type="date"
          placeholder="Date"
          name="birthday"
          max={getMaxDate()}
          ref={register()}
        />
      </div>

      <div className="input-switch__group">
        <input
          type="checkbox"
          name="age"
          id="age"
          value="age"
          ref={register({
            required: "Required",
          })}
        />
        <label htmlFor="age" className={errors.age && "validation-error"} />
        <p style={{ marginLeft: 12 }}>
          <b>Verify your age</b>
          <br />I AM AT LEAST 18 YEARS OLD
        </p>
      </div>

      {renderTermsAndConditionsInput()}
    </div>
  );

  const renderStreamerDetails = (termsAndConditions = false) => (
    <div className="account__inner">
      <h5 className="account__title">
        {hasSession
          ? `Hi ${authContext.user?.profileName}!`
          : "STREAMER DETAILS"}
      </h5>
      <p className="account__description">
        PLEASE PROVIDE ONE OR MORE OF THE LISTED STREAMING SOURCES. THESE SHOULD
        BE <b>ACTIVELY</b> USED. THIS WILL ALLOW US TO ASSESS YOUR VALIDITY FOR
        THE PLATFORM AND BEGIN THE VALIDATION PROCESS FOR YOUR STREAMER ACCOUNT
        ACCESS!
      </p>
      <div className="input-text__group">
        <input
          type="text"
          id="twitch-username"
          placeholder="YOUR TWITCH USERNAME"
          name="twitchUsername"
          className={
            (errors.twitchUsername || streamerError) && "validation-error"
          }
          ref={register({
            minLength: { value: 3, message: "Min 3 characters" },
            maxLength: { value: 50, message: "Max 50 characters" },
          })}
        />
        <label htmlFor="twitch-username">YOUR TWITCH USERNAME</label>
        <small id="twitch-username-help">
          SHOULD BE LIKE <b>SPINTWIX</b> FROM TWITCH.TV/<b>SPINTWIX</b>
        </small>
        {errors.twitchUsername && (
          <p className="validation-error-message">
            {errors.twitchUsername.message}
          </p>
        )}
      </div>

      <div className="input-text__group">
        <input
          type="text"
          id="youtube-username"
          placeholder="YOUR YOUTUBE USERNAME"
          name="youtubeUsername"
          className={
            (errors.youtubeUsername || streamerError) && "validation-error"
          }
          ref={register({
            minLength: { value: 3, message: "Min 3 characters" },
            maxLength: { value: 50, message: "Max 50 characters" },
          })}
        />
        <label htmlFor="youtube-username">YOUR YOUTUBE CHANNEL</label>
        <small id="youtube-username-help">
          SHOULD BE LIKE <b>SPINTWIX</b> FROM YOUTUBE.COM/
          <b>SPINTWIX</b> OR USE YOUR CHANNEL ID
        </small>
        {errors.youtubeUsername && (
          <p className="validation-error-message">
            {errors.youtubeUsername.message}
          </p>
        )}
      </div>

      <p className="account__description">
        ONCE YOUR INFORMATION IS SUBMITTED IT WILL BE EVALUATED AND WE WILL
        CONTACT YOU AS SOON AS POSSIBLE!
      </p>

      {termsAndConditions && renderTermsAndConditionsInput()}
    </div>
  );

  const renderDefaultForm = (children) => (
    <section className="account">
      <form className="account__form" onSubmit={handleSubmit(onSubmit)}>
        {children}
        <div className="account__inner">
          <div className="input-button__group">
            <button type="submit" className="btn btn--medium">
              {hasSession ? "Apply" : "Sign up"}
            </button>
            {!hasSession && <small id="button-help">*REQUIRED FIELD</small>}
          </div>
        </div>
      </form>

      {error && <AlertError error={error} />}
      {streamerError && <Alert style="danger" message={streamerError} />}
    </section>
  );

  const render = () => {
    if (hasSession && !authContext.isStreamer) {
      return (
        <>
          <Title title="BECOME A STREAMER" />
          {renderDefaultForm(<>{renderStreamerDetails(true)}</>)}
        </>
      );
    }

    if (!hasSession && isStreamerRequest) {
      return (
        <>
          <Title title="BECOME A STREAMER" />
          {renderDefaultForm(
            <>
              {renderAccountDetails()}
              {renderStreamerDetails()}
              {renderProfileDetails()}
            </>
          )}
        </>
      );
    }

    if (!hasSession) {
      return (
        <>
          <Title title="BECOME A MEMBER" />
          {renderDefaultForm(
            <>
              {renderAccountDetails()}
              {renderProfileDetails()}
            </>
          )}
        </>
      );
    }

    return <ErrorPage />;
  };

  return render();
};

export default RegisterPage;
