import React, { createContext, useCallback, useContext, useState } from "react";
import { AuthContext } from "../../context/auth.context";
import { LoginModal } from "./login-modal";

export interface LoginOffer {
  title: string;
  description: JSX.Element;
  url: string;
}

export interface LoginModalContextProps {
  openLoginModal: (onClose?: () => void, offer?: LoginOffer) => void;
}

export const LoginModalContext = createContext<LoginModalContextProps>(
  undefined as any
);

export const LoginModalContextProvider: React.FC = ({ children }) => {
  const [{ show, onClose, offer }, setState] = useState<{
    show: boolean;
    onClose?: () => void;
    offer?: LoginOffer
  }>({
    show: false,
    onClose: () => null,
  });

  const openLoginModal = useCallback((onClose?: () => void, offer?: LoginOffer) => {
    setState({
      show: true,
      onClose,
      offer
    });
  }, []);

  const closeLoginModal = useCallback(
    () =>
      setState({
        show: false,
        onClose: () => null,
      }),
    []
  );

  return (
    <LoginModalContext.Provider value={{ openLoginModal }}>
      {children}
      <LoginModal
        show={show}
        onClose={() => {
          onClose?.();
          closeLoginModal();
        }}
        offer={offer}
      />
    </LoginModalContext.Provider>
  );
};

export const useLoginModal = () => {
  const context = useContext(LoginModalContext);
  const { userId } = useContext(AuthContext);

  const runOrOpenLoginModal = (runnable: () => void) => {
    if (userId) {
      runnable();
    } else {
      context.openLoginModal();
    }
  };

  return {
    ...context,
    runOrOpenLoginModal,
  };
};
