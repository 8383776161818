import * as React from "react";
import { useState } from "react";
import { LayoutContext } from "../context/layout.context";
import Title from "../components/title";
import { useMutation } from "@apollo/client";
import Loading from "../components/loading";
import AlertError from "../components/alert-error";
import Alert from "../components/alert";
import { RouteProps } from "./index";
import { ResetPasswordMutation } from "../dtos/reset-password.dto";
import { AuthContext } from "../context/auth.context";
import { navigate } from "gatsby";
import { PasswordStrengthPattern } from "../dtos/user.dto";
import { SeoContext } from "../context/seo.context";

interface ResetPasswordProps extends RouteProps {
  code: string;
  legacy?: string;
}

const ResetPasswordPage: React.FC<ResetPasswordProps> = ({ code, legacy }) => {
  const seoContext = React.useContext(SeoContext);
  const authContext = React.useContext(AuthContext);
  const [password, setPassword] = useState(``);
  const [passwordConfirmation, setPasswordConfirmation] = useState(``);

  const [request, { loading, error, data }] = useMutation(
    ResetPasswordMutation
  );

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await request({
      variables: { password, passwordConfirmation, code },
    });

    await authContext.handleAuthResponse?.(response.data.resetPassword);
  };

  const isLegacy = () => {
    return legacy && legacy === "legacy";
  };

  const canSubmit = () => {
    return password && password === passwordConfirmation;
  };

  React.useEffect(() => {
    seoContext.setData({
      title: isLegacy()
        ? `Gain access to your account`
        : `Update your password`,
      description: isLegacy()
        ? `Update your password to gain access to your existing CasinoRing account`
        : `Update your password by entering a new one and confirming it afterwards`,
    });
  }, []);

  const form = (
    <>
      <div className="input-text__group">
        <input
          type="password"
          id="password"
          pattern={PasswordStrengthPattern}
          autoComplete="new-password"
          placeholder="Choose a password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <label htmlFor="password">Choose a password*</label>
        <small id="password-help">
          A MINIMUM OF EIGHT CHARACTERS, AT LEAST ONE UPPERCASE LETTER, ONE
          LOWERCASE LETTER, ONE NUMBER AND ONE SPECIAL CHARACTER
        </small>
      </div>

      <div className="input-text__group">
        <input
          type="password"
          id="password-confirm"
          autoComplete="new-password"
          placeholder="Confirm password"
          onChange={(event) => setPasswordConfirmation(event.target.value)}
        />
        <label htmlFor="password-confirm">Confirm password*</label>
      </div>

      <div className="input-button__group">
        <button
          type="submit"
          className="btn btn--medium"
          disabled={!canSubmit()}
        >
          Update
        </button>
        <small id="button-help">*REQUIRED FIELD</small>
      </div>
    </>
  );

  return (
    <>
      <Title
        title={
          isLegacy()
            ? "HEY! WELCOME TO OUR BRAND NEW PLATFORM!"
            : "UPDATE YOUR PASSWORD"
        }
      />

      <section className="account">
        <form className="account__form" onSubmit={(event) => submit(event)}>
          <div className="account__inner">
            <p>
              {isLegacy()
                ? `Update your password to gain access to your existing CasinoRing account. You can do this by creating a new secure password and confirming it. Afterwards you can click the update button to submit your changes.`
                : `Please fill in your new password and confirm your input. After this you can click the update button to confirm your changes.`}
            </p>

            {loading && <Loading />}
            {data && <Alert message={`Password updated`} style="info" />}
            {error && <AlertError error={error} />}
            {!loading && !data && form}
          </div>
        </form>
      </section>
    </>
  );
};

export default ResetPasswordPage;
