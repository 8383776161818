import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import { useMutation } from "@apollo/client";
import { AuthContext, IAuthWithJwtResponse } from "../context/auth.context";
import { apiBaseUrl } from "../utils/api";
import { loginMutation } from "../dtos/user.dto";
import Loading from "./loading";
import AlertError from "./alert-error";
import { useLoginModal } from "./modals/login-modal.context";

const ToolbarLogin: React.FC = () => {
  const authContext = useContext(AuthContext);
  const { openLoginModal } = useLoginModal();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");

  const [login, { error, loading }] = useMutation<{
    login: IAuthWithJwtResponse;
  }>(loginMutation);

  const canSubmit = () => {
    return !loading && identifier && password;
  };

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { data } = await login({
      variables: {
        identifier,
        password,
      },
    });

    if (data?.login) {
      authContext.setAuth?.(data.login);
    }
  };

  const getAuthUrl = (type: string) => {
    return `${apiBaseUrl()}/connect/${type}`;
  };

  if (authContext.userId) return <></>;

  return (
    <>
      <section className="login d-block d-sm-block d-md-none d-lg-none d-xl-none no-animation">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <a
            onClick={() => openLoginModal()}
            className="btn btn--medium"
            style={{
              flex: 4,
              marginRight: 8,
              marginLeft: 38,
            }}
          >
            Login
          </a>
          <a
            href={getAuthUrl("twitch")}
            className="btn btn--twitch"
            style={{ flex: 2, padding: 0, height: 48, marginRight: 8 }}
          >
            <img
              style={{
                margin: "auto",
                height: "100%",
                padding: 8,
                width: "auto",
              }}
              src="/images/twitch-white.svg"
            />
          </a>
          <a
            href={getAuthUrl("google")}
            className="btn btn--youtube"
            style={{ flex: 2, padding: 0, height: 48 }}
          >
            <img
              style={{
                margin: "auto",
                height: "100%",
                padding: 10,
                width: "auto",
              }}
              src="/images/youtube-white.svg"
            />
          </a>
        </div>
      </section>

      <section className="login d-none d-sm-none d-md-block d-lg-block d-xl-block no-animation">
        <form
          style={{ display: "flex", flexDirection: "row" }}
          className="login__form form-inline"
          onSubmit={(event) => submit(event)}
        >
          <div className="input-text__group" style={{ flex: 3 }}>
            <input
              type="text"
              style={{ width: "100%" }}
              id="toolbar-email"
              className="form-control"
              placeholder="E-mail"
              autoComplete="email"
              onChange={(event) => setIdentifier(event.target.value)}
            />
            <label
              style={{
                whiteSpace: "pre",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              htmlFor="toolbar-email"
            >
              E-mail
            </label>
          </div>

          <div className="input-text__group" style={{ flex: 3 }}>
            <input
              style={{ width: "100%" }}
              type="password"
              id="toolbar-password"
              className="form-control"
              placeholder="password"
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <label
              style={{
                whiteSpace: "pre",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              htmlFor="toolbar-password"
            >
              Password
            </label>
          </div>

          <div className="input-text__group" style={{ flex: 1 }}>
            <button
              style={{ width: "100%" }}
              type="submit"
              className="btn btn--input"
              disabled={!canSubmit()}
            >
              LOGIN
            </button>
          </div>

          <a
            href={getAuthUrl("twitch")}
            className="btn btn--twitch"
            style={{ flex: 1, height: 44, padding: 0, marginRight: 6 }}
          >
            <img
              style={{
                margin: "auto",
                height: "100%",
                padding: 8,
                width: "auto",
              }}
              src="/images/twitch-white.svg"
            />
          </a>

          <a
            href={getAuthUrl("google")}
            className="btn btn--youtube"
            style={{ flex: 1, height: 44, padding: 0 }}
          >
            <img
              style={{
                margin: "auto",
                height: "100%",
                padding: 10,
                width: "auto",
              }}
              src="/images/youtube-white.svg"
            />
          </a>
        </form>

        <div
          className="login__extra"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Link to="/forgot-password/" className="login__extra-link">
            FORGOT PASSWORD?
          </Link>
          <Link to="/register/" className="login__extra-link">
            JOIN NOW
          </Link>
        </div>

        {error && <AlertError error={error} />}
        {loading && <Loading />}
      </section>
    </>
  );
};

export default ToolbarLogin;
