import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

export interface Answer {
  question: string;
  value: string;
}

export type PollStepperState = {
  answers: Answer[];
  submit: (step: number, input: Answer) => void;
  format: () => string;
};

const PollStepperContext = createContext<PollStepperState>({
  answers: [],
  submit: () => {},
  format: () => "",
});

export const PollStepperProvider = ({ children }: PropsWithChildren<{}>) => {
  const [answers, setAnswer] = useState<Answer[]>([]);

  const submit = (step: number, input: Answer) => {
    answers[step] = input;
    setAnswer([...answers]);
  };

  const format = () => {
    let content = "";

    for (const answer of answers) {
      content += `<p><b>${answer.question}</b></br>${answer.value}</p>`;
    }

    return content;
  };

  return (
    <PollStepperContext.Provider value={{ answers, submit, format }}>
      {children}
    </PollStepperContext.Provider>
  );
};

export const usePollStepperContext = () => useContext(PollStepperContext);
