import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { RouteProps } from ".";
import { SinglePageDto } from "../dtos/single-page.dto";
import { SinglePageTermsAndConditionsFields } from "../dtos/single-page-terms-and-conditions.dto";
import { SinglePagePrivacyStatementFields } from "../dtos/single-page-privacy-statement";
import removeMd from "remove-markdown";
import {
  ConcatSinglePageDto,
  SinglePageContactFields,
  SinglePageContactInfoDto,
  SinglePageContactMailDto,
} from "../dtos/single-page-contact.dto";
import { SinglePageCookiePolicyFields } from "../dtos/single-page-cookie-policy";
import Alert, { somethingWentWrong } from "../components/alert";
import Loading from "../components/loading";
import WysiwygContent from "../components/wysiwyg-content";
import { SinglePageBecomePartnerFields } from "../dtos/single-page-become-partner.dto";
import SingleContentBecomePartnerForm from "../components/single-content-become-partner-contact";
import SingleContentBecomePartnerBanner from "../components/single-content-become-partner-banner";
import { SinglePagePartnershipInfoFields } from "../dtos/single-page-partnership-info.dto";
import { SeoContext } from "../context/seo.context";
import SingleContentCookieScriptDeclaration from "../components/single-content-cookiescript-declaration";

interface SingleContentPage extends RouteProps {
  type: SinglePageDto;
}

interface TypeMappingInterface {
  [key: string]: {
    query: string;
    fields: string;
  };
}

const mapping: TypeMappingInterface = {
  termsAndConditions: {
    query: `termsAndCondition`,
    fields: SinglePageTermsAndConditionsFields,
  },
  privacyStatement: {
    query: `privacyStatement`,
    fields: SinglePagePrivacyStatementFields,
  },
  cookiePolicy: {
    query: `cookiePolicy`,
    fields: SinglePageCookiePolicyFields,
  },
  contact: {
    query: `contact`,
    fields: SinglePageContactFields,
  },
  becomePartner: {
    query: `becomePartner`,
    fields: SinglePageBecomePartnerFields,
  },
  partnershipInfo: {
    query: `partnershipInfo`,
    fields: SinglePagePartnershipInfoFields,
  },
};

const generateQuery = (query: string, fields: string) => {
  return gql`
    query SingleContentQuery {
      ${query} {
      ${fields}
    }
  }`;
};

const SingleContentPage: React.FC<SingleContentPage> = ({ type }) => {
  const seoContext = useContext(SeoContext);
  const queryType = mapping[type].query;

  const { loading, data, error } = useQuery<{
    [key: string]: ConcatSinglePageDto;
  }>(generateQuery(queryType, mapping[type].fields));

  const metaDescription: string =
    type === SinglePageDto.TERMS_AND_CONDITIONS ||
    type === SinglePageDto.BECOME_PARTNER ||
    type === SinglePageDto.PARTNERSHIP_INFO
      ? `intro`
      : `content`;

  useEffect(() => {
    if (data) {
      seoContext.setData({
        title: data?.[queryType]?.title,
        description: data?.[queryType]?.[metaDescription]
          ? removeMd(data?.[queryType]?.[metaDescription])
          : ``,
      });
    }
  }, [data]);

  if (loading) return <Loading />;
  if (error) return <Alert message={somethingWentWrong} style="danger" />;

  return (
    <>
      <section className="page-content page-content-single">
        <h3 className="page-content__extratitle">{data?.[queryType]?.title}</h3>
        <div className="page-content__text">
          {data?.[queryType]?.intro && (
            <h2 className="page-content__subtitle">
              {data?.[queryType]?.intro}
            </h2>
          )}
          <WysiwygContent html={data?.[queryType]?.content} />

          {(data?.[queryType]?.mail || data?.[queryType]?.info) && (
            <section className="contact">
              <div className="row">
                {data?.[queryType]?.info && (
                  <div className="col-12 col-lg-6">
                    <h3>Information</h3>
                    <div className="row">
                      {data?.[queryType]?.info.map(
                        (info: SinglePageContactInfoDto) => (
                          <div className="col-12">
                            <p className="contact-option">{info.content}</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                <div className="contact-spacer" />
                {data?.[queryType]?.mail && (
                  <div className="col-12 col-lg-6">
                    <h3>Mail</h3>
                    <div className="row">
                      {data?.[queryType]?.mail.map(
                        (mail: SinglePageContactMailDto) => (
                          <div className="col-12">
                            <a
                              className="contact-option"
                              href={`mailto:${mail.address}`}
                            >
                              <i className="icon-comment" /> {mail.title}
                            </a>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
        {type === SinglePageDto.CONTACT && <SingleContentBecomePartnerBanner />}
        {type === SinglePageDto.COOKIE_POLICY && (
          <SingleContentCookieScriptDeclaration />
        )}
        {type === SinglePageDto.BECOME_PARTNER && (
          <SingleContentBecomePartnerForm />
        )}
      </section>
    </>
  );
};

export default SingleContentPage;
