export interface SinglePageTermsAndConditionsDto {
  title: string;
  intro: string;
  content: string;
}

export const SinglePageTermsAndConditionsFields = `
  title
  intro
  content
`;
