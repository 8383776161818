import { gql } from "@apollo/client";
import { IOwnableDto } from "../context/permission.context";
import { userFields } from "./user.dto";

export interface CommentDto extends IOwnableDto {
  id?: string;
  comment?: CommentDto;
  content?: string;
  comments?: CommentDto[];
  createdAt?: Date;
}

export const commentFields = `
  id
  content
  comment {
    id
  }
  owner {
    ${userFields}
  }
  partner {
    id
  }
  createdAt
`;

export const nestedCommentFields = () => `
  ${commentFields}
  comments(limit: 1, sort:"createdAt:ASC") { ${commentFields} }
`;

export const deleteCommentMutation = gql`
  mutation deleteCommentMutation($id: ID!) {
    deleteComment(input: { where: { id: $id } }) {
      comment {
        id
      }
    }
  }
`;
