import { UserDto } from "./user.dto";

export interface CreditableDto {
  enabled: boolean;
  balance: number;
}

export interface Dto {
  id?: string;
  owner?: UserDto;
  regularPost?: CreditableDto;
  pinnedPost?: CreditableDto;
  banner?: CreditableDto;
  topBanner?: CreditableDto;
}

export enum CreditableType {
  REGULAR_POST = <any>"regularPost",
  PINNED_POST = <any>"pinnedPost",
  BANNER = <any>"banner",
  TOP_BANNER = <any>"topBanner",
}

export enum CreditableMutationTypes {
  REGULAR_POST = "RegularPost",
  PINNED_POST = "PinnedPost",
  BANNER = "Banner",
  TOP_BANNER = "TopBanner",
}

export const UrlRegex =
  /^(http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
