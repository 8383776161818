export const prettyCounter = (value: number) => {
  const abbrevs = ["", "k", "m"];

  let steps = 0,
    stepSize = 1000;

  while (value >= stepSize) {
    value = value / stepSize;
    steps++;
  }

  return `${steps > 0 ? value.toFixed(1) : value}${abbrevs[steps]}`;
};
