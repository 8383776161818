import React, { useContext, useEffect, useMemo } from "react";
import ReactMde from "react-mde";
import { converter } from "../../../utils/showdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { AuthContext } from "../../../context/auth.context";
import { useLoginModal } from "../../modals/login-modal.context";
import { useMutation, useQuery } from "@apollo/client";
import {
  demoGamePersonalReviewQuery,
  DemoGameReview,
  demoGameReviewsMutation
} from "../../../dtos/demo-games.dto";
import { first } from "lodash";
import { useOpenSnackbar } from "../../../utils/snackbar";
import { SnackbarEnum } from "../../../dtos/snackbar.dto";
import { DemoGamesReviewRating } from "./rating";

interface Review {
  content: string;
}

interface Props {
  gameId: string;
}

export const DemoGamesReviewForm = ({ gameId }: Props) => {
  const { userId } = useContext(AuthContext);
  const openSnackbar = useOpenSnackbar();
  const { openLoginModal } = useLoginModal();

  const [mutate] = useMutation(demoGameReviewsMutation)

  const { data, refetch } = useQuery<{
    reviews: DemoGameReview[];
  }>(demoGamePersonalReviewQuery, {
    variables: { gameId, userId },
    skip: !userId,
  });

  const { id, content } = first(data?.reviews) || {};

  const { values, errors, setFieldValue, submitForm } = useFormik<Review>({
    initialValues: {
      content: ""
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      content: Yup.string()
        .min(50, "Content should be at least 50 characters")
        .max(2500, "Content cannot exceed 2500 characters")
        .required("Content is required"),
    }),
    onSubmit: async (values) => {
      const { errors } = await mutate({
        variables: {
          gameId,
          content: values.content,
        },
      })

      if (errors) return openSnackbar({ status: SnackbarEnum.ERROR, content: `Could not ${!!content ? 'update' : 'submit'} your review` });
      openSnackbar({ content: `${!!content ? 'Updated' : 'Submitted'} your review` });
      await refetch()
    },
  });

  useEffect(() => {
    if (content) setFieldValue('content', content)
  },[content])

  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write"
  );

  const review = useMemo(() => id ?
    <div className="community-rating">
      <p>Rating by community</p>
      <DemoGamesReviewRating gameId={gameId} reviewId={id} readOnly />
    </div> : null, [id])

  const controls = useMemo(() => (
    <>
      <div className={classNames({ 'content-error': !!errors.content })}>
        <ReactMde
          value={values.content}
          onChange={(value) => setFieldValue('content', value)}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          toolbarCommands={[["bold", "italic", "strikethrough"]]}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(converter.makeHtml(markdown))
          }
          maxEditorHeight={100}
        />
      </div>

      {!!errors.content && <p className="form-error">{errors.content}</p>}

      <div className="controls">
        {review}
        <button className="btn" onClick={() => submitForm()}>
          {!!content ? 'Update' : 'Submit'}
        </button>
      </div>
    </>
  ), [content, values.content, selectedTab, errors])

  const notice = useMemo(() => (
    <div className="controls">
      <p>You have to be a member, in order to write a review</p>
      <button className="btn" onClick={() => openLoginModal()}>
        Login or join now
      </button>
    </div>
  ), [])

  return <div className="form">
    <h3>{!!content ? 'Edit' : 'Write'} your review</h3>
    <p>Share your thoughts on this demo game below. You can also read about other members experiences.</p>

    {userId && controls}
    {!userId && notice}
  </div>
}