import React, { useState, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Controller } from "swiper/core";
import "swiper/swiper.scss";
import { MediaDto, mediaFields } from "../../dtos/media.dto";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import classNames from "classnames";

SwiperCore.use([Autoplay, Controller]);

const query = gql`
    query StreamersLobbyTopBanners {
        streamersLobbyBanners(
            sort: "createdAt:DESC"
            where: {
              type: "top"
            }
        ) {
            id
            url
            image {
                ${mediaFields}
            }
        }
    }
`;

interface Slide {
  id: string;
  url: string;
  image: MediaDto;
}

const NavButton = ({ placement, onClick }) => {
  const Icon = placement === "left" ? FaChevronLeft : FaChevronRight;

  return (
    <div
      className={classNames("navigation-button", `${placement}`)}
      onClick={onClick}
    >
      <Icon />
    </div>
  );
};

export const StreamersLobbyTopSlider = () => {
  const { data } = useQuery<{ streamersLobbyBanners: Slide[] }>(query);
  const slides = data?.streamersLobbyBanners || []
  const key = useMemo(() => Math.random(), [slides.length]);
  const [controller, setController] = useState<SwiperCore>();

  if (slides.length === 0) return null;

  return (
    <div
      className="top-banner-slider banner banner--homepage"
      data-aos="fade-up"
    >
      <Swiper
        key={key}
        spaceBetween={25}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        onSwiper={setController}
        enabled={slides.length > 1}
        loop
        grabCursor
        // autoHeight
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <a className="slide-content" href={slide.url!} target="_blank">
              <img
                src={slide.image.formats?.large?.url!}
                style={{ width: "100%", userSelect: "none" }}
                draggable={false}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      {slides.length > 1 && (
        <>
          <NavButton placement="left" onClick={() => controller?.slidePrev()} />
          <NavButton
            placement="right"
            onClick={() => controller?.slideNext()}
          />
        </>
      )}
    </div>
  );
}