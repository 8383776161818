import React, { useContext } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { useApolloClient } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import { sumBy } from "lodash";
import { RouteProps } from "..";
import {
  BlocBuilder,
  BlocContext,
  BlocProvider,
} from "../../bloc/bloc.context";
import Alert, { somethingWentWrong } from "../../components/alert";
import Loading from "../../components/loading";
import MediaImage from "../../components/media-image";
import { useConfirmModal } from "../../components/modals/confirm-modal.context";
import Title from "../../components/title";
import { AuthContext } from "../../context/auth.context";
import { PermissionContext } from "../../context/permission.context";
import { BonushuntBloc } from "./bonushunt.bloc";
import { BonushuntCreateComponent } from "./bonushunt.create";
import { BonushuntReadDto } from "./bonushunt.dto";
import {
  BonushuntAddBonusEvent,
  BonushuntEditEvent,
  BonushuntLoadRequestEvent,
  BonushuntRemoveBonusEvent,
} from "./bonushunt.event";
import { BonushuntFieldComponent } from "./bonushunt.field";
import {
  BonushuntInitialState,
  BonushuntLoadFailureState,
  BonushuntLoadSuccessState,
  BonushuntState,
} from "./bonushunt.state";

const useStyles = makeStyles({
  root: {
    padding: 12,
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  headerMember: {
    flex: 1,
  },
  headerMemberAvatar: {
    overflow: "hidden",
    width: 128,
    height: 128,
    display: "block",
    position: "relative",
  },
  headerDetails: {
    flex: 2,
  },
  detailsText: {},
});

export interface BonushuntPageProps extends RouteProps {
  slug?: string;
}

const BonushuntPage: React.FC<BonushuntPageProps> = ({ slug }) => {
  const classes = useStyles();
  const apollo = useApolloClient();
  const authContext = useContext(AuthContext);
  const permissionContext = useContext(PermissionContext);
  const { openConfirmModal } = useConfirmModal();

  const round = (value: number, decimals = 2): string => {
    return value.toFixed(decimals);
  };

  const calculateAverageBetSize = (hunt: BonushuntReadDto): number => {
    const bonuses = hunt.bonuses || [];
    const total = sumBy(bonuses, (bonus) => bonus.betSize as number);

    return bonuses.length > 0 ? total / bonuses.length : 0;
  };

  const calculateAverageRequiredBonuses = (hunt: BonushuntReadDto): number => {
    const bonuses = hunt.bonuses || [];

    return (hunt.startingBalance as number) / (bonuses.length || 1);
  };

  function* mapStateToComponents(
    context: BlocContext<BonushuntBloc>,
    state: BonushuntState
  ): Iterable<React.ReactNode> {
    if (state instanceof BonushuntInitialState) {
      yield <Loading key="hunt-loading" />;
    }

    if (state instanceof BonushuntLoadSuccessState) {
      const canEdit = permissionContext.canUser(state.props.user, "update");

      yield (
        <Title
          key="hunt-title"
          title={`Bonushunt ${state.props.user?.profileName}`}
        />
      );

      yield (
        <section
          key="hunt-details"
          className="page-content page-content--background"
        >
          <div className="page-content__text">
            <div className={classes.header}>
              <div className={classes.headerMember}>
                <div className={classes.headerMemberAvatar}>
                  <Link to={`/profile/${state.props.user.id}`}>
                    <MediaImage
                      className="clickable"
                      src={state.props.user?.avatar}
                      format="small"
                    />
                  </Link>
                </div>
              </div>
              <div className={classes.headerDetails}>
                {canEdit ? (
                  <BonushuntFieldComponent
                    type="date"
                    placeholder="Date"
                    model={
                      state.props.user.bonushuntPage?.date?.toString() as string
                    }
                    onBlur={(value): BonushuntBloc =>
                      context.bloc.add(
                        new BonushuntEditEvent({
                          data: {
                            date: value,
                          },
                        })
                      )
                    }
                  />
                ) : (
                  <h2>{state.props.user.bonushuntPage?.date}</h2>
                )}

                {state.props.user?.twitchUsername && (
                  <div style={{ marginTop: 16 }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://twitch.tv/${state.props.user?.twitchUsername}`}
                      className="clickable"
                      style={{ marginTop: 16 }}
                    >
                      <i className="icon-twitch"></i> &nbsp;{" "}
                      {state.props.user?.twitchUsername}
                    </a>
                  </div>
                )}

                {state.props.user?.youtubeUsername && (
                  <div style={{ marginTop: 8 }}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://youtube.com/${state.props.user?.twitchUsername}`}
                      className="clickable"
                    >
                      <i className="icon-youtube"></i> &nbsp;{" "}
                      {state.props.user?.youtubeUsername}
                    </a>
                  </div>
                )}

                {canEdit ? (
                  <>
                    <BonushuntFieldComponent
                      style={{ marginTop: 16 }}
                      type="text"
                      placeholder="Casino display name"
                      model={
                        state.props.user.bonushuntPage?.casinoName as string
                      }
                      onBlur={(value): BonushuntBloc =>
                        context.bloc.add(
                          new BonushuntEditEvent({
                            data: {
                              casinoName: value,
                            },
                          })
                        )
                      }
                    />

                    <BonushuntFieldComponent
                      type="text"
                      placeholder="Casino link"
                      model={
                        state.props.user.bonushuntPage?.casinoUrl as string
                      }
                      onBlur={(value): BonushuntBloc =>
                        context.bloc.add(
                          new BonushuntEditEvent({
                            data: {
                              casinoUrl: value,
                            },
                          })
                        )
                      }
                    />
                  </>
                ) : (
                  <div style={{ marginTop: 12 }}>
                    <a
                      style={{ height: 32 }}
                      className="clickable"
                      target="_blank"
                      rel="noreferrer"
                      href={state.props.user.bonushuntPage?.casinoUrl}
                    >
                      <strong>Casino: </strong>
                      {state.props.user.bonushuntPage?.casinoName}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      );

      yield (
        <section
          key="hunt-bonuses"
          className="page-content page-content--background"
        >
          <div className="page-content__text">
            <div style={{ paddingTop: 0, paddingLeft: 12, paddingRight: 12 }}>
              <table style={{ marginTop: 0, marginBottom: 32 }}>
                <tbody>
                  <tr>
                    <td width="50%">Starting balance</td>
                    {canEdit ? (
                      <BonushuntFieldComponent
                        type="number"
                        className="with-design"
                        placeholder="Starting balance"
                        model={
                          state.props.user.bonushuntPage
                            ?.startingBalance as number
                        }
                        onBlur={(value): BonushuntBloc =>
                          context.bloc.add(
                            new BonushuntEditEvent({
                              data: {
                                startingBalance: value,
                              },
                            })
                          )
                        }
                      />
                    ) : (
                      <td width="50%" align="left">
                        € {state.props.user.bonushuntPage?.startingBalance}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td>Average bet size</td>
                    <td align="left">
                      €{" "}
                      {round(
                        calculateAverageBetSize(
                          state.props.user.bonushuntPage as BonushuntReadDto
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Average per bonus to break even</td>
                    <td align="left">
                      €{" "}
                      {round(
                        calculateAverageRequiredBonuses(
                          state.props.user.bonushuntPage as BonushuntReadDto
                        )
                      )}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="page-content__inner">
              <h3>
                Bonuses ({state.props.user.bonushuntPage?.bonuses?.length || 0})
                {canEdit && (
                  <button
                    style={{
                      marginLeft: 16,
                    }}
                    className={classNames("btn", {
                      disabled:
                        state.props.user.bonushuntPage?.bonuses?.length === 0,
                    })}
                    disabled={
                      state.props.user.bonushuntPage?.bonuses?.length === 0
                    }
                    onClick={() =>
                      openConfirmModal({
                        message: "Are you sure you want to delete all entries?",
                        onClose: (result) =>
                          result &&
                          context.bloc.add(
                            new BonushuntEditEvent({
                              data: {
                                bonuses: [],
                              },
                            })
                          ),
                      })
                    }
                  >
                    Clear
                  </button>
                )}
              </h3>
              <table>
                <tbody>
                  {state.props.user.bonushuntPage?.bonuses?.map(
                    (bonus, index) => (
                      <tr key={index}>
                        <td>{bonus.slot?.name}</td>
                        <td>
                          € {bonus.betSize}{" "}
                          {canEdit && (
                            <>
                              &bull;{" "}
                              <div
                                onClick={(): BonushuntBloc =>
                                  context.bloc.add(
                                    new BonushuntRemoveBonusEvent({ index })
                                  )
                                }
                                style={{ display: "inline" }}
                                className="clickable"
                              >
                                Delete
                              </div>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                  {canEdit && (
                    <BonushuntCreateComponent
                      onAdd={(bonus): BonushuntBloc =>
                        context.bloc.add(new BonushuntAddBonusEvent({ bonus }))
                      }
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      );
    }

    if (state instanceof BonushuntLoadFailureState) {
      yield (
        <Alert key="hunt-error" message={somethingWentWrong} style="danger" />
      );
    }
  }

  return (
    <BlocProvider<BonushuntBloc>
      create={(): BonushuntBloc =>
        new BonushuntBloc(apollo).add(
          new BonushuntLoadRequestEvent({ slug: slug as string })
        )
      }
    >
      <BlocBuilder<BonushuntBloc, BonushuntState>
        builder={(context, state): JSX.Element => (
          <>{Array.from(mapStateToComponents(context, state))}</>
        )}
      />
    </BlocProvider>
  );
};

export default BonushuntPage;
