import React from "react";
import CountUp from "react-countup";

interface BalanceProps {
  balance?: number;
}

const Balance: React.FC<BalanceProps> = ({ balance }) => {
  return (
    <div className="account-balance" style={{ marginTop: 0 }}>
      <div className="account-balance__title">CASINORING BALANCE</div>
      <div className="account-balance__count count">
        <CountUp end={balance || 0} duration={1.5} />
      </div>
      <div className="account-balance__points">POINTS</div>
    </div>
  );
};

export default Balance;
