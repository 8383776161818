import { gql } from "@apollo/client";
import { IOwnableDto } from "../context/permission.context";
import { GiveawayDto } from "./giveaway.dto";
import { PostDto } from "./post.dto";

export interface GiveawayAnswerDto extends IOwnableDto {
  id?: string;
  giveaway?: GiveawayDto;
  post?: PostDto;
  value?: number;
  winnerSeed?: number;
  answers?: string;
}

export const giveawayAnswerFields = `
  id
  owner { 
    id 
    profileName
  }
  post {
    id
    multiplier
  }
  winnerSeed
  value
  answers
`;

export const createGiveawayAnswerMutation = gql`
  mutation createGiveawayAnswerMutation($input: GiveawayAnswerInput!) {
    createGiveawayAnswer(input: { data: $input }) {
      giveawayAnswer {
        ${giveawayAnswerFields}
      }
    }
  }
`;

export const deleteGiveawayAnswerMutation = gql`
  mutation deleteGiveawayAnswerMutation($id: ID!) {
    deleteGiveawayAnswer(input: { where: { id: $id } }) {
      giveawayAnswer {
        id
      }
    }
  }
`;
