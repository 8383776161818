import React from "react";
import { Modal } from "react-bootstrap";
import WysiwygContent from "../../components/wysiwyg-content";
import { useOnBack } from "../../utils/history";

export interface GiveawayRulesModalProps {
  show: boolean;
  rules?: string;
  onClose: () => void;
}

export const GiveawayRulesModal: React.FC<GiveawayRulesModalProps> = ({
  show,
  rules,
  onClose,
}) => {
  useOnBack(onClose);

  return (
    <Modal show={show} onHide={onClose} dialogClassName="modal-dialog-centered">
      <div className="modal-header">
        <h5 className="modal-title">RULES &amp; CONDITIONS</h5>
        <button type="button" className="modal-close" onClick={onClose}>
          close <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <WysiwygContent html={rules} />
      </div>

      <div className="modal-footer">
        <button
          style={{ float: "right", marginRight: 16 }}
          className="btn btn btn--medium"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
