import { DependencyList, useEffect } from "react";
import { generateVersion } from "./generate-version";

export const useOnBack = (
  onBack: () => void,
  deps?: DependencyList,
  pushHistory = true,
  routeTitle = "Modal"
) => {
  useEffect(() => {
    pushHistory && history.pushState(generateVersion(), routeTitle);
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", onBack, false);

    return () => window.removeEventListener("popstate", onBack);
  }, deps);
};
