import React, { useContext, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { useMutation, useQuery } from "@apollo/client";
import {
  demoGameRatingsAverageQuery,
  demoGameRatingsMutation,
} from "../../../dtos/demo-games.dto";
import { AuthContext } from "../../../context/auth.context";
import { useLoginModal } from "../../modals/login-modal.context";
import { useOpenSnackbar } from "../../../utils/snackbar";
import { SnackbarEnum } from "../../../dtos/snackbar.dto";
import { generateVersion } from "../../../utils/generate-version";

interface Props {
  gameId: string;
  reviewId: string;
  readOnly?: boolean
}

export const DemoGamesReviewRating = ({ gameId, reviewId, readOnly }: Props) => {
  const { userId } = useContext(AuthContext);
  const { openLoginModal } = useLoginModal();
  const openSnackbar = useOpenSnackbar();
  const [version, setVersion] = useState<string>(generateVersion())

  const { data, refetch } = useQuery<{ demoGameRatingAverage: number }>(
    demoGameRatingsAverageQuery,
    {
      variables: { gameId, reviewId },
    }
  );

  const [mutate] = useMutation(demoGameRatingsMutation);

  const handler = async (value: number) => {
    if (!userId) {
      setVersion(generateVersion())
      return openLoginModal()
    }

    const { errors } = await mutate({
      variables: {
        gameId,
        reviewId,
        value,
      },
    })

    if (errors) return openSnackbar({ status: SnackbarEnum.ERROR, content: `Could not set your rating` });
    openSnackbar({ content: `Your rating has been set` });
    await refetch()
  }

  return (
    <div className="rating">
      <Rating
        key={version}
        onClick={handler}
        ratingValue={data?.demoGameRatingAverage || 0}
        size={25}
        fillColor="#008ca5"
        emptyColor="#bdbdbd"
        readonly={readOnly}
      />
    </div>
  );
};
