import React, { FC, useEffect, useState } from "react";

enum Sorting {
  CREATION_DATE_ASCENDING = "createdAt:ASC",
  CREATION_DATE_DESCENDING = "createdAt:DESC",
}

export interface QueryFilter {
  dateFrom?: string;
  dateTill?: string;
  sort?: string;
  videoOnly?: boolean;
}

interface Properties {
  onUpdate: (filter: QueryFilter) => void;
}

export const ModeratorFilters: FC<Properties> = ({ onUpdate }) => {
  const [filter, setFilter] = useState<QueryFilter>();

  const update = (key: string, value?: string | boolean) => {
    setFilter({
      ...filter,
      [key]: value ? value : undefined,
    });
  };

  useEffect(() => {
    if (filter) onUpdate(filter);
  }, [filter]);

  return (
    <div
      className="form-inline moderator-filters"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <label htmlFor="members">DATE (FROM)</label>
        <input
          type="date"
          placeholder="Date"
          onChange={(event) => update("dateFrom", event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="members">DATE (TILL)</label>
        <input
          type="date"
          placeholder="Date"
          onChange={(event) => update("dateTill", event.target.value)}
        />
      </div>

      <div className="input-switch__group">
        <input
          type="checkbox"
          id="has-video"
          onChange={(event) => update("videoOnly", event.target.checked)}
        />
        <label htmlFor="has-video" />
        <p style={{ marginLeft: 12 }}>
          <b>Video</b>
          <br />
          Whether the post has a video attached or not
        </p>
      </div>

      <div className="input-group">
        <select
          name="sorting"
          id="sorting"
          placeholder="Sorting"
          onChange={(event) => update("sort", event.target.value)}
        >
          <option value={""}>Standard</option>
          <option value={Sorting.CREATION_DATE_ASCENDING}>
            Creation date (ascending)
          </option>
          <option value={Sorting.CREATION_DATE_DESCENDING}>
            Creation date (descending)
          </option>
        </select>
      </div>
    </div>
  );
};
