import React, { useEffect, useState } from "react";
import { StreamerLiveStreamOfferDto as Dto } from "../../dtos/user.dto";
import ReactMde from "react-mde";
import { Converter } from "showdown";

interface StreamerBonusOfferContentProps {
  offer: Dto;
  converter: Converter;
  toolbar: string[][];
  onUpdate: (offer: Dto) => void | Promise<void>;
  onRemove: () => void | Promise<void>;
}

const StreamerLiveStreamOffer: React.FC<StreamerBonusOfferContentProps> = ({
  offer,
  toolbar,
  converter,
  onUpdate,
  onRemove,
}) => {
  const [tab, setTab] = useState<"write" | "preview">(
    offer.id ? `preview` : "write"
  );
  const [data, setData] = useState<Dto>(offer);

  useEffect(() => {
    if (data === offer) return;
    onUpdate?.(data);
  }, [data]);

  return (
    <>
      <div
        className="input-text__group"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <h6 style={{ flex: 1, fontSize: 15 }}>{data?.title}</h6>

        <a
          style={{
            fontSize: 12.5,
            color: "#008ca5",
            textDecoration: "underline",
          }}
          onClick={() => onRemove()}
        >
          Remove
        </a>
      </div>

      <div className="input-textarea__group">
        <ReactMde
          value={data?.content}
          onChange={(value): void => {
            setData({ ...data, content: value });
          }}
          selectedTab={tab}
          onTabChange={setTab}
          toolbarCommands={toolbar}
          minEditorHeight={100}
          minPreviewHeight={100}
          generateMarkdownPreview={(markdown): Promise<string> =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      </div>
    </>
  );
};

export default StreamerLiveStreamOffer;
