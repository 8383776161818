import React from "react";
import { DemoGameCTA } from "../../dtos/demo-games.dto";

interface Props {
  data: DemoGameCTA;
}

export const DemoGamesCta = ({ data }: Props) => {
  const { title, content, button, url } = data;

  return (
    <section className="banner-container">
      <div className="banner-container_content">
        {title && <h2>{title}</h2>}
        {content && <p>{content}</p>}
      </div>
      {url && button && (
        <div className="banner-container_action">
          <a href={url} className="btn">
            {button}
          </a>
        </div>
      )}
    </section>
  );
};
