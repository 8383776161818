import React, {useEffect, useState} from "react";
import {Dropzone} from "./dropzone"
import {gql, useMutation} from "@apollo/client";
import Loading from "../../loading";
import {multipleUploadMutation} from "../../../dtos/media.dto";
import Alert from "../../alert";
import {MarkdownEditor} from "./markdown-editor";
import {CreditableMutationTypes, UrlRegex} from "../../../dtos/partnership.dto";
import {Link} from "./link";
import {CancelToken} from "axios";
import {imageMimeTypes, videoMimeTypes} from "../../../dtos/mime-types.dto";
import {File, LoadingMode} from "../post-modal";
import ProgressButton from "../../progress-button";

interface Properties {
    type: CreditableMutationTypes;
    requested: () => void;
    cancelToken: CancelToken;
    setLoading: (loading: boolean) => void;
    requiresApproval?: boolean;
}

export const Input: React.FC<Properties> = ({ type, requested, cancelToken, setLoading, requiresApproval }) => {
    const isPost = type === CreditableMutationTypes.REGULAR_POST || type === CreditableMutationTypes.PINNED_POST
    const [files, setFiles] = useState<any[]>([]);
    const [content, setContent] = useState('');

    const [mutate, { loading }] = useMutation(gql(`
        mutation createMarketingItem${type}($input: create${isPost ? 'Post' : 'Banner'}Input!) {
            createMarketingItem${type}(input: $input) {
                marketingItem {
                    id
                }
            }
        }
    `));

    const [loadingMode, setLoadingMode] = useState<LoadingMode>(LoadingMode.LINEAR);
    const [uploadProgress, setUploadProgress] = useState<number | null>();

    const [
        fileMutate,
        { loading: fileLoading },
    ] = useMutation(multipleUploadMutation, {
        context: {
            fetchOptions: {
                cancelToken,
                onUploadProgress: (progress: ProgressEvent) => setUploadProgress(Math.round((progress.loaded * 100) / progress.total))
            },
        },
    });

    const isLoading = loading || fileLoading;
    const [error, setError] = useState<string>();

    const getData = (media: any[], videos?: any[]) => (
        isPost ?
        {
            content,
            // Add media, when required
            ...(media && media.length ? { media } : {}),
            // Add videos, when required
            ...(videos && videos.length ? { videos } : {})
        } :
        {
            url: content,
            image: media[0]
        }
    )

    const getHint = () => {
        const base = (width, height) => `It should have the aspect ratio and dimensions of at least ${width} x ${height} pixels`;
        if (type === CreditableMutationTypes.BANNER) return base('720', '180')
        if (type === CreditableMutationTypes.TOP_BANNER) return base('1280', '320')
    }

    const submit = async () => {
        // Clear error
        setError(undefined);

        // Switch loading mode when uploading video's
        setLoadingMode(
            files.some((file) => videoMimeTypes.includes(file.type))
            ? LoadingMode.PROGRESSIVE
            : LoadingMode.LINEAR
        );

        // Backend creation of post
        try {
            // Validation checks
            if (isPost && (!content || content.length < 5)) throw new Error('Please provide content (at least 5 characters)')
            if (!isPost && files.length !== 1) throw new Error('Please provide a valid image')
            if (!isPost && !UrlRegex.test(content)) throw new Error('Please provide a valid URL')

            // Mutate files to upload
            const { data: mutatedFiles } = files.length ?
                await fileMutate({ variables: { files: files } }) :
                { data: { multipleUpload: [] } };

            // Extract images and videos
            const images = mutatedFiles.multipleUpload
                ?.filter((file: File) => imageMimeTypes.includes(file.mime))
                .map((file: File) => file.id);

            const videos = mutatedFiles.multipleUpload
                ?.filter((file: File) => videoMimeTypes.includes(file.mime))
                .map((file: File) => ({ media: file.id }));

            // Mutate actual post
            await mutate({ variables: { input: { data: getData(images, videos) } } });

            // Set requested
            requested();
        } catch (error) {
            setError(error.message);
            setUploadProgress(null);
        }
    }

    useEffect(() => { setLoading(!!uploadProgress) }, [uploadProgress])

    return isLoading && loadingMode !== LoadingMode.PROGRESSIVE ?
        <Loading /> : (
        <>
            <Dropzone
                title={isPost ? 'MEDIA' : 'BANNER'}
                initialFiles={files}
                onFileChange={(files) => setFiles(files)}
                maxFiles={!isPost ? 1 : 10} // Soft limit
                hint={getHint()}
                allowVideos={isPost}
            />

            {
                isPost ?
                <MarkdownEditor
                    initialContent={content}
                    onContentChange={(content) => setContent(content)}
                /> :
                <Link
                    initialContent={content}
                    onContentChange={(content) => setContent(content)}
                />
            }

            <div className="modal-actions">
                {error && <Alert message={error} style="danger" />}

                <ProgressButton
                    disabled={isLoading}
                    disableLoader={loadingMode === LoadingMode.LINEAR}
                    progress={uploadProgress}
                    onClick={() => submit()}
                >
                    {requiresApproval ? 'Request' : 'Create'}
                </ProgressButton>
            </div>
        </>
    )
}
