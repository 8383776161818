import { UserDto, userFields } from "./user.dto";
import { MediaDto, mediaFields } from "./media.dto";
import { SlotDto } from "./slot.dto";
import { CasinoDto } from "./casino.dto";
import { CommentDto } from "./comment.dto";
import { gql } from "@apollo/client";
import { IOwnableDto } from "../context/permission.context";
import { VideoDto } from "./video.dto";

export interface PostDto extends IOwnableDto {
  id?: string;
  content?: string;
  giveaway?: string;

  media?: MediaDto[];
  videos?: VideoDto[];

  isPinned?: boolean;

  slot?: SlotDto;
  casino?: CasinoDto;

  win?: number;
  bet?: number;
  multiplier?: number;

  showOnHomepage?: boolean;
  showOnHighlights?: boolean;

  comments?: CommentDto[];
  commentsLocked?: boolean;

  processed?: boolean;

  partner?: UserDto;

  createdAt?: Date;
}

export const postMultiplier = (post: PostDto) => {
  return Math.round((post.win || 0) / (post.bet || 1));
};

export const postSticker = (multiplier: number) => {
  const stickers = [
    { multi: 100, sticker: "NiceHit" },
    { multi: 200, sticker: "BigWin" },
    { multi: 500, sticker: "MegaWin" },
    { multi: 1000, sticker: "UltraWin" },
    { multi: 2500, sticker: "HumongousWin" },
  ];
  const match = stickers.reverse().find((st) => multiplier >= st.multi);

  return match ? match.sticker : null;
};

export const postFields = `
      id
      content
      win
      bet
      multiplier
      createdAt
      showOnHomepage
      showOnHighlights
      owner {
        ${userFields}
      }
      partner {
        id
      }
      slot {
        id
        name
      }
      casino {
        id
        name
        logo {
          ${mediaFields}
        }
      }
      media {
        ${mediaFields}
      }
      videos {
        providerId
        media {
          ${mediaFields}
        }
      }
      commentsLocked
`;

export const createPostMutation = gql`
  mutation createPostMutation($input: createPostInput!) {
    createPost(input: $input) {
      post {
        id
      }
    }
  }
`;

export const updatePostMutation = gql`
  mutation updatePostMutation($input: updatePostInput!) {
    updatePost(input: $input) {
      post {
        id
      }
    }
  }
`;

export const deletePostMutation = gql`
  mutation deletePostMutation($id: ID!) {
    deletePost(input: { where: { id: $id } }) {
      post {
        id
      }
    }
  }
`;
