import * as React from "react";

interface Props {
  mode: "show" | "hide"
  onClick: () => void;
}

export const InputButtonShowHide = ({ mode, onClick }: Props) => (
  <button className="input-button" onClick={(e) => { e.preventDefault(); onClick();}}>
    {mode === 'show' ? <i className="icon-hide" /> : <i className="icon-show" />}
  </button>
)