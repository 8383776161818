import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import CommentCreator from "./comment-creator";
import Loading from "./loading";
import { Link } from "gatsby";
import {
  CommentDto,
  commentFields,
  deleteCommentMutation,
} from "../dtos/comment.dto";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AggregateDto, AggregateTypedDto } from "../dtos/aggregate.dto";
import { AuthContext } from "../context/auth.context";
import {
  createReactionMutation,
  deleteReactionMutation,
  ReactionDto,
  reactionFields,
} from "../dtos/reaction.dto";
import { prettyCounter } from "../utils/counter";
import { first } from "lodash";
import { PermissionContext } from "../context/permission.context";
import MediaImage from "./media-image";
import { balanceCheck } from "../utils/balance";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarConfig } from "../utils/snackbar";
import { arrayWithout } from "../utils/array";
import { useConfirmModal } from "./modals/confirm-modal.context";
import { useLoginModal } from "./modals/login-modal.context";
import VerifiedBadge from "./verified-badge";

interface CommentProps {
  postId?: string;
  partnerId?: string;
  comment: CommentDto;
  hasChilds: boolean;
  onDeleted?: () => void | Promise<void>;
  allowInteractions: boolean;
}

const commentQuery = gql`
  query commentQuery($id: ID!, $start: Int!, $limit: Int!, $hasNoChilds: Boolean!, $isGuest: Boolean!, $userId: ID) {
    commentsConnection(start: $start, limit: $limit, where: { comment: $id, active_ne: false }, sort:"createdAt:ASC") @skip(if: $hasNoChilds) {
      values {
        ${commentFields}
      }
      aggregate {
        count
      }
    }
    reactionsConnection(where: { comment: $id, active_ne: false }) {
      aggregate {
        count
      }
    }
    reaction: reactions(limit: 1, where: { owner: $userId, comment: $id, active_ne: false }) @skip(if: $isGuest) {
      ${reactionFields}
    }
  }
`;

const Comment: React.FC<CommentProps> = ({
  postId,
  partnerId,
  comment,
  hasChilds,
  onDeleted,
  allowInteractions,
}) => {
  const commentBatchCount = 10;
  const authContext = useContext(AuthContext);
  const permissionContext = useContext(PermissionContext);
  const [openSnackbar] = useSnackbar(snackbarConfig);
  const { openConfirmModal } = useConfirmModal();
  const { runOrOpenLoginModal } = useLoginModal();

  const makeVariables = (start: number, limit: number) => ({
    start,
    limit,
    id: comment.id,
    hasNoChilds: !hasChilds,
    isGuest: !authContext.userId,
    userId: authContext.userId,
  });

  const {
    data: interactionsData,
    loading: interactionsLoading,
    fetchMore,
  } = useQuery<{
    commentsConnection: AggregateTypedDto<CommentDto>;
    reactionsConnection: AggregateDto;
    reaction: ReactionDto[];
  }>(commentQuery, {
    variables: makeVariables(0, 1),
  });

  const [comments, setComments] = useState<CommentDto[]>([]);
  const [commentsDisplay, setCommentsDisplay] = useState<CommentDto[]>([]);
  const [commentsVisibility, setCommentsVisibility] = useState<string>();
  const [creatorFocusOn, setCreatorFocusOn] = useState<string>();
  const [createReaction] = useMutation(createReactionMutation);
  const [deleteReaction] = useMutation(deleteReactionMutation);
  const [deleteComment] = useMutation(deleteCommentMutation);
  const [emulatedReaction, setEmulatedReaction] = useState(false);

  const hasReaction = () => {
    return (interactionsData?.reaction?.length || 0) > 0;
  };

  const viewMoreComments = (limit?: number) => {
    return fetchMore({
      variables: makeVariables(
        comments.length || 0,
        limit || commentBatchCount
      ),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setComments([
          ...comments,
          ...(fetchMoreResult.commentsConnection.values || []),
        ]);

        return prev;
      },
    });
  };

  const refetchReactions = () => {
    return fetchMore({
      variables: makeVariables(0, 1),
      updateQuery: (prev, { fetchMoreResult }) => {
        setEmulatedReaction(false);

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          reaction: fetchMoreResult.reaction,
          reactionsConnection: fetchMoreResult.reactionsConnection,
        });
      },
    });
  };

  const toggleReaction = async () => {
    if (!hasReaction()) {
      setEmulatedReaction(true);

      await createReaction({
        variables: {
          input: {
            owner: authContext.userId,
            comment: comment.id,
          },
        },
      });

      await balanceCheck(authContext, openSnackbar);
    } else {
      await deleteReaction({
        variables: {
          id: first(interactionsData?.reaction)?.id,
        },
      });
    }

    await refetchReactions();
  };

  const startComposing = () => {
    setCreatorFocusOn(Math.random().toString());
  };

  const totalComments = () =>
    interactionsData?.commentsConnection?.aggregate?.count || 0;

  const commentsLeft = () => {
    return totalComments() - comments.length;
  };

  const reactions = () => {
    return interactionsData?.reactionsConnection.aggregate.count || 0;
  };

  const commentsVisible = () => !!commentsVisibility;

  const requestDisplayUpdate = (override?: boolean) => {
    const toggle = override !== undefined ? override : commentsVisible();
    const value = toggle ? Math.random().toString() : undefined;

    setCommentsVisibility(value);
  };

  const toggleVisibility = async (override?: boolean) => {
    const newValue = override || !commentsVisible();

    if (newValue && commentsLeft() > 0) {
      await viewMoreComments();
    }

    requestDisplayUpdate(newValue);
  };

  const extendReplies = async () => {
    await viewMoreComments();

    requestDisplayUpdate();
  };

  const performDelete = async () => {
    const { data } = await deleteComment({ variables: { id: comment.id } });

    if (data) {
      await onDeleted?.();
    }
  };

  useEffect(() => {
    if (interactionsData) {
      setComments(interactionsData?.commentsConnection?.values || []);
    }
  }, [interactionsData]);

  useEffect(() => {
    setCommentsDisplay(commentsVisible() ? comments : []);
  }, [commentsVisibility]);

  const renderCommentAction = (
    name: string,
    onClick: () => void,
    classOverride?: string
  ) => {
    return (
      <>
        <p
          className={`comment-action clickable ${classOverride}`}
          onClick={onClick}
        >
          {name}
        </p>{" "}
        &bull;{" "}
      </>
    );
  };

  return (
    <>
      <div className={hasChilds ? "" : "slide-in-animation-fast"}>
        <div className="article-comments__item">
          <Link
            to={`/profile/${comment.owner?.id}/`}
            className={`article-comments__image ${
              comment.owner?.avatar ? "" : "avatar-invisible--comment"
            }`}
          >
            <MediaImage
              src={comment.owner?.avatar}
              format="thumbnail"
              isClickable
            />
          </Link>

          <div className="article-comments__content light-border">
            <div className="article-comments__content-title">
              <Link to={`/profile/${comment.owner?.id}/`}>
                {comment.owner?.profileName}
              </Link>
              <VerifiedBadge user={comment.owner} />
            </div>

            <p>{comment.content}</p>

            {reactions() > 0 && (
              <div className="comment-reaction scale-in-animation-fast">
                <i className="icon-thumbs-up"></i> {prettyCounter(reactions())}
              </div>
            )}
          </div>
          <div className="article-comments__meta">
            <div className="article-comments__meta-action">
              {allowInteractions && (
                <>
                  {renderCommentAction(
                    "Like",
                    () => runOrOpenLoginModal(() => toggleReaction()),
                    emulatedReaction || hasReaction()
                      ? "reaction-active-text"
                      : ""
                  )}
                  {hasChilds &&
                    renderCommentAction("Reply", () =>
                      runOrOpenLoginModal(() => startComposing())
                    )}
                  {permissionContext.can(comment, "comment.delete") &&
                    renderCommentAction("Delete", () =>
                      openConfirmModal({
                        title: "Are you sure you want to delete this comment?",
                        onClose: (result) => result && performDelete(),
                      })
                    )}
                </>
              )}

              <Moment fromNow>{comment.createdAt}</Moment>
            </div>
          </div>
        </div>

        {hasChilds && (
          <div className="indent">
            <CommentCreator
              allowed={!!creatorFocusOn}
              focusOn={creatorFocusOn}
              commentId={comment.id}
              postId={postId}
              partnerId={partnerId}
              onCreated={(newComment) => {
                setComments([...comments, newComment]);
                setCommentsDisplay([...commentsDisplay, newComment]);
              }}
            />

            {totalComments() > 0 && (
              <div
                className="more-comment-wrapper fade-in-animation-fast replies"
                style={{ marginBottom: 12 }}
              >
                <p className="more-comments" onClick={() => toggleVisibility()}>
                  {commentsVisibility ? "HIDE" : "SHOW"} {totalComments()}{" "}
                  REPLIES
                </p>
              </div>
            )}

            {commentsDisplay.map((childComment) => (
              <Comment
                key={childComment.id}
                comment={childComment}
                postId={postId}
                hasChilds={false}
                onDeleted={() => {
                  setComments(arrayWithout(childComment, comments));
                  setCommentsDisplay(
                    arrayWithout(childComment, commentsDisplay)
                  );
                }}
                allowInteractions={allowInteractions}
              />
            ))}

            {commentsVisibility && commentsLeft() > 0 && (
              <div className="more-comment-wrapper fade-in-animation-fast">
                <p className="more-comments" onClick={() => extendReplies()}>
                  VIEW{" "}
                  {commentsLeft() > commentBatchCount ? "" : commentsLeft()}{" "}
                  MORE REPLIES
                </p>
              </div>
            )}

            {interactionsLoading && interactionsData && <Loading />}
          </div>
        )}
      </div>
      <div style={{ marginBottom: 12 }}></div>
    </>
  );
};

export default Comment;
