import React from "react";
import { Link } from "gatsby";

export const SidebarMenu = () => (
  <div className="menu">
    <h5 className="menu__title">ABOUT CASINORING</h5>
    <ul className="menu__list">
      <li className="menu__list-item clickable">
        <Link to="/contact">Contact</Link>
      </li>
      <li className="menu__list-item clickable">
        <Link to="/become-partner">Become a Partner</Link>
      </li>
      <li className="menu__list-item clickable">
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </li>
      <li className="menu__list-item clickable">
        <Link to="/privacy-statement">Privacy Statement</Link>
      </li>
      <li className="menu__list-item clickable">
        <Link to="/cookie-policy">Cookie Policy</Link>
      </li>
      <li className="menu__list-item disabled">
        <a href="#">
          Copyright © {new Date().getFullYear()} CasinoRing
        </a>
      </li>
      <li className="menu__list-item disabled">
        <a href="#">All rights reserved</a>
      </li>
      <li className="menu__list-item">
        <a
          href="https://gamstop.co.uk/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <i className="icon-logo-gamstop"></i>
        </a>
      </li>
      <li className="menu__list-item">
        <a
          href="https://www.begambleaware.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <i className="icon-logo-begambleaware"></i>
        </a>
      </li>
    </ul>
  </div>
)