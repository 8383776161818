import React, { useContext, useEffect } from "react";
import Alert from "../components/alert";
import { SeoContext } from "../context/seo.context";

const browser = typeof window !== "undefined" && window;

const ErrorPage: React.FC<{ default?: boolean }> = () => {
  const seoContext = useContext(SeoContext);
  const message = "We can't seem to find the page you're looking for.";

  useEffect(() => {
    seoContext.setData({
      title: "Something went wrong!",
      description: message,
      noIndex: true,
    });
  }, []);

  return browser ? <Alert message={message} style="danger" /> : <></>;
};

export default ErrorPage;
