import React from "react";
import { CasinoDto } from "../../../dtos/casino.dto";
import MediaImage from "../../media-image";

interface Props {
  data: CasinoDto
}

export const DemoGamesOffersPreview = ({ data }: Props) => (
  <a className="offer" href={data.affiliate.slug!}>
    <MediaImage src={data.logo} format="medium" />
    <div className="wrapper">
      <span className="content">
        {data.bonusOffer}
      </span>
      <div className="action">Play now</div>
    </div>
  </a>
)